(function () {
  'use strict';
  var twoFactorComponent = {
      templateUrl: '/app/modules/auth/twoFactor/2fa.html',
      controller: [
        '$location',
        'BreadCrumbsService',
        'HttpService',
        'AuthDataService',
        function ($location, BreadCrumbsService, HttpService, AuthDataService) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('2fa.2fa'));
          vm.item = _.clone($location.search());
          vm.sendCode = sendCode;
          vm.isComplete = isComplete;
          /**
			 * @desc Check status is_complete all inputs
			 * @param {Boolean} status
			 */
          function isComplete(status) {
            vm.item.is_complete = status;
            if (status) {
              sendCode();
            }
          }
          /**
			 * @desc Action send Code for Verify
			 */
          function sendCode() {
            var obj = {
                email: vm.item.email,
                code: vm.item.confirmation_code,
                remember: vm.item.remember
              };
            HttpService.post('/2fa/validate', obj, function (resp) {
              if (resp.user) {
                BreadCrumbsService.clear();
                AuthDataService.loadModule(resp);
              } else {
                AuthDataService.clearAuth();
              }
            }, function (resp) {
              if (_.has(resp, 'message')) {
                vm.errors = { code: [resp.message] };
              }
            });
          }
        }
      ]
    };
  angular.module('app.twofactor.component', []).component('twoFactorComponent', twoFactorComponent);
  ;
}());