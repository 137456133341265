(function () {
  'use strict';
  splitInputFactory.$inject = ['$splitConstant'];
  angular.module('app.factory.split_input', []).factory('$splitConstant', splitConstantFactory).factory('$splitInput', splitInputFactory);
  ;
  /**
	 * Factory function that creates $splitConstant service.
	 */
  function splitConstantFactory() {
    var self = {
        model: _sniffUserAgent(navigator.userAgent),
        utility: {
          keymap: {
            alt: 18,
            ctrl: 17,
            cmd_ff: 224,
            cmd_other: 91,
            cmd_right: 93,
            esc: 27,
            shift: 16,
            tab: 9,
            del: 8,
            'delete': 46,
            enter: 13,
            left: 37,
            up: 38,
            right: 39,
            down: 40,
            'pageup': 33,
            'pagedown': 34,
            end: 35,
            home: 36,
            space: 32,
            semicolon: 59,
            equals_sign: 187,
            minus_sign: 189,
            comma: 188,
            period: 190,
            left_square_bracket: 219,
            right_square_bracket: 221,
            V: 86,
            a: 65,
            e: 69,
            b: 66,
            k: 75,
            i: 73,
            z: 90,
            y: 89,
            insert: 45
          },
          isNumericKey: function (which) {
            return which >= 48 && which <= 57;
          },
          cmdKey: function (e) {
            if (!e) {
              return false;
            }
            if (self.model.is_mac) {
              return !!e.metaKey;
            }
            return !!e.ctrlKey;
          }
        }
      };
    return self;
    function _isWin7Plus(ua) {
      var matches = ua.match(/Windows NT ([0-9]+\.[0-9]+)\b/);
      if (!matches || matches.length < 2)
        return false;
      return parseInt(matches[1]) >= 6;
    }
    function _getOSXVersion(ua) {
      var matches = ua.match(/(?:Mac OS X )([0-9][0-9]_[0-9]+)(_[0-9])?/);
      if (!matches)
        matches = ua.match(/(?:Mac OS X )([0-9][0-9]\.[0-9]+)(\.[0-9])?/);
      if (!matches || !matches[1])
        return 0;
      return matches[1].replace('_', '.');
    }
    function _sniffUserAgent(ua) {
      var is_mac = /(OS X)/g.test(ua), is_win = ua.indexOf('Windows') !== -1, is_lin = ua.indexOf('Linux') !== -1, is_iOS = /(iPad|iPhone|iPod)/g.test(ua), is_android = /(Android)/g.test(ua), is_edge = /(Edge)/g.test(ua), is_chrome = /(Chrome)/g.test(ua) && !is_edge, is_chrome_mobile = is_chrome && (is_iOS || is_android), is_our_app = /(Slack)/g.test(ua), is_IE = /(MSIE|Trident)/g.test(ua), is_FF = /(Firefox)/g.test(ua), is_safari = /(Safari)/g.test(ua), is_electron = /(AtomShell)/g.test(ua), is_old_ie = /(MSIE)/g.test(ua);
      return {
        is_iOS: is_iOS,
        is_IE: is_IE,
        is_FF: is_FF,
        is_chrome_desktop: is_chrome && !is_chrome_mobile && !is_our_app,
        is_chrome_mobile: is_chrome_mobile,
        is_safari_desktop: is_safari && !is_chrome && is_mac && !is_iOS,
        is_mac: is_mac,
        mac_version: _getOSXVersion(ua) || undefined,
        is_win: is_win,
        is_win_7_plus: _isWin7Plus(ua),
        is_lin: is_lin,
        is_our_app: is_our_app,
        is_electron: is_electron,
        is_old_ie: is_old_ie
      };
    }
  }
  function splitInputFactory($splitConstant) {
    var _$focused, _focused_index, $splitInput = {};
    // Factory current splitInput object
    /**
		 * Function that bind container inputs.
		 */
    $splitInput.make = function ($container) {
      _bindContainerInputs($container);
    };
    /**
		 * Function that get value group input
		 */
    $splitInput.value = function ($container) {
      return _getContainerValue($container);
    };
    /**
		 * Function that check status isComplete
		 */
    $splitInput.isComplete = function ($container) {
      return _isComplete($container);
    };
    return $splitInput;
    // private method _isComplete
    function _isComplete($container) {
      var $inputs = $container.find('input'), is_complete = true;
      if (!$inputs) {
        return false;
      }
      $inputs.each(function (index, $input) {
        if (!$input.value) {
          is_complete = false;
          return false;
        }
      });
      return is_complete;
    }
    // private method _setContainerValue
    function _setContainerValue($container, value) {
      var $inputs = $container.find('input'), num_inputs_left = $inputs.length - _focused_index;
      value = value.substring(0, num_inputs_left);
      _.forEach(value.split(''), function (int_string) {
        _$focused.val(int_string);
        _moveRight($inputs);
      });
    }
    // private method _getContainerValue
    function _getContainerValue($container) {
      var $inputs = $container.find('input'), val = '';
      _.forEach($inputs, function ($input) {
        val += $input.value;
      });
      return val;
    }
    // private method _blurHandler
    function _blurHandler() {
      var $element = $(this);
      if (_$focused === $element)
        _$focused = $('');
      $element.parent('.group-item').removeClass('focused');
      $element.off('blur', _blurHandler);
    }
    // private method _moveLeft focus
    function _moveLeft($inputs) {
      if (_focused_index <= 0) {
        return false;
      }
      var $prev_element = $($inputs[_focused_index - 1]);
      if ($prev_element) {
        $prev_element.focus().val($prev_element.val());
      }
    }
    // private method _moveRight focus
    function _moveRight($inputs) {
      if (_focused_index >= $inputs.length - 1) {
        return false;
      }
      var $next_element = $($inputs[_focused_index + 1]);
      if ($next_element) {
        $next_element.focus().val($next_element.val());
      }
    }
    // private method _onCharDeleted ($splitConstant.utility.keymap.del)
    function _onCharDeleted($inputs) {
      if (!_$focused.val()) {
        _moveLeft($inputs);
      }
    }
    // private method _onCharAdded after keypress input
    function _onCharAdded($inputs) {
      if (_$focused.val() && _$focused.is($inputs.last())) {
        return false;
      }
      _$focused.val('').one('input', function () {
        _moveRight($inputs);
      });
    }
    // private method _bindContainerInputs
    function _bindContainerInputs($container) {
      var $inputs = $container.find('input');
      $container.on('keydown', 'input', function (e) {
        switch (e.keyCode) {
        case $splitConstant.utility.keymap.del:
          return _onCharDeleted($inputs);
        case $splitConstant.utility.keymap.left:
          e.preventDefault();
          return _moveLeft($inputs);
        case $splitConstant.utility.keymap.right:
          e.preventDefault();
          return _moveRight($inputs);
        }
      }).on('keypress', 'input', function (e) {
        if ($splitConstant.utility.isNumericKey(e.which)) {
          return _onCharAdded($inputs);
        }
        if (e.charCode && !$splitConstant.utility.cmdKey(e))
          e.preventDefault();
      });
      // event handlers focus input
      $container.on('focus', 'input', function () {
        _$focused = $(this);
        _focused_index = $inputs.index(_$focused);
        _$focused.parent('.group-item').addClass('focused');
        _$focused.on('blur', _blurHandler);
      });
      // event handlers paste input
      $container.on('paste', 'input', function (e) {
        e.preventDefault();
        e = e.originalEvent || e;
        var clipboard_data = window.clipboardData || e.clipboardData, clipboard_text = clipboard_data.getData('text') || clipboard_data.getData('text/plain');
        clipboard_text = clipboard_text.replace(/\D/g, '');
        _setContainerValue($container, clipboard_text);
        $(this).trigger('input');
      });
    }
  }
}());