(function () {
  'use strict';
  var d3Progress = {
      bindings: { endPercent: '<' },
      templateUrl: '/app/modules/shared/components/d3/d3-progress.html',
      controller: function () {
        var vm = this;
        var colors = { 'pink': '#f4a9ca' };
        var color = colors.pink, radius = 80, border = 3, padding = 10, startPercent = 0, endPercent = 0, arc, parent, svg, defs, filter;
        vm.$onInit = function () {
          endPercent = vm.endPercent / 100;
          var twoPi = Math.PI * 2, boxSize = (radius + padding) * 2, count = Math.abs((endPercent - startPercent) / 0.01), step = endPercent < startPercent ? -0.01 : 0.01;
          arc = d3.svg.arc().startAngle(0).innerRadius(radius).outerRadius(radius - border);
          parent = d3.select('div#d3-progress');
          svg = parent.append('svg').attr('width', boxSize).attr('height', boxSize);
          defs = svg.append('defs');
          filter = defs.append('filter').attr('id', 'blur');
          filter.append('feGaussianBlur').attr('in', 'SourceGraphic').attr('stdDeviation', '2');
          var g = svg.append('g').attr('transform', 'translate(' + boxSize / 2 + ',' + boxSize / 2 + ')');
          var meter = g.append('g').attr('class', 'progress-meter');
          meter.append('path').attr('class', 'background').attr('fill', '#fff').attr('fill-opacity', 0.2).attr('d', arc.endAngle(twoPi));
          var foreground = meter.append('path').attr('class', 'foreground').attr('fill', color).attr('fill-opacity', 1).attr('stroke', color).attr('stroke-width', 0).attr('stroke-opacity', 1).attr('filter', 'url(#blur)');
          var front = meter.append('path').attr('class', 'foreground').attr('fill', color).attr('fill-opacity', 1);
          function updateProgress(progress) {
            foreground.attr('d', arc.endAngle(twoPi * progress));
            front.attr('d', arc.endAngle(twoPi * progress));
          }
          var progress = startPercent;
          (function loops() {
            updateProgress(progress);
            if (count > 0) {
              count--;
              progress += step;
              setTimeout(loops, 10);
            }
          }());
        };
      }
    };
  var d3Spline = {
      bindings: {
        data: '<',
        tooltip: '<',
        color: '<?',
        instance: '&?',
        id: '@'
      },
      template: '',
      controller: [
        'browserInfo',
        function (browserInfo) {
          'ngInject';
          var vm = this, chart, isMobile = browserInfo.isMobile(), tooltip = vm.tooltip || {
              show: !isMobile,
              format: {
                value: function (value) {
                  var format = d3.format('$');
                  return format(value);
                }
              }
            }, color = vm.color || {
              income: '#7489e3',
              expense: '#f49454',
              net_income: '#49a65e'
            };
          vm.$onInit = function () {
            chart = c3.generate({
              bindto: '#' + vm.id,
              size: { height: 270 },
              legend: { show: false },
              interaction: { enabled: !isMobile },
              transition: { duration: isMobile ? 0 : 350 },
              data: {
                x: 'x',
                columns: vm.data,
                type: 'area-spline'
              },
              axis: {
                y: { show: false },
                x: {
                  type: 'timeseries',
                  tick: { format: '%Y-%m' }
                }
              },
              padding: {
                bottom: 5,
                left: 10,
                right: 25,
                top: 20
              },
              tooltip: tooltip,
              oninit: function () {
                generateGradient('#d3Financials svg');
              },
              onrendered: function () {
                var svg = d3.select('#d3Financials svg')[0][0];
                if (!_.isNull(svg)) {
                  addGradient(svg);
                }
              }
            });
            if (vm.instance) {
              return vm.instance({ chart: chart });
            }
            function linearGradient(e, t, n) {
              var linear = n.append('linearGradient').attr('id', e).attr('x1', '100%').attr('y1', '50%').attr('x2', '100%').attr('y2', '100%');
              linear.append('stop').attr('offset', '0%').attr('stop-color', t).attr('stop-opacity', 1);
              linear.append('stop').attr('offset', '100%').attr('stop-color', t).attr('stop-opacity', 0);
            }
            function generateGradient(e) {
              var defs = d3.select(e).select('defs');
              linearGradient('incomeGradient', color.income, defs);
              linearGradient('expenseGradient', color.expense, defs);
              linearGradient('netIncomeGradient', color.net_income, defs);
            }
            function addGradient(e) {
              var elIncome = e.querySelector('.c3-target-income .c3-areas path'), elExpense = e.querySelector('.c3-target-expense .c3-areas path'), elNetIncome = e.querySelector('.c3-target-net-income .c3-areas path');
              if (elIncome && elExpense && elNetIncome) {
                var income = elIncome.style, expense = elExpense.style, netIncome = elNetIncome.style;
                income.fill = 'url("#incomeGradient")';
                income.opacity = 0.4;
                expense.fill = 'url("#expenseGradient")';
                expense.opacity = 0.4;
                netIncome.fill = 'url("#netIncomeGradient")';
                netIncome.opacity = 0.4;
              }
            }
          };
          vm.$onDestroy = function () {
            chart.destroy();
          };
        }
      ]
    };
  var d3AuthActivity = {
      bindings: {
        data: '<',
        tooltip: '<',
        instance: '&?',
        id: '@',
        dateFormat: '@'
      },
      template: '',
      controller: [
        'browserInfo',
        function (browserInfo) {
          'ngInject';
          var vm = this, chart, isMobile = browserInfo.isMobile(), tooltip = vm.tooltip || {
              show: !isMobile,
              format: {
                value: function (value) {
                  var format = d3.format('$');
                  return format(value);
                }
              }
            }, color = { global: '#7489e3' };
          vm.$onInit = function () {
            chart = c3.generate({
              bindto: '#' + vm.id,
              size: { height: 280 },
              legend: { show: false },
              interaction: { enabled: !isMobile },
              transition: { duration: isMobile ? 0 : 350 },
              data: {
                x: 'x',
                columns: vm.data,
                type: 'area-spline'
              },
              axis: {
                y: { show: false },
                x: {
                  type: 'timeseries',
                  tick: {
                    format: function (x) {
                      return moment(x).format(vm.dateFormat || 'DD, MMMM');
                    }
                  }
                }
              },
              padding: {
                bottom: 5,
                left: 15,
                right: 15,
                top: 5
              },
              tooltip: tooltip,
              oninit: function () {
                generateGradient('#' + vm.id + ' svg');
              },
              onrendered: function () {
                var svg = d3.select('#' + vm.id + ' svg')[0][0];
                if (!_.isNull(svg)) {
                  addGradient(svg);
                }
              }
            });
            if (vm.instance) {
              return vm.instance({ chart: chart });
            }
            function linearGradient(e, t, n) {
              var linear = n.append('linearGradient').attr('id', e).attr('x1', '100%').attr('y1', '50%').attr('x2', '100%').attr('y2', '100%');
              linear.append('stop').attr('offset', '0%').attr('stop-color', t).attr('stop-opacity', 1);
              linear.append('stop').attr('offset', '100%').attr('stop-color', t).attr('stop-opacity', 0);
            }
            function generateGradient(e) {
              var defs = d3.select(e).select('defs');
              linearGradient('gradient', color.global, defs);
            }
            function addGradient(e) {
              var elCount = e.querySelector('.c3-target-count .c3-areas path');
              if (elCount) {
                var count = elCount.style;
                count.fill = 'url("#gradient")';
                count.opacity = 0.85;
              }
            }
          };
          vm.$onDestroy = function () {
            chart.destroy();
          };
        }
      ]
    };
  var d3Donut = {
      bindings: {
        data: '<',
        color: '<?',
        tooltip: '<?',
        donut: '<?',
        size: '<?',
        instance: '&?',
        id: '@'
      },
      template: '',
      controller: [
        'browserInfo',
        function (browserInfo) {
          'ngInject';
          var vm = this, chart, isMobile = browserInfo.isMobile(), defaultColor = vm.color || [
              '#7489e3',
              '#f49454'
            ], size = vm.size || 180, tooltip = vm.tooltip || {
              show: !isMobile,
              format: {
                value: function (value) {
                  return value;
                }
              }
            }, donut = vm.donut || {
              expand: !isMobile,
              width: 4,
              label: { show: false }
            };
          vm.$onInit = function () {
            chart = c3.generate({
              bindto: '#' + vm.id,
              size: { height: size },
              interaction: { enabled: !isMobile },
              transition: { duration: isMobile ? 0 : 350 },
              color: { pattern: defaultColor },
              data: {
                columns: vm.data,
                type: 'donut'
              },
              legend: { show: false },
              axis: { x: { show: false } },
              donut: donut,
              tooltip: tooltip
            });
            if (vm.instance) {
              return vm.instance({ chart: chart });
            }
          };
          vm.$onDestroy = function () {
            chart.destroy();
          };
        }
      ]
    };
  angular.module('app.d3.components', []).component('d3Progress', d3Progress).component('d3Spline', d3Spline).component('d3AuthActivity', d3AuthActivity).component('d3Donut', d3Donut);
  ;
}());