(function () {
  'use strict';
  var fieldsetPets = {
      bindings: { pets: '<' },
      templateUrl: '/app/modules/shared/components/fieldsets/pets.html',
      controller: function () {
        var vm = this;
        vm.removeBlock = removeBlock;
        vm.addBlock = addBlock;
        /**
			 * remove pets block
			 * @param key
			 */
        function removeBlock(key) {
          vm.pets.splice(key, 1);
        }
        /**
			 * add pets block
			 */
        function addBlock() {
          vm.pets.push({});
        }
      }
    };
  var fieldsetVehicles = {
      bindings: {
        vehicles: '=',
        checkIfRequired: '&',
        notApplicable: '<',
        requiredForApplication: '<',
        errors: '<?'
      },
      templateUrl: '/app/modules/shared/components/fieldsets/vehicles.html',
      controller: [
        'Notify',
        function (Notify) {
          'ngInject';
          var vm = this;
          vm.removeBlock = removeBlock;
          vm.addBlock = addBlock;
          vm.canRemoveBlock = canRemoveBlock;
          vm.notApplicableChanged = notApplicableChanged;
          vm.getVehiclesError = getVehiclesError;
          function removeBlock(key) {
            if (vm.requiredForApplication && vm.vehicles.length === 1) {
              Notify.error(Lang.get('notify_actions.landlord.applications.can_not_remove'));
            } else {
              vm.vehicles.splice(key, 1);
            }
          }
          function addBlock() {
            vm.vehicles.push({});
          }
          function canRemoveBlock(key) {
            return !(vm.requiredForApplication && key === 0);
          }
          function notApplicableChanged() {
            vm.vehicles = vm.notApplicable ? [] : [{}];
          }
          /**
			 * @namespace getVehiclesError
			 * @desc Get errors for vehicles
			 * @param {Number} index Index of each item
			 * @memberOf fieldsetVehicles
			 */
          function getVehiclesError(index) {
            if (!_.isUndefined(vm.errors)) {
              return _.head(vm.errors['vehicles.' + index]);
            }
          }
        }
      ]
    };
  var fieldsetInsurance = {
      bindings: { insurance: '=' },
      templateUrl: '/app/modules/shared/components/fieldsets/insurance.html',
      controller: [
        '$filter',
        'array_util',
        function ($filter, array_util) {
          'ngInject';
          var vm = this;
          vm.removeBlock = removeBlock;
          vm.addBlock = addBlock;
          vm.showInsuranceFieldset = !!(vm.insurance && vm.insurance.id);
          vm.insuranceNotificationPeriod = array_util.partition($filter('trans_as_array')('form.insurance.notification_period.options'), 1);
          // remove pets block
          function removeBlock() {
            delete vm.insurance;
            vm.showInsuranceFieldset = false;
          }
          // add pets block
          function addBlock() {
            vm.insurance = {};
            vm.showInsuranceFieldset = true;
          }
        }
      ]
    };
  var fieldsetNotes = {
      bindings: { item: '<' },
      templateUrl: '/app/modules/shared/components/fieldsets/notes.html',
      controller: function () {
        var vm = this;
        vm.ui = {
          notes: {
            is_visible: vm.item.notes ? vm.item.notes : false,
            show: function () {
              this.is_visible = true;
            },
            hide: function () {
              this.is_visible = false;
              vm.item.notes = null;
            }
          }
        };
      }
    };
  /**
	 * Template view property info
	 * Example:
	 *
	 * <fieldset-property-info
	 		property="{object}"
	 		address1="{{:: property.address1 }}"
	 		city-address="{{:: property.cityAddress }}"
	 ></fieldset-property-info>
	 */
  var fieldsetPropertyInfo = {
      bindings: {
        stateName: '@',
        stateParams: '<',
        unitStateName: '@',
        unitStateParams: '<',
        title: '@',
        property: '<',
        unitName: '@',
        unitType: '@',
        address1: '@',
        cityAddress: '@',
        setclass: '@',
        unitCount: '@?',
        listingTitle: '@?'
      },
      templateUrl: '/app/modules/shared/components/fieldsets/property-info.html',
      controller: [
        '$state',
        function ($state) {
          'ngInject';
          var vm = this;
          vm.state = $state;
          vm.getPropertyName = getPropertyName;
          /**
			 * Get property name
			 * @returns {*}
			 */
          function getPropertyName() {
            return vm.listingTitle || vm.property.name || vm.property.title;
          }
          /**
			 * helper is single property
			 * @type {{isSingle: fieldsetPropertyInfo.helper.isSingle}}
			 */
          vm.helper = {
            isSingle: function (property) {
              return property.type === 1;
            }
          };
        }
      ]
    };
  /**
	 * Template view profile info
	 * Example:
	 */
  var fieldsetProfileInfo = {
      bindings: {
        stateName: '@?',
        stateParams: '<?',
        messageShow: '<?',
        messageParams: '<?',
        title: '@',
        attr: '<',
        status: '<',
        item: '<',
        setclass: '<',
        verified: '<',
        phone: '<',
        email: '@'
      },
      transclude: { listLease: '?listLease' },
      templateUrl: '/app/modules/shared/components/fieldsets/profile-info.html',
      controller: [
        '$state',
        function ($state) {
          'ngInject';
          var vm = this;
          vm.state = $state;
          // Pro contacts
          if (vm.item && vm.item.role === 'client' && vm.item.email_2) {
            vm.item.email = vm.item.email_2;
          }
          vm.size = vm.attr && vm.attr.size;
          vm.width = vm.attr && vm.attr.width;
        }
      ]
    };
  /**
	 * Template view profile avatar info
	 * Example:
	 */
  var fieldsetImage = {
      bindings: {
        stateName: '@',
        stateParams: '<',
        title: '@',
        attr: '<',
        item: '<'
      },
      templateUrl: '/app/modules/shared/components/fieldsets/image.html',
      controller: [
        '$state',
        function ($state) {
          'ngInject';
          var vm = this;
          vm.state = $state;
          vm.size = vm.attr && vm.attr.size;
          vm.width = vm.attr && vm.attr.width;
        }
      ]
    };
  var fieldsetEmergencyContact = {
      bindings: {
        emergencyContact: '=',
        checkIfRequired: '&',
        requiredForApplication: '<'
      },
      templateUrl: '/app/modules/shared/components/fieldsets/emergency_contact.html'
    };
  /**
	 * Application E-Signature field
	 * <fieldset-application-e-signature></fieldset-application-e-signature>
	 * @type {{bindings: {application: string, isForm: string}, templateUrl: string, controller: controller}}
	 */
  var fieldsetApplicationESignature = {
      bindings: {
        application: '<',
        isForm: '<'
      },
      templateUrl: '/app/modules/shared/components/fieldsets/application-signature.html',
      controller: [
        '$rootScope',
        'HttpService',
        'FileService',
        'APP_CONFIG',
        'ngDialog',
        'Notify',
        function ($rootScope, HttpService, FileService, APP_CONFIG, ngDialog, Notify) {
          'ngInject';
          var vm = this;
          vm.statuses = APP_CONFIG.constants.application_status;
          vm.actions = {
            canSign: canSign,
            showAgree: showAgree
          };
          vm.popupESignature = popupESignatureFn;
          /**
			 * Check can user sign application
			 * @returns {*|boolean|string}
			 */
          function canSign() {
            return vm.application.is_signature_required && vm.isForm;
          }
          /**
			 * Signature popup
			 * @returns {boolean}
			 */
          function popupESignatureFn() {
            if (!canSign()) {
              return false;
            }
            ngDialog.open({
              plain: true,
              template: '<e-signature img-url="{{ $ctrl.imgUrl }}" save="$ctrl.save(data)"></e-signature>',
              controller: function () {
                this.vm = angular.extend({}, vm);
                this.imgUrl = vm.application.tenantSignature ? vm.application.tenantSignature.file_url : '';
                /**
						 * Sign application
						 * @param data
						 */
                this.save = function (data) {
                  if (data) {
                    HttpService.post('/tenant/applications/' + vm.application.id + '/signature', data, function (response) {
                      vm.application.tenantSignature = response.signature;
                      vm.error = false;
                      ngDialog.closeAll();
                    }, function (errors) {
                      if (!_.isUndefined(errors['message'])) {
                        Notify.error(errors['message']);
                      }
                    });
                  }
                };
              },
              controllerAs: '$ctrl'
            });
          }
          /**
			 *
			 * @returns {boolean}
			 */
          function showAgree() {
            return vm.isForm && (vm.application.status === vm.statuses.draft || vm.application.status === vm.statuses.info_requested || vm.application.status === vm.statuses.draft_saved);
          }
          // check signature error
          $rootScope.$on('form:error', function (event, res) {
            vm.error = _.get(res, 'signature.0');
          });
        }
      ]
    };
  angular.module('app.components.fieldsets', []).component('fieldsetPets', fieldsetPets).component('fieldsetVehicles', fieldsetVehicles).component('fieldsetInsurance', fieldsetInsurance).component('fieldsetNotes', fieldsetNotes).component('fieldsetPropertyInfo', fieldsetPropertyInfo).component('fieldsetProfileInfo', fieldsetProfileInfo).component('fieldsetImage', fieldsetImage).component('fieldsetEmergencyContact', fieldsetEmergencyContact).component('fieldsetApplicationESignature', fieldsetApplicationESignature);
  ;
}());