(function () {
  'use strict';
  /**
	 * Applications documents to fill out
	 * <documents-to-fill-out></documents-to-fill-out>
	 * @type {{bindings: {application: string}, templateUrl: string, controller: controller}}
	 */
  var documentsToFillOut = {
      bindings: { application: '<' },
      templateUrl: '/app/modules/shared/components/applications/document-to-fill-out.html',
      controller: [
        '$rootScope',
        'APP_CONFIG',
        function ($rootScope, APP_CONFIG) {
          'ngInject';
          var vm = this;
          if ($rootScope.auth.role === APP_CONFIG.constants.userRoles.landlord) {
            vm.files = $rootScope.auth.application_files;
          } else {
            vm.files = vm.application.property_unit.user_landlord.application_files;
          }
        }
      ]
    };
  angular.module('app.shared_components.applications', []).component('documentsToFillOut', documentsToFillOut);
  ;
}());