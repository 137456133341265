(function () {
  'use strict';
  /**
	 * @namespace dwollaTransfersComponent
	 * @desc Transfers status & activity
	 * @param {Object} transfers
	 */
  var dwollaTransfersComponent = {
      bindings: { transfers: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/dwollaTransfers/view.html',
      controller: [
        '$element',
        'BreadCrumbsService',
        function ($element, BreadCrumbsService) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.payments_view.label'), 'settings.payments_view');
          BreadCrumbsService.addCrumb(Lang.get('dwolla.tabs.transfers'));
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
        }
      ]
    };
  angular.module('app.dwollaTransfers.component', []).component('dwollaTransfersComponent', dwollaTransfersComponent);
  ;
}());