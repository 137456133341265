(function () {
  'use strict';
  /* Fn
	 ============================================================================================================= */
  configure.$inject = ['$stateProvider'];
  CommonCtrl_SuspendedAccount.$inject = ['APP_CONFIG'];
  function configure($stateProvider) {
    $stateProvider.state('common', {
      abstract: true,
      url: '',
      template: '<div ui-view="content" class="full-width" />'
    }).state('common.suspended_account', {
      url: '/suspended_account',
      views: {
        content: {
          controller: 'CommonCtrl.suspended_account as $ctrl',
          templateUrl: '/app/modules/shared/pages/common/suspended_account.html'
        }
      }
    });
    ;
  }
  function CommonCtrl_SuspendedAccount(APP_CONFIG) {
    var vm = this;
    vm.support_email = APP_CONFIG.support_email;
  }
  angular.module('app.shared_common', []).config(configure).controller('CommonCtrl.suspended_account', CommonCtrl_SuspendedAccount);
  ;
}());