(function () {
  'use strict';
  TwoFactorAuthService.$inject = [
    '$state',
    '$rootScope',
    'HttpService',
    'Notify'
  ];
  angular.module('app.services.settings', []).service('TwoFactorAuthService', TwoFactorAuthService);
  ;
  function TwoFactorAuthService($state, $rootScope, HttpService, Notify) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        enable: enable,
        verify: verify
      };
    return service;
    /**
		 *
		 * @returns {*}
		 */
    function enable() {
      return HttpService.put('/settings/2fa/enable', {}, function (resp) {
        return resp.qr_code;
      }, function (err) {
        Notify.error(err.message);
      });
    }
    /**
		 *
		 * @param code
		 * @param redirectState
		 */
    function verify(code, redirectState) {
      if (!redirectState) {
        redirectState = 'settings.profile_view';
      }
      HttpService.post('/settings/2fa/verify', {
        code: code,
        email: $rootScope.auth.email
      }, function () {
        $state.go(redirectState);
      });
    }
  }
}());