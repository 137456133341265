(function () {
  'use strict';
  configure.$inject = ['$stateProvider'];
  angular.module('app.payments.states', ['app.payments.components']).config(configure);
  ;
  /* Fn
	 ============================================================================================================= */
  function configure($stateProvider) {
    $stateProvider.state('settings.payments_view', {
      url: '/payments',
      views: { content: { component: 'settingsPaymentsMain' } }
    }).state('settings.payments_edit', {
      url: '/payments/{gateway:paypal|stripe}',
      views: {
        content: {
          component: 'settingsPaymentsEdit',
          bindings: { item: 'config.data' }
        }
      },
      resolve: {
        config: [
          'HttpService',
          '$stateParams',
          function (HttpService, $stateParams) {
            return HttpService.get('/settings/payments/' + $stateParams.gateway);
          }
        ]
      }
    });
  }
}());