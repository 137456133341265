(function () {
  'use strict';
  angular.module('app.services.subscription', []).service('SubscriptionService', SubscriptionService);
  ;
  function SubscriptionService() {
    var prefix = '_annual', packages = [], user;
    var service = {
        setUser: setUser,
        setPackages: setPackages,
        getActivePlan: getActivePlan,
        isActivePackageDefault: isActivePackageDefault,
        getActivePackage: getActivePackage,
        getActivePackageData: getActivePackageData,
        everSubscribed: everSubscribed,
        isSubscribed: isSubscribed,
        pkg: new Pkg()
      };
    return service;
    /**
		 * Setup current user
		 *
		 * @param userObj User instance
		 */
    function setUser(userObj) {
      user = userObj;
    }
    /**
		 * Set packages data
		 * @param packagesArray
		 */
    function setPackages(packagesArray) {
      packages = packagesArray;
    }
    /**
		 * Get active plan slug from auth object
		 *
		 * @returns {null}
		 */
    function getActivePlan() {
      return user.subscriptions.length ? user.subscriptions[0].stripe_plan : _getDefaultPkg().slug;
    }
    /**
		 * Return whether active package is default
		 *
		 * @returns {boolean}
		 */
    function isActivePackageDefault() {
      var activePackage = service.getActivePackage();
      return activePackage ? !!activePackage.is_default : false;
    }
    /**
		 * Get active package for current user
		 *
		 * @returns {*}
		 */
    function getActivePackage() {
      return service.pkg.getBySlug();
    }
    /**
		 * Get active package data.
		 *
		 * @param key Accepts price or title
		 * @returns {string}
		 */
    function getActivePackageData(key) {
      var activePackage = service.getActivePackage();
      return activePackage ? activePackage[key] : '';
    }
    /**
		 * Check if user ever had any subscription
		 *
		 * @returns {boolean}
		 */
    function everSubscribed() {
      return !!user.stripe_id;
    }
    /**
		 * Just return if user has active subscription
		 *
		 * @returns {boolean}
		 */
    function isSubscribed() {
      return user.subscription && !!user.subscription.isSubscribed;
    }
    /**
		 * Helper method to get default package
		 * @returns {*}
		 * @private
		 */
    function _getDefaultPkg() {
      return _.find(packages, { 'is_default': 1 });
    }
    /**
		 * PACKAGES METHODS
		 */
    function Pkg() {
      var self = this;
      /**
			 * Generate slug with annual prefix
			 * @param slug
			 * @returns {string}
			 */
      this.generateAnnualSlug = function (slug) {
        return slug + prefix;
      };
      /**
			 * Generate regular slug from annual
			 * @param slug
			 */
      this.generateRegularSlug = function (slug) {
        return slug.slice(0, prefix.length - 1);
      };
      /**
			 * Check if slug name has annual prefix
			 *
			 * @param slug
			 * @returns {boolean}
			 */
      this.isAnnual = function (slug) {
        return slug ? slug.indexOf(prefix) > 0 : false;
      };
      /**
			 * Get annual slug if regular provided and vise versa
			 * @param slug
			 * @returns {string}
			 */
      this.getReverseSlug = function (slug) {
        return self.isAnnual(slug) ? self.generateRegularSlug(slug) : self.generateAnnualSlug(slug);
      };
      /**
			 * Get not annual packages
			 *
			 * @returns {*}
			 */
      this.getRegular = function () {
        return _.filter(packages, { 'is_annual': 0 });
      };
      /**
			 * Check if package name matches needle
			 *
			 * @param pkg
			 * @param matchName
			 * @returns {boolean}
			 */
      this.match = function (pkg, matchName) {
        return pkg.slug === matchName || pkg.slug === self.getReverseSlug(matchName);
      };
      /**
			 * Check if pkg equals to current user active
			 *
			 * @param pkg
			 * @returns {boolean}
			 */
      this.isActive = function (pkg) {
        var active = service.getActivePlan();
        return active === pkg.slug || active === self.getReverseSlug(pkg.slug);
      };
      /**
			 * Get package by slug name.
			 *
			 *
			 * @param slug
			 * @returns {*}
			 */
      this.getBySlug = function (slug) {
        slug = slug || service.getActivePlan();
        return _.find(packages, { 'slug': slug });
      };
      /**
			 * Get package option limit by name
			 *
			 * @param pkg
			 * @param optionName
			 */
      this.getOptionLimit = function (pkg, optionName) {
        return _.find(pkg.options, { 'option': optionName }).limit;
      };
      /**
			 * Returns if user has access to option according to his package options
			 * Available optionName : tc_payments,tenant_match,e_signature,property_board,domain
			 *
			 * @param optionName
			 * @returns {boolean}
			 */
      this.userHasAccess = function (optionName) {
        var option = _.find(user.package_options, { 'option': optionName });
        return option ? !!option.limit : false;
      };
    }
  }
}());