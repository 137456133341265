(function () {
  'use strict';
  ContentService.$inject = ['APP_CONFIG'];
  angular.module('app.services.content', []).service('ContentService', ContentService);
  ;
  function ContentService(APP_CONFIG) {
    var service = {
        loadContent: loadContent,
        currentRole: currentRole
      };
    return service;
    /**
		 * Load content
		 * @param role
		 */
    function loadContent(role) {
      if (role) {
        role = service.currentRole(role);
        service.$contentLoad = APP_CONFIG.load_files[role];
        if (window.cordova && role) {
          service.$contentLoad = [
            'build/' + role + '.min.css',
            'build/' + role + '-vendor.min.js',
            'build/' + role + '-app.min.js'
          ];
        }
      }
    }
    /**
		 *
		 * @param role
		 * @returns {*}
		 */
    function currentRole(role) {
      if (role === 'admin') {
        role = 'landlord';
      }
      if (role === 'landlord_admin') {
        role = 'landlord';
      }
      if (role === 'super') {
        role = 'admin';
      }
      return role;
    }
  }
}());