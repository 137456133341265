(function () {
  'use strict';
  var lazyImg = {
      bindings: {
        imgLarge: '@srcLarge',
        imgSmall: '@srcSmall',
        imgWidth: '@imgWidth'
      },
      transclude: true,
      templateUrl: '/app/modules/shared/components/global/lazy-img.html',
      controller: [
        '$element',
        function ($element) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            var imgSmall = new Image(), imgLarge = new Image(), smallClass = $element.find('.small'), lazyContainer = $element.find('.lazy-img'), img = $element.find('img');
            imgSmall.src = vm.imgSmall;
            imgSmall.onload = function () {
              smallClass.css('opacity', '1');
              lazyContainer.addClass('spinner');
              imgLarge.src = vm.imgLarge;
              imgLarge.onload = function () {
                lazyContainer.removeClass('spinner');
                img.addClass('loaded');
              };
            };
          };
        }
      ]
    };
  var switchLanguage = {
      bindings: { init: '@' },
      templateUrl: '/app/modules/shared/components/global/switch-language.html',
      controller: [
        '$window',
        'LangService',
        'APP_CONFIG',
        function ($window, LangService, APP_CONFIG) {
          'ngInject';
          var vm = this;
          vm.show = APP_CONFIG.showLocalization || false;
          vm.isActive = function (lang) {
            return LangService.isLang(lang);
          };
          vm.setLang = function (lang) {
            LangService.setLang(lang, function () {
              $window.location.reload();
            });
          };
        }
      ]
    };
  var containerOpacity = {
      bindings: { init: '@' },
      templateUrl: '/app/modules/shared/components/global/container-opacity.html',
      controller: function () {
        var vm = this;
        vm.toggle = function () {
          $('[data-pages="sidebar"]').data('pg.sidebar').toggleSidebar();
          return false;
        };
      }
    };
  var loadingBar = {
      bindings: { setclass: '@' },
      template: '<div class="loading-bar--small {{ $ctrl.setclass }}"><div class="loading-bar-inner"><div class="loading-bar-circle-1"></div><div class="loading-bar-circle-2"></div></div></div>'
    };
  var globalFilterTags = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/global/filter-tags.html',
      controller: [
        '$scope',
        '$q',
        'ng_util',
        'HttpService',
        'PagingFilterService',
        'FilterService',
        function ($scope, $q, ng_util, HttpService, PagingFilterService, FilterService) {
          'ngInject';
          var vm = this;
          // init pagination
          PagingFilterService.bind($scope);
          ng_util.definePropertyObj(FilterService, 'filterTags', function (source) {
            if (source) {
              if (source.newValue !== source.oldValue) {
                runFilter(source.newValue);
              }
            }
          });
          runFilter($scope.filter);
          vm.clearOption = function (name, index) {
            vm.options.splice(index, 1);
            PagingFilterService.clearKey(name);
          };
          function runFilter(filter) {
            if (filter && filter.property) {
              var url = '/landlord/property/forFilter', params = {};
              var promise = asyncRequest(HttpService, url, params, $q);
              promise.then(function (obj) {
                if (obj.data && obj.data.list) {
                  obj.data.list[0].name = 'property';
                  vm.options = obj.data.list;
                }
              });
            } else {
              PagingFilterService.clearKey('property');
            }
          }
          function asyncRequest(HttpService, url, params, $q) {
            var deferred = $q.defer();
            HttpService.getWParams(url, { 'params': params }, function (resp) {
              deferred.resolve({ data: resp });
            }, function () {
              deferred.reject();
            });
            return deferred.promise;
          }
        }
      ]
    };
  var ngColumnizer = {
      bindings: {},
      controller: [
        '$timeout',
        function ($timeout) {
          'ngInject';
          $timeout(function () {
            Cargo.Plugins.columnizer.init();
          }, 0);
        }
      ]
    };
  var sessionExpired = {
      templateUrl: '/app/modules/shared/components/global/session-expired.html',
      controller: [
        '$rootScope',
        '$state',
        '$timeout',
        'HttpService',
        'AuthDataService',
        'Notify',
        'SessionExpiresService',
        function ($rootScope, $state, $timeout, HttpService, AuthDataService, Notify, SessionExpiresService) {
          'ngInject';
          var vm = this;
          vm.persistent = 1;
          // Sets remember me by default checked
          vm.active = SessionExpiresService.active;
          vm.login = function () {
            var data = {
                email: $rootScope.auth.email,
                password: vm.password,
                persistent: vm.persistent
              };
            $rootScope.auth_data.rememberMe = vm.persistent;
            HttpService.post('/auth/login', data, function (resp) {
              // Prevent using previous password on next session expired call
              vm.password = null;
              if (resp.twoFactorAuthRequired) {
                if (window.cordova) {
                  window.location.href = 'index.html/#!/two_factor';
                } else {
                  window.location.href = '/two_factor';
                }
              }
              if (resp.status) {
                resp = resp.data;
              }
              if (resp.user) {
                AuthDataService.loadModule(resp);
                angular.element('body').removeClass('session-expires');
                SessionExpiresService.hide();
              }
            }, function (resp) {
              if (resp.message) {
                Notify.error(resp.message);
              }
              if (resp.password) {
                vm.errors = resp.password;
              }
            });
          };
        }
      ]
    };
  /**
	 * Uses to split long text. Shows short text and adds link to open full text
	 * @type {{bindings: {text: string, max: string}, template: string, controller: controller}}
	 */
  var separatedText = {
      bindings: {
        text: '@',
        max: '<?'
      },
      template: '<p ng-bind-html="$ctrl.visibleText"></p><a class="m-btn-link u-textLower" ng-if="$ctrl.showSwitcher()" ng-click="$ctrl.switchText();">{{ $ctrl.getSwitchLinkText() }}</a>',
      controller: function () {
        var vm = this;
        vm.max = vm.max || 400;
        // Default
        vm.shortText = getShortText(vm.text, true);
        vm.visibleText = vm.shortText;
        vm.switchText = switchText;
        vm.getSwitchLinkText = getSwitchLinkText;
        vm.showSwitcher = showSwitcher;
        function switchText() {
          vm.visibleText = vm.text === vm.visibleText ? vm.shortText : vm.text;
        }
        function getSwitchLinkText() {
          return vm.text === vm.visibleText ? Lang.get('general.hide') : Lang.get('general.show_more');
        }
        function showSwitcher() {
          return vm.text.length > parseInt(vm.max);
        }
        /**
			 *
			 * @param value
			 * @param wordWise - if true, cut only by words bounds
			 * @returns {*}
			 */
        function getShortText(value, wordWise) {
          if (!value)
            return '';
          var max = parseInt(vm.max);
          if (!max)
            return value;
          if (value.length <= max)
            return value;
          value = value.substr(0, max);
          if (wordWise) {
            var lastSpace = value.lastIndexOf(' ');
            if (lastSpace !== -1) {
              //Also remove . and , so its gives a cleaner result.
              if (value.charAt(lastSpace - 1) === '.' || value.charAt(lastSpace - 1) === ',') {
                lastSpace = lastSpace - 1;
              }
              value = value.substr(0, lastSpace);
            }
          }
          return value;
        }
      }
    };
  var avatar = {
      bindings: {
        item: '<',
        width: '@',
        size: '@?'
      },
      templateUrl: '/app/modules/shared/components/global/avatar.html',
      controller: function () {
        var vm = this;
        vm.$onChanges = function () {
          vm.src = _.get(vm.item, 'avatar.sizes.' + vm.size + '.url');
        };
      }
    };
  angular.module('app.components.global', []).component('lazyImg', lazyImg).component('switchLanguage', switchLanguage).component('containerOpacity', containerOpacity).component('loadingBar', loadingBar).component('globalFilterTags', globalFilterTags).component('ngColumnizer', ngColumnizer).component('sessionExpired', sessionExpired).component('separatedText', separatedText).component('avatar', avatar);
  ;
}());