(function () {
  'use strict';
  configure.$inject = ['$stateProvider'];
  angular.module('app.filemanager.states', [
    'app.filemanager.services',
    'app.filemanager.components'
  ]).config(configure);
  ;
  /* Fn
	 ============================================================================================================= */
  function configure($stateProvider) {
    $stateProvider.state('file_manager', {
      abstract: true,
      url: '/filemanager',
      templateProvider: [
        '$templateFactory',
        '$rootScope',
        '$filter',
        function ($templateFactory, $rootScope, $filter) {
          var userRole = $filter('userRoleFixer')($rootScope.auth.role);
          return $templateFactory.fromUrl('/app/modules/' + userRole + '/pages/layout.html');
        }
      ]
    }).state('file_manager.list', {
      url: '?property_id?name?unit_id?page?is_single_family',
      views: {
        content: {
          component: 'fileManagerList',
          bindings: { files: 'Files.data' }
        },
        'nav@file_manager.list': {
          component: 'fileManagerNav',
          bindings: {
            files: 'Files.data',
            properties: 'Properties.data',
            units: 'Units.data'
          }
        }
      },
      resolve: {
        Files: [
          'HttpService',
          '$transition$',
          function (HttpService, $transition$) {
            return HttpService.getResolve('/filemanager', 'settings.main', { params: $transition$.params() });
          }
        ],
        Properties: [
          '$transition$',
          '$rootScope',
          'HttpService',
          function ($transition$, $rootScope, HttpService) {
            if ($rootScope.auth._isLandlord() && !$transition$.params().property_id || $transition$.params().is_single_family === 'true' || $rootScope.auth._isPro()) {
              return HttpService.get('/filemanager/properties');
            }
            return { data: [] };
          }
        ],
        Units: [
          '$transition$',
          '$rootScope',
          'HttpService',
          function ($transition$, $rootScope, HttpService) {
            if ($rootScope.auth._isLandlord() && $transition$.params().property_id && $transition$.params().is_single_family === 'false') {
              return HttpService.getWParams('/filemanager/units', { params: { property_id: $transition$.params().property_id } });
            }
            return { data: [] };
          }
        ]
      }
    });
    ;
  }
}());