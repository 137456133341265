(function () {
  'use strict';
  /**
	 * Screening list item
	 * @type {{bindings: {screening: string}, templateUrl: string}}
	 */
  var screeningListItem = {
      bindings: { screening: '<' },
      templateUrl: '/app/modules/shared/components/screening/list/item.html',
      controller: [
        '$rootScope',
        '$state',
        '$filter',
        'APP_CONFIG',
        'Notify',
        'HttpService',
        'ScreeningsService',
        'CordovaService',
        function ($rootScope, $state, $filter, APP_CONFIG, Notify, HttpService, ScreeningsService, CordovaService) {
          'ngInject';
          var vm = this, statuses = APP_CONFIG.constants.screening_status;
          vm.application = vm.screening.application;
          vm.logoPath = ScreeningsService.getLogo(vm.screening.package);
          vm.isPaid = ScreeningsService.isPaid(vm.screening);
          vm.price = ScreeningsService.getPrice(vm.screening);
          vm.isPending = isPending();
          vm.documentToken = _.get(vm.screening, 'report.document.token');
          vm.cancel = cancel;
          vm.canLoad = canLoad;
          vm.canOpen = canOpen;
          vm.canSubmit = canSubmit;
          vm.canCancel = canCancel;
          vm.isIdRequired = isIdRequired;
          vm.viewReport = viewReport;
          /**
			 * Cancels screening report
			 */
          function cancel() {
            var warningTitle = Lang.get('screening.cancel_report_warning', {
                name: $rootScope.auth._isTenant() ? vm.screening.application.property_unit.user_landlord.name : vm.screening.application.name,
                warning: getCancelWarning(),
                for_or_by: Lang.get($rootScope.auth._isTenant() ? 'general.by' : 'general.for'),
                report_or_request: Lang.get(vm.screening.status === statuses.requested_tenant ? 'general.requested' : 'general.report').toLowerCase()
              });
            Notify.confirm(function () {
              HttpService.put('/screening/' + vm.screening.id + '/cancel', null, function () {
                $state.reload();
                Notify.success(Lang.get('screening.canceled'));
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }, warningTitle, null, {
              style: { redBtn: true },
              btnConfirmTitle: Lang.get('notify_actions.im_sure')
            });
          }
          /**
			 * Get cancel warning
			 */
          function getCancelWarning() {
            if (isUserResponsibleForPayment()) {
              return Lang.get('screening.refund_policy_explain');
            }
            if ($rootScope.auth._isLandlord()) {
              return Lang.get('screening.applicant_wont_see_screening');
            }
            if ($rootScope.auth._isTenant()) {
              return Lang.get('screening.you_wont_able_screening');
            }
          }
          /**
			 * @returns {boolean|*}
			 */
          function canLoad() {
            var allowedStatuses = [
                statuses.completed,
                statuses.partial,
                statuses.error
              ];
            return _.includes(allowedStatuses, vm.screening.status) && !canOpen();
          }
          /**
			 * @returns {*}
			 */
          function canOpen() {
            return vm.screening.report_url;
          }
          /**
			 * @returns {boolean|*}
			 */
          function canSubmit() {
            return vm.screening.status === statuses.waiting_submit || $rootScope.auth._isTenant() && vm.screening.status === statuses.requested_tenant && vm.screening.package !== 'rentprep_smartmove';
          }
          /**
			 * @returns {*}
			 */
          function canCancel() {
            if (vm.screening.status === statuses.requested_tenant) {
              return true;
            }
            // statuses.pending is in_process status
            if (vm.screening.payment_responsible && (isPending() || vm.screening.status === statuses.pending)) {
              return isUserResponsibleForPayment();
            }
            return false;
          }
          /**
			 * Check is pending
			 */
          function isPending() {
            var pendingStatuses = [
                statuses.waiting_submit,
                statuses.suspended,
                statuses.id_required,
                statuses.error,
                statuses.not_found
              ];
            return _.includes(pendingStatuses, vm.screening.status);
          }
          /**
			 * @returns {boolean}
			 */
          function isUserResponsibleForPayment() {
            switch ($rootScope.auth.role) {
            case 'admin':
              return vm.screening.payment_responsible === 'landlord';
            case 'tenant':
              return vm.screening.payment_responsible === 'tenant';
            }
            return false;
          }
          /**
			 * Is ID required
			 *
			 * @returns {boolean}
			 */
          function isIdRequired() {
            return vm.screening.status === 6;
          }
          /**
			 * @desc View screening report
			 * @returns {Window | null}
			 * @memberOf screeningListItem
			 */
          function viewReport() {
            if (CordovaService.isCordova()) {
              HttpService.get('/files/download/' + vm.documentToken, function (resp) {
                if (resp.file_url) {
                  return CordovaService.openExternalUrl(resp.file_url);
                }
              });
            } else {
              return window.open($filter('dashboard_url')('/files/download/' + vm.documentToken), '_system');
            }
          }
        }
      ]
    };
  /**
	 * Universal form wrap for request screening
	 * Includes: Header with steps, Service Form and navigation
	 *
	 * @type {{bindings: {application: string, screening: string}, templateUrl: string, controller: controller}}
	 */
  var screeningForm = {
      bindings: {
        application: '<',
        screening: '<'
      },
      templateUrl: '/app/modules/shared/components/screening/screening-form.html',
      controller: [
        '$stateParams',
        '$state',
        '$scope',
        'cfpLoadingBar',
        'APP_CONFIG',
        'ScreeningsService',
        'Notify',
        'HttpService',
        'ngDialog',
        function ($stateParams, $state, $scope, cfpLoadingBar, APP_CONFIG, ScreeningsService, Notify, HttpService, ngDialog) {
          'ngInject';
          var vm = this;
          vm.steps = _getSteps();
          vm.formName = _getFormName();
          vm.activeStep = _.first(vm.steps);
          vm.price = APP_CONFIG.screenings.packages[vm.screening.package].price;
          vm.service = ScreeningsService.getServiceByPackage(vm.screening.package);
          vm.submit = submit;
          vm.nextStep = nextStep;
          vm.previousStep = previousStep;
          vm.isLastStep = isLastStep;
          vm.isFirstStep = isFirstStep;
          vm.getLogo = ScreeningsService.getLogo;
          vm.isNeedPay = ScreeningsService.isNeedPay(vm.screening);
          vm.data = ScreeningsService.getDataForSubmitForm(vm.screening, vm.application);
          function submit() {
            vm.error = null;
            if (!vm.isNeedPay) {
              _requestScreening();
              return;
            }
            // Uses to get validation error from API before Stripe error
            if (!_.get(vm.stripe, 'number')) {
              _requestScreening();
              return;
            }
            cfpLoadingBar.start();
            Stripe.card.createToken(vm.stripe, function (status, response) {
              cfpLoadingBar.complete();
              if (response.error) {
                vm.error = response.error.message;
                $scope.$digest();
                return;
              }
              vm.data.stripe_token = response.id;
              _requestScreening();
            });
          }
          function isLastStep() {
            return _.last(vm.steps) === vm.activeStep;
          }
          function isFirstStep() {
            return _.first(vm.steps) === vm.activeStep;
          }
          function previousStep() {
            var stepIndex = _.indexOf(vm.steps, vm.activeStep);
            if (!vm.steps[stepIndex - 1]) {
              return;
            }
            vm.activeStep = vm.steps[stepIndex - 1];
          }
          function nextStep() {
            var stepIndex = _.indexOf(vm.steps, vm.activeStep);
            if (!vm.steps[stepIndex + 1]) {
              return;
            }
            vm.data.step = stepIndex;
            HttpService.post('/screening/validation', vm.data, function () {
              vm.activeStep = vm.steps[stepIndex + 1];
            }, function (response) {
              Notify.error(response.message);
            });
          }
          function _getSteps() {
            return _.clone(APP_CONFIG.screenings.form_steps[_getFormName()]);
          }
          function _getFormName() {
            switch (vm.screening.package) {
            case 'rentprep_basic':
            case 'rentprep_pro':
            case 'rentprep_platinum':
              return 'rentprep';
            }
            return vm.screening.package;
          }
          function _requestScreening() {
            var url = vm.screening.id ? '/screening/' + vm.screening.id + '/submit' : '/screening';
            HttpService.post(url, vm.data, function () {
              $scope.$emit('form:error', false);
              var key;
              switch (vm.screening.package) {
              case 'rentprep_pro':
              case 'rentprep_basic':
                key = 'rentprep';
                break;
              default:
                key = vm.screening.package;
              }
              ngDialog.openConfirm({
                template: '/app/modules/shared/components/screening/dialog/successful_order.html',
                plain: false,
                showClose: false,
                data: {
                  request: vm.screening.package === 'rentprep_smartmove',
                  content: Lang.get('screening.success_dialog.' + key + '.content')
                }
              }).then(_redirectToScreenings, _redirectToScreenings);
            }, function (response) {
              if (response.screening_id) {
                $state.go('screening.submit', { screening_id: response.screening_id });
              }
              if (response.message) {
                Notify.error(response.message, 5000);
              }
              if (response.package) {
                Notify.error(_.first(response.package), 5000);
              }
              if (response.errors) {
                if (_.isArray(response.errors)) {
                  Notify.error(_.first(response.errors), 5000);
                }
                if (response.errors['candidate.ssn']) {
                  previousStep();
                }
                vm.credit_card_errors = response.errors;
              }
            });
          }
          function _redirectToScreenings() {
            $state.go('applications.screening', { application_id: vm.application.id });
          }
        }
      ]
    };
  /**
	 * Screening form steps
	 * @type {{bindings: {activeStep: string, steps: string}, templateUrl: string, controller: controller}}
	 */
  var screeningFormHeader = {
      bindings: {
        activeStep: '=',
        steps: '<'
      },
      templateUrl: '/app/modules/shared/components/screening/screening-form-header.html',
      controller: [
        'APP_CONFIG',
        function (APP_CONFIG) {
          'ngInject';
          var vm = this;
          vm.getStepIcon = getStepIcon;
          vm.isStepDone = isStepDone;
          vm.goToStep = goToStep;
          function getStepIcon(step) {
            return APP_CONFIG.screenings.step_icons[step];
          }
          function isStepDone(step) {
            return _.indexOf(vm.steps, step) < _.indexOf(vm.steps, vm.activeStep);
          }
          function goToStep(step) {
            if (isStepDone(step)) {
              vm.activeStep = step;
            }
          }
        }
      ]
    };
  var screeningFormService = {
      bindings: {
        package: '<',
        activeStep: '<',
        steps: '=',
        data: '<'
      },
      controller: [
        '$scope',
        '$element',
        '$compile',
        function ($scope, $element, $compile) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            var newScope = $scope.$new(true, $scope);
            newScope.$ctrl = vm;
            $element.html(getTemplate(vm.package));
            $compile($element.contents())(newScope);
          };
          function getTemplate(name) {
            switch (name) {
            case 'checkr_basic':
              return '<checkr-form data=\'$ctrl.data\' active-step=\'$ctrl.activeStep\'></checkr-form>';
            case 'rentprep':
              return '<rentprep-form data=\'$ctrl.data\' active-step=\'$ctrl.activeStep\'></rentprep-form>';
            case 'rentprep_smartmove':
              return '<rentprep-smartmove-form data=\'$ctrl.data\' active-step=\'$ctrl.activeStep\' steps=\'$ctrl.steps\'></rentprep-smartmove-formform>';
            }
          }
        }
      ]
    };
  var checkrForm = {
      bindings: {
        activeStep: '<',
        data: '<'
      },
      templateUrl: '/app/modules/shared/components/screening/service_forms/checkr.html'
    };
  var rentprepForm = {
      bindings: {
        activeStep: '<',
        data: '<'
      },
      templateUrl: '/app/modules/shared/components/screening/service_forms/rentprep.html'
    };
  var rentprepSmartmoveForm = {
      bindings: {
        activeStep: '<',
        data: '<',
        steps: '='
      },
      templateUrl: '/app/modules/shared/components/screening/service_forms/rentprep_smartmove.html',
      controller: [
        '$filter',
        function ($filter) {
          'ngInject';
          var vm = this;
          vm.data.application.who_pays = 'tenant';
          vm.data.fcra = 1;
          // FCRA checked by default
          whoPaysChanged();
          vm.whoPaysChanged = whoPaysChanged;
          vm.bankruptcyChanged = bankruptcyChanged;
          vm.yesNoOptions = getYesNoOptions();
          vm.whoPaysOptions = getWhoPaysOptions();
          vm.unitClassificationOptions = getClassificationOptions();
          function whoPaysChanged() {
            if (vm.data.application.who_pays === 'tenant') {
              vm.steps = _.remove(vm.steps, function (step) {
                return step !== 'payment';
              });
            } else if (!_.includes(vm.steps, 'payment')) {
              vm.steps.push('payment');
            }
          }
          function bankruptcyChanged() {
            if (!vm.data.application.decline_for_open_bankruptcy) {
              vm.data.application.open_bankruptcy_window = null;
            }
          }
          function getYesNoOptions() {
            return [
              [{
                  key: 0,
                  value: Lang.get('general.no')
                }],
              [{
                  key: 1,
                  value: Lang.get('general.yes')
                }]
            ];
          }
          function getWhoPaysOptions() {
            return [
              [{
                  key: 'tenant',
                  value: Lang.get('general.role.tenant')
                }],
              [{
                  key: 'landlord',
                  value: $filter('landlordMode')(vm.data.landlord.mode)
                }]
            ];
          }
          function getClassificationOptions() {
            return [
              [{
                  key: 'Conventional',
                  value: Lang.get('screening.smartmove.conventional')
                }],
              [{
                  key: 'Subsidized',
                  value: Lang.get('screening.smartmove.subsidized')
                }]
            ];
          }
        }
      ]
    };
  angular.module('app.shared_components.screening', []).component('screeningListItem', screeningListItem).component('screeningForm', screeningForm).component('screeningFormHeader', screeningFormHeader).component('screeningFormService', screeningFormService).component('checkrForm', checkrForm).component('rentprepForm', rentprepForm).component('rentprepSmartmoveForm', rentprepSmartmoveForm);
  ;
}());