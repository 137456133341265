(function () {
  'use strict';
  paging.$inject = [
    'HttpService',
    '$location',
    '$state',
    '$stateParams',
    '$timeout',
    '$rootScope',
    '$anchorScroll',
    'PagingFilterService',
    'FilterService'
  ];
  PagingFilterService.$inject = [
    '$rootScope',
    '$location',
    '$state',
    '$stateParams',
    'FilterService'
  ];
  angular.module('app.paging', []).directive('paging', paging).service('PagingFilterService', PagingFilterService);
  ;
  function PagingFilterService($rootScope, $location, $state, $stateParams, FilterService) {
    var _scope;
    //$rootScope.$new()
    this.bind = function (scope, params) {
      var initParams = {};
      _scope = scope;
      angular.forEach(params ? _.clone(params) : $stateParams, function (val, key) {
        if (key !== '#') {
          // fix after update ui-router
          if (isNaN(val * 1)) {
            initParams[key] = val;
          } else {
            initParams[key] = val * 1;
          }
        }
      });
      // SET filter to scope
      scope.filter = initParams;
      FilterService.cancel();
      // Watch for changes
      scope.$watch(function (scope) {
        return scope.filter;
      }, _.throttle(function (newVal, oldVal) {
        $rootScope.$emit('paging_filter:updated', newVal, oldVal);
      }, 100), true);
    };
    this.getScope = function () {
      if (_scope) {
        return _scope;
      } else {
        return { filter: {} };
      }
    };
    this.init = function (params) {
      _.extend(_scope.filter, params);  // $rootScope.$emit('paging_filter:inited', _scope.filter);
    };
    this.update = function (params) {
      _.extend(_scope.filter, params);  // $rootScope.$emit('paging_filter:update', _scope.filter, old);
    };
    this.set = function (key, value) {
      if (_scope) {
        if (value === null) {
          delete _scope.filter[key];
          $location.search(_scope.filter);
        } else {
          _scope.filter[key] = value;
        }
        $rootScope.$emit('paging_filter:updated', _scope.filter);
      }
    };
    this.clearKey = function (key) {
      // Clear
      _scope.filter[key] = undefined;
    };
    this.clear = function () {
      // Clear
      _scope.filter = {};
      FilterService.cancel();
      $location.search({});
    };
  }
  function paging(HttpService, $location, $state, $stateParams, $timeout, $rootScope, $anchorScroll, PagingFilterService, FilterService) {
    /**
		 * Assign default scope values from settings
		 * Feel free to tweak / fork these for your application
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {Object} attrs - The local directive attribute object
		 */
    function setScopeValues(scope, attrs) {
      // scope.Queue = [];
      scope.List = [];
      scope.Hide = false;
      scope.dots = scope.dots || '...';
      scope.page = parseInt(scope.page) || 1;
      scope.total = parseInt(scope.total) || 0;
      scope.ulClass = scope.ulClass || 'pagination';
      scope.adjacent = parseInt(scope.adjacent) || 2;
      scope.activeClass = scope.activeClass || 'active';
      scope.disabledClass = scope.disabledClass || 'disabled';
      scope.scrollTop = scope.$eval(attrs.scrollTop);
      scope.hideIfEmpty = scope.$eval(attrs.hideIfEmpty);
      scope.showPrevNext = scope.$eval(attrs.showPrevNext);
      scope.collection = scope.collection || [];
    }
    /**
		 * Validate and clean up any scope values
		 * This happens after we have set the scope values
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {int} pageCount - The last page number or total page count
		 */
    function validateScopeValues(scope, pageCount) {
      // Block where the page is larger than the pageCount
      if (scope.page > pageCount) {
        scope.page = pageCount;
      }
      // Block where the page is less than 0
      if (scope.page <= 0) {
        scope.page = 1;
      }
      // Block where adjacent value is 0 or below
      if (scope.adjacent <= 0) {
        scope.adjacent = 2;
      }
      // Hide from page if we have 1 or less pages
      // if directed to hide empty
      if (pageCount <= 1) {
        scope.Hide = scope.hideIfEmpty;
      }
    }
    /**
		 * Assign the method action to take when a page is clicked
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {int} page - The current page of interest
		 * @param filter - from scope.filter
		 */
    function internalAction(scope, page, filter) {
      // Fix ?page=1
      if (page == 1) {
        page = null;
      }
      // Update the page in scope
      scope.page = page;
      // Set filter to pagination
      scope.filter = filter;
      // Disable reload
      // $state.current.reloadOnSearch = false;
      $stateParams.page = page;
      if (!scope.isWidget) {
        $location.search('page', page);
      }
      HttpService.getWParams(scope.url, { params: filter }, function (data) {
        //// Pass our parameters to the paging action
        scope.pagingAction({
          page: scope.page,
          pageSize: scope.pageSize,
          total: scope.total
        });
        if (scope.callbackFx && typeof scope.callbackFx === 'function') {
          scope.callbackFx()(data);
        }
        data.list = data.list || [];
        if (data.pagination) {
          scope.page = data.pagination.current_page || 1;
          scope.lastPage = data.pagination.last_page || 1;
          scope.pageSize = data.pagination.per_page || 12;
          scope.total = data.pagination.total || 0;
        }
        // If group by
        scope.collection = scope.group && scope.group.length ? _.groupBy(data.list, function (x) {
          return x[scope.group];
        }) : data.list;
        // If allowed scroll up to the top of the page
        $timeout(function () {
          // Scroll top
          if (!scope.isWidget) {
            $anchorScroll();
          }  // Enable reload
             // $state.current.reloadOnSearch = true;
        }, 100);
      });
    }
    /**
		 * Add the first, previous, next, and last buttons if desired
		 * The logic is defined by the mode of interest
		 * This method will simply return if the scope.showPrevNext is false
		 * This method will simply return if there are no pages to display
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {int} pageCount - The last page number or total page count
		 * @param {string} mode - The mode of interest either prev or last
		 */
    function addPrevNext(scope, pageCount, mode) {
      // Ignore if we are not showing
      // or there are no pages to display
      if (!scope.showPrevNext || pageCount < 1) {
        return;
      }
      // Local variables to help determine logic
      var disabled, alpha, beta;
      // Determine logic based on the mode of interest
      // Calculate the previous / next page and if the click actions are allowed
      if (mode === 'prev') {
        disabled = scope.page - 1 <= 0;
        var prevPage = scope.page - 1 <= 0 ? 1 : scope.page - 1;
        alpha = {
          value: '<<',
          title: 'First Page',
          page: 1
        };
        beta = {
          value: '<',
          title: 'Previous Page',
          page: prevPage
        };
      } else {
        disabled = scope.page + 1 > pageCount;
        var nextPage = scope.page + 1 >= pageCount ? pageCount : scope.page + 1;
        alpha = {
          value: '>',
          title: 'Next Page',
          page: nextPage
        };
        beta = {
          value: '>>',
          title: 'Last Page',
          page: pageCount
        };
      }
      // Build the first list item
      var alphaItem = {
          value: alpha.value,
          title: alpha.title,
          liClass: disabled ? scope.disabledClass : '',
          action: function () {
            if (!disabled) {
              internalAction(scope, alpha.page, _.extend(_.clone(PagingFilterService.getScope().filter), { page: alpha.page }));
            }
          }
        };
      // Build the second list item
      var betaItem = {
          value: beta.value,
          title: beta.title,
          liClass: disabled ? scope.disabledClass : '',
          action: function () {
            if (!disabled) {
              internalAction(scope, beta.page, _.extend(_.clone(PagingFilterService.getScope().filter), { page: beta.page }));
            }
          }
        };
      // Add the items
      scope.List.push(alphaItem);
      scope.List.push(betaItem);
    }
    /**
		 * Adds a range of numbers to our list
		 * The range is dependent on the start and finish parameters
		 *
		 * @param {int} start - The start of the range to add to the paging list
		 * @param {int} finish - The end of the range to add to the paging list
		 * @param {Object} scope - The local directive scope object
		 */
    function addRange(start, finish, scope) {
      var i = 0;
      for (i = start; i <= finish; i++) {
        var item = {
            value: i,
            title: 'Page ' + i,
            liClass: scope.page == i ? scope.activeClass : '',
            action: function () {
              internalAction(scope, this.value, _.extend(_.clone(PagingFilterService.getScope().filter), { page: this.value }));
            }
          };
        scope.List.push(item);
      }
    }
    /**
		 * Add Dots ie: 1 2 [...] 10 11 12 [...] 56 57
		 * This is my favorite function not going to lie
		 *
		 * @param {Object} scope - The local directive scope object
		 */
    function addDots(scope) {
      scope.List.push({ value: scope.dots });
    }
    /**
		 * Add the first or beginning items in our paging list
		 * We leverage the 'next' parameter to determine if the dots are required
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {int} next - the next page number in the paging sequence
		 */
    function addFirst(scope, next) {
      addRange(1, 2, scope);
      // We ignore dots if the next value is 3
      // ie: 1 2 [...] 3 4 5 becomes just 1 2 3 4 5
      if (next != 3) {
        addDots(scope);
      }
    }
    /**
		 * Add the last or end items in our paging list
		 * We leverage the 'prev' parameter to determine if the dots are required
		 *
		 * @param {int} pageCount - The last page number or total page count
		 * @param {Object} scope - The local directive scope object
		 * @param {int} prev - the previous page number in the paging sequence
		 */
    // Add Last Pages
    function addLast(pageCount, scope, prev) {
      // We ignore dots if the previous value is one less that our start range
      // ie: 1 2 3 4 [...] 5 6  becomes just 1 2 3 4 5 6
      if (prev != pageCount - 2) {
        addDots(scope);
      }
      addRange(pageCount - 1, pageCount, scope);
    }
    /**
		 * The main build function used to determine the paging logic
		 * Feel free to tweak / fork values for your application
		 *
		 * @param {Object} scope - The local directive scope object
		 * @param {Object} attrs - The local directive attribute object
		 */
    function build(scope, attrs) {
      // Block divide by 0 and empty page size
      if (!scope.pageSize || scope.pageSize <= 0) {
        scope.pageSize = 1;
      }
      // Determine the last page or total page count
      var pageCount = Math.ceil(scope.total / scope.pageSize);
      // Set the default scope values where needed
      setScopeValues(scope, attrs);
      // Validate the scope values to protect against strange states
      validateScopeValues(scope, pageCount);
      // Create the beginning and end page values
      var start, finish;
      // Calculate the full adjacency value
      var fullAdjacentSize = scope.adjacent * 2 + 2;
      // Add the Next and Previous buttons to our list
      addPrevNext(scope, pageCount, 'prev');
      // If the page count is less than the full adjacnet size
      // Then we simply display all the pages, Otherwise we calculate the proper paging display
      if (pageCount <= fullAdjacentSize + 2) {
        start = 1;
        addRange(start, pageCount, scope);
      } else {
        // Determine if we are showing the beginning of the paging list
        // We know it is the beginning if the page - adjacent is <= 2
        if (scope.page - scope.adjacent <= 2) {
          start = 1;
          finish = 1 + fullAdjacentSize;
          addRange(start, finish, scope);
          addLast(pageCount, scope, finish);
        }  // Determine if we are showing the middle of the paging list
           // We know we are either in the middle or at the end since the beginning is ruled out above
           // So we simply check if we are not at the end
           // Again 2 is hard coded as we always display two pages after the dots
        else if (scope.page < pageCount - (scope.adjacent + 2)) {
          start = scope.page - scope.adjacent;
          finish = scope.page + scope.adjacent;
          addFirst(scope, start);
          addRange(start, finish, scope);
          addLast(pageCount, scope, finish);
        }  // If nothing else we conclude we are at the end of the paging list
           // We know this since we have already ruled out the beginning and middle above
        else {
          start = pageCount - fullAdjacentSize;
          finish = pageCount;
          addFirst(scope, start);
          addRange(start, finish, scope);
        }
      }
      // Add the next and last buttons to our paging list
      addPrevNext(scope, pageCount, 'next');
    }
    /**
		 * The angular return value required for the directive
		 * Feel free to tweak / fork values for your application
		 */
    return {
      restrict: 'EA',
      scope: {
        page: '=',
        pageSize: '=',
        lastPage: '=',
        total: '=',
        collection: '=',
        dots: '@',
        hideIfEmpty: '@',
        ulClass: '@',
        activeClass: '@',
        disabledClass: '@',
        adjacent: '@',
        scrollTop: '@',
        showPrevNext: '@',
        pagingAction: '&',
        url: '@',
        callbackFx: '&?',
        group: '@',
        filter: '=?',
        isWidget: '=?'
      },
      template: '<ul ng-hide="Hide" ng-class="ulClass"> ' + '<li ' + 'title="{{Item.title}}" ' + 'ng-class="Item.liClass" ' + 'ng-click="Item.action()" ' + 'ng-repeat="Item in List"> ' + '<span ng-bind="Item.value"></span> ' + '</li>' + '</ul>',
      link: function (scope, element, attrs) {
        // scope.$parent.pagination - pagination object
        // Set directive pagination.filter filter
        if (PagingFilterService.getScope()) {
          scope.filter = PagingFilterService.getScope().filter;
        }
        // Hook in our watched items
        scope.$watchCollection('[page,pageSize,total]', function () {
          build(scope, attrs);
        });
        var stopWatch = $rootScope.$on('paging_filter:updated', _.debounce(function (event, newValue, oldValue) {
            // Update directive pagination.filter filter
            scope.filter = newValue || {};
            if (newValue !== oldValue) {
              FilterService.changeSource({
                newValue: newValue,
                oldValue: oldValue
              });
              // $state.current.reloadOnSearch = false;
              var newParams = _.clone(newValue);
              if (newValue)
                delete newValue.page;
              if (oldValue)
                delete oldValue.page;
              if (!_.isEqual(newValue, oldValue)) {
                newParams.page = 1;
              }
              if (!scope.isWidget) {
                _.each(newParams, function (value, key) {
                  $stateParams[key] = value;
                  $location.search(key, value);
                });
              }
              internalAction(scope, 1, newValue);
            }
          }));
        scope.$on('$destroy', stopWatch);
      }
    };
  }
}());