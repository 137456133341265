(function () {
  'use strict';
  configure.$inject = ['$stateProvider'];
  angular.module('app.auth.states', [
    'app.auth.components',
    'app.login.component',
    'app.forgot.components',
    'app.signup.component',
    'app.confirm.component',
    'app.twofactor.component'
  ]).config(configure);
  ;
  /* Fn
	 ============================================================================================================= */
  function configure($stateProvider) {
    $stateProvider.state('auth', {
      abstract: true,
      url: '',
      views: {
        auth: { templateUrl: '/app/modules/auth/layout.html' },
        'header@auth': { component: 'authHeaderComponent' },
        'content@auth': { component: 'authContentComponent' }
      },
      resolve: {
        Auth: [
          '$location',
          '$q',
          function ($location, $q) {
            var visible, deferred = $q.defer();
            if (window.cordova) {
              visible = window.cordova && window.device && window.device.available;
              if (!visible) {
                $location.path('/');
              }
              deferred.resolve({ visibleLoginPage: visible });
            } else {
              deferred.resolve({ visibleLoginPage: true });
            }
            return deferred.promise;
          }
        ]
      }
    }).state('auth.login', {
      url: '/login?email?authError?role?invite?application_apply',
      views: { form: { component: 'loginComponent' } }
    }).state('auth.two_factor_validate', {
      url: '/two_factor?email',
      views: { form: { component: 'twoFactorComponent' } }
    }).state('auth.signup', {
      url: '/signup?email?authError?role?invite?property_id?application_apply',
      views: { form: { component: 'signupComponent' } }
    }).state('auth.confirm', {
      url: '/confirm?email?application_apply?subscription',
      views: { form: { component: 'confirmComponent' } }
    }).state('auth.forgotPassword', {
      url: '/forgot-password?email?reSend',
      views: { form: { component: 'forgotPasswordComponent' } }
    }).state('auth.forgotPasswordConfirm', {
      url: '/forgot-password/confirm?email?confirmation_code',
      views: { form: { component: 'forgotPasswordConfirmComponent' } }
    }).state('auth.forgotPasswordChangePassword', {
      url: '/forgot-password/changePassword?email?confirmation_code?twoFactorAuthRequired',
      views: { form: { component: 'forgotPasswordChangeComponent' } }
    }).state('auth.logout', {
      url: '/logout',
      views: { form: { component: 'logoutComponent' } }
    });
    ;
  }
}());