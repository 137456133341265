(function () {
  'use strict';
  /**
	 * @namespace transactionsCheckout
	 * @desc Transactions checkout
	 * @example <transactions-checkout></transactions-checkout>
	 */
  var transactionsCheckout = {
      bindings: { item: '<' },
      templateUrl: '/app/modules/shared/components/transactions/checkout/checkout.html',
      controller: [
        'APP_CONFIG',
        function (APP_CONFIG) {
          'ngInject';
          var vm = this, transactionStatus;
          // Transaction status constants
          transactionStatus = APP_CONFIG.constants.transaction_status;
          vm.item = vm.item || {};
          vm.statusClass = statusClass;
          vm.checkoutDetails = checkoutDetails;
          vm.allowPartialPayment = allowPartialPayment;
          _setCheckoutAmount();
          function _setCheckoutAmount() {
            // If partial payments disabled - no need reset amount
            if (!vm.allowPartialPayment()) {
              return false;
            }
            if (vm.item.transactions) {
              _.map(vm.item.transactions, function (payment) {
                if (_.get(payment, 'checkout.amount') > 0) {
                  payment.checkout.amount = 0;
                }
              });
              vm.item.amount = 0;
            }
          }
          function statusClass() {
            switch (vm.item.status) {
            case 0:
              return 'status-0';
            case 1:
              return 'status-3';
            case 2:
              return 'status-1';
            }
          }
          function checkoutDetails() {
            var details = [];
            _.forEach(vm.item.transactions, function (x) {
              if (x.checkout.details) {
                details.push(x.checkout.details);
              }
            });
            return details.join[' '];
          }
          function allowPartialPayment(transaction) {
            if (!vm.item.receiver || !vm.item.receiver.payments) {
              return false;
            }
            if ([
                transactionStatus.unpaid,
                transactionStatus.partial
              ].indexOf(vm.item.status) === -1) {
              return false;
            }
            if (_.isUndefined(transaction)) {
              transaction = _.find(vm.item.transactions, function (item) {
                return !item.isDueDate;
              });
              if (!transaction) {
                transaction = { isDueDate: true };
              }
            }
            return vm.item.receiver.payments.partial_payments && !transaction.isDueDate;
          }
        }
      ]
    };
  /**
	 * @namespace transactionsCheckoutPayButton
	 * @desc Transactions checkout pay button
	 * @example <transactions-checkout-pay-button></transactions-checkout-pay-button>
	 * @memberOf transactionsCheckout
	 */
  var transactionsCheckoutPayButton = {
      bindings: {
        checkout: '=',
        total: '@?',
        gateways: '@?'
      },
      templateUrl: '/app/modules/shared/components/transactions/checkout/checkout-pay-button.html',
      controller: [
        '$rootScope',
        '$filter',
        '$state',
        'ngDialog',
        'PaypalPayService',
        'StripePayService',
        'DwollaPayService',
        'APP_CONFIG',
        'HttpService',
        'Notify',
        'CordovaService',
        function ($rootScope, $filter, $state, ngDialog, PaypalPayService, StripePayService, DwollaPayService, APP_CONFIG, HttpService, Notify, CordovaService) {
          'ngInject';
          var vm = this, allowedGateways;
          if (vm.gateways) {
            allowedGateways = vm.gateways.split(',');
            _.each(vm.checkout.receiver.payments.gateway, function (gateway, key) {
              if (!_.includes(allowedGateways, key)) {
                gateway.enabled = false;
              }
            });
          }
          vm.gateway_options = checkOptions();
          function checkOptions() {
            if (vm.checkout && vm.checkout.receiver && vm.checkout.receiver.payments && vm.checkout.receiver.payments.is_enabled) {
              if (vm.checkout.receiver.payments.gateway.dwolla.enabled && showIfUSOnly()) {
                return 'dwolla';
              } else if (vm.checkout.receiver.payments.gateway.paypal.enabled) {
                return 'paypal';
              } else if (vm.checkout.receiver.payments.gateway.stripe.enabled) {
                return 'stripe';
              }
            }
          }
          vm.showPayOnline = showPayOnline;
          vm.totalAmount = totalAmount;
          vm.checkGatewayAvailable = checkGatewayAvailable;
          vm.showIfUSOnly = showIfUSOnly;
          vm.byPayPal = byPayPal;
          vm.byStripe = byStripe;
          vm.byDwolla = byDwolla;
          vm.updateCheckout = updateCheckout;
          function totalAmount() {
            return _.reduce(vm.checkout.transactions, function (memo, transaction) {
              return memo + parseFloat(transaction.checkout.amount);
            }, 0);
          }
          function showIfUSOnly() {
            return vm.checkout.currency === 'USD';
          }
          function checkGatewayAvailable(gateway) {
            if (!vm.checkout) {
              return false;
            }
            if (!vm.checkout.receiver || !vm.checkout.receiver.payments) {
              return false;
            }
            if (gateway === 'dwolla' && vm.checkout.currency !== 'USD') {
              return false;
            }
            if (allowedGateways && allowedGateways.indexOf(gateway) === -1) {
              return false;
            }
            return vm.checkout.receiver.payments.is_enabled && vm.checkout.receiver.payments.gateway[gateway].enabled && !vm.checkout.status;
          }
          function showPayOnline() {
            if (!vm.checkout) {
              return false;
            }
            if ([
                0,
                2,
                3
              ].indexOf(vm.checkout.status) === -1) {
              return false;
            }
            if (!vm.checkout.receiver || !vm.checkout.receiver.payments || !vm.checkout.receiver.payments.is_enabled) {
              return false;
            }
            if (allowedGateways) {
              var allow = false;
              _.forEach(allowedGateways, function (gateway) {
                if (vm.checkout.receiver.payments.gateway[gateway].enabled) {
                  allow = true;
                }
              });
              if (!allow) {
                return false;
              }
            }
            return _.get(vm.checkout, 'receiver.payments.currencies').indexOf(vm.checkout.currency) !== -1;
          }
          function byPayPal() {
            var tabWindowId;
            if (!CordovaService.isCordova()) {
              tabWindowId = window.open($filter('dashboard_url')('/waiting'), '_system');
            }
            updateCheckout(function () {
              // Detect mobile application
              if (CordovaService.isCordova()) {
                return CordovaService.openExternalUrl(PaypalPayService.generateLink(vm.checkout.token));
              } else {
                tabWindowId.location.href = PaypalPayService.generateLink(vm.checkout.token);
              }
            });
          }
          function byStripe() {
            updateCheckout(function () {
              var avatar = _.get(vm.checkout, 'receiver.avatar'), options = {
                  checkout_token: vm.checkout.token,
                  stripe_pk: vm.checkout.receiver.payments.gateway.stripe.stripe_pk,
                  image: avatar ? $filter('getAvatarUrl')(_.get(vm.checkout, 'receiver.avatar'), 'preview', 6) : null,
                  locale: 'auto',
                  email: $rootScope.auth.email,
                  name: vm.checkout.receiver.name,
                  description: vm.checkout.description,
                  amount: StripePayService.calculateAmountWithFee(vm.checkout),
                  amount_original: vm.checkout.amount,
                  currency: vm.checkout.currency
                };
              // Run
              StripePayService.transfer(options);
            });
          }
          function byDwolla() {
            updateCheckout(function () {
              if (!$rootScope.auth.payments.gateway.dwolla.enabled) {
                $state.go('settings.payments_view');
                return false;
              }
              ngDialog.openConfirm({
                plain: true,
                template: '<dwolla-bank-accounts-choose checkout="$ctrl.checkout"></dwolla-bank-accounts-choose>',
                controllerAs: '$ctrl',
                controller: function () {
                  this.vm = angular.extend({}, vm);
                  this.checkout = vm.checkout;
                },
                scope: 'true',
                data: { title: Lang.get('dwolla.dialog_source_choose') }
              }).then(function (source) {
                //Make payment
                var details = {
                    checkout_token: vm.checkout.token,
                    source_id: source.source_id
                  };
                DwollaPayService.transfer(details, function () {
                });
              });
            });
          }
          function updateCheckout(callback) {
            var total, payments;
            if ($state.current.name === 'applications.pay_fee') {
              return callback();
            }
            if (!vm.checkout.transactions || !vm.checkout.transactions.length) {
              return false;
            }
            total = _.reduce(vm.checkout.transactions, function (memo, transaction) {
              return memo + parseFloat(transaction.checkout.amount);
            }, 0);
            payments = _.map(vm.checkout.transactions, function (trans) {
              return {
                transaction_id: trans.id,
                amount: trans.checkout.amount
              };
            });
            if (!total) {
              return Notify.error(Lang.get('transactions.checkout_error_min_amount'));
            }
            if (!callback) {
              callback = angular.noop;
            }
            if (parseFloat(total) !== parseFloat(vm.checkout.amount)) {
              HttpService.put([
                '/transactions/checkout/',
                vm.checkout.token
              ].join(''), { payments: payments }, function (checkout) {
                vm.checkout = checkout;
                callback();
              }, function (errors) {
                if (errors.message) {
                  Notify.error(errors.message);
                }
              });
            } else {
              callback();
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaBankAccountsChoose
	 * @desc Dwolla bank accounts choose
	 * @example <dwolla-bank-accounts-choose></dwolla-bank-accounts-choose>
	 * @memberOf transactionsCheckoutPayButton
	 */
  var dwollaBankAccountsChoose = {
      bindings: { checkout: '=' },
      templateUrl: '/app/modules/shared/components/transactions/dialog/sources-dialog-choose.html',
      controller: [
        '$rootScope',
        'ngDialog',
        'Notify',
        'DwollaService',
        'APP_CONFIG',
        function ($rootScope, ngDialog, Notify, DwollaService, APP_CONFIG) {
          'ngInject';
          var vm = this, constTransactionCategory = APP_CONFIG.constants.transaction_category;
          init();
          // Construct
          vm.isLoaded = false;
          vm.sourceSelected = null;
          vm.fundingSources = [];
          vm.action = { selectSource: selectSource };
          /**
			 * @desc Get funding sources
			 */
          function init() {
            var transaction;
            // Get sources
            DwollaService.getSources(true, successCallback, errorCallback);
            // Request callback
            function successCallback(list) {
              // Set sources
              if (list.length) {
                if ($rootScope.auth._isLandlord()) {
                  transaction = _.get(vm.checkout, 'transactions.0');
                  vm.fundingSources = orderSourcesForSelect(list, transaction);
                  selectSource(_.get(vm.fundingSources, '0'));
                } else {
                  vm.fundingSources = _.filter(list, {
                    status: 'verified',
                    type: 'bank'
                  });
                  selectSource(_.get(vm.fundingSources, '0'));
                }
              }
              // Set loaded true
              vm.isLoaded = true;
              // Check if exist balance amount
              DwollaService.getBalance(function (resp) {
                if (resp.balance && parseFloat(resp.balance.value) > 0) {
                  vm.fundingSources = _.filter(list, { status: 'verified' });
                }
              });
            }
            // Error callback
            function errorCallback(err) {
              if (err.message) {
                Notify.error(err.message);
              }
            }
          }
          /**
			 * @desc Select source for select
			 * @param source
			 * @return {*|void}
			 */
          function selectSource(source) {
            if (source && source.status !== 'verified') {
              return Notify.error('Please verify this account before make payment');
            }
            vm.sourceSelected = source;
          }
          /**
			 * @desc Order sources by type for current transaction
			 * @param fundingSources
			 * @param transaction
			 * @return {*}
			 */
          function orderSourcesForSelect(fundingSources, transaction) {
            var accountsSorted = [], accountMain, accountDeposit, accountProperty, accountPropertyLinked;
            fundingSources = _.filter(fundingSources, {
              status: 'verified',
              type: 'bank'
            });
            accountMain = _.find(fundingSources, { account_type: 'main' });
            accountDeposit = _.find(fundingSources, { account_type: 'deposit' });
            accountPropertyLinked = _.find(fundingSources, function (account) {
              return account.account_type === 'property' && _.find(account.links || [], {
                linkable_id: transaction.property_id,
                linkable_type: 'App\\Property'
              });
            });
            accountProperty = _.filter(fundingSources, function (account) {
              if (accountPropertyLinked && accountPropertyLinked.id === account.id) {
                return false;
              }
              return account.account_type === 'property';
            });
            if ($rootScope.auth._isLandlord()) {
              switch (transaction.category) {
              case constTransactionCategory.expense:
                if (!transaction.property_id) {
                  accountsSorted = _.concat(accountMain, accountPropertyLinked, accountProperty);
                } else {
                  accountsSorted = _.concat(accountPropertyLinked, accountProperty, accountMain);
                }
                break;
              case constTransactionCategory.refund:
                if ($rootScope.auth._isPM()) {
                  accountsSorted = _.concat(accountPropertyLinked, accountProperty);
                } else {
                  accountsSorted = _.concat(accountDeposit, accountPropertyLinked, accountProperty, accountMain);
                }
                break;
              }
            } else {
              return fundingSources;
            }
            return _.filter(accountsSorted, function (item) {
              return item;
            });
          }
        }
      ]
    };
  angular.module('app.shared_components.transactions_checkout', []).component('transactionsCheckout', transactionsCheckout).component('transactionsCheckoutPayButton', transactionsCheckoutPayButton).component('dwollaBankAccountsChoose', dwollaBankAccountsChoose);
  ;
}());