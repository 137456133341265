(function () {
  'use strict';
  // For templates
  configure.$inject = [
    '$locationProvider',
    '$httpProvider',
    '$animateProvider',
    'cfpLoadingBarProvider',
    '$compileProvider',
    'localStorageServiceProvider',
    'APP_CONFIG'
  ];
  runBlock.$inject = [
    '$rootScope',
    '$location',
    '$transitions',
    '$state',
    '$timeout',
    '$anchorScroll',
    '$filter',
    'beforeUnload',
    'BreadCrumbsService',
    'EmitService',
    'CordovaService',
    'APP_CONFIG',
    'AuthDataService',
    'SeoService',
    'RealTimeService'
  ];
  angular.module('app.templates_auth', []);
  ;
  angular.module('app', [
    'angular-loading-bar',
    'oc.lazyLoad',
    'app.templates_auth',
    'app.core',
    'ui.router',
    'ngSanitize',
    'ngDialog',
    'ngAnimate',
    'contenteditable',
    'angularFileUpload',
    'monospaced.elastic',
    'ngBrowserInfo',
    'ngDropdowns',
    'com.2fdevs.videogular',
    'ui.select',
    'app.d3.components',
    'notification',
    'ksSwiper',
    'dndLists',
    'angular-medium-editor',
    'ui.bootstrap.contextMenu',
    'LocalStorageModule',
    'app.paging',
    'app.services',
    'app.services.presentation',
    'app.services.social',
    'app.services.settings',
    'app.services.cordova',
    'app.services.content',
    'app.services.calendar',
    'app.services.subscription',
    'app.helper',
    'app.factories',
    'app.factory.resize',
    'app.factory.split_input',
    'app.filters',
    'app.utils',
    'app.notify',
    'app.auth_components',
    'app.components.global',
    'app.components.settings',
    'app.components.main',
    'app.components.form',
    'app.components.widgets',
    'app.components.filter',
    'app.components.fieldsets',
    'app.components.creditCard',
    'app.directives.global',
    'app.directives_main',
    'app.directives_form',
    'app.auth.services',
    'app.auth.states',
    'app.shared_common'
  ]).config(configure).run(runBlock).component('app', {
    templateUrl: '/app/modules/index.html',
    controller: [
      'APP_CONFIG',
      'ng_util',
      'cfpLoadingBar',
      'ContentService',
      function (APP_CONFIG, ng_util, cfpLoadingBar, ContentService) {
        'ngInject';
        var vm = this, role;
        vm.$onInit = function () {
          role = ContentService.currentRole(role);
          if (role) {
            vm.files_to_load = APP_CONFIG.load_files[role];
            if (window.cordova) {
              vm.files_to_load = [
                'build/' + role + '.min.css',
                'build/' + role + '-vendor.min.js',
                'build/' + role + '-app.min.js'
              ];
            }
          }
        };
        ng_util.definePropertyObj(ContentService, 'contentLoad', function (contentLoad) {
          if (!_.isUndefined(contentLoad)) {
            vm.files_to_load = contentLoad;
          }
        });
      }
    ]
  });
  ;
  /* Fn
	 ============================================================================================================ */
  function configure($locationProvider, $httpProvider, $animateProvider, cfpLoadingBarProvider, $compileProvider, localStorageServiceProvider, APP_CONFIG) {
    // Set localStorage prefix
    localStorageServiceProvider.setPrefix('');
    // $ocLazyLoadProvider.config({
    // 	debug: true
    // });
    // Configure Location provider for browser/mobile
    // Browser
    if (!window.cordova) {
      $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
      });
      cfpLoadingBarProvider.includeBar = false;
      cfpLoadingBarProvider.latencyThreshold = 0;
    }  // Cordova
    else {
      // Add #!
      $locationProvider.html5Mode({
        enabled: false,
        requireBase: false
      });
      if (window.cordova.platformId !== 'android') {
        cfpLoadingBarProvider.includeBar = false;
        cfpLoadingBarProvider.includeSpinner = false;
        cfpLoadingBarProvider.latencyThreshold = 50;
      } else {
        cfpLoadingBarProvider.includeBar = false;
        cfpLoadingBarProvider.latencyThreshold = 0;
      }
    }
    $compileProvider.preAssignBindingsEnabled(true);
    $animateProvider.classNameFilter(/tc-is-animating/);
    // Disabling Debug Data
    $compileProvider.debugInfoEnabled(APP_CONFIG.app_env !== 'production');
    $httpProvider.defaults.useXDomain = true;
    $httpProvider.interceptors.push('authInterceptor', 'supportPretendInterceptor', 'suspendedAccountInterceptor', 'subscriptionInterceptor', 'cacheHttpInterceptor', 'maintenanceInterceptor', 'throttlingInterceptor', 'welcomeInterceptor');
    // 'duplicateRequestInterceptor'
    $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    Stripe.setPublishableKey(APP_CONFIG.services.stripe.publishable);
  }
  function runBlock($rootScope, $location, $transitions, $state, $timeout, $anchorScroll, $filter, beforeUnload, BreadCrumbsService, EmitService, CordovaService, APP_CONFIG, AuthDataService, SeoService, RealTimeService) {
    // $trace.enable('TRANSITION');
    // Set messages object
    Lang.setMessages(APP_CONFIG.trans);
    window.myAppErrorLog = [];
    $state.defaultErrorHandler(function (error) {
      // This is a naive example of how to silence the default error handler.
      window.myAppErrorLog.push(error);
    });
    // Currently logged user info
    AuthDataService.updateAuth({});
    $rootScope.auth_data = {};
    $rootScope.host = APP_CONFIG.host;
    $rootScope.constants = APP_CONFIG.constants;
    //fixed moment warning
    moment.createFromInputFallback = function (config) {
      // unreliable string magic, or
      config._d = new Date(config._i);
    };
    // Check if url is invite, clear credentials for SignUp
    if ($location.url().indexOf('/signup?invite') === 0 || $location.url().indexOf('/confirm?confirmation_code') === 0) {
      // Clear auth
      AuthDataService.clearAuth();
    }
    // Try login
    AuthDataService.verify(function (err, data) {
      if (!data) {
        RealTimeService.create();
        RealTimeService.initAuthResolver();
      }
    });
    // Set angular init for cordova
    // after 10 seconds
    if (window.cordova && window.mobile) {
      // Start spinner, in Cordova
      if (window.plugins.spinnerDialog) {
        window.plugins.spinnerDialog.hide();
      }
    }
    $transitions.onStart({}, function () {
      BreadCrumbsService.clear();
      EmitService.clear();
      $rootScope.state = $state;
      //sidebar
      angular.element('body').removeClass('sidebar-open');
      angular.element('.m-sidebar').removeClass('visible');
    });
    $transitions.onSuccess({}, function () {
      $rootScope.breadCrumbs = BreadCrumbsService.getCrumbs();
      $rootScope.state = $state;
      // For SEO
      $timeout(function () {
        angular.element('title').text(SeoService.getTitle());
      }, 100);
      // Clean listeners
      $rootScope.$$listeners['form:error'] = [];
      $rootScope.$$listeners['form:success'] = [];
      $anchorScroll();
    });
    $transitions.onError({}, function (transition) {
      if (APP_CONFIG.app_env !== 'production') {
        window.console.log('Resolve Error: ', transition.error());
      }
    });
    // State, maybe remove form this (use in $stateChangeStart)
    $rootScope.state = $state;
    // Init Cordova
    if (window.cordova) {
      CordovaService.init();
    }
    // Other, need change
    $rootScope.isEmptyObject = function (object) {
      var clone = _.clone(object);
      if (clone) {
        delete clone.status;
        // WorkOrders, Applications
        delete clone.status;  // RentalsFind
      }
      return _.isEmpty($filter('removeNulls')(clone));
    };
  }
}());