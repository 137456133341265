(function () {
  'use strict';
  /**
	 * @namespace dwollaTransfersComponent
	 * @desc Layout
	 * @param {Object} customer - Customer information
	 */
  var dwollaLayoutComponent = {
      bindings: { customer: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/dwollaLayout/layout.html',
      controller: [
        '$element',
        'DwollaService',
        function ($element, DwollaService) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            // Prepare customer
            vm.customer = DwollaService.prepareCustomer(vm.customer);
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.getHelpUrl = getHelpUrl;
          /**
			 * @desc Get url to need help
			 * @param {String} role - Role dashboard
			 */
          function getHelpUrl(role) {
            switch (role) {
            case 'admin': {
                return 'https://www.tenantcloud.com/faq/category/43';
              }
            case 'tenant':
              return 'https://www.tenantcloud.com/faq/category/59';
            case 'owner':
              return 'https://www.tenantcloud.com/faq/category/111';
            case 'professional':
              return 'https://www.tenantcloud.com/faq/category/94';
            default:
              return 'https://www.tenantcloud.com/faq/category/43';
            }
          }
        }
      ]
    };
  angular.module('app.dwollaLayout.component', []).component('dwollaLayoutComponent', dwollaLayoutComponent);
  ;
}());