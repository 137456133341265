(function () {
  'use strict';
  CameraService.$inject = [
    'Notify',
    '$splitConstant'
  ];
  angular.module('app.core.camera.services', []).service('CameraService', CameraService);
  ;
  /**
	 * Camera Service
	 *
	 * @returns {{cameraLoad: cameraLoad, cameraStop: cameraStop, isAllowCameraLoad: isAllowCameraLoad}}
	 */
  function CameraService(Notify, $splitConstant) {
    var streamRef, video, is_safari_desktop = $splitConstant.model.is_safari_desktop, getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        cameraLoad: cameraLoad,
        cameraStop: cameraStop,
        isAllowCameraLoad: isAllowCameraLoad
      };
    return service;
    /**
		 * Camera load
		 */
    function cameraLoad() {
      if (getUserMedia) {
        getUserMedia.call(navigator, { video: true }, function (stream) {
          streamRef = stream;
          video = angular.element('video')[0];
          if (window.URL) {
            video.src = window.URL.createObjectURL(stream);
          } else {
            video.src = stream;
          }
        }, function () {
          Notify.close();
          Notify.alert(Lang.get('general.camera.error.access_camera'));
        });
      }
    }
    /**
		 * Camera stop
		 */
    function cameraStop() {
      if (!_.isUndefined(streamRef)) {
        streamRef.getTracks().map(function (val) {
          val.stop();
        });
      }
    }
    /**
		 * Check is allow camera load
		 *
		 * @returns {*}
		 */
    function isAllowCameraLoad() {
      return !_.isUndefined(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) && !is_safari_desktop && !window.cordova;
    }
  }
}());