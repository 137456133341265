(function () {
  'use strict';
  CalendarService.$inject = [
    '$q',
    '$filter',
    'HttpService'
  ];
  angular.module('app.services.calendar', []).service('CalendarService', CalendarService);
  ;
  function CalendarService($q, $filter, HttpService) {
    var events;
    var service = {
        getEvents: getEvents,
        getEventsByDate: getEventsByDate,
        countDuplicateValue: countDuplicateValue,
        getImageMonth: getImageMonth
      };
    return service;
    /**
		 *
		 * @param instance
		 * @param dateStr
		 */
    function getEvents(instance, dateStr) {
      var params, deferred;
      if (instance && !dateStr) {
        params = {
          'start': moment([
            instance.currentYear,
            instance.currentMonth
          ]).subtract(2, 'month').endOf('month').format('YYYY/MM/DD'),
          'end': moment([
            instance.currentYear,
            instance.currentMonth
          ]).add(2, 'month').endOf('month').format('YYYY/MM/DD')
        };
      } else if (instance && dateStr) {
        params = {
          'start': moment(dateStr).subtract(1, 'month').startOf('month').format('YYYY/MM/DD'),
          'end': moment(dateStr).add(1, 'month').endOf('month').format('YYYY/MM/DD')
        };
      } else {
        params = {
          'start': moment().subtract(2, 'month').endOf('month').format('YYYY/MM/DD'),
          'end': moment().add(2, 'month').endOf('month').format('YYYY/MM/DD')
        };
      }
      deferred = $q.defer();
      HttpService.getWParams('/calendar', { 'params': params }, function (resp) {
        deferred.resolve(cacheEvents(resp));
      }, function () {
        deferred.reject();
      });
      return deferred.promise;
    }
    /**
		 *
		 * @param dayElem
		 * @returns {*}
		 */
    function getEventsByDate(dayElem) {
      var deferred = $q.defer();
      if (events) {
        deferred.resolve(_findEvent(dayElem));
      } else {
        service.getEvents().then(function () {
          deferred.resolve(_findEvent(dayElem));
        });
      }
      return deferred.promise;
    }
    /**
		 *
		 * @param events
		 * @returns {Array}
		 */
    function countDuplicateValue(events) {
      if (events) {
        var eventsArray = _.groupBy(events, function (item) {
            return $filter('dateFormat')(item.start, 'YYYY-MM-DD');
          });
        return _.reduce(eventsArray, function (acc, event, index) {
          acc.push({
            start: index,
            count: event.length
          });
          return acc;
        }, []);
      } else {
        return [];
      }
    }
    /**
		 *
		 * @param month
		 * @returns {boolean}
		 */
    function getImageMonth(month) {
      var filterMonth = $filter('dateFormat')(month, 'M'),
        // add filter dateFormat return number month
        findImg = _.find($filter('trans_as_array')('calendar_events.images'), { key: parseInt(filterMonth) });
      // find image url from array
      return !_.isUndefined(findImg) && findImg.value;
    }
    /**
		 *
		 * @param resp
		 * @private
		 */
    function _extract(resp) {
      return _prepareEvents(resp.events);
    }
    /**
		 *
		 * @param resp
		 * @returns {*}
		 */
    function cacheEvents(resp) {
      events = _extract(resp);
      return events;
    }
    /**
		 *
		 * @param dayElem
		 * @returns {*}
		 * @private
		 */
    function _findEvent(dayElem) {
      return _.find(events, { 'date': dayElem.getAttribute('aria-label') });
    }
    /**
		 *
		 * @param events
		 * @private
		 */
    function _prepareEvents(events) {
      return _.map(events, function (event) {
        var openLink;
        event.details = event.details || {};
        event.title = Lang.get('calendar_events.events.' + event.remindable_type + '.' + event.event_type + '.title', event.details);
        event.description = Lang.get('calendar_events.events.' + event.remindable_type + '.' + event.event_type + '.description', event.details);
        event.date = moment(event.start).format('YYYY-MM-DD');
        openLink = Lang.get('calendar_events.events.' + event.remindable_type + '.' + event.event_type + '.openLink', event.details);
        if (openLink) {
          event.openLink = openLink;
        }
        return event;
      });
    }
  }
}());