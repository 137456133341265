(function () {
  'use strict';
  /**
	 * @namespace workordersMessages
	 * @desc Workorders messages
	 * @example <workorders-messages></workorders-messages>
	 * @param {Object} messages
	 * @param {Object} workorder
	 */
  var workordersMessages = {
      bindings: {
        messages: '=',
        workorder: '<'
      },
      templateUrl: '/app/modules/shared/components/workorders/messages.html',
      controller: [
        '$rootScope',
        'HttpService',
        'Notify',
        function ($rootScope, HttpService, Notify) {
          'ngInject';
          var vm = this, page = 1, replyPage = [];
          vm.loadMore = loadMore;
          vm.loadMoreReplies = loadMoreReplies;
          vm.reply = reply;
          vm.remove = remove;
          vm.canReply = canReply;
          vm.canRemove = canRemove;
          vm.getReply = getReply;
          vm.isShowTenants = isShowTenants;
          vm.isShowProfessionl = isShowProfessionl;
          /**
			 * @desc Show tenants to professional if it allowed in MR settings
			 * @returns {boolean}
			 * @memberOf workordersMessages
			 */
          function isShowTenants() {
            return vm.workorder.tenants && (!$rootScope.auth._isPro() || vm.workorder.conf.show_tenant);
          }
          /**
			 * @desc Show professional to tenants if it allowed in MR settings
			 * @returns {boolean}
			 * @memberOf workordersMessages
			 */
          function isShowProfessionl() {
            return vm.workorder.user_professional && (!$rootScope.auth._isTenant() || vm.workorder.conf.show_professional);
          }
          function loadMore() {
            page++;
            HttpService.get('/workorders/' + vm.workorder.id + '/comments?page=' + page, function (resp) {
              vm.messages.list = _.union(vm.messages.list, resp.list);
              vm.messages.list = _.uniqBy(vm.messages.list, 'id');
              vm.messages.pagination = resp.pagination;
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
          function loadMoreReplies(key) {
            var replies = vm.messages.list[key].replies, reply_id = _.first(replies).reply_id;
            if (!replyPage[reply_id]) {
              replyPage[reply_id] = 1;
            }
            replyPage[reply_id]++;
            HttpService.get('/workorders/' + vm.workorder.id + '/comments?page=' + replyPage[reply_id] + '&reply_id=' + reply_id, function (resp) {
              vm.messages.list[key].replies = _.union(replies, resp.list);
              vm.messages.list[key].replies = _.uniqBy(vm.messages.list[key].replies, 'id');
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
          function reply(message) {
            vm.showReply = vm.showReply !== message.id ? message.id : null;
          }
          function getReply(showReply) {
            vm.showReply = showReply;
          }
          function remove(message) {
            Notify.confirm(function () {
              HttpService.delete('/workorders/' + vm.workorder.id + '/comments/' + message.id, function () {
                Notify.success(Lang.get('notify_actions.successfully'));
                vm.messages.list = _.filter(vm.messages.list, function (messageInList) {
                  return messageInList !== message;
                });
                vm.messages.pagination.total--;
              }, function () {
                Notify.error(Lang.get('notify_actions.ooops'));
              });
            }, Lang.get('notify_actions.delete_confirm'));
          }
          function canReply(message) {
            if (!vm.workorder.landlord_id) {
              return false;
            }
            if (!$rootScope.auth._hasAccessToSection('workorders', 'edit')) {
              return false;
            }
            return $rootScope.auth.id !== message.user_id;
          }
          function canRemove(message) {
            if (!$rootScope.auth._hasAccessToSection('workorders', 'delete')) {
              return false;
            }
            return $rootScope.auth.id === message.user_id;
          }
        }
      ]
    };
  var workordersMessagesCreate = {
      bindings: {
        workorder: '<',
        messages: '=',
        replyId: '<',
        close: '<',
        setreply: '&'
      },
      templateUrl: '/app/modules/shared/components/workorders/messages-create.html',
      controller: [
        '$rootScope',
        '$scope',
        'HttpService',
        function ($rootScope, $scope, HttpService) {
          'ngInject';
          var vm = this;
          if (!vm.message) {
            vm.message = { files: [] };
          }
          vm.canSend = canSend;
          vm.create = create;
          vm.reply = reply;
          function create() {
            vm.message.reply_id = vm.replyId;
            HttpService.post('/workorders/' + vm.workorder.id + '/comments', vm.message, function (resp) {
              $scope.$emit('form:error', false);
              vm.messages.unshift(resp);
              vm.message = { files: [] };
              vm.workorder.comments_count++;
              vm.setreply({ showReply: false });
            });
          }
          function reply(message) {
            vm.setreply({ showReply: vm.showReply !== message.id ? message.id : null });
          }
          function canSend() {
            if ($rootScope.auth.id !== vm.workorder.landlord_id) {
              return !!vm.workorder.landlord_id;
            }
            return vm.workorder.shared.tenants.length || vm.workorder.shared.professional || vm.user_id !== vm.workorder.landlord_id;
          }
        }
      ]
    };
  var customRadioTime = {
      bindings: {
        model: '<',
        options: '<',
        name: '@'
      },
      templateUrl: '/app/modules/shared/components/workorders/custom-radio-time.html',
      controller: function () {
        var vm = this;
        vm.model.time = angular.isArray(vm.model.time) ? vm.model.time : [];
        vm.customRadio = {
          toggleTime: function (key) {
            var idx = vm.model.time.indexOf(key);
            // is currently selected
            if (idx > -1) {
              vm.model.time.splice(idx, 1);
            } else {
              vm.model.time.push(key);
            }
          },
          isDisabled: function (key) {
            var disabledKey = [
                2,
                3,
                4
              ], anyTime = 1;
            if (vm.model.time.indexOf(anyTime) !== -1) {
              if (disabledKey.indexOf(key) !== -1) {
                vm.model.time = [anyTime];
                return true;
              }
            }
          }
        };
      }
    };
  angular.module('app.components.workorders', []).component('workordersMessages', workordersMessages).component('workordersMessagesCreate', workordersMessagesCreate).component('customRadioTime', customRadioTime);
  ;
}());