(function () {
  'use strict';
  angular.module('app.notify', ['ui-notification']).config([
    'NotificationProvider',
    function (NotificationProvider) {
      NotificationProvider.setOptions({
        delay: 4000,
        startTop: 40,
        startRight: 10,
        verticalSpacing: 20,
        horizontalSpacing: 20,
        positionX: 'right',
        positionY: 'top',
        closeOnClick: true,
        maxCount: 5,
        templateUrl: '/app/modules/shared/factories/angular-ui-notification.html'
      });
    }
  ]).factory('Notify', [
    '$rootScope',
    'ngDialog',
    'Notification',
    'CordovaService',
    function ($rootScope, ngDialog, Notification, CordovaService) {
      var defaultDelay = window.location.href.indexOf('seleniumtc.l') !== -1 ? 100 : 4000;
      return {
        confirm: function (callback, title, template, data, cancelCallback, opts) {
          if (CordovaService.dialogAvailable() && !template && !data) {
            CordovaService.dialogConfirm(callback, title || Lang.get('general.are_you_sure_you_want_to_do_this'));
          } else {
            var options = {
                templateUrl: template ? template.toString() : '/app/modules/shared/directives/main/confirm.html',
                showClose: false,
                data: {
                  title: title,
                  data: data
                },
                scope: true
              };
            angular.extend(options, opts);
            ngDialog.openConfirm(options).then(function (value) {
              $rootScope.$evalAsync(function () {
                callback(value);
              });
            }, function (value) {
              if (cancelCallback) {
                $rootScope.$evalAsync(function () {
                  cancelCallback(value);
                });
              }
            });
          }
        },
        alert: function (message, title) {
          title = title ? title : Lang.get('notify_actions.notify.attention');
          if (CordovaService.dialogAvailable()) {
            CordovaService.dialogAlert(message, title);
          } else {
            ngDialog.open({
              templateUrl: '/app/modules/shared/factories/notify-alert.html',
              scope: true,
              data: {
                title: title,
                message: message
              }
            });
          }
        },
        ok: function (data) {
          var message, title;
          title = data.title || '';
          if (CordovaService.dialogAvailable()) {
            message = [
              data.message,
              data.line1,
              data.line2
            ].join(' ');
            CordovaService.dialogOk(message, title);
          } else {
            ngDialog.open({
              templateUrl: '/app/modules/shared/factories/notify-ok.html',
              scope: true,
              data: {
                title: data.title,
                message: data.message,
                line1: data.line1,
                line2: data.line2
              }
            });
          }
        },
        info: function (text, delay) {
          if (CordovaService.toastAvailable()) {
            CordovaService.toast(text, 'info', delay || defaultDelay);
          } else {
            Notification.info({
              message: text,
              delay: delay || defaultDelay
            });
          }
        },
        success: function (text, delay) {
          if (CordovaService.toastAvailable()) {
            CordovaService.toast(text, 'success', delay || defaultDelay);
          } else {
            Notification.success({
              message: text,
              delay: delay || defaultDelay
            });
          }
        },
        warning: function (text) {
          if (CordovaService.toastAvailable()) {
            CordovaService.toast(text, 'warning');
          } else {
            Notification.warning({ message: text });
          }
        },
        error: function (text, delay) {
          if (CordovaService.toastAvailable()) {
            CordovaService.toast(text, 'error', delay || defaultDelay);
          } else {
            Notification.error({
              message: text,
              delay: delay || defaultDelay
            });
          }
        },
        clear: function () {
          Notification.clearAll();
        },
        close: function () {
          ngDialog.closeAll();
        }
      };
    }
  ]);
  ;
}());