(function () {
  'use strict';
  var confirmCode = {
      bindings: {
        item: '=',
        errors: '<',
        isComplete: '&',
        label: '@'
      },
      templateUrl: '/app/modules/auth/components/confirm-code.html',
      controller: [
        '$scope',
        '$splitInput',
        '$element',
        function ($scope, $splitInput, $element) {
          'ngInject';
          var vm = this, $elem = $element.find('.m-confirmation-code');
          $splitInput.make($elem);
          $scope.$watchCollection('$ctrl.confirm', function () {
            if ($splitInput.value($elem)) {
              vm.item.confirmation_code = $splitInput.value($elem);
            }
            vm.isComplete({ status: $splitInput.isComplete($elem) });
          });
        }
      ]
    };
  var rental = {
      templateUrl: '/app/modules/auth/components/rental.html',
      controller: [
        '$rootScope',
        '$stateParams',
        'AuthInviteService',
        'HttpService',
        'APP_CONFIG',
        function ($rootScope, $stateParams, AuthInviteService, HttpService, APP_CONFIG) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            vm.isLoaded = false;
            getRental();
          };
          /**
			 * Get Apply Rental Application
			 */
          function getRental() {
            HttpService.getWParams('/auth/application_info', { params: $stateParams }, function (resp) {
              vm.isLoaded = true;
              vm.rental = resp.apply_rental;
              vm.landlord_mode = Lang.get('general.mode.options.' + _.get(vm.rental, 'user_landlord.mode'));
              vm.linkToListing = 'https://' + resp.apply_rental_domain + '.' + APP_CONFIG.host + '/listing/' + resp.apply_rental.unit_id;
            });
          }
        }
      ]
    };
  var invite = {
      templateUrl: '/app/modules/auth/components/invite.html',
      controller: [
        '$rootScope',
        '$stateParams',
        'HttpService',
        'AuthInviteService',
        'AuthTransformer',
        function ($rootScope, $stateParams, HttpService, AuthInviteService, AuthTransformer) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            vm.isLoaded = false;
            getInvite();
          };
          /**
			 * Get invite info
			 */
          function getInvite() {
            HttpService.getWParams('/auth/invite_info', { params: $stateParams }, function (resp) {
              vm.isLoaded = true;
              vm.invite = resp.invite;
              vm.landlord_mode = Lang.get('general.mode.options.' + _.get(vm.invite, 'sender.mode'));
              vm.isSenderLandlord = AuthTransformer.build(vm.invite.sender)._isLandlord();
              AuthInviteService.setInvite(resp.invite);
              $rootScope.$emit('auth:invite');
            });
          }
        }
      ]
    };
  angular.module('app.auth_components', []).component('confirmCode', confirmCode).component('rental', rental).component('invite', invite);
  ;
}());