(function () {
  'use strict';
  var filterContactTenants = {
      bindings: { isMovein: '<' },
      templateUrl: '/app/modules/landlord/components/filter/contacts/tenants.html',
      controller: [
        '$scope',
        '$q',
        '$filter',
        'ng_util',
        'PagingFilterService',
        'FilterService',
        'HttpService',
        function ($scope, $q, $filter, ng_util, PagingFilterService, FilterService, HttpService) {
          'ngInject';
          var vm = this, refreshUnitUrl = true, unitUrl = '/landlord/property/unitsForFilter';
          // Construct
          vm.isLoaded = false;
          vm.propertyUrl = '/landlord/property/forFilter';
          vm.needShowUnit = needShowUnit;
          vm.refreshUnitOptions = function (q) {
            vm.units = [];
            vm.propertySelected = false;
            if (unitUrl && $scope.filter && $scope.filter.property) {
              var params = {
                  property_id: $scope.filter.property,
                  q: q
                };
              if ($scope.filter.unit) {
                params.id = $scope.filter.unit;
              }
              var promise = asyncRequest(HttpService, unitUrl, params, $q);
              promise.then(function (obj) {
                vm.units = obj.units.list;
                if (!obj.units.showSearch && refreshUnitUrl) {
                  unitUrl = false;
                }
                refreshUnitUrl = false;
                vm.propertySelected = true;
              });
            }
          };
          /**
			 * Show input unit if property multi
			 * @returns {boolean}
			 */
          function needShowUnit() {
            var property = _.find(vm.properties, function (item) {
                return item.key === $scope.filter.property;
              });
            return _.get(property, 'type') === 2 && vm.isMovein;
          }
          // delete params.page;
          initFilter($scope, vm, PagingFilterService);
          ng_util.definePropertyObj(FilterService, 'request', function (request) {
            if (request) {
              vm.isLoaded = true;
            }
          });
          ng_util.definePropertyObj(FilterService, 'source', function (source) {
            if (source) {
              if (source.newValue.property !== (source.oldValue && source.oldValue.property)) {
                if (_.isUndefined(source.newValue.property) || source.newValue.unit) {
                  $scope.filter.unit = null;
                }
                unitUrl = '/landlord/property/unitsForFilter';
                vm.refreshUnitOptions();
              }
            }
          });
        }
      ]
    };
  var filterContactOwners = {
      templateUrl: '/app/modules/landlord/components/filter/contacts/owners.html',
      controller: [
        '$scope',
        '$q',
        '$filter',
        'ng_util',
        'PagingFilterService',
        'FilterService',
        'HttpService',
        function ($scope, $q, $filter, ng_util, PagingFilterService, FilterService, HttpService) {
          'ngInject';
          var vm = this, refreshUnitUrl = true, unitUrl = '/landlord/property/unitsForFilter';
          // Construct
          vm.isLoaded = false;
          vm.propertyUrl = '/landlord/property/forFilter';
          vm.needShowUnit = needShowUnit;
          vm.refreshUnitOptions = function (q) {
            vm.units = [];
            vm.propertySelected = false;
            if (unitUrl && $scope.filter && $scope.filter.property) {
              var params = {
                  property_id: $scope.filter.property,
                  q: q
                };
              if ($scope.filter.unit) {
                params.id = $scope.filter.unit;
              }
              var promise = asyncRequest(HttpService, unitUrl, params, $q);
              promise.then(function (obj) {
                vm.units = obj.units.list;
                if (!obj.units.showSearch && refreshUnitUrl) {
                  unitUrl = false;
                }
                refreshUnitUrl = false;
                vm.propertySelected = true;
              });
            }
          };
          /**
			 * Show input unit if property multi
			 * @returns {boolean}
			 */
          function needShowUnit() {
            var property = _.find(vm.properties, function (item) {
                return item.key === $scope.filter.property_id;
              });
            return _.get(property, 'type') === 2;
          }
          // delete params.page;
          initFilter($scope, vm, PagingFilterService);
          ng_util.definePropertyObj(FilterService, 'request', function (request) {
            if (request) {
              vm.isLoaded = true;
            }
          });
          ng_util.definePropertyObj(FilterService, 'source', function (source) {
            if (source) {
              if (source.newValue.property !== (source.oldValue && source.oldValue.property)) {
                if (_.isUndefined(source.newValue.property) || source.newValue.unit) {
                  $scope.filter.unit = null;
                }
                unitUrl = '/landlord/property/unitsForFilter';
                vm.refreshUnitOptions();
              }
            }
          });
        }
      ]
    };
  var filterContactProfessionals = {
      templateUrl: '/app/modules/landlord/components/filter/contacts/professionals.html',
      controller: [
        '$scope',
        '$q',
        '$filter',
        'ng_util',
        'PagingFilterService',
        'FilterService',
        'HttpService',
        function ($scope, $q, $filter, ng_util, PagingFilterService, FilterService, HttpService) {
          'ngInject';
          var vm = this;
          // Construct
          vm.isLoaded = false;
          vm.serviceProMainCategories = {};
          _getServiceProMainCategories();
          vm.refreshServiceProSubOptions = function () {
            vm.serviceProSubCategories = [];
            vm.serviceProMainCategoriesSelected = false;
            HttpService.getWParams('/landlord/servicepro/forFilter', {
              params: {
                filter_by: 'only_sub',
                parent_id: $scope.filter.category_id
              }
            }, function (resp) {
              vm.serviceProSubCategories = resp.list;
              if (vm.serviceProSubCategories.length) {
                vm.serviceProMainCategoriesSelected = true;
              }
            });
          };
          // delete params.page;
          initFilter($scope, vm, PagingFilterService);
          ng_util.definePropertyObj(FilterService, 'request', function (request) {
            if (request) {
              vm.isLoaded = true;
            }
          });
          function _getServiceProMainCategories() {
            HttpService.getWParams('/landlord/servicepro/forFilter', { params: { filter_by: 'only_main' } }, function (resp) {
              vm.serviceProMainCategories = $filter('serviceProMainCategories')(resp.list);
            });
          }
        }
      ]
    };
  var filterContactProviders = {
      templateUrl: '/app/modules/landlord/components/filter/contacts/providers.html',
      controller: [
        '$scope',
        '$q',
        '$filter',
        'ng_util',
        'PagingFilterService',
        'FilterService',
        'HttpService',
        function ($scope, $q, $filter, ng_util, PagingFilterService, FilterService, HttpService) {
          'ngInject';
          var vm = this;
          // Construct
          vm.isLoaded = false;
          vm.serviceProMainCategories = {};
          _getServiceProMainCategories();
          vm.refreshServiceProSubOptions = function () {
            vm.serviceProSubCategories = [];
            vm.serviceProMainCategoriesSelected = false;
            HttpService.getWParams('/landlord/servicepro/forFilter', {
              params: {
                filter_by: 'only_sub',
                parent_id: $scope.filter.category_id
              }
            }, function (resp) {
              vm.serviceProSubCategories = resp.list;
              if (vm.serviceProSubCategories.length) {
                vm.serviceProMainCategoriesSelected = true;
              }
            });
          };
          // delete params.page;
          initFilter($scope, vm, PagingFilterService);
          ng_util.definePropertyObj(FilterService, 'request', function (request) {
            if (request) {
              vm.isLoaded = true;
            }
          });
          function _getServiceProMainCategories() {
            HttpService.getWParams('/landlord/servicepro/forFilter', { params: { filter_by: 'only_main' } }, function (resp) {
              vm.serviceProMainCategories = $filter('serviceProMainCategories')(resp.list);
            });
          }
        }
      ]
    };
  function asyncRequest(HttpService, url, params, $q) {
    var deferred = $q.defer();
    HttpService.getWParams(url, { 'params': params }, function (resp) {
      deferred.resolve({ units: resp });
    }, function () {
      deferred.reject();
    });
    return deferred.promise;
  }
  function initFilter($scope, vm, PagingFilterService) {
    // init pagination
    PagingFilterService.bind($scope);
    vm.clearFilter = function () {
      PagingFilterService.clear();
    };
  }
  angular.module('app.landlord_components.filter.contact', []).component('filterContactTenants', filterContactTenants).component('filterContactOwners', filterContactOwners).component('filterContactProfessionals', filterContactProfessionals).component('filterContactProviders', filterContactProviders);
  ;
}());