(function () {
  'use strict';
  FileManagerService.$inject = [
    '$stateParams',
    'APP_CONFIG'
  ];
  angular.module('app.filemanager.services', []).service('FileManagerService', FileManagerService);
  ;
  function FileManagerService($stateParams, APP_CONFIG) {
    var service = {
        ui: ui,
        getParentName: getParentName
      };
    var _show = false;
    return service;
    /**
		 *
		 * @returns {{getNav: getNav, setNav: setNav}}
		 */
    function ui() {
      return {
        getNav: function () {
          return _show;
        },
        setNav: function (show) {
          _show = show;
        }
      };
    }
    /**
		 * @param file
		 * @returns {*}
		 * @private
		 */
    function getParentName(file) {
      var parents = APP_CONFIG.file_manager.type_groups, parent = _.get(file, 'parent.name') || _.get(file, 'parent.title') || _.get(file, 'parent.fullAddress'), name = null;
      _.forEach(parents, function (item, key) {
        if (_.indexOf(item, file.type) !== -1) {
          name = Lang.get('file_manager.type_groups.' + key);
          if (parent) {
            name += ' ' + parent;
          }
        }
      });
      if (name) {
        return name;
      }
      if (parent) {
        if (!$stateParams.unit_id && $stateParams.property_id) {
          return Lang.get('general.property') + ' ' + parent;
        }
        if ($stateParams.unit_id) {
          return Lang.get('general.unit') + ' ' + parent;
        }
        return Lang.get('general.other') + ' ' + parent;
      }
      return Lang.get('notify_actions.not_found');
    }
  }
}());