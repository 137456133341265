(function () {
  'use strict';
  var jumbotron = {
      bindings: { breadcrumbs: '<' },
      templateUrl: '/app/modules/shared/components/main/jumbotron.html'
    };
  var footerSidebar = {
      bindings: {
        addBtn: '<?',
        importBtn: '<?',
        findBtn: '<?',
        setclass: '<'
      },
      transclude: {
        inviteApplyOnline: '?inviteApplyOnline',
        btnHelpCenter: '?btnHelpCenter'
      },
      templateUrl: '/app/modules/shared/components/main/footer-sidebar.html',
      controller: [
        'FilterService',
        function (FilterService) {
          'ngInject';
          var vm = this;
          vm.showFilterPanel = function () {
            FilterService.display();
          };
        }
      ]
    };
  /**
	 * @namespace footerSidebarTransactions
	 * @desc Footer sidebar transactions
	 */
  var footerSidebarTransactions = {
      transclude: {
        createInvoice: '?createInvoice',
        markAsPaid: '?markAsPaid',
        payOnline: '?payOnline'
      },
      templateUrl: '/app/modules/shared/components/main/footer-sidebar-transactions.html'
    };
  var headerSidebar = {
      bindings: {
        hideGrid: '<?',
        hideFilter: '<?',
        hideSearch: '<?',
        addBtn: '<?',
        importBtn: '<?',
        setclass: '<'
      },
      transclude: {
        navList: '?navList',
        btnFilter: '?btnFilter',
        moneyAction: '?moneyAction',
        btnHelpCenter: '?btnHelpCenter',
        inviteApplyOnline: '?inviteApplyOnline',
        btnCsv: '?btnCsv'
      },
      templateUrl: '/app/modules/shared/components/main/header-sidebar.html',
      controller: [
        '$rootScope',
        '$scope',
        '$state',
        '$element',
        '$mdMedia',
        'FilterService',
        'ColumnInterfaceService',
        'PagingFilterService',
        'ng_util',
        function ($rootScope, $scope, $state, $element, $mdMedia, FilterService, ColumnInterfaceService, PagingFilterService, ng_util) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-fillWidth');
          };
          ng_util.definePropertyObj(FilterService, 'filterTags', function (source) {
            if (source) {
              if (JSON.stringify(source.newValue) !== JSON.stringify(source.oldValue)) {
                vm.filter = _getFilter(source.newValue);
              }
            }
          });
          // after check filterTags set new state filter
          if (PagingFilterService.getScope()) {
            var _pagingFilter = PagingFilterService.getScope().filter;
            vm.filter = _getFilter(_pagingFilter);
          }
          vm.$mdMedia = $mdMedia;
          vm.resizeWindow = ColumnInterfaceService.init();
          vm.changeGrid = ColumnInterfaceService.changeGrid;
          vm.showFilterPanel = function () {
            FilterService.display();
          };
          /**
			 * Get Filter
			 * @param source
			 * @returns {Array}
			 * @private
			 */
          function _getFilter(source) {
            if ($state.current.name === 'equipment.list') {
              delete source.property_id;
            }
            if ($state.current.name === 'equipment.unit_list') {
              delete source.property_id;
              delete source.unit_id;
            }
            return _.filter(source, function (item) {
              if (!_.isUndefined(item) && !_.isNull(item)) {
                return true;
              }
            });
          }
        }
      ]
    };
  var headerList = {
      bindings: {},
      transclude: {
        typeClick: '?typeClick',
        typeList: '?typeList',
        sortClick: '?sortClick',
        sortList: '?sortList',
        total: '?total',
        typeTitle: '?typeTitle'
      },
      templateUrl: '/app/modules/shared/components/main/header-list.html',
      controller: function () {
        var vm = this;
        vm.clickDotsSort = clickDotsSort;
        vm.clickDotsType = clickDotsType;
        /**
			 * clickDotsSort
			 */
        function clickDotsSort() {
          vm.showDotsSort = !vm.showDotsSort;
          vm.showDotsType = false;
        }
        /**
			 * clickDotsType
			 */
        function clickDotsType() {
          vm.showDotsType = !vm.showDotsType;
          vm.showDotsSort = false;
        }
      }
    };
  var maintenanceMode = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/main/maintenance_mode.html',
      controller: [
        '$rootScope',
        '$filter',
        'APP_CONFIG',
        function ($rootScope, $filter, APP_CONFIG) {
          'ngInject';
          var vm = this, status = !!APP_CONFIG.maintenance_mode.status, deadline;
          vm.maintenance_mode = status;
          if (status) {
            deadline = APP_CONFIG.maintenance_mode.deadline;
            vm.deadline = $filter('convertDateByTimezone')(deadline, 'dd MMM yyyy h:mm a');
          }
          $rootScope.$on('maintenance_mode:enable', function (event, data) {
            vm.maintenance_mode = true;
            deadline = data.mode_enables_at.datetime;
            vm.deadline = $filter('convertDateByTimezone')(deadline, 'dd MMM yyyy h:mm a');
          });
        }
      ]
    };
  var chat = {
      bindings: { activeClientId: '<' },
      templateUrl: '/app/modules/shared/components/main/chat.html',
      controller: [
        '$rootScope',
        '$state',
        '$filter',
        '$mdMedia',
        '$timeout',
        '$notification',
        '$window',
        'MessageHelper',
        'Notify',
        'ng_util',
        'image_util',
        'HttpService',
        'EmitService',
        'CounterService',
        'PopupService',
        'CordovaService',
        function ($rootScope, $state, $filter, $mdMedia, $timeout, $notification, $window, MessageHelper, Notify, ng_util, image_util, HttpService, EmitService, CounterService, PopupService, CordovaService) {
          'ngInject';
          var vm = this, page = 1, messagesPage = 1, loadCon = { on: false };
          /**
			 * SCOPE INIT
			 ============*/
          vm.scrolldown = true;
          vm.getCounter = CounterService.getCounter;
          vm.conversations = [];
          vm.activeConv = { messages: false };
          vm.isActivePropertyBoard = false;
          vm.property_id = false;
          vm.pagination = [];
          vm.search = {};
          vm.loadMoreConversations = loadMoreConversationFn;
          vm.searchConversation = searchConversationFn;
          vm.loadConversation = loadConversationFn;
          vm.removeConversation = removeConversationFn;
          vm.exportConversation = exportConversationFn;
          vm.popupOpen = PopupService.open;
          vm.goToUser = goToUser;
          vm.onScrollConversation = onScrollConversation;
          vm.newMessage = newMessage;
          vm.isCurrentYear = isCurrentYear;
          vm.$mdMedia = $mdMedia;
          vm.helper = {
            show: false,
            showNav: function () {
              this.show = true;
            },
            hideNav: function () {
              this.show = false;
            }
          };
          /**
			 * Load clients
			 */
          HttpService.get('/messages/users', function (resp) {
            // Sort clients by last message date
            vm.clients = sortClientsByLastMessage(resp.data);
            // Uses to store initial contacts, to determine is user has some connected contacts when searching in filter
            vm.initialClients = _.clone(vm.clients);
            if (resp.activePropertyBoardId) {
              vm.isActivePropertyBoard = true;
              vm.property_id = resp.activePropertyBoardId;
            }
            var client;
            if (vm.activeClientId) {
              // Load conversation from url
              client = _.find(vm.clients, { id: parseInt(vm.activeClientId) });
            } else if (vm.clients.length) {
              // Load first client
              client = _.first(vm.clients);
            }
            if (client) {
              loadConversationFn(client);
            }
          });
          /**
			 * WATCHERS and LISTENERS
			 =======================*/
          //Auto filtering if smth was typed
          ng_util.definePropertyObj(vm.search, 'q', function (value) {
            if (!_.isUndefined(value)) {
              searchConversationFn(value);
            }
          });
          //request for HTML5 notifications api
          $notification.requestPermission();
          ng_util.definePropertyObj(EmitService, 'newMessage', function (message) {
            if (!_.isUndefined(message)) {
              newMessageWasAdded(message);
            }
          });
          // Refreshes seen
          ng_util.definePropertyObj(EmitService, 'threadWasReadObject', function (data) {
            if (!_.isUndefined(data)) {
              if (!vm.activeConv || vm.activeConv.conversationId !== data.thread.id) {
                return;
              }
              var participant = _.find(data.participants, function (participant) {
                  return participant.user_id !== $rootScope.auth.id;
                });
              ng_util.safeApply($rootScope, function () {
                _.map(vm.activeConv.messages, function (message) {
                  message.is_read = participant.last_read >= message.created_at;
                  return message;
                });
              });
            }
          });
          /**
			 * CONVERSATIONS LIST functions
			 ==============================*/
          /**
			 * Helper method to group fire events
			 *
			 * @param message
			 */
          function newMessageWasAdded(message) {
            _.map(vm.clients, function (client) {
              if (client.relation_id === message.user_id) {
                client.thread = {
                  id: message.thread_id,
                  last_message: message,
                  updated_at: message.updated_at
                };
                if (client.id !== vm.activeConv.companion.id) {
                  client.thread.is_unread = true;
                }
              }
              return client;
            });
            vm.clients = sortClientsByLastMessage(vm.clients);
            if (vm.activeConv) {
              vm.activeConv.conversationId = message.thread_id;
              if (vm.activeConv.companion.relation_id === message.user_id) {
                message = assignUserToMessage(message);
                vm.activeConv.messages.push(message);
                vm.activeConv.messages = _.uniqBy(vm.activeConv.messages, 'id');
                vm.activeConv.messages = MessageHelper.prepareDataWithShowDateLabels(vm.activeConv.messages);
                HttpService.put('/messages/' + message.thread_id + '/lastRead', null, null, { ignoreLoadingBar: true });
              }
            }
          }
          /**
			 * Scroll down after add new message
			 */
          function newMessage() {
            vm.clients = sortClientsByLastMessage(vm.clients);
            vm.scrolldown = true;
          }
          function sortClientsByLastMessage(clients) {
            return _.sortBy(clients, function (client) {
              // Disconnected low priority
              if (client.status !== 2) {
                return -1;
              }
              // Empty contacts
              if (!client.thread || !client.thread.last_message) {
                return 0;
              }
              // Sort by last message date
              return new Date(client.thread.last_message.created_at).getTime();
            }).reverse();
          }
          /**
			 * Load more conversations
			 */
          function loadMoreConversationFn() {
            page++;
            var params = { 'params': { page: page } };
            if (vm.search) {
              params.params = _.extend(params.params, vm.search);
            }
            HttpService.getWParams('/messages/users', params, function (resp) {
              vm.conversations = vm.conversations.concat(resp.data);
              vm.pagination = resp.pagination;
            });
          }
          /**
			 * Search between conversations by params and set new list of convs
			 */
          function searchConversationFn(query) {
            page = 1;
            HttpService.getWParams('/messages/users', { 'params': { q: query } }, function (resp) {
              resp.data = _.sortBy(resp.data, 'created_at');
              vm.clients = resp.data;
              vm.pagination = resp.pagination;
              angular.element('#userScrollable').scrollTop(0).perfectScrollbar('update');
            });
          }
          /**
			 * ONE CONVERSATION functions
			 ===========================*/
          /**
			 * Show one conversation by id
			 * @param client
			 */
          function loadConversationFn(client) {
            vm.helper.showNav();
            vm.scrolldown = true;
            // If already loaded
            if (vm.activeConv.companion && vm.activeConv.companion.id === client.id) {
              return;
            }
            messagesPage = 1;
            loadCon.on = true;
            vm.activeConv.client = client;
            if (client.thread) {
              vm.activeConv.conversationId = client.thread.id;
              client.thread.is_unread = false;
            }
            HttpService.get('/messages/' + client.id, function (resp) {
              resp.data = _.sortBy(resp.data, 'created_at');
              _.map(resp.data, assignUserToMessage);
              vm.activeConv.messages = MessageHelper.prepareDataWithShowDateLabels(resp.data);
              vm.activeConv.pagination = resp.pagination;
              vm.activeConv.companion = client;
              vm.activeConv.companion.hasActiveLease = resp.hasActiveLease;
              loadCon.on = false;
              angular.element('#messageScrollable').scrollTop(0).perfectScrollbar('update');
            });
          }
          function assignUserToMessage(message) {
            message.user = $rootScope.auth.id != message.user_id ? vm.activeConv.client : $rootScope.auth.subAccount;
            return message;
          }
          function removeConversationFn() {
            Notify.confirm(function () {
              HttpService.delete('/messages/' + vm.activeConv.conversationId, function (resp) {
                Notify.success(resp.message);
                vm.activeConv.messages = [];
                vm.activeConv.pagination = {
                  current_page: 1,
                  last_page: 1
                };
                vm.activeConv.companion.thread.last_message = null;
                vm.clients = sortClientsByLastMessage(vm.clients);
              });
            }, Lang.get('messages.delete_thread_confirm'));
          }
          /**
			 * @name exportConversationFn
			 * @desc Export conversation
			 * @param conversation
			 * @returns {Window | null}
			 * @memberOf chat
			 */
          function exportConversationFn(conversation) {
            if (CordovaService.isCordova()) {
              HttpService.get('/messages/thread/' + conversation.conversationId + '/export?file_url=true', function (resp) {
                if (resp.file_url) {
                  return CordovaService.openExternalUrl(resp.file_url);
                }
              });
            } else {
              return window.open($filter('dashboard_url')('/v1/messages/thread/' + conversation.conversationId + '/export'), '_system');
            }
          }
          /**
			 * Paginate messages
			 */
          function onScrollConversation(scrollTop) {
            // Prevent loading if last page
            if (vm.activeConv.pagination.last_page === messagesPage) {
              return;
            }
            vm.scrollTop = scrollTop;
            if (vm.scrollTop !== 0 || loadCon.on !== false) {
              return;
            }
            messagesPage++;
            HttpService.getWParams('/messages/' + vm.activeConv.companion.id, { 'params': { page: messagesPage } }, function (resp) {
              if (!resp.data.length) {
                return;
              }
              resp.data = _.map(resp.data, assignUserToMessage);
              var messages = resp.data.concat(vm.activeConv.messages);
              messages = _.uniqBy(messages, 'id');
              messages = _.sortBy(messages, 'created_at');
              vm.activeConv.messages = MessageHelper.prepareDataWithShowDateLabels(messages);
              vm.activeConv.pagination = resp.pagination;
              vm.scrolldown = false;
            });
          }
          /**
			 * Go to user page by its role and id
			 */
          function goToUser(role, id) {
            if (!$rootScope.auth._hasAccessToSection('contacts', 'view')) {
              return;
            }
            if (id === false) {
              return;
            }
            switch (role) {
            case 'professional':
              $state.go('professionals.view', { id: id });
              break;
            case 'tenant':
              $state.go('tenants.view', { id: id });
              break;
            case 'owner':
              $state.go('owners.view', { id: id });
              break;
            }
          }
          function isCurrentYear(date) {
            return moment(date).format('YYYY') === moment().format('YYYY');
          }
        }
      ]
    };
  var messageSender = {
      bindings: {
        conversationid: '<',
        clients: '<',
        companion: '<',
        messages: '=',
        options: '<',
        clientId: '<',
        addNewMessage: '&'
      },
      templateUrl: '/app/modules/shared/components/main/message-sender.html',
      controller: [
        'APP_CONFIG',
        '$mdMedia',
        '$state',
        '$rootScope',
        'EmitService',
        'HttpService',
        'MessageHelper',
        'FileService',
        'Notify',
        function (APP_CONFIG, $mdMedia, $state, $rootScope, EmitService, HttpService, MessageHelper, FileService, Notify) {
          'ngInject';
          var vm = this, apiUrl = '/messages', message;
          vm.$mdMedia = $mdMedia;
          _resetScope();
          if (vm.clientId) {
            vm.clientId = parseInt(vm.clientId);
            vm.newMessage.recipients = _.find(vm.options, { id: vm.clientId });
          }
          vm.addMessage = addMessage;
          vm.redirectConfirmation = redirectConfirmation;
          function addMessage() {
            if (!vm.newMessage.body) {
              if (vm.files && vm.files.length) {
                Notify.error(Lang.get('notify_actions.messages.please_add_a_message'));
              } else {
                angular.element('.form-control').focus();
                // fix focus after submit
                Notify.error(Lang.get('notify_actions.messages.empty_message'));
              }
              return false;
            }
            message = angular.copy(vm.newMessage);
            message.body = _parseLine(message);
            message.user = $rootScope.auth;
            message.user_id = $rootScope.auth.id;
            vm.messages.push(message);
            _resetScope();
            HttpService.post(apiUrl + '/' + vm.clientId, message, function (respMessage) {
              vm.messages.splice(vm.messages.indexOf(message), 1, respMessage);
              angular.element('.form-control').focus();
              // fix focus after submit
              // Update message in clients list
              _.map(vm.clients, function (client) {
                // Attach thread to client
                if (vm.clientId === client.id) {
                  client.thread = {
                    id: respMessage.thread_id,
                    last_message: respMessage
                  };
                }
                return client;
              });
              vm.messages = _.uniqBy(vm.messages, 'id');
              vm.addNewMessage(respMessage);
              EmitService.messageNew(respMessage);
            }, function (error) {
              vm.messages.splice(vm.messages.indexOf(message), 1);
              if (error) {
                Notify.error(error.message);
              }
            }, { ignoreLoadingBar: true });
          }
          function _parseLine(input) {
            if (!input.body) {
              return '';
            }
            var lines = input.body.match(/[^\n]+/g), newLines = '';
            for (var i = 0; i < lines.length; i++) {
              newLines = newLines + lines[i] + ' <br> ';
            }
            return newLines;
          }
          function _resetScope() {
            vm.newMessage = { files: [] };
            vm.files = vm.newMessage.files;
          }
          /**
			 * @desc Redirect to notice send state
			 * @param {Number} id of tenant
			 */
          function redirectConfirmation(id) {
            Notify.confirm(function () {
              $state.go('notice.send', { tenant_id: id });
            }, Lang.get('general.redirect_confirmation'));
          }
        }
      ]
    };
  var placeholderNoItems = {
      bindings: {
        label1: '@',
        label2: '@',
        btnurl: '@',
        btnname: '@',
        linkurl: '@',
        linkname: '@',
        sizeIcon: '@',
        smileIcon: '@',
        importtype: '@',
        setClass: '@'
      },
      templateUrl: '/app/modules/shared/components/main/placeholder.html',
      controller: [
        '$mdMedia',
        '$element',
        function ($mdMedia, $element) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-maxWidth');
          };
          vm.$mdMedia = $mdMedia;
        }
      ]
    };
  var placeholderNoPermission = {
      bindings: {
        label1: '@',
        label2: '@'
      },
      templateUrl: '/app/modules/shared/components/main/placeholderNoPermission.html'
    };
  var globalNotifications = {
      bindings: { notifications: '<' },
      templateUrl: '/app/modules/shared/components/main/notifications.html',
      controller: [
        '$element',
        'HttpService',
        'Notify',
        function ($element, HttpService, Notify) {
          'ngInject';
          var vm = this;
          vm.removeNotification = removeNotification;
          vm.referNotification = referNotification;
          vm.$onInit = function () {
            getStatistics();
          };
          /**
			 * Remove notification for current user (after click 'hide this')
			 *
			 * @param notification
			 */
          function removeNotification(notification) {
            HttpService.delete('/notification/' + notification.id, function () {
              _removeNotification(notification);
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
          /**
			 * Refer by button link and remove notification
			 *
			 * @param notification
			 */
          function referNotification(notification) {
            HttpService.put('/notification/' + notification.id, notification, function () {
              _removeNotification(notification);
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
          /**
			 * Setting notifications list
			 */
          function getStatistics() {
            HttpService.getWParams('/statistics/notifications', { ignoreLoadingBar: true }, function (resp) {
              vm.notifications = resp;
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
          /**
			 * Remove current notification from notifications list
			 *
			 * @param notification
			 * @private
			 */
          function _removeNotification(notification) {
            vm.notifications = _.filter(vm.notifications, function (item) {
              return item.id !== notification.id;
            });
          }
        }
      ]
    };
  var photoswipe = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/main/photoswipe.html'
    };
  var pageFooter = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/main/footer.html',
      controller: [
        'APP_CONFIG',
        '$state',
        function (APP_CONFIG, $state) {
          'ngInject';
          var vm = this;
          vm.appVersion = APP_CONFIG.app_version;
          vm.state = {
            isNotRentalsFind: function () {
              return $state.current.name !== 'rentals.find';
            },
            isMessages: function () {
              return $state.includes('messages.*') || $state.current.name === 'posts.list';
            }
          };
        }
      ]
    };
  var eSignature = {
      bindings: {
        showBaseImg: '<',
        imgUrl: '@',
        save: '&'
      },
      templateUrl: '/app/modules/shared/components/main/e-signature.html',
      controller: [
        '$rootScope',
        'HttpService',
        'Notify',
        function ($rootScope, HttpService, Notify) {
          'ngInject';
          var vm = this;
          vm.agreeSignatureTerms = agreeSignatureTerms();
          vm.saveSignature = function () {
            var data = vm.accept();
            if (_.isUndefined(data.dataUrl) || _.isNull(data.dataUrl)) {
              Notify.error(Lang.get('settings.documents.e_signature.empty_signature'));
              return;
            }
            vm.save({ data: data });
          };
          vm.saveSignatureSign = function () {
            var data = {
                'draw': false,
                'confirm_name': vm.confirm_name,
                'initials': vm.initials
              };
            vm.save({ data: data });
          };
          vm.agreeTerms = agreeTerms;
          vm.ui = {
            show: vm.imgUrl,
            showDraw: function () {
              this.show = true;
            },
            hideDraw: function () {
              this.show = false;
            }
          };
          function agreeTerms() {
            if ($rootScope.auth.role !== 'admin') {
              return true;
            }
            HttpService.post('/landlord/settings/signature/agreeTerms', [], function () {
              $rootScope.auth.settings.agreeSignatureTerm = true;
              vm.agreeSignatureTerms = true;
            });
          }
          function agreeSignatureTerms() {
            if ($rootScope.auth.role !== 'admin') {
              return true;
            }
            return $rootScope.auth.settings && $rootScope.auth.settings.agreeSignatureTerm;
          }
        }
      ]
    };
  var timeLine = {
      bindings: {
        item: '<',
        onlyNote: '<',
        type: '@'
      },
      templateUrl: '/app/modules/shared/components/main/timeline.html',
      controller: [
        '$element',
        '$rootScope',
        'HttpService',
        'Notify',
        'FilterService',
        'ng_util',
        'scroll_util',
        function ($element, $rootScope, HttpService, Notify, FilterService, ng_util, scroll_util) {
          'ngInject';
          var vm = this, elFilter = angular.element($element).find('.m-filter-panel'), resource_id = vm.item.id, resource_type = vm.type, listLength = true, page = 0;
          vm.isLoaded = false;
          vm.showLoading = false;
          vm.filter = vm.onlyNote ? 'notes' : 'all';
          vm.transformLogs = transformLogs;
          vm.loadWithFilter = loadWithFilter;
          vm.onScroll = onScroll;
          vm.allowActions = allowActions;
          vm.getTooltip = _.get(Lang.get('timeline.tooltip'), vm.type);
          vm.noteActions = {
            add: addNote,
            edit: editNote,
            remove: deleteNote
          };
          function addNote() {
            page = 0;
            vm.paginationList = [];
            loadTimeline();
          }
          function editNote(item) {
            vm.paginationList = _.map(vm.paginationList, function (x) {
              if (x.note) {
                delete x.note.isEdit;
              }
              return x;
            });
            transformLogs(vm.paginationList);
            item.isEdit = true;
          }
          function deleteNote(id, logId) {
            Notify.confirm(function () {
              HttpService.delete('/resource/' + resource_id + '/type/' + resource_type + '/notes/' + id, function () {
                var log = _.find(vm.paginationList, { id: logId });
                if (log) {
                  var index = _.indexOf(vm.paginationList, log);
                  vm.paginationList.splice(index, 1);
                  transformLogs(vm.paginationList);
                }
                Notify.success(Lang.get('notes.delete_success'));
              });
            }, Lang.get('notes.delete_confirm'));
          }
          // open filter
          ng_util.definePropertyObj(FilterService, 'timeline', function (params) {
            if (!_.isUndefined(params)) {
              if (params.isShow) {
                $element.addClass('open');
                vm.data = params.transaction;
                vm.title = Lang.get('general.timeline');
                page = 0;
                vm.paginationList = [];
                loadTimeline();
                elFilter.find('.m-filter-panel__scrollbar').scrollTop(0).perfectScrollbar('update');
                scroll_util.addStyleScrollBar(true);
              } else {
                scroll_util.addStyleScrollBar(false);
                $element.removeClass('open');
              }
            }
          });
          vm.showFilterMenu = function () {
            FilterService.hideTimeline();
            vm.isLoaded = false;
          };
          function transformLogs(data) {
            vm.pagination.list = _.groupBy(data, function (log) {
              return log.date;
            });
          }
          function loadWithFilter(filter) {
            if (vm.filter == filter) {
              return false;
            }
            vm.filter = filter;
            page = 0;
            vm.paginationList = [];
            loadTimeline();
          }
          function allowActions(log) {
            //allowed types : Notes and Support demo note
            return log.action === 36 || log.action === 60;
          }
          /**
			 * Paginate timeline
			 */
          function onScroll(scrollTop, scrollHeight) {
            if (scrollTop === scrollHeight && listLength) {
              loadTimeline();
            }
          }
          function loadTimeline() {
            page++;
            vm.showLoading = true;
            var timelineParams = {
                entity_id: vm.item.id,
                entity_type: parseInt(vm.type),
                filter: vm.filter,
                page: page
              };
            HttpService.getWParams('/timeline', {
              params: timelineParams,
              ignoreLoadingBar: true
            }, function (resp) {
              vm.pagination = resp.pagination;
              vm.paginationList = vm.paginationList ? vm.paginationList.concat(resp.list) : resp.list;
              vm.pagination.list = vm.paginationList;
              listLength = resp.list.length;
              transformLogs(vm.pagination.list);
              vm.isLoaded = true;
              vm.showLoading = false;
            }, function (error) {
              Notify.error(error.message);
              vm.showFilterMenu();
            });
          }
        }
      ]
    };
  var noteForm = {
      bindings: {
        item: '<',
        parent: '<',
        type: '<',
        callbackFn: '&'
      },
      templateUrl: '/app/modules/shared/components/main/note-form.html',
      controller: [
        '$element',
        '$parse',
        '$rootScope',
        'HttpService',
        'Notify',
        function ($element, $parse, $rootScope, HttpService, Notify) {
          'ngInject';
          var vm = this, resource_id = vm.parent.id, resource_type = vm.type;
          vm.$onInit = function () {
            _getAdmins();
          };
          vm.oldItem = _.clone(vm.item);
          vm.actions = {
            save: saveNote,
            cancel: cancelEditNote
          };
          function cancelEditNote() {
            vm.item.text = vm.oldItem.text;
            vm.item.remind_date = vm.oldItem.remind_date;
            delete vm.item.isEdit;
          }
          function saveNote() {
            if (!vm.item.id) {
              vm.item.resource_id = resource_id;
              vm.item.resource_type = resource_type;
              HttpService.post('/resource/' + resource_id + '/type/' + resource_type + '/notes', vm.item, function () {
                Notify.success(Lang.get('notes.create_success'));
                vm.item = {
                  text: null,
                  files: [],
                  remind_date: null
                };
                if (_.isFunction(vm.callbackFn)) {
                  $parse(vm.callbackFn)(vm)();
                }
                $rootScope.$emit('form:error', false);
              }, function (error) {
                Notify.error(_.get(error, 'message', Lang.get('notify_actions.ooops')));
              });
            } else {
              HttpService.put('/resource/' + resource_id + '/type/' + resource_type + '/notes/' + vm.item.id, vm.item, function (resp) {
                delete vm.item.isEdit;
                vm.item.assignee = _.get(resp, 'assignee');
                Notify.success(Lang.get('notes.update_success'));
              }, function (error) {
                Notify.error(_.get(error, 'message', Lang.get('notify_actions.ooops')));
              });
            }
          }
          /**
			 * @name _getAdmins
			 * @desc Get admins for select
			 * @private
			 */
          function _getAdmins() {
            vm.adminOptions = [];
            if ($rootScope.auth._isLandlord()) {
              HttpService.get('/landlord/todo/getAdmins', function (resp) {
                vm.adminOptions = resp.admins;
              });
            }
          }
        }
      ]
    };
  var timeLinePanel = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/main/timeline-panel.html',
      controller: [
        'FilterService',
        function (FilterService) {
          'ngInject';
          var vm = this;
          vm.showTimeline = function () {
            FilterService.showTimeline();
          };
        }
      ]
    };
  var dots = {
      bindings: { index: '<' },
      transclude: true,
      templateUrl: '/app/modules/shared/components/main/dots.html',
      controller: [
        'DotsService',
        function (DotsService) {
          'ngInject';
          var vm = this;
          if (_.isUndefined(vm.index)) {
            vm.index = 0;
          }
          vm.clickDots = clickDots;
          DotsService.setDots(vm.index);
          /**
			 * @param index
			 */
          function clickDots(index) {
            vm.showDotsMenu = DotsService.getDots();
            _.each(vm.showDotsMenu, function (item, key) {
              if (parseInt(index) === parseInt(key)) {
                vm.showDotsMenu[key] = !vm.showDotsMenu[key];
              } else {
                vm.showDotsMenu[key] = false;
              }
            });
            DotsService.updateDots(vm.showDotsMenu);
          }
          /**
			 * Clear/destroy dots
			 */
          vm.$onDestroy = function () {
            DotsService.clearDots();
          };
        }
      ]
    };
  var btnFilter = {
      bindings: { setclass: '@' },
      transclude: true,
      templateUrl: '/app/modules/shared/components/main/btn-filter.html',
      controller: [
        '$scope',
        'PagingFilterService',
        'FilterService',
        'ng_util',
        function ($scope, PagingFilterService, FilterService, ng_util) {
          'ngInject';
          var vm = this;
          vm.clickDots = clickDots;
          vm.clearFilter = clearFilter;
          vm.toggleCheckbox = toggleCheckbox;
          // vm.position = position;
          ng_util.definePropertyObj(FilterService, 'filterTags', function (source) {
            if (source) {
              if (JSON.stringify(source.newValue) !== JSON.stringify(source.oldValue)) {
                vm.count = _getFilter(source.newValue).length;
              }
            }
          });
          if (PagingFilterService.getScope()) {
            vm.filter = PagingFilterService.getScope().filter;
            _.forEach(vm.filter, function (item, key) {
              if (_.isNumber(item)) {
                vm.filter[key] = _.toString(item);
              }
            });
            vm.count = _getFilter(vm.filter).length;
          }
          /**
			 * clear Filter
			 */
          function clearFilter() {
            _.forEach(vm.filter, function (item, key) {
              vm.filter[key] = undefined;
            });
            vm.showDotsMenu = false;
          }
          /**
			 * click Dots
			 */
          function clickDots() {
            // btnRect = $event.currentTarget.getBoundingClientRect();
            vm.showDotsMenu = !vm.showDotsMenu;
          }
          /**
			 * toggle Checkbox
			 * @param name
			 * @param param
			 */
          function toggleCheckbox(name, param) {
            if (!_.isNull(vm.filter[name]) && !_.isUndefined(vm.filter[name])) {
              vm.filter[name] = undefined;
            } else if (_.isNumber(param)) {
              vm.filter[name] = _.toString(param);
            } else {
              vm.filter[name] = param;
            }
          }
          /**
			 *
			 * @returns {{top: string, left: string}}
			 */
          // function position() {
          // 	return { top: (btnRect.bottom + 8) + 'px', left: (btnRect.right - 250) + 'px' };
          // }
          /**
			 * Get Filter
			 * @param source
			 * @returns {Array}
			 * @private
			 */
          function _getFilter(source) {
            return _.filter(source, function (item) {
              if (!_.isUndefined(item)) {
                return true;
              }
            });
          }
        }
      ]
    };
  var btnDownload = {
      bindings: {},
      transclude: true,
      templateUrl: '/app/modules/shared/components/main/btn-download.html',
      controller: function () {
        var vm = this;
        vm.clickDots = clickDots;
        function clickDots() {
          vm.showDotsMenu = !vm.showDotsMenu;
        }
      }
    };
  var btnDownloadReport = {
      bindings: {
        type: '<',
        filter: '<'
      },
      templateUrl: '/app/modules/shared/components/main/btn-download-report.html',
      controller: [
        '$rootScope',
        'APP_CONFIG',
        'Notify',
        'CordovaService',
        'HttpService',
        function ($rootScope, APP_CONFIG, Notify, CordovaService, HttpService) {
          'ngInject';
          var vm = this;
          vm.linkToReport = linkToReport;
          vm.cordovaClick = cordovaClick;
          function linkToReport(fileType) {
            if (CordovaService.isCordova()) {
              return '';
            }
            return generateLinkToReport(fileType, 'href');
          }
          function cordovaClick(event, fileType) {
            var url;
            if (!CordovaService.isCordova()) {
              return false;
            }
            event.preventDefault();
            // Link to file
            url = generateLinkToReport(fileType, 'url');
            HttpService.get(url, function (resp) {
              if (resp.file_url) {
                return CordovaService.openExternalUrl(resp.file_url);
              }
            }, function (error) {
              Notify.error(_.get(error, 'message', Lang.get('notify_actions.ooops')));
            });
          }
          function generateLinkToReport(fileType, responseType) {
            var link, file_url, fallbackUrl;
            // Format pdf or xlsx
            fileType = fileType || 'pdf';
            // Return href or url
            responseType = responseType || 'href';
            // is need return file_url
            file_url = responseType === 'url' ? true : null;
            // Generate link to report
            fallbackUrl = _.extend(_.clone(vm.filter), {
              export: fileType,
              file_url: file_url
            });
            fallbackUrl = _.pickBy(fallbackUrl, _.identity);
            // Removes from object undefined and null values
            delete fallbackUrl['daterange'];
            // Remove unused params for backend, use only for frontend filter
            link = '/' + $rootScope.auth._role() + '/reports/' + vm.type + '?' + _.map(fallbackUrl, function (val, key) {
              return key + '=' + val;
            }).join('&');
            switch (responseType) {
            case 'url':
              return link;
            case 'href':
              return APP_CONFIG.external_api + link;
            default:
              return '';
            }
          }
        }
      ]
    };
  var btnBack = {
      bindings: { setclass: '@?' },
      transclude: true,
      templateUrl: [
        '$attrs',
        function ($attrs) {
          'ngInject';
          if ($attrs.cancel) {
            return '/app/modules/shared/components/main/btn-cancel.html';
          } else if ($attrs.back) {
            return '/app/modules/shared/components/main/btn-back.html';
          } else {
            return '/app/modules/shared/components/main/btn-back-icon.html';
          }
        }
      ],
      controller: [
        '$element',
        'BreadCrumbsService',
        function ($element, BreadCrumbsService) {
          'ngInject';
          var vm = this, crumbs = angular.copy(BreadCrumbsService.getCrumbs());
          vm.$postLink = function () {
            $element.addClass('u-block');
          };
          if (crumbs.length > 1) {
            vm.back = _.find(crumbs.splice(-2, 1), 'state');
          }
        }
      ]
    };
  var btnSave = {
      bindings: {
        id: '<?',
        disabled: '<?'
      },
      transclude: true,
      templateUrl: '/app/modules/shared/components/main/btn-save.html'
    };
  var btnSend = {
      bindings: { message: '<' },
      templateUrl: '/app/modules/shared/components/main/btn-send.html',
      controller: [
        '$rootScope',
        '$element',
        '$timeout',
        function ($rootScope, $element, $timeout) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            var elem = angular.element($element), replace = '.replace', plane = '.plane', hidden = '.hidden';
            vm.clickingCallback = function () {
              if (vm.message) {
                elem.find(plane).addClass('fly');
                elem.find(hidden).addClass('visible');
                elem.find(replace).removeClass('icon-line-send').addClass('icon-line-ok');
                $timeout(function () {
                  elem.find(replace).removeClass('icon-line-ok').addClass('icon-line-send');
                  elem.find(hidden).removeClass('visible');
                  elem.find(plane).removeClass('fly');
                }, 1000);
              }
            };
            $element.bind('click', vm.clickingCallback);
          };
        }
      ]
    };
  var daterangeFilter = {
      bindings: {
        filter: '<',
        callback: '&?'
      },
      templateUrl: '/app/modules/landlord/components/transactions/transactions-daterange.html',
      controller: [
        '$rootScope',
        '$scope',
        '$filter',
        'ng_util',
        'PagingFilterService',
        function ($rootScope, $scope, $filter, ng_util, PagingFilterService) {
          'ngInject';
          var vm = this, dateRange, fp, date_format_to_string = $rootScope.auth && $rootScope.auth.date_format_to_string && $rootScope.auth.date_format_to_string.toUpperCase();
          vm.label = $filter('trans')('general.daterange.all');
          dateRange = {
            'all': [],
            'one_week': [
              moment().subtract(6, 'days'),
              moment()
            ],
            'two_week': [
              moment().subtract(13, 'days'),
              moment()
            ],
            'last_month': [
              moment().subtract(1, 'month'),
              moment()
            ],
            'three_month': [
              moment().subtract(3, 'month'),
              moment()
            ],
            'last_year': [
              moment().subtract(1, 'year').startOf('month'),
              moment()
            ]
          };
          vm.dateOpts = {
            dateFormat: 'm/d/Y',
            allowInput: true,
            animate: false,
            mode: 'range',
            altInput: false,
            altFormat: 'm/d/Y'
          };
          // return selectedDates
          vm.dateOpts.onChange = function (selectedDates) {
            vm.helper.setIndex('custom_range');
            runFilter(selectedDates);
          };
          vm.helper = {
            index: 'all',
            setDateRange: function (index) {
              this.index = index;
              if (dateRange[index].length) {
                var daterange = [
                    dateRange[index][0].toDate(),
                    dateRange[index][1].toDate()
                  ];
                fp.setDate(daterange);
              }
              runFilter(dateRange[index]);
            },
            getIndex: function () {
              return this.index;
            },
            setIndex: function (index) {
              this.index = index;
            },
            isActive: function (index) {
              return this.index === index;
            }
          };
          fp = new Flatpickr(angular.element('#range')[0], vm.dateOpts);
          /**
			 * destroy flatPicker
			 */
          vm.$onDestroy = function () {
            fp.destroy();
          };
          /**
			 * run filter date range
			 * @param selectedDates
			 */
          function runFilter(selectedDates) {
            var dateFrom, dateTo;
            if (selectedDates.length) {
              dateFrom = moment(selectedDates[0]).format('MM/DD/YYYY');
              if (!vm.filter) {
                PagingFilterService.set('date_from', dateFrom);
              } else {
                vm.filter.date_from = dateFrom;
              }
              vm.label = moment(selectedDates[0]).format(date_format_to_string);
              if (selectedDates[1]) {
                dateTo = moment(selectedDates[1]).format('MM/DD/YYYY');
                if (!vm.filter) {
                  PagingFilterService.set('date_to', dateTo);
                } else {
                  vm.filter.date_to = dateTo;
                }
                vm.label = moment(selectedDates[0]).format(date_format_to_string) + ' <i>' + $filter('trans')('general.daterange.to') + '</i> ' + moment(selectedDates[1]).format(date_format_to_string);
              }
            } else {
              if (!vm.filter) {
                PagingFilterService.set('date_from', null);
                PagingFilterService.set('date_to', null);
              } else {
                vm.filter.date_from = null;
                vm.filter.date_to = null;
              }
              vm.label = $filter('trans')('general.daterange.all');
            }
            if (vm.callback) {
              vm.callback({ filter: vm.filter });
            } else {
              ng_util.safeApply($scope);
            }
          }
        }
      ]
    };
  angular.module('app.components.main', ['signature']).component('jumbotron', jumbotron).component('footerSidebar', footerSidebar).component('footerSidebarTransactions', footerSidebarTransactions).component('headerSidebar', headerSidebar).component('headerList', headerList).component('maintenanceMode', maintenanceMode).component('chat', chat).component('messageSender', messageSender).component('placeholderNoItems', placeholderNoItems).component('placeholderNoPermission', placeholderNoPermission).component('globalNotifications', globalNotifications).component('photoswipe', photoswipe).component('pageFooter', pageFooter).component('eSignature', eSignature).component('timeLine', timeLine).component('timeLinePanel', timeLinePanel).component('noteForm', noteForm).component('dots', dots).component('btnFilter', btnFilter).component('btnDownload', btnDownload).component('btnDownloadReport', btnDownloadReport).component('btnBack', btnBack).component('btnSave', btnSave).component('btnSend', btnSend).component('daterangeFilter', daterangeFilter);
  ;
}());