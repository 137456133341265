(function () {
  'use strict';
  /**
	 *
	 * @type {{bindings: {isSettingsMenu: string}, templateUrl: templateUrl, controller: controller}}
	 */
  var TwoFactorAuthStatus = {
      bindings: { isSettingsMenu: '<?' },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          'ngInject';
          if ($attrs.isSettingsMenu) {
            return '/app/modules/shared/components/settings/2f/status_for_setting_menu.html';
          } else {
            return '/app/modules/shared/components/settings/2f/status.html';
          }
        }
      ],
      controller: [
        'ngDialog',
        'Notify',
        'HttpService',
        function (ngDialog, Notify, HttpService) {
          'ngInject';
          var vm = this;
          vm.disable = disable;
          vm.showTwoStepAuthBackupCodes = showTwoStepAuthBackupCodes;
          function disable() {
            Notify.confirm(function () {
              HttpService.put('/settings/2fa/disable', {}, function () {
                vm.is2FAEnabled = false;
                Notify.success(Lang.get('2fa.inactive_2fa'));
              }, function (err) {
                Notify.error(err.message);
              });
            });
          }
          function showTwoStepAuthBackupCodes() {
            HttpService.get('/settings/2fa/backup_codes', function (resp) {
              ngDialog.open({
                templateUrl: '/app/modules/shared/components/settings/2f/show_backup_codes.html',
                showClose: true,
                data: { codes: resp.codes },
                scope: true
              });
            }, function () {
              Notify.error(Lang.get('notify_actions.ooops'));
            });
          }
        }
      ]
    };
  /**
	 *
	 * @type {{bindings: {userCalendar: string}, templateUrl: templateUrl, controller: controller}}
	 */
  var googleCalendarSync = {
      bindings: {
        userCalendar: '<',
        needSubscription: '<'
      },
      templateUrl: function () {
        return '/app/modules/shared/components/settings/google_calendar_sync.html';
      },
      controller: [
        '$rootScope',
        'APP_CONFIG',
        'Notify',
        'HttpService',
        'SocialConnectService',
        'SubscriptionService',
        function ($rootScope, APP_CONFIG, Notify, HttpService, SocialConnectService, SubscriptionService) {
          'ngInject';
          var vm = this;
          vm.enabled = isEnabled();
          vm.connectGoogleCalendar = SocialConnectService.connectGoogleCalendar;
          vm.unlink = unlink;
          vm.switchSync = switchSync;
          vm.isSubscriptionAppropriate = isSubscriptionAppropriate;
          $rootScope.$on('landlord:google_calendar_credentials', function (event, data) {
            vm.userCalendar = data.userCalendar;
          });
          function unlink() {
            Notify.confirm(function () {
              HttpService.delete('/settings/calendar_sync/' + vm.userCalendar.id, function () {
                Notify.success(Lang.get('calendar_sync.unlinked'));
                vm.userCalendar = null;
                vm.enabled = false;
              });
            });
          }
          function switchSync() {
            var error = function () {
              vm.enabled = !vm.enabled;
              Notify.error(Lang.get('notify_actions.ooops'));
            };
            HttpService.post('/settings/calendar_sync/' + vm.userCalendar.id + '/switch', null, function (resp) {
              vm.enabled = !!resp.status;
              Notify.success(Lang.get(resp.status ? 'calendar_sync.enabled' : 'calendar_sync.disabled'));
            }, error);
          }
          function isSubscriptionAppropriate() {
            if (!vm.needSubscription) {
              return true;
            }
            return SubscriptionService.pkg.userHasAccess('google_calendar');
          }
          function isEnabled() {
            return vm.userCalendar && vm.userCalendar.calendar_id;
          }
        }
      ]
    };
  angular.module('app.components.settings', []).component('twoFactorAuthStatus', TwoFactorAuthStatus).component('googleCalendarSync', googleCalendarSync);
  ;
}());