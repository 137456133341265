(function () {
  'use strict';
  DwollaService.$inject = [
    '$rootScope',
    '$cacheFactory',
    '$httpParamSerializer',
    'HttpService',
    'Notify',
    'APP_CONFIG'
  ];
  angular.module('app.dwolla.services', []).service('DwollaService', DwollaService);
  ;
  /**
	 * @namespace DwollaService
	 * @desc Dwolla service methods
	 * @constructor
	 */
  function DwollaService($rootScope, $cacheFactory, $httpParamSerializer, HttpService, Notify, APP_CONFIG) {
    var cache = $cacheFactory('dwolla'), ttl = 1000, handlers = {};
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        remove: cache.remove,
        clearCache: cache.removeAll,
        getIAVToken: getIAVToken,
        prepareCustomer: prepareCustomer,
        getBalance: getBalance,
        getSources: getSources,
        getTransfers: getTransfers,
        updateBalance: updateBalance,
        updateSources: updateSources,
        updateTransfers: updateTransfers,
        notify: notify,
        subscribe: subscribe,
        destroyHandlers: destroyHandlers
      };
    return service;
    /**
		 * @desc Get IAV token
		 * @param {String} method
		 * @param {Function} successCallback
		 * @param {Function} errorCallback
		 */
    function getIAVToken(method, successCallback, errorCallback) {
      // Get IAV
      HttpService.getWParams('/dwolla/customer/' + (method || 'iav_token'), { ignoreLoadingBar: true }, function (resp) {
        if (!window.dwolla) {
          return Notify.error('Dwolla script not loaded');
        }
        window.dwolla.configure(APP_CONFIG.app_env === 'production' ? 'prod' : 'sandbox');
        successCallback(resp.token);
      }, function (errors) {
        Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
        errorCallback(errors);
      });
    }
    /**
		 * @desc Prepare customer
		 * @param {Object} customer - Customer information
		 * @returns {Object}
		 */
    function prepareCustomer(customer) {
      if (!customer) {
        customer = {};
      }
      // Helpers
      customer.isCreated = function () {
        return this.id;
      };
      customer.isVerified = function () {
        return _.includes([
          'verified',
          'unverified'
        ], this.status);
      };
      customer.isSuspended = function () {
        return this.status === 'suspended';
      };
      customer.isDeactivated = function () {
        return this.status === 'deactivated';
      };
      customer.isNeedRetry = function () {
        return this.status === 'retry';
      };
      customer.isNeedDocument = function () {
        return this.status === 'document';
      };
      return customer;
    }
    /**
		 * @desc Get balance
		 * @param {Function} successCallback
		 * @param {Function} errorCallback
		 */
    function getBalance(successCallback, errorCallback) {
      var cacheKey = '/dwolla/funding_sources/balance';
      if (cache.get(cacheKey)) {
        successCallback(cache.get(cacheKey));
      } else {
        HttpService.getWParams('/dwolla/funding_sources/balance', { ttl: ttl }, function (resp) {
          cache.put(cacheKey, resp);
          successCallback(resp);
        }, function (resp) {
          errorCallback(resp);
        });
      }
    }
    /**
		 * @desc Get sources
		 * @param {?} fresh
		 * @param {Function} successCallback
		 * @param {Function} errorCallback
		 */
    function getSources(fresh, successCallback, errorCallback) {
      var cacheKey = '/dwolla/funding_sources';
      if (cache.get(cacheKey) && !fresh) {
        successCallback(cache.get(cacheKey));
      } else {
        var url = '/dwolla/funding_sources' + (fresh ? '?fresh=true' : '');
        HttpService.getWParams(url, { ttl: ttl }, function (resp) {
          cache.put(cacheKey, resp);
          successCallback(resp);
        }, function (resp) {
          errorCallback(resp);
        });
      }
    }
    /**
		 * @desc Get transfers
		 * @param {?} offset
		 * @param {Function} successCallback
		 * @param {Function} errorCallback
		 */
    function getTransfers(offset, successCallback, errorCallback) {
      //Build params
      var params = {
          limit: 25,
          offset: offset || 0
        };
      // Get data
      HttpService.get('/dwolla/transfers?' + $httpParamSerializer(params), function (resp) {
        // cache.put(cacheKey, resp);
        successCallback(resp);
      }, function (resp) {
        errorCallback(resp);
      });
    }
    /**
		 * @desc Update balance
		 */
    function updateBalance() {
      cache.remove('/dwolla/funding_sources/balance');
      this.notify('update_balance');
    }
    /**
		 * @desc Update sources
		 */
    function updateSources() {
      cache.remove('/dwolla/funding_sources');
      this.notify('update_sources');
    }
    /**
		 * @desc Update transfers
		 */
    function updateTransfers() {
      this.notify('update_transfers');
    }
    /**
		 * @desc Notify
		 * @param {Number} key
		 */
    function notify(key) {
      if (handlers[key]) {
        handlers[key].forEach(function (handler) {
          handler();
        });
      }
    }
    /**
		 * @desc Subscribe
		 * @param {Number} key
		 * @param {Function} callback
		 */
    function subscribe(key, callback) {
      if (!angular.isArray(handlers[key])) {
        handlers[key] = [];
      }
      handlers[key].push(callback);
    }
    /**
		 * @desc Destroy handlers
		 */
    function destroyHandlers() {
      handlers = {};
    }
  }
}());