(function () {
  'use strict';
  /**
	 * Custom credit card
	 *
	 * <custom-credit-card></custom-credit-card>
	 *
	 * @type {{bindings: {name: string, zip: string, model: string, setclass: string, errors: string, cardbrand: string, cardlastfour: string}, templateUrl: templateUrl, controller: controller}}
	 */
  var customCreditCard = {
      bindings: {
        name: '<',
        zip: '<',
        model: '=',
        setclass: '@',
        errors: '<',
        cardbrand: '@?',
        cardlastfour: '@?'
      },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          'ngInject';
          if ($attrs.cardbrand) {
            return '/app/modules/shared/components/creditcard/credit-card-view.html';
          } else {
            return '/app/modules/shared/components/creditcard/credit-card.html';
          }
        }
      ],
      controller: [
        '$rootScope',
        function ($rootScope) {
          'ngInject';
          var vm = this;
          $rootScope.$on('form:error', function (event, response) {
            if (response) {
              // Try to find credit_card error
              if (_.isArray(response) || _.isObject(response)) {
                var error = _.find(response, function (error, key) {
                    return key.includes('credit_card.');
                  });
                if (error) {
                  vm.errors = error;
                }
              }
            } else {
              vm.errors = null;
            }
          });
        }
      ]
    };
  angular.module('app.components.creditCard', []).directive('creditCardAutoTab', creditCardAutoTab).component('customCreditCard', customCreditCard);
  ;
  /**
	 * Auto tab between inputs for credit cards
	 * @returns {{restrict: string, link: link}}
	 */
  function creditCardAutoTab() {
    return {
      restrict: 'A',
      link: function (scope, element, attrs) {
        element.bind('keyup', function () {
          // The condition which checks whether the input is valid and not empty
          if (!this.classList.contains('ng-empty') && this.classList.contains('ng-valid')) {
            var currentElement = document.getElementById(attrs.creditCardAutoTab);
            // The condition for input with credit card's number
            if (currentElement && this.name === 'ccNumber') {
              currentElement.focus();
              return;
            }
            // The condition for input with zip number
            if (currentElement && this.name === 'address_zip' && this.value.length === this.maxLength) {
              currentElement.focus();
              return;
            }
            // The condition for inputs with attribute size
            if (currentElement && this.size <= this.value.length) {
              currentElement.focus();
            }
          }
        });
      }
    };
  }
}());