(function () {
  'use strict';
  var fileManagerList = {
      bindings: { files: '<' },
      templateUrl: '/app/modules/shared/pages/filemanager/list.html',
      controller: [
        '$scope',
        '$timeout',
        '$sce',
        '$state',
        '$filter',
        '$stateParams',
        '$rootScope',
        '$element',
        'BreadCrumbsService',
        'FileManagerService',
        'PagingFilterService',
        'ColumnInterfaceService',
        'Notify',
        'HttpService',
        'PopupService',
        function ($scope, $timeout, $sce, $state, $filter, $stateParams, $rootScope, $element, BreadCrumbsService, FileManagerService, PagingFilterService, ColumnInterfaceService, Notify, HttpService, PopupService) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.filemanager.label'));
          // ui
          vm.ui = FileManagerService.ui();
          //grid
          vm.resizeWindow = ColumnInterfaceService.init();
          vm.changeGrid = ColumnInterfaceService.changeGrid;
          //an array of data
          vm.pagination = vm.files.pagination;
          vm.collection = vm.files.list;
          //default values
          vm.selectedAllFiles = 0;
          vm.showOptionButtons = false;
          vm.showPreviewButtons = false;
          vm.sort = {
            column: 'created_at',
            reverse: true
          };
          vm.selectedFiles = {
            total: 0,
            size: 0,
            file: null
          };
          vm.download = vm.files.download;
          vm.selectedAll = selectedAll;
          vm.selectedOnce = selectedOnce;
          vm.getSelectedFiles = getSelectedFiles;
          vm.changeSorting = changeSorting;
          vm.selectedCls = selectedCls;
          vm.destroy = destroy;
          vm.previewImage = previewImage;
          vm.downloadMultiple = downloadMultiple;
          vm.cancelDownload = cancelDownload;
          vm.menuOptions = menuOptions;
          vm.hideOptionMenu = hideOptionMenu;
          //update download progress bar
          $rootScope.$on('filemanager:update-progress_bar', function (event, percent) {
            vm.download.percent = percent;
            vm.download.in_progress = true;
            $scope.$digest();
          });
          //update download complete status
          $rootScope.$on('filemanager:update-download_file', function (event, file) {
            vm.download.in_progress = false;
            vm.download.file = file;
            $scope.$digest();
          });
          function selectedAll() {
            //select all files
            vm.selectedAllFiles = !vm.selectedAllFiles;
            _.forEach(vm.collection, function (item) {
              item.checked = vm.selectedAllFiles;
              item.cannot_remove = false;
            });
            _changeShowButtonOption();
          }
          /**
			 * @param file
			 */
          function selectedOnce(file) {
            //select once file
            var selectedFile = _.find(vm.collection, { id: file.id });
            if (selectedFile) {
              selectedFile.checked = !selectedFile.checked;
              selectedFile.cannot_remove = false;
            }
            _changeShowButtonOption();
          }
          function getSelectedFiles() {
            //extract only selected files
            return _.filter(vm.collection, function (item) {
              return item.checked === true;
            });
          }
          /**
			 * @param column
			 */
          function changeSorting(column) {
            vm.sort.reverse = column !== null && vm.sort.column === column ? !vm.sort.reverse : false;
            vm.sort.column = column;
          }
          /**
			 * @param column
			 * @returns {boolean|string}
			 */
          function selectedCls(column) {
            return column === vm.sort.column && 'sort-' + vm.sort.reverse;
          }
          /**
			 * @param file
			 */
          function destroy(file) {
            var files = [], params = {};
            //prepare an array of files
            if (file) {
              files = [file];
            } else {
              files = getSelectedFiles();
            }
            //leave files that are allowed to delete
            _.remove(files, function (file) {
              if (file.show_cannot_remove === true) {
                file.checked = false;
              }
              return file.show_cannot_remove === true;
            });
            //show error
            if (!files.length) {
              vm.showOptionButtons = false;
              return;
            }
            //if the number of selected files does not exceed 10,
            // then display a detailed table of information about the file and to whom it belongs
            if (files.length <= 10) {
              params.parents = _.reduce(files, function (parents, item) {
                return parents.concat({
                  parentName: _getParentName(item),
                  fileName: item.base_name
                });
              }, []);
              params.title = Lang.get('notify_actions.file_manager.destroy_warning_with_file_names');
            } else {
              //otherwise, show general information about the number of selected files
              params.title = Lang.get('notify_actions.file_manager.destroy_warning_with_count_files', { count: files.length });
            }
            Notify.confirm(function () {
              HttpService.post('/filemanager/destroy', {
                files: files,
                filters: $stateParams
              }, function () {
                $state.reload();
              }, function (errors) {
                //show error
                if (errors.files || errors.filters) {
                  Notify.error(Lang.get('notify_actions.empty_data_set'));
                  return;
                }
                //show error
                if (errors.message) {
                  Notify.error(errors.message);
                  return;
                }
                Notify.confirm(function () {
                  //delete the allowed files and show those that can not be deleted
                  _transformErrors(errors, true);
                }, Lang.get('notify_actions.file_manager.files_can_not_be_deleted'), null, {
                  btnCancelTitle: Lang.get('general.close'),
                  btnConfirmTitle: Lang.get('general.show')
                }, function () {
                  //delete the allowed files
                  _transformErrors(errors, false);
                });
              });
            }, false, '/app/modules/shared/pages/filemanager/dialogs/destroy.html', params);
          }
          function previewImage() {
            var file = _.first(getSelectedFiles());
            if (_.isUndefined(file)) {
              Notify.error(Lang.get('notify_actions.empty_data_set'));
              return;
            }
            if (file.is_image) {
              PopupService.open(file, vm.collection);
            }
          }
          function downloadMultiple() {
            //extract only selected files
            var files = getSelectedFiles();
            //make Files Not Selected
            _unSelectFiles();
            //show error
            if (!files.length) {
              Notify.error(Lang.get('notify_actions.empty_data_set'));
              return;
            }
            HttpService.post('/filemanager/generateFiles', { files: files }, function (resp) {
              //assign initial values for downloading files
              _setDownloadInformation(resp);
              //hide optional menu
              vm.showOptionButtons = false;
            }, function (error) {
              Notify.error(error['message']);
            });
          }
          function cancelDownload() {
            HttpService.post('/filemanager/cancelDownload', {}, function (resp) {
              //assign default values to download files
              _setDownloadInformation(resp);
            });
          }
          /**
			 * @param file
			 * @returns {[null]}
			 */
          function menuOptions(file) {
            var menu = [[
                  Lang.get('general.download'),
                  function ($itemScope) {
                    var link = document.createElement('a');
                    document.body.appendChild(link);
                    link.href = $filter('downloadFile')($itemScope.file);
                    link.download = $itemScope.file.name;
                    link.click();
                  }
                ]];
            if (file.is_image) {
              menu.push([
                Lang.get('general.preview'),
                function ($itemScope) {
                  PopupService.open($itemScope.file, vm.collection);
                }
              ]);
            }
            if (!file.show_cannot_remove) {
              menu.push([
                Lang.get('general.delete'),
                function ($itemScope) {
                  destroy($itemScope.file);
                }
              ]);
            }
            return menu;
          }
          function hideOptionMenu() {
            //hide optional menu
            vm.showOptionButtons = false;
          }
          function _changeShowButtonOption() {
            //extract only selected files
            var selectedFiles = getSelectedFiles();
            if (selectedFiles.length) {
              //show optional menu
              vm.showOptionButtons = true;
              // for preview file
              vm.showPreviewButtons = false;
              vm.selectedFiles.file = null;
              if (selectedFiles.length === 1) {
                vm.selectedFiles.file = _.first(selectedFiles);
                vm.showPreviewButtons = vm.selectedFiles.file.is_image;
              }
            } else {
              //hide optional menu
              vm.showOptionButtons = false;
            }
            //count the number and size of the selected files
            _calcFilesTotal();
          }
          function _calcFilesTotal() {
            //extract only selected files
            var files = getSelectedFiles();
            //default selected file size
            vm.selectedFiles.size = 0;
            //count the number of files to choose
            vm.selectedFiles.total = files.length;
            //calculate the size of the selected files
            _.forEach(files, function (item) {
              vm.selectedFiles.size += item.size;
            });
          }
          /**
			 * @param errors
			 * @param showError
			 * @private
			 */
          function _transformErrors(errors, showError) {
            if (_.isUndefined(showError)) {
              showError = true;
            }
            //show files not selected files
            var notChecked = _.filter(vm.collection, function (item) {
                return item.checked !== true;
              });
            vm.collection = notChecked.concat(errors);
            if (showError) {
              _.forEach(errors, function (error) {
                _.forEach(vm.collection, function (item) {
                  //show files that can not be deleted
                  if (item.id === error.id) {
                    item.cannot_remove = true;
                  }
                  //unselected files
                  item.checked = false;
                });
              });
            } else {
              _unSelectFiles();
            }
            _changeShowButtonOption();
            //do not show files that can not be deleted after 5 seconds
            $timeout(function () {
              _.forEach(vm.collection, function (item) {
                if (item.cannot_remove) {
                  item.cannot_remove = false;
                }
              });
            }, 5000);
          }
          function _unSelectFiles() {
            //unselected files
            _.forEach(vm.collection, function (item) {
              item.checked = false;
            });
          }
          /**
			 * @param file
			 * @returns {*}
			 * @private
			 */
          function _getParentName(file) {
            return FileManagerService.getParentName(file);
          }
          /**
			 * @param data
			 * @private
			 */
          function _setDownloadInformation(data) {
            vm.download = {
              percent: data.percent,
              in_progress: data.in_progress,
              file: data.file
            };
          }
          PagingFilterService.bind($scope);
        }
      ]
    };
  var fileManagerNav = {
      bindings: {
        files: '<',
        properties: '<?',
        units: '<?'
      },
      templateUrl: '/app/modules/shared/pages/filemanager/components/nav.html',
      controller: [
        '$scope',
        '$element',
        'BreadCrumbsService',
        'EmitService',
        'ng_util',
        function ($scope, $element, BreadCrumbsService, EmitService, ng_util) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          //navigation data
          if (_.get(vm.units, 'list', []).length) {
            vm.navData = vm.units;
          } else {
            vm.navData = vm.properties;
          }
          vm.pagination = vm.files.pagination;
          vm.storage = vm.files.storage;
          ng_util.definePropertyObj(EmitService, 'storage', function (storage) {
            if (!_.isUndefined(storage)) {
              vm.storage = storage;
            }
          });
        }
      ]
    };
  var fileManagerScroll = {
      bindings: {
        totalFiles: '@',
        data: '<',
        storage: '<'
      },
      templateUrl: '/app/modules/shared/pages/filemanager/components/scroll.html',
      controller: [
        '$rootScope',
        '$stateParams',
        'FileManagerService',
        'HttpService',
        function ($rootScope, $stateParams, FileManagerService, HttpService) {
          'ngInject';
          var vm = this, propertyId = parseInt($stateParams.property_id), unitId = parseInt($stateParams.unit_id), page = 1;
          isActive();
          //an array of data
          vm.nextPageUrl = _.get(vm.data, 'pagination.next_page_url');
          vm.data = vm.data.list;
          // ui
          vm.ui = FileManagerService.ui();
          vm.propertyId = propertyId;
          vm.propertySortByReverse = false;
          vm.isServicePro = $rootScope.auth._isPro();
          vm.breadcrumbs = [];
          vm.sortBy = sortBy;
          vm.getNavPropertyId = getNavPropertyId;
          vm.isSingleFamily = isSingleFamily;
          vm.getNavUnitId = getNavUnitId;
          vm.loadMore = loadMore;
          if (propertyId) {
            var propertyData = _.find(vm.data, { property_id: propertyId });
            if (!_.isUndefined(propertyData)) {
              vm.breadcrumbs.push({
                id: propertyData.property.id,
                name: propertyData.property.name,
                activated: propertyId && !unitId,
                params: {
                  property_id: propertyData.property.id,
                  name: null,
                  unit_id: null,
                  page: null
                }
              });
            }
            if (unitId) {
              var unitData = _.find(vm.data, {
                  id: unitId,
                  property_id: propertyId
                });
              if (!_.isUndefined(unitData)) {
                vm.breadcrumbs.push({
                  id: unitData.id,
                  name: unitData.name,
                  activated: true,
                  params: {
                    property_id: propertyData.property.id,
                    name: null,
                    unit_id: unitData.id,
                    page: null
                  }
                });
              }
            }
          }
          function isActive() {
            _.forEach(vm.data, function (item) {
              if (unitId && $stateParams.is_single_family === 'false') {
                item.activated = unitId === item.id;
              }
              if (propertyId && !unitId && $stateParams.is_single_family === 'true') {
                item.activated = propertyId === item.id;
              }
            });
          }
          function sortBy() {
            vm.propertySortByReverse = !vm.propertySortByReverse;
          }
          /**
			 * @param item
			 * @returns {*}
			 */
          function getNavPropertyId(item) {
            return !vm.propertyId || vm.isServicePro || $stateParams.is_single_family === 'true' ? item.id : item.property_id;
          }
          /**
			 * @param item
			 * @returns {boolean}
			 */
          function isSingleFamily(item) {
            return item.type === 1 && !item.property_id;
          }
          /**
			 * @param item
			 * @returns {null}
			 */
          function getNavUnitId(item) {
            return vm.propertyId && !vm.isServicePro && $stateParams.is_single_family === 'false' ? item.id : null;
          }
          /**
			 * Load more
			 */
          function loadMore() {
            page++;
            var url = '/filemanager/' + (propertyId && $stateParams.is_single_family === 'false' ? 'units?property_id=' + propertyId : 'properties');
            HttpService.getWParams(url, { params: { page: page } }, function (resp) {
              vm.nextPageUrl = _.get(resp, 'pagination.next_page_url');
              if (!resp.list.length) {
                page--;
                return;
              }
              vm.data = _.concat(vm.data, resp.list);
            });
          }
        }
      ]
    };
  angular.module('app.filemanager.components', []).component('fileManagerList', fileManagerList).component('fileManagerNav', fileManagerNav).component('fileManagerScroll', fileManagerScroll);
  ;
}());