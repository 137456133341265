(function () {
  'use strict';
  feedHelper.$inject = [
    '$rootScope',
    '$filter'
  ];
  calendarEventHelper.$inject = ['$filter'];
  dateHelper.$inject = ['$filter'];
  angular.module('app.helper', []).factory('MessageHelper', messageHelper).factory('FeedHelper', feedHelper).factory('CalendarEventHelper', calendarEventHelper).factory('DateHelper', dateHelper);
  ;
  function messageHelper() {
    return { prepareDataWithShowDateLabels: prepareDataWithShowDateLabels };
    /**
		 * Function for settings ShowDate labels
		 */
    function prepareDataWithShowDateLabels(data) {
      var currentDate = null;
      _.map(data, function (item) {
        if (!item.updated_at) {
          return;
        }
        var parsedDate = moment(item.updated_at).format('L');
        item.showDate = false;
        if (currentDate === null || currentDate != parsedDate) {
          item.showDate = true;
          // set as today first message
          if (parsedDate == moment().format('L')) {
            item.isToday = true;
          }
          // set as yesterday first message
          if (parsedDate == moment().subtract(1, 'days').format('L')) {
            item.isYesterday = true;
          }
          currentDate = moment(item.updated_at).format('L');
        }
      });
      return data;
    }
  }
  /**
	 * Helpers for feed. Generating links, messages and days chronic etc.
	 */
  function feedHelper($rootScope, $filter) {
    return {
      prepareDataWithShowDateLabels: prepareDataWithShowDateLabels,
      generateOpenLink: generateOpenLink,
      prepareGroupParameter: prepareGroupParameter
    };
    /**
		 * Function for settings ShowDate labels
		 */
    function prepareDataWithShowDateLabels(data) {
      var currentDate = null;
      _.map(data, function (item) {
        var parsedDate = $filter('messageDayMonth')(item.created_at);
        var yesterday = moment(moment().date(moment().date() - 1)).toDate();
        item.showDate = false;
        item.isToday = parsedDate == $filter('messageDayMonth')(moment());
        item.isYesterday = parsedDate == $filter('messageDayMonth')(yesterday);
        item.openLink = generateOpenLink(item.type, item.details);
        if (currentDate === null || currentDate != parsedDate) {
          //check if it is not today
          item.showDate = true;
          currentDate = $filter('messageDayMonth')(item.created_at);
        }
      });
      return data;
    }
    function generateOpenLink(feedType, details) {
      feedType = parseInt(feedType);
      var url;
      switch (feedType) {
      // Connections
      case 1:
      case 8:
        if ($rootScope.auth.role === 'tenant') {
          url = '/connections';
          break;
        }
        if ($rootScope.auth.role === 'professional') {
          url = '/contacts/' + details.invite_id;
          break;
        }
        if ($rootScope.auth.role === 'admin') {
          var connection_user_type = details.connection_user_type || 'tenant';
          url = '/users/' + connection_user_type + 's';
          break;
        }
        url = '/invites/' + details.invite_id;
        break;
      case 7:
      case 2:
      case 3:
      case 9:
      case 10:
        if ($rootScope.auth.role === 'tenant') {
          url = '/connections';
          break;
        }
        if ($rootScope.auth.role === 'professional') {
          url = '/contacts';
          break;
        }
        if ($rootScope.auth.role === 'admin') {
          connection_user_type = details.connection_user_type || 'tenant';
          url = '/users/' + connection_user_type + 's/' + details.invite_id + '/view';
          break;
        }
        url = '/invites';
        break;
      case 11:
        url = '/workorders/' + details.order_id + '/view';
        break;
      case 12:
        url = '/workorders/' + details.order_id + '/view';
        break;
      case 13:
      case 14:
      case 15:
      case 16:
      case 17:
      case 18:
      case 21:
        url = '/workorders/' + details.order_id + '/view';
        break;
      case 22:
        if (_.get(details, 'order_id')) {
          url = '/workorders/' + details.order_id + '/view?tab=messages';
        }
        break;
      case 40:
      case 41:
      case 42:
      case 44:
      case 45:
        url = '/applications/' + details.application_id;
        break;
      case 50:
        if ($rootScope.auth.role === 'tenant') {
          url = '/rentals/' + details.item_id + '/finances';
        } else {
          url = '/transactions';
        }
        break;
      case 70:
        url = '/applications/' + details.application_id + '/screening';
        break;
      case 71:
      case 72:
      case 73:
      case 74:
        url = '/applications/' + details.application_id + '/screening';
        break;
      case 80:
        url = '/users/tenants/' + details.tenant_id;
        break;
      case 81:
        url = '/property/' + details.property_id + '/edit';
        break;
      case 82:
        url = '/profile';
        break;
      case 101:
        url = '/feedback/' + details.id;
        break;
      case 111:
        url = '/rentals/' + details.id + '/view';
        break;
      case 112:
        url = '/property/' + details.propertyId + '/units/' + details.unitId + '/leases/' + details.leaseId + '/view';
        break;
      case 120:
      case 121:
      case 122:
      case 123:
        url = '/property/' + details.property_id + '/equipment/' + details.equipment_id + '/edit';
        break;
      case 140:
        url = '/settings/verification';
        break;
      case 180:
        url = '/listings/' + details.listing_id + '/tenant_match';
        break;
      case 181:
        url = '/rentwish?invites=true';
        break;
      case 182:
        url = '/rentwish';
        break;
      case 183:
        url = '/settings/billing';
        break;
      case 200:
      case 201:
        return false;
      case 220:
        url = '/calendar?date=' + details.date;
        break;
      default:
        url = '';
      }
      //if(userRole === 'tenant' || userRole === 'professional' || userRole === 'owner') {
      //	url = '/dashboard' + url;
      //}
      return url;
    }
    function prepareGroupParameter(params) {
      if (!_.isUndefined(params.group)) {
        params['group[]'] = params.group;
        delete params.group;
      }
      return params;
    }
  }
  /**
	* Helpers for feed. Generating links, messages and days chronic etc.
	*/
  function calendarEventHelper($filter) {
    return { prepareData: prepareData };
    /**
		 * Function for settings ShowDate labels
		 */
    function prepareData(data) {
      var currentDate = null;
      _.map(data, function (item) {
        var parsedDate = $filter('messageDayMonth')(item.created_at);
        var yesterday = moment(moment().date(moment().date() - 1)).toDate();
        item.showDate = false;
        item.isToday = parsedDate == $filter('messageDayMonth')(moment());
        item.isYesterday = parsedDate == $filter('messageDayMonth')(yesterday);
        item.openLink = generateOpenLink(item.type, item.details);
        if (currentDate === null || currentDate != parsedDate) {
          //check if it is not today
          item.showDate = true;
          currentDate = $filter('messageDayMonth')(item.created_at);
        }
      });
      return data;
    }
    function generateOpenLink(remindableType, details) {
      var url;
      switch (remindableType) {
      // Connections
      case 'App\\PropertyUnitLease':
        url = '/invites/' + details.invite_id;
        break;
      default:
        url = false;
      }
      return url;
    }
  }
  /**
	 * Helpers to work with date
	 */
  function dateHelper($filter) {
    return { calculateAge: calculateAge };
    function calculateAge(birthday) {
      var yearsCount = $filter('dateDiffAsYears')(birthday);
      return yearsCount <= 0 ? 0 : yearsCount;  //var past = new Date(birthday);
                                                //var ageDifMs = Date.now() - past.getTime();
                                                //var ageDate = new Date(ageDifMs); // milliseconds from epoch
                                                //return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
  }
}());