(function () {
  'use strict';
  var loginComponent = {
      templateUrl: '/app/modules/auth/login/login.html',
      controller: [
        '$scope',
        '$state',
        '$stateParams',
        '$location',
        'CordovaService',
        'AuthDataService',
        'HttpService',
        'BreadCrumbsService',
        'SocialConnectService',
        'Notify',
        function ($scope, $state, $stateParams, $location, CordovaService, AuthDataService, HttpService, BreadCrumbsService, SocialConnectService, Notify) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('auth.log_in'));
          vm.isCordovaApp = CordovaService.isCordova();
          vm.item = _.clone($location.search());
          vm.isLoaded = false;
          vm.item.persistent = 1;
          // Sets remember me as checked by default
          vm.login = login;
          vm.socialConnect = SocialConnectService.open;
          // social errors
          if (vm.item.authError) {
            Notify.error(Lang.get('auth.auth_errors.' + vm.item.authError));
          }
          /**
			 * Action send Login Form.
			 * @returns {{}|*}
			 */
          function login() {
            vm.isLoaded = true;
            HttpService.post('/auth/login', vm.item, function (resp) {
              if (resp.twoFactorAuthRequired) {
                $state.go('auth.two_factor_validate');
              } else {
                if (resp.user) {
                  BreadCrumbsService.clear();
                  AuthDataService.loadModule(resp);
                  vm.isLoaded = false;
                } else {
                  AuthDataService.clearAuth();
                  vm.isLoaded = false;
                  if (resp.profile) {
                    // Not registered by social network
                    Notify.error(Lang.get('auth.auth_errors.2'));
                  }
                }
              }
            }, function (resp) {
              AuthDataService.clearAuth();
              vm.isLoaded = false;
              if (_.has(resp, 'message')) {
                Notify.error(resp.message);
              }
            }, { ignoreLoadingBar: true });
          }
        }
      ]
    };
  var logoutComponent = {
      controller: [
        'AuthDataService',
        'HttpService',
        function (AuthDataService, HttpService) {
          'ngInject';
          AuthDataService.logout(HttpService);
        }
      ]
    };
  angular.module('app.login.component', []).component('loginComponent', loginComponent).component('logoutComponent', logoutComponent);
  ;
}());