(function () {
  'use strict';
  SocialConnectService.$inject = ['$window'];
  angular.module('app.services.social', []).service('SocialConnectService', SocialConnectService).service('UniqueAgentIDService', UniqueAgentIDService);
  ;
  /* Fn
	 ============================================================================================================= */
  function SocialConnectService($window) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        open: open,
        connectGoogleCalendar: connectGoogleCalendar
      };
    return service;
    /**
		 *
		 * @param event
		 * @param provider
		 * @returns {boolean}
		 */
    function open(event, provider) {
      var width, height, options;
      if (window.cordova) {
        return false;
      }
      event.preventDefault();
      switch (provider) {
      case 'facebook':
        width = 600;
        height = 530;
        break;
      case 'google':
        width = 452;
        height = 633;
        break;
      case 'linkedin':
        width = 527;
        height = 582;
        break;
      }
      options = _stringifyOptions({
        width: width,
        height: height,
        top: $window.screenY + ($window.outerHeight - height) / 2.5,
        left: $window.screenX + ($window.outerWidth - width) / 2
      });
      window.open(event.currentTarget.href, provider, options);
    }
    /**
		 *
		 * @param event
		 * @returns {boolean}
		 */
    function connectGoogleCalendar(event) {
      var width = 600, height = 530, options;
      if (window.cordova) {
        return false;
      }
      event.preventDefault();
      options = _stringifyOptions({
        width: width,
        height: height,
        top: $window.screenY + ($window.outerHeight - height) / 2.5,
        left: $window.screenX + ($window.outerWidth - width) / 2
      });
      window.open(event.currentTarget.href, 'Google', options);
    }
    /**
		 *
		 * @param options
		 * @returns {string}
		 * @private
		 */
    function _stringifyOptions(options) {
      var parts = [];
      angular.forEach(options, function (value, key) {
        parts.push(key + '=' + value);
      });
      return parts.join(',');
    }
  }
  function UniqueAgentIDService() {
    var uaid = window.uuidv4();
    this.token = function () {
      return uaid;
    };
  }
}());