(function () {
  'use strict';
  mdMediaFactory.$inject = [
    '$mdConstant',
    '$rootScope',
    '$window',
    'EmitService'
  ];
  angular.module('app.factory.resize', []).factory('$mdConstant', MdConstantFactory).factory('$mdMedia', mdMediaFactory);
  /**
	 * Factory function that creates the grab-bag $mdConstant service.
	 * @ngInject
	 */
  function MdConstantFactory() {
    // noinspection UnnecessaryLocalVariableJS
    var self = {
        MEDIA: {
          'xs': '(max-width: 480px)',
          'sm': '(max-width: 768px)',
          'md': '(max-width: 992px)',
          'lg': '(max-width: 1270px)',
          'xs-max': '(max-width: 767px)',
          'sm-max': '(max-width: 991px)',
          'md-max': '(max-width: 1269px)',
          'landscape': '(orientation: landscape)',
          'portrait': '(orientation: portrait)',
          'print': 'print'
        }
      };
    return self;
  }
  function mdMediaFactory($mdConstant, $rootScope, $window, EmitService) {
    var queries = {}, mqls = {}, results = {};
    $mdMedia.getQuery = getQuery;
    return $mdMedia;
    function $mdMedia(query) {
      var validated = queries[query];
      if (_.isUndefined(validated)) {
        validated = queries[query] = validate(query);
      }
      var result = results[validated];
      if (_.isUndefined(result)) {
        result = add(validated);
      }
      return result;
    }
    function validate(query) {
      return $mdConstant.MEDIA[query] || (query.charAt(0) !== '(' ? '(' + query + ')' : query);
    }
    function add(query) {
      var result = mqls[query];
      if (!result) {
        result = mqls[query] = $window.matchMedia(query);
      }
      result.addListener(onQueryChange);
      return results[result.media] = !!result.matches;
    }
    function onQueryChange(query) {
      // watcher
      EmitService.watchResize(!!query.matches);
      $rootScope.$evalAsync(function () {
        results[query.media] = !!query.matches;
      });
    }
    function getQuery(name) {
      return mqls[name];
    }
  }
}());