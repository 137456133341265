(function () {
  'use strict';
  /**
	 * @namespace dwollaCustomerInfo
	 * @desc Dwolla customer info
	 * @example <dwolla-customer-info></dwolla-customer-info>
	 * @param {Object} customer - Customer information
	 * @memberOf dwollaSettingsComponent
	 */
  var dwollaCustomerInfo = {
      bindings: { customer: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/customer-info.html',
      controller: [
        '$rootScope',
        'HttpService',
        'Notify',
        function ($rootScope, HttpService, Notify) {
          'ngInject';
          var vm = this;
          vm.isDwollaActive = _.get($rootScope, 'auth.payments.gateway.dwolla.enabled', false);
          vm.changeActiveSettings = changeActiveSettings;
          /**
			 * @desc Get payments status
			 * @returns {*}
			 */
          function changeActiveSettings() {
            if (vm.isDwollaActive) {
              Notify.confirm(function () {
                changeSettings();
              }, Lang.get('dwolla.notify_actions.turn_off_tcpayments'));
            } else {
              changeSettings();
            }
            function changeSettings() {
              vm.isDwollaActive = !vm.isDwollaActive;
              HttpService.put('/dwolla/customer/change_active', { enabled: vm.isDwollaActive }, function () {
                Notify.success(Lang.get('notify_actions.successfully'));
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaBalance
	 * @desc Dwolla balance info
	 * @example <dwolla-balance></dwolla-balance>
	 * @param {Object} customer - Customer information
	 * @param {Array} sources
	 * @param {Array} balance
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaBalance = {
      bindings: {
        customer: '<',
        sources: '<',
        balance: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/balance.html',
      controller: [
        '$rootScope',
        '$state',
        '$stateParams',
        '$filter',
        'HttpService',
        'Notify',
        'DwollaService',
        'ngDialog',
        function ($rootScope, $state, $stateParams, $filter, HttpService, Notify, DwollaService, ngDialog) {
          'ngInject';
          var vm = this;
          // Construct
          vm.customerBalance = _transformBalanceToString(vm.balance);
          vm.fundingSources = _transformSources(vm.sources);
          vm.showBalanceBlock = showBalanceBlock;
          // Transfer
          vm.transfer = {
            canAddMoney: canAddMoney,
            canWithdraw: canWithdraw,
            clickAddMoney: clickAddMoney,
            clickWithdraw: clickWithdraw
          };
          // Action from query params
          if ($stateParams.action) {
            switch ($stateParams.action) {
            case 'withdraw':
              if (vm.transfer.canWithdraw()) {
                vm.transfer.clickWithdraw();
              }
              break;
            }
          }
          // Subscription
          DwollaService.subscribe('update_balance', function () {
            _getBalance();
          });
          DwollaService.subscribe('update_sources', function () {
            _getSources();
          });
          /**
			 * @desc Show balance if isLandlord or isOwner
			 * @returns {*}
			 */
          function showBalanceBlock() {
            if (!$rootScope.auth._isTenant()) {
              return true;
            }
            if (!vm.customerBalance) {
              return false;
            }
            return parseFloat(vm.customerBalance.replace('USD ', ''));
          }
          /**
			 * @desc Can add money if isLandlord
			 * @returns {boolean}
			 */
          function canAddMoney() {
            if (vm.customer.isVerified() && !$rootScope.auth._isTenant()) {
              return true;
            }
            return vm.customerBalance ? parseFloat(vm.customerBalance.replace('USD ', '')) < 0 : false;
          }
          /**
			 * @desc Can withdraw if isLandlord
			 * @returns {boolean}
			 */
          function canWithdraw() {
            if (vm.customer.isVerified() && !$rootScope.auth._isTenant()) {
              return _.get(vm.balance, 'value', 0) > 0;
            }
            return vm.customerBalance ? parseFloat(vm.customerBalance.replace('USD ', '')) > 0 : false;
          }
          /**
			 * @desc Dialog add money
			 */
          function clickAddMoney() {
            ngDialog.open({
              plain: true,
              template: '<dwolla-add-money funding-sources="$ctrl.fundingSources"></dwolla-add-money>',
              className: 'ngdialog-theme-default',
              controllerAs: '$ctrl',
              controller: function () {
                this.vm = angular.extend({}, vm);
                this.fundingSources = vm.fundingSources;
              }
            });
          }
          /**
			 * @desc Dialog add withdraw
			 */
          function clickWithdraw() {
            ngDialog.open({
              plain: true,
              template: '<dwolla-withdraw funding-sources="$ctrl.fundingSources"></dwolla-withdraw>',
              className: 'ngdialog-theme-default',
              controllerAs: '$ctrl',
              controller: function () {
                this.vm = angular.extend({}, vm);
                this.fundingSources = vm.fundingSources;
              }
            });
          }
          /**
			 * @desc Get balance
			 * @private
			 */
          function _getBalance() {
            DwollaService.getBalance(successCallback, _requestErrorCallback);
            function successCallback(resp) {
              vm.balance = resp;
              vm.customerBalance = _transformBalanceToString(resp);
            }
          }
          /**
			 * @desc Get funding sources
			 * @private
			 */
          function _getSources() {
            DwollaService.getSources(null, successCallback, _requestErrorCallback);
            function successCallback(resp) {
              vm.fundingSources = _transformSources(resp);
            }
          }
          /**
			 * @desc Transform balance
			 * @private
			 */
          function _transformBalanceToString(balance) {
            return [
              _.get(balance, 'currency', 'USD'),
              _.get(balance, 'value', '0,00')
            ].join(' ');
          }
          /**
			 * @desc Transform sources
			 * @private
			 */
          function _transformSources(list) {
            var kv_array = [];
            _.forEach(list, function (item) {
              if (item.type !== 'balance' && item.status === 'verified') {
                kv_array.push({
                  key: item.id,
                  value: item.name
                });
              }
            });
            return kv_array;
          }
          /**
			 * @desc Error callback for requests
			 * @private
			 */
          function _requestErrorCallback(errors) {
            Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
          }
        }
      ]
    };
  /**
	 * @namespace dwollaSources
	 * @desc Dwolla sources
	 * @example <dwolla-sources></dwolla-sources>
	 * @param {Object} customer - Customer information
	 * @param {Array} sources
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaSources = {
      bindings: {
        customer: '<',
        sources: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/sources.html',
      controller: [
        '$rootScope',
        '$state',
        '$element',
        'HttpService',
        'Notify',
        'ngDialog',
        'DwollaService',
        'APP_CONFIG',
        function ($rootScope, $state, $element, HttpService, Notify, ngDialog, DwollaService, APP_CONFIG) {
          'ngInject';
          var vm = this, ACCOUNT_TYPE = APP_CONFIG.constants.dwolla.source.account_type;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.$onInit = function () {
            // Prepare accounts list
            // Initial sources by account type
            vm.sources = _.reject(vm.sources, { type: 'balance' });
          };
          vm.action = {
            getSourcesMain: getSourcesMain,
            getSourcesDeposit: getSourcesDeposit,
            getSourcesProperty: getSourcesProperty,
            showButtonAdd: showButtonAdd
          };
          // Subscription
          DwollaService.subscribe('update_sources', function () {
            _refreshSources(true);
          });
          /**
			 * Get bank source for main account
			 * @returns {*}
			 */
          function getSourcesMain() {
            return _.find(vm.sources, { account_type: ACCOUNT_TYPE.main });
          }
          /**
			 * Get bank source for deposit account
			 * @returns {*}
			 */
          function getSourcesDeposit() {
            return _.find(vm.sources, { account_type: APP_CONFIG.constants.dwolla.source.account_type.deposit });
          }
          /**
			 * Get bank sources list for property account
			 * @returns {*}
			 */
          function getSourcesProperty() {
            return _.filter(vm.sources, { account_type: APP_CONFIG.constants.dwolla.source.account_type.property });
          }
          /**
			 * Is allowed to add new bank account
			 * @returns {boolean}
			 */
          function showButtonAdd() {
            return vm.sources.length <= 6;
          }
          // TODO don't use
          // function initiateMicroDeposit(bank) {
          // 	Notify.confirm(function(){
          // 		HttpService.post('/dwolla/funding_sources/micro_deposits_initiate', {source_id: bank.source_id}, success,  error);
          // 		function success() {
          // 			DwollaService.updateSources();
          // 			Notify.success(Lang.get('general.success'));
          // 		}
          // 		function error(errors) {
          // 			DwollaService.updateSources();
          // 			Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
          // 		}
          // 	}, Lang.get('dwolla.notify_actions.initiate_micro_deposits'));
          // }
          /**
			 * @desc Refresh sources
			 * @param {?} fresh
			 * @private
			 */
          function _refreshSources(fresh) {
            DwollaService.getSources(fresh, successCallback, errorCallback);
            function successCallback(list) {
              vm.sources = list ? _.reject(list, { type: 'balance' }) : [];
            }
            function errorCallback(errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaSourcesBank
	 * @desc Dwolla sources bank
	 * @example <dwolla-sources-bank></dwolla-sources-bank>
	 * @param {Object} bank - Bank item
	 * @param {Array} sources
	 * @param {Object} bank Bank item
	 * @memberOf dwollaSources
	 */
  var dwollaSourcesBank = {
      bindings: {
        sources: '<?',
        bank: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/sources-bank.html',
      controller: [
        '$rootScope',
        'APP_CONFIG',
        'HttpService',
        'Notify',
        'DwollaService',
        'ngDialog',
        function ($rootScope, APP_CONFIG, HttpService, Notify, DwollaService, ngDialog) {
          'ngInject';
          var vm = this, ACCOUNT_TYPE = APP_CONFIG.constants.dwolla.source.account_type;
          vm.canRemove = canRemove;
          vm.canVerify = canVerify;
          vm.checkMicroDeposit = checkMicroDeposit;
          vm.countSourceLinks = countSourceLinks;
          vm.isAccountType = isAccountType;
          vm.canDefineAs = canDefineAs;
          vm.defineAs = defineAs;
          vm.removeBank = removeBank;
          vm.dwollaIavOpen = false;
          /**
			 * @namespace canRemove
			 * @desc Is user can remove bank source
			 * @returns {boolean}
			 */
          function canRemove() {
            if (!vm.bank) {
              return false;
            }
            return vm.bank.type !== 'balance';
          }
          /**
			 * @namespace countSourceLink
			 * @desc Count source links
			 * @returns {number}
			 */
          function countSourceLinks() {
            return _.get(vm.bank, 'links', []).length;
          }
          /**
			 * @namespace isAccountType
			 * @desc Check if bank source account_type eq param
			 * @returns {boolean}
			 */
          function isAccountType(type) {
            return vm.bank.account_type === type;
          }
          /**
			 * @namespace isAccountType
			 * @desc Check if user can define current bank source as param type
			 * @returns {boolean}
			 */
          function canDefineAs(type) {
            return $rootScope.auth._isLandlord() && !_.find(vm.sources || [], { account_type: type });
          }
          /**
			 * @namespace defineAs
			 * @desc Is user can remove bank source
			 * @param {?} type
			 * @returns {*}
			 */
          function defineAs(type) {
            if (!type) {
              switch (vm.bank.account_type) {
              case ACCOUNT_TYPE.main:
              case ACCOUNT_TYPE.deposit:
                type = ACCOUNT_TYPE.property;
                break;
              case ACCOUNT_TYPE.property:
                type = ACCOUNT_TYPE.main;
                break;
              }
            }
            HttpService.put('/dwolla/funding_sources/' + vm.bank.source_id, { account_type: type }, success, error);
            function success() {
              DwollaService.updateSources();
              Notify.success(Lang.get('general.success'));  // TODO text
            }
            function error(errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            }
          }
          /**
			 * @namespace canVerify
			 * @desc Is user can verify bank account by micro deposits
			 * @returns {boolean}
			 */
          function canVerify() {
            if (!vm.bank) {
              return false;
            }
            return vm.bank.type !== 'balance' && vm.bank.status === 'unverified';
          }
          /**
			 * @namespace removeBank
			 * @desc Remove bank account
			 * @returns {*}
			 */
          function removeBank() {
            Notify.confirm(function () {
              HttpService.delete('/dwolla/funding_sources/' + vm.bank.source_id, function () {
                DwollaService.updateSources();
                Notify.success(Lang.get('general.success'));
              });
            }, Lang.get('dwolla.notify_actions.delete_source'));
          }
          /**
			 * @namespace checkMicroDeposit
			 * @desc Validate bank account via micro deposits
			 * @returns {*}
			 */
          function checkMicroDeposit() {
            HttpService.get('/dwolla/funding_sources/micro_deposits_exist/' + vm.bank.source_id, success, error);
            function success(resp) {
              if (_.get(resp, 'micro_deposit')) {
                ngDialog.open({
                  plain: true,
                  template: '<dwolla-sources-micro-deposits bank="$ctrl.bank"></dwolla-sources-micro-deposits>',
                  className: 'ngdialog-theme-default',
                  controllerAs: '$ctrl',
                  controller: function () {
                    this.vm = angular.extend({}, vm);
                    this.bank = vm.bank;
                  }
                });
              }
            }
            function error(errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaSourcesIav
	 * @desc Dwolla sources iav
	 * @example <dwolla-sources-iav></dwolla-sources-iav>
	 * @memberOf dwollaBankAccountComponent.dwollaSources
	 */
  var dwollaSourcesIav = {
      bindings: {},
      templateUrl: '/app/modules/shared/pages/dwolla/components/sources-iav.html',
      controller: [
        '$state',
        '$timeout',
        'Notify',
        'DwollaService',
        function ($state, $timeout, Notify, DwollaService) {
          'ngInject';
          // Get IAV token for current customer
          DwollaService.getIAVToken('iav_token', function (token) {
            window.dwolla.iav.start(token, {
              container: 'iavContainer',
              microDeposits: true,
              fallbackToMicroDeposits: false
            }, function (errors) {
              if (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
                return false;
              }
              // Check if micro deposits initiated
              Notify.success(Lang.get('dwolla.bank_account_created'));
              // Update sources
              DwollaService.updateSources();
            });
          });
        }
      ]
    };
  /**
	 * @namespace dwollaSourcesMicroDeposits
	 * @desc Dwolla sources micro deposits
	 * @example <dwolla-sources-micro-deposits></dwolla-sources-micro-deposits>
	 * @memberOf dwollaBankAccountComponent.dwollaSources
	 */
  var dwollaSourcesMicroDeposits = {
      bindings: { bank: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/dialog/sources-micro-deposits.html',
      controller: [
        '$state',
        '$timeout',
        'ngDialog',
        'Notify',
        'HttpService',
        'DwollaService',
        function ($state, $timeout, ngDialog, Notify, HttpService, DwollaService) {
          'ngInject';
          var vm = this;
          var defaultModel = {
              source_id: vm.bank.source_id,
              amount1: '0.00',
              amount2: '0.00'
            };
          vm.microDeposit = {
            model: defaultModel,
            reset: function () {
              this.model = defaultModel;
            }
          };
          vm.validation_error_message = null;
          vm.action = { verifyDeposits: verifyDeposits };
          function verifyDeposits() {
            HttpService.post('/dwolla/funding_sources/micro_deposits_check/' + vm.bank.source_id, vm.microDeposit.model, successCallback, errorCallback);
            function successCallback() {
              // Update sources
              Notify.success(Lang.get('dwolla.bank_account_verified'));
              vm.microDeposit.reset();
              // Update sources
              DwollaService.updateSources();
              // Close
              ngDialog.closeAll();
            }
            function errorCallback(errors) {
              if (_.get(errors, 'message')) {
                Notify.error(errors.message);
                vm.validation_error_message = errors.message;
              }
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaPlaid
	 * @desc Dwolla plaid
	 * @example <dwolla-plaid></dwolla-plaid>
	 * @memberOf dwollaBankAccountComponent.dwollaSources
	 */
  var dwollaPlaid = {
      bindings: {
        accountType: '@',
        label: '@?'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/plaid.html',
      controller: [
        '$stateParams',
        'ngDialog',
        'APP_CONFIG',
        'Notify',
        'HttpService',
        'DwollaService',
        function ($stateParams, ngDialog, APP_CONFIG, Notify, HttpService, DwollaService) {
          'ngInject';
          var vm = this, linkHandler;
          vm.open = open;
          /**
			 * Open plaid popup
			 */
          function open() {
            if (APP_CONFIG.services.plaid.enabled || $stateParams.plaid_enabled) {
              // Use Plaid
              _init();
              linkHandler.open();
            } else {
              // Use Dwolla Instant account verification (IAV)
              ngDialog.open({
                plain: true,
                template: '<dwolla-sources-iav bank="$ctrl.bank"></dwolla-sources-iav>',
                className: 'ngdialog-theme-default',
                controllerAs: '$ctrl',
                controller: function () {
                  this.vm = angular.extend({}, vm);
                  this.bank = vm.bank;
                }
              });
            }
          }
          /**
			 * @desc Plaid initialization
			 * @private
			 */
          function _init() {
            linkHandler = Plaid.create({
              selectAccount: true,
              env: APP_CONFIG.services.plaid.mode,
              key: APP_CONFIG.services.plaid.publicKey,
              product: ['auth'],
              onSuccess: function (public_token, metadata) {
                /**
						 * The onSuccess function is called when the user has successfully
						 * authenticated and selected an account to use.
						 *
						 * Then marge account to dwolla
						 */
                HttpService.post('/dwolla/plaid/integrate', {
                  public_token: public_token,
                  account_id: metadata.account_id,
                  account_name: metadata.account.name,
                  account_type: vm.accountType
                }, function () {
                  // Update sources
                  DwollaService.updateSources();
                  Notify.success(Lang.get('dwolla.bank_account_created'));
                }, function (resp) {
                  Notify.error(_.get(resp, 'message', Lang.get('notify_actions.ooops')));
                });
              }
            });
          }
        }
      ]
    };
  /**
	 * @namespace dwollaTransfers
	 * @desc Dwolla transfers
	 * @example <dwolla-transfers></dwolla-transfers>
	 * @param {Object} transfers
	 * @memberOf dwollaTransfersComponent
	 */
  var dwollaTransfers = {
      bindings: { transfers: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/transfers.html',
      controller: [
        '$element',
        '$mdMedia',
        'HttpService',
        'Notify',
        'DwollaService',
        'FilterService',
        function ($element, $mdMedia, HttpService, Notify, DwollaService, FilterService) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            vm.transfers = vm.transfers.data;
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.paging = {
            total: vm.transfers.total || 0,
            offset: 0,
            currentPage: 0,
            pageLimit: 25,
            disablePrevPage: function () {
              return this.currentPage <= 0;
            },
            disableNextPage: function () {
              return this.currentPage === this.getTotalPages() - 1;
            },
            nextPage: function () {
              this.currentPage += 1;
              this.offset = this.currentPage * this.pageLimit;
              this.callback();
            },
            previousPage: function () {
              this.currentPage -= 1;
              this.offset = this.currentPage * this.pageLimit;
              this.callback();
            },
            getTotalPages: function () {
              return Math.ceil(this.total / this.pageLimit);
            },
            callback: function () {
              _getTransfers(this.offset);
            }
          };
          // Construct
          vm.$mdMedia = $mdMedia;
          vm.action = { cancel: cancel };
          vm.showFilterMenu = showFilterMenu;
          /**
			 * @desc Show filter menu
			 * @param {Object} transfer
			 */
          function showFilterMenu(transfer) {
            FilterService.showDetails(transfer);
          }
          /**
			 * @desc Cancel
			 * @param {Object} transfer
			 */
          function cancel(transfer) {
            Notify.confirm(function () {
              HttpService.delete('/dwolla/transfers/' + transfer.id, successCallback, errorCallback);
            }, Lang.get('dwolla.notify_actions.cancel_transfer'));
            function successCallback() {
              //transfer.status = 'cancelled';
              DwollaService.updateBalance();
              _getTransfers(0);
              Notify.success(Lang.get('notify_actions.successfully'));
            }
            function errorCallback(errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            }
          }
          /**
			 *
			 * @param offset
			 * @private
			 */
          function _getTransfers(offset) {
            // Clear Sources cache
            DwollaService.getTransfers(offset, successCallback, errorCallback);
            function successCallback(resp) {
              vm.isLoaded = true;
              vm.transfers = resp.data;
              vm.paging.total = resp.total;
            }
            function errorCallback(errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaFilterDetails
	 * @desc Dwolla filter details
	 * @example <dwolla-filter-details></dwolla-filter-details>
	 * @memberOf dwollaTransfersComponent
	 */
  var dwollaFilterDetails = {
      bindings: {},
      templateUrl: '/app/modules/shared/pages/dwolla/components/transfers-details.html',
      transclude: true,
      controller: [
        '$scope',
        '$element',
        '$location',
        'ng_util',
        'FilterService',
        'Notify',
        'HttpService',
        function ($scope, $element, $location, ng_util, FilterService, Notify, HttpService) {
          'ngInject';
          var vm = this;
          vm.isLoaded = false;
          vm.details = {};
          // open filter
          ng_util.definePropertyObj(FilterService, 'params', function (params) {
            if (!_.isUndefined(params)) {
              if (params.isShow) {
                vm.isLoaded = false;
                $element.addClass('open');
                vm.details = params.details;
                getDetails(params.details.id, function (resp) {
                  vm.details.item = resp;
                  vm.isLoaded = true;
                  vm.title = Lang.get('dwolla.transfers');
                });
              } else {
                $element.removeClass('open');
              }
            }
          });
          vm.showFilterMenu = function () {
            FilterService.display();
          };
          /**
			 * @desc Get information transfers
			 * @param {Number} id transfer id
			 * @param {Function} successCallback
			 */
          function getDetails(id, successCallback) {
            HttpService.getWParams('/dwolla/transfers/' + id, { ignoreLoadingBar: true }, function (resp) {
              if (successCallback) {
                successCallback(resp);
              }
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
        }
      ]
    };
  /**
	 * @namespace dwollaCustomerNew
	 * @desc Dwolla customer new
	 * @example <dwolla-customer-new></dwolla-customer-new>
	 * @param {Object} customer Customer information
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaCustomerNew = {
      bindings: { customer: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/customer-new.html',
      controller: [
        '$rootScope',
        '$filter',
        '$element',
        'cfpLoadingBar',
        'HttpService',
        'Notify',
        'ng_util',
        'array_util',
        function ($rootScope, $filter, $element, cfpLoadingBar, HttpService, Notify, ng_util, array_util) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.businessClassifications = [];
          vm.businessIndustries = [];
          getBusinessClassifications();
          _.extend(vm.customer, {
            firstName: $rootScope.auth.firstName,
            lastName: $rootScope.auth.lastName,
            email: $rootScope.auth.email,
            type: $rootScope.auth.isCompany ? 'business' : 'personal',
            address1: $rootScope.auth.address1,
            address2: $rootScope.auth.address2,
            city: $rootScope.auth.city,
            state: $rootScope.auth.state,
            postalCode: $rootScope.auth.zip,
            dateOfBirth: null,
            ssn: null,
            phone: null,
            businessClassification: null,
            businessType: null,
            businessName: $rootScope.auth.isCompany ? $rootScope.auth.company : null,
            ein: null,
            doingBusinessAs: null,
            website: $rootScope.auth.website,
            agreement: false,
            _view: {
              type: function () {
                return Lang.get('dwolla.customerOptions.' + vm.customer.type);
              },
              businessType: function () {
                return Lang.get('dwolla.businessTypeOptions.' + vm.customer.businessType);
              },
              businessClassification: function () {
                var obj = _.find(vm.businessClassifications, { key: vm.customer.$preBusinessClassification });
                return obj ? obj.value : null;
              },
              businessIndustry: function () {
                var obj = _.find(vm.businessIndustries, { key: vm.customer.businessClassification });
                return obj ? obj.value : null;
              }
            }
          });
          vm.action = { validate_data: validate_data };
          vm.customerOptions = array_util.partition($filter('trans_as_array')('dwolla.customerOptions'), 1);
          vm.customerOptionsTooltip = {
            'personal': Lang.get('dwolla.customerOptionsTooltip.personal'),
            'business': Lang.get('dwolla.customerOptionsTooltip.business')
          };
          vm.customerOptionsDesc = {
            'personal': Lang.get('dwolla.customerOptionsDesc.personal'),
            'business': Lang.get('dwolla.customerOptionsDesc.business')
          };
          // Watch for Business Type Options
          ng_util.definePropertyObj(vm.customer, 'preBusinessClassification', function (value) {
            if (!value || !vm.businessClassifications.length) {
              return false;
            }
            vm.businessIndustries = _.find(vm.businessClassifications, { key: value }).subtypes;
            vm.customer.businessClassification = null;
          });
          function getBusinessClassifications() {
            HttpService.get('/dwolla/customer/business_classifications', function (resp) {
              vm.businessClassifications = resp;
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
          function validate_data() {
            HttpService.post('/dwolla/customer/validate_data', vm.customer, function () {
              vm.customer.validated_data = true;
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
        }
      ]
    };
  /**
	 * @namespace dwollaCustomerNewInfo
	 * @desc Dwolla customer new information
	 * @example <dwolla-customer-new-info></dwolla-customer-new-info>
	 * @param {Object} customer Customer information
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaCustomerNewInfo = {
      bindings: { customer: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/customer-new-info.html',
      controller: [
        '$rootScope',
        '$state',
        '$filter',
        '$element',
        'cfpLoadingBar',
        'ngDialog',
        'HttpService',
        'Notify',
        function ($rootScope, $state, $filter, $element, cfpLoadingBar, ngDialog, HttpService, Notify) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.action = { submit: submit };
          function submit() {
            HttpService.post('/dwolla/customer', vm.customer, function (resp) {
              // Delete ssn
              delete vm.customer.ssn;
              // Extend customer
              _.extend(vm.customer, resp);
              // Update auth immediately for state reload
              _.set($rootScope, 'auth.payments.gateway.dwolla.enabled', true);
              _.set($rootScope, 'auth.payments.gateway.dwolla.verification_paid', true);
              _.set($rootScope, 'auth.payments.gateway.dwolla.customer', resp.id);
              _.set($rootScope, 'auth.payments.gateway.dwolla.status', resp.status);
              // Reload state
              $state.go('payments.dwolla_customer', {}, { reload: true });
            }, function (errors) {
              vm.customer.validated_data = false;
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
        }
      ]
    };
  /**
	 * @namespace dwollaCustomerRetry
	 * @desc Dwolla customer retry
	 * @example <dwolla-customer-retry></dwolla-customer-retry>
	 * @param {Object} customer Customer information
	 * @param {Array} businessClassifications
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaCustomerRetry = {
      bindings: {
        customer: '<',
        businessClassifications: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/customer-new.html',
      controller: [
        '$rootScope',
        '$state',
        'HttpService',
        'Notify',
        'ng_util',
        function ($rootScope, $state, HttpService, Notify, ng_util) {
          'ngInject';
          var vm = this;
          vm.businessClassifications = [];
          vm.businessIndustries = [];
          getBusinessClassifications();
          vm.action = { validate_data: submit };
          ng_util.definePropertyObj(vm.customer, 'preBusinessClassification', function (value) {
            if (!value || !vm.businessClassifications.length) {
              return false;
            }
            vm.businessIndustries = _.find(vm.businessClassifications, { key: value }).subtypes;
            vm.customer.businessClassification = null;
          });
          function getBusinessClassifications() {
            HttpService.get('/dwolla/customer/business_classifications', function (resp) {
              vm.businessClassifications = resp;
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
          function submit() {
            HttpService.put('/dwolla/customer', vm.customer, function (resp) {
              Notify.success(Lang.get('dwolla.customer_info_updated'));
              _.extend(vm.customer, resp);
              // Update auth immediately for state reload
              _.set($rootScope, 'auth.payments.gateway.dwolla.customer', resp.id);
              _.set($rootScope, 'auth.payments.gateway.dwolla.status', resp.status);
              // Reload state
              $state.go('payments.dwolla_customer', {}, { reload: true });
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
        }
      ]
    };
  /**
	 * @namespace dwollaCustomerDoc
	 * @desc Dwolla customer documents
	 * @example <dwolla-customer-doc></dwolla-customer-doc>
	 * @param {Object} customer Customer information
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaCustomerDoc = {
      bindings: { customer: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/customer-doc.html',
      controller: [
        '$filter',
        'APP_CONFIG',
        'AuthDataService',
        'FileUploader',
        'cfpLoadingBar',
        'HttpService',
        'Notify',
        function ($filter, APP_CONFIG, AuthDataService, FileUploader, cfpLoadingBar, HttpService, Notify) {
          'ngInject';
          var vm = this, allowedMimeTypes = [
              'image/jpeg',
              'image/jpg',
              'image/png',
              'image/tiff',
              'application/pdf'
            ];
          vm.$onInit = function () {
            getDocuments();
          };
          vm.isLoaded = false;
          vm.documentType = getDocumentType();
          vm.documentOptions = [];
          vm.documents = [];
          vm.uploader = new FileUploader(uploadParams());
          vm.showUpload = showUpload;
          function getDocuments() {
            HttpService.get('/dwolla/customer/documents', function (docs) {
              vm.isLoaded = true;
              if (docs) {
                vm.documents = docs;
              }
              initDocumentOptions();
            });
          }
          function uploadParams() {
            return {
              url: APP_CONFIG.external_api + '/dwolla/customer/documents',
              removeAfterUpload: true,
              queueLimit: 1,
              filters: [
                {
                  name: 'imageFilter',
                  fn: function (item) {
                    var allowed = _.includes(allowedMimeTypes, item.type);
                    if (!allowed) {
                      return Notify.alert(Lang.get('general.uploader_error_extension_type.6'));
                    }
                    return allowed;
                  }
                },
                {
                  name: 'imageSize',
                  fn: function (item) {
                    var allow = item.size <= 1024 * 1024 * 10;
                    if (!allow) {
                      return Notify.alert(Lang.get('general.uploader_error_size', { limit: 10 }));
                    }
                    return allow;
                  }
                }
              ],
              formData: [],
              onBeforeUploadItem: function (item) {
                item.formData.push({ documentType: vm.documentType });
              },
              onProgressItem: function () {
                cfpLoadingBar.start();
              },
              onCompleteItem: function (item, response, status) {
                cfpLoadingBar.complete();
                if (response.message) {
                  Notify.error(response.message);
                }
                if (status === 200) {
                  getDocuments();
                }
              },
              onErrorItem: function (item, errors) {
                Notify.alert(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              }
            };
          }
          function showUpload() {
            return _.reduce(vm.documents, function (acc, item) {
              return item.status === 'pending' ? acc + 1 : acc;
            }, 0) < 2;
          }
          function getDocumentType() {
            return vm.customer.type === 'personal' ? 'license' : 'other';
          }
          function initDocumentOptions() {
            if (vm.customer.verificationType === 'personal') {
              return vm.documentOptions = $filter('trans_as_array')('dwolla.documentTypeOptions.personal');
            }
            if (vm.customer.verificationType === 'business' && !vm.documents.length) {
              return vm.documentOptions = $filter('trans_as_array')('dwolla.documentTypeOptions.business');
            }
            vm.documentOptions = $filter('trans_as_array')('dwolla.documentTypeOptions.other');
          }
        }
      ]
    };
  /**
	 * @namespace dwollaLinkProperties
	 * @desc Dwolla link properties to the bank accounts
	 * @example <dwolla-link-properties></dwolla-link-properties>
	 * @memberOf dwollaBankAccountComponent
	 */
  var dwollaLinkProperties = {
      bindings: { sources: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/link-properties.html',
      controller: [
        'ngDialog',
        'HttpService',
        'Notify',
        function (ngDialog, HttpService, Notify) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            prepareSources(vm.sources);
            getProperties();
          };
          // Construct
          vm.isLoaded = false;
          vm.fundingSources = [];
          vm.pagination = {};
          vm.pagination.list = [];
          vm.action = {
            getStatus: getStatus,
            closeDialog: closeDialog,
            updateLink: updateLink
          };
          function getStatus(dwolla_source_link) {
            return dwolla_source_link && dwolla_source_link.source_id ? 'assigned' : 'not_assigned';
          }
          function getProperties() {
            // Get data
            HttpService.getWParams('/landlord/property/list_linked_to_dwolla_source', { ignoreLoadingBar: true }, function (resp) {
              vm.isLoaded = true;
              vm.pagination = resp.pagination;
              vm.pagination.list = resp.list;
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
          function closeDialog() {
            ngDialog.close();
          }
          function prepareSources(list) {
            vm.isLoaded = true;
            var kvArray = [];
            _.forEach(list, function (item) {
              // Init balance account
              if (item.type === 'balance') {
                item.id = undefined;
                item.source_id = undefined;
                item.name = Lang.get('dwolla.tcpayments_balance');
              }
              // Push to options only verified
              if (item.status === 'verified') {
                kvArray.push({
                  key: item.source_id,
                  value: item.name
                });
              }
            });
            vm.fundingSources = _.chain(kvArray).sortBy('key').sortBy('value').value();
          }
          function updateLink(property) {
            if (!property.dwolla_source_link) {
              return false;
            }
            if (property.dwolla_source_link.id && !property.dwolla_source_link.source_id) {
              // Unlink
              HttpService.delete('/dwolla/funding_sources/unlink_property/' + property.id, function () {
                Notify.success(Lang.get('notify_actions.successfully'));
                property.dwolla_source_link = {};
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            } else if (property.dwolla_source_link.source_id) {
              // Link
              var postData = {
                  source_uid: property.dwolla_source_link.source_id,
                  type: 'property',
                  id: property.id
                };
              HttpService.put('/dwolla/funding_sources/linked', postData, function (resp) {
                Notify.success(Lang.get('notify_actions.successfully'));
                property.dwolla_source_link = resp;
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }
          }
        }
      ]
    };
  /**
	 * @namespace dwollaTabs
	 * @desc Dwolla tabs
	 * @example <dwolla-tabs></dwolla-tabs>
	 * @memberOf dwollaLayout
	 */
  var dwollaTabs = { templateUrl: '/app/modules/shared/pages/dwolla/components/tabs.html' };
  /**
	 * @namespace dwollaWithdraw
	 * @desc Dwolla withdraw
	 * @example <dwolla-withdraw></dwolla-withdraw>
	 * @param {Array} fundingSources
	 * @memberOf dwollaBalance
	 */
  var dwollaWithdraw = {
      bindings: { fundingSources: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/dialog/withdraw.html',
      controller: [
        'HttpService',
        'DwollaService',
        'Notify',
        'ngDialog',
        function (HttpService, DwollaService, Notify, ngDialog) {
          'ngInject';
          var vm = this;
          vm.submitWithdraw = submitWithdraw;
          /**
			 *
			 */
          function submitWithdraw() {
            Notify.confirm(function () {
              HttpService.post('/dwolla/transfers/withdraw', vm.transfer, function () {
                DwollaService.updateBalance();
                DwollaService.updateTransfers();
                Notify.success(Lang.get('dwolla.transfer_initiated'));
                ngDialog.closeAll();
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }, Lang.get('dwolla.notify_actions.withdraw'));
          }
        }
      ]
    };
  /**
	 * @namespace dwollaAddMoney
	 * @desc Dwolla add money
	 * @example <dwolla-add-money></dwolla-add-money>
	 * @param {Array} fundingSources
	 * @memberOf dwollaBalance
	 */
  var dwollaAddMoney = {
      bindings: { fundingSources: '<' },
      templateUrl: '/app/modules/shared/pages/dwolla/components/dialog/add-money.html',
      controller: [
        'HttpService',
        'DwollaService',
        'Notify',
        'ngDialog',
        function (HttpService, DwollaService, Notify, ngDialog) {
          'ngInject';
          var vm = this;
          vm.submitAddMoney = submitAddMoney;
          /**
			 *
			 */
          function submitAddMoney() {
            Notify.confirm(function () {
              HttpService.post('/dwolla/transfers/add_money', vm.transfer, function () {
                DwollaService.updateBalance();
                DwollaService.updateTransfers();
                Notify.success(Lang.get('dwolla.transfer_initiated'));
                ngDialog.closeAll();
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }, Lang.get('dwolla.notify_actions.add_money'));
          }
        }
      ]
    };
  /**
	 * @namespace dwollaAutomaticWithdraw
	 * @desc Dwolla automatic withdraw
	 * @example <dwolla-automatic-withdraw></dwolla-automatic-withdraw>
	 * @param {Object} customer Customer information
	 * @param {Object} sources
	 * @param {Object} recurring
	 * @memberOf dwollaSettingsComponent
	 */
  var dwollaAutomaticWithdraw = {
      bindings: {
        customer: '<',
        sources: '<',
        recurring: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/components/automatic-withdraw.html',
      controller: [
        '$rootScope',
        '$filter',
        'HttpService',
        'DwollaService',
        'Notify',
        function ($rootScope, $filter, HttpService, DwollaService, Notify) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            _prepareSources(vm.sources);
          };
          // Recurring
          vm.recurring = {
            list: vm.recurring || [],
            minDate: $filter('dateFormat')(moment()),
            showForm: false,
            isLoaded: false,
            resetModel: function () {
              this.model = {
                repeat_start: undefined,
                frequency: undefined,
                destination: undefined
              };
            },
            allowRecurring: allowRecurring,
            autoWithdraw: autoWithdraw,
            isAutoWithdraw: isAutoWithdraw,
            submitRecurring: submitRecurring,
            removeRecurring: removeRecurring
          };
          /**
			 *
			 * @returns {boolean}
			 */
          function allowRecurring() {
            if (!_fundingSourcesExist()) {
              return false;
            }
            return [
              'admin',
              'owner'
            ].indexOf($rootScope.auth.role) !== -1;
          }
          /**
			 *
			 */
          function autoWithdraw() {
            var self = this;
            return _.find(self.list, { type: 'withdraw' });
          }
          /**
			 *
			 * @returns {*}
			 */
          function isAutoWithdraw() {
            var self = this;
            return self.list.length && _.find(self.list, { type: 'withdraw' });
          }
          /**
			 *
			 */
          function submitRecurring() {
            var self = this;
            HttpService.post('/dwolla/recurring', self.model, function (resp) {
              Notify.success(Lang.get('notify_actions.successfully'));
              self.list.push(resp);
              self.resetModel();
            }, function (errors) {
              Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
            });
          }
          /**
			 *
			 * @param recurring
			 */
          function removeRecurring(recurring) {
            var self = this;
            Notify.confirm(function () {
              HttpService.delete('/dwolla/recurring/' + recurring.id, function () {
                Notify.success(Lang.get('notify_actions.successfully'));
                self.list = _.reject(self.list, { id: recurring.id });
                self.resetModel();
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            }, Lang.get('dwolla.notify_actions.cancel_recurring'));
          }
          /**
			 *
			 * @returns {Array|*|number}
			 * @private
			 */
          function _fundingSourcesExist() {
            return vm.fundingSources && vm.fundingSources.length;
          }
          /**
			 *	Transform sources for auto withdrawal
			 * @private
			 */
          function _prepareSources(list) {
            var kv_array = [];
            _.forEach(list, function (item) {
              if (item.type !== 'balance' && item.status === 'verified') {
                kv_array.push({
                  key: item.source_id,
                  value: item.name
                });
              }
            });
            vm.fundingSources = kv_array;
            vm.recurring.isLoaded = true;
          }
        }
      ]
    };
  angular.module('app.dwolla.components', []).component('dwollaCustomerInfo', dwollaCustomerInfo).component('dwollaBalance', dwollaBalance).component('dwollaSources', dwollaSources).component('dwollaSourcesBank', dwollaSourcesBank).component('dwollaSourcesIav', dwollaSourcesIav).component('dwollaSourcesMicroDeposits', dwollaSourcesMicroDeposits).component('dwollaPlaid', dwollaPlaid).component('dwollaTransfers', dwollaTransfers).component('dwollaFilterDetails', dwollaFilterDetails).component('dwollaCustomerNew', dwollaCustomerNew).component('dwollaCustomerNewInfo', dwollaCustomerNewInfo).component('dwollaCustomerRetry', dwollaCustomerRetry).component('dwollaCustomerDoc', dwollaCustomerDoc).component('dwollaLinkProperties', dwollaLinkProperties).component('dwollaTabs', dwollaTabs).component('dwollaWithdraw', dwollaWithdraw).component('dwollaAddMoney', dwollaAddMoney).component('dwollaAutomaticWithdraw', dwollaAutomaticWithdraw);
  ;
}());