(function () {
  'use strict';
  PresentationService.$inject = [
    '$rootScope',
    'HttpService',
    'ngDialog',
    'ng_util'
  ];
  angular.module('app.services.presentation', []).service('PresentationService', PresentationService);
  ;
  function PresentationService($rootScope, HttpService, ngDialog, ng_util) {
    // noinspection UnnecessaryLocalVariableJS
    var service = { openWidget: openWidget };
    return service;
    function openWidget() {
      HttpService.getWParams('/presentation', { ignoreLoadingBar: true }, function (resp) {
        if (!resp.isShowPresentation) {
          return;
        }
        ngDialog.open({
          templateUrl: '/app/modules/shared/pages/presentation/widget.html',
          scope: true,
          showClose: false,
          closeByEscape: false,
          closeByDocument: false,
          className: 'ngdialog-theme-default m-modal--presentation',
          data: resp.list,
          controllerAs: '$ctrl',
          controller: function () {
            var vm = this;
            vm.swiper = {};
            vm.start = 1;
            _runCheckIsEnd();
            vm.next = next;
            vm.read = read;
            vm.skip = skip;
            vm.onReadySwiper = function (swiper) {
              swiper.on('slideChangeStart', function () {
                _isEnd(swiper.isEnd);
              });
            };
            function next() {
              vm.swiper.slideNext();
            }
            function read() {
              HttpService.post('/presentation/read', {}, function () {
                _closeAll();
              }, function () {
                _closeAll();
              });
            }
            function skip() {
              HttpService.post('/presentation/skip', {}, function () {
                _closeAll();
              }, function () {
                _closeAll();
              });
            }
            function _closeAll() {
              ngDialog.closeAll();
            }
            function _runCheckIsEnd() {
              var sliders = _.get(resp, 'list.sliders');
              vm.end = sliders.length === vm.start;
            }
            function _isEnd(isEnd) {
              ng_util.safeApply($rootScope, function () {
                vm.end = isEnd;
              });
            }
          }
        });
      });
    }
  }
}());