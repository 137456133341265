(function () {
  'use strict';
  ScreeningsService.$inject = [
    '$rootScope',
    '$stateParams',
    'APP_CONFIG'
  ];
  angular.module('app.services.screenings', []).service('ScreeningsService', ScreeningsService);
  ;
  function ScreeningsService($rootScope, $stateParams, APP_CONFIG) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        isNeedPay: isNeedPay,
        isPaid: isPaid,
        getPrice: getPrice,
        getDataForSubmitForm: getDataForSubmitForm,
        getLogo: getLogo,
        getServiceByPackage: getServiceByPackage
      };
    return service;
    function getLogo(packageName) {
      if (packageName === 'checkr_basic') {
        return 'images/screening/checkr-logo-blue.svg';
      }
      return 'images/screening/rentprep_logo.svg';
    }
    function getServiceByPackage(packageName) {
      return APP_CONFIG.screenings.packages[packageName].service;
    }
    function getPrice(screening) {
      return APP_CONFIG.screenings.packages[screening.package].price;
    }
    /**
		 * @desc Сheck for paid report
		 * @param screening
		 * @returns {boolean|*}
		 * @memberOf ScreeningsService
		 */
    function isPaid(screening) {
      var statuses = APP_CONFIG.constants.screening_status;
      return screening.status !== statuses.requested_tenant && screening.charge_id;
    }
    /**
		 *
		 * @param screening
		 * @returns {*|boolean}
		 */
    function isNeedPay(screening) {
      if (!APP_CONFIG.screenings.packages[screening.package].needs_payment) {
        return false;
      }
      return !screening.id || screening.status === APP_CONFIG.constants.screening_status.requested_tenant;
    }
    /**
		 *
		 * @param screening
		 * @param application
		 * @returns {{}}
		 */
    function getDataForSubmitForm(screening, application) {
      if (screening.package === 'rentprep_smartmove') {
        return _getDataForSmartmove(screening, application);
      }
      return _getDataForRentprepAndCheckr(screening, application);
    }
    function _getDataForRentprepAndCheckr(screening, application) {
      var user = $rootScope.auth, previous_landlord, current_landlord, employer, data = {};
      if (screening) {
        data.service = screening.service;
        data.package = screening.package;
      } else {
        data.service = $stateParams.service;
        data.package = $stateParams.package;
      }
      data.application_id = application.id;
      data.customer = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        street_address: user.address1,
        city: user.city,
        state: user.state,
        zip: user.zip
      };
      data.candidate = {
        first_name: application.firstName,
        last_name: application.lastName,
        middle_name: application.middleName,
        dob: application.birthday,
        phone: application.phone,
        email: application.email,
        ssn: application.ssn
      };
      data.current_landlord = { street_address: '' };
      data.previous_landlord = { street_address: '' };
      data.employer = { street_address: '' };
      previous_landlord = _.find(application.residences, function (residence) {
        return !residence.is_current;
      });
      if (previous_landlord) {
        data.previous_landlord = {
          street_address: previous_landlord.address1,
          city: previous_landlord.city,
          zip: previous_landlord.zip,
          state: previous_landlord.state,
          first_name: previous_landlord.manager_first_name,
          last_name: previous_landlord.manager_last_name,
          phone: previous_landlord.phone,
          email: previous_landlord.email
        };
      }
      current_landlord = _.find(application.residences, function (residence) {
        return residence.is_current;
      });
      if (current_landlord) {
        data.candidate.street_address = current_landlord.address1;
        data.candidate.city = current_landlord.city;
        data.candidate.state = current_landlord.state;
        data.candidate.zip = current_landlord.zip;
        data.current_landlord = {
          first_name: current_landlord.manager_first_name,
          last_name: current_landlord.manager_last_name,
          phone: current_landlord.phone,
          email: current_landlord.email,
          mode: current_landlord.mode,
          city: current_landlord.city,
          state: current_landlord.state,
          zip: current_landlord.zip,
          street_address: current_landlord.address1
        };
      }
      employer = _.find(application.employments, function (employment) {
        return employment.is_current;
      });
      if (employer) {
        data.employer = {
          company_name: employer.name,
          street_address: employer.address1,
          first_name: employer.supervisor_first_name,
          last_name: employer.supervisor_last_name,
          phone: employer.supervisor_phone
        };
      }
      return data;
    }
    function _getDataForSmartmove(screening, application) {
      var data = {};
      data.application_id = application.id;
      if (screening) {
        data.service = screening.service;
        data.package = screening.package;
      } else {
        data.service = $stateParams.service;
        data.package = $stateParams.package;
      }
      var listing = application.property_unit_listing;
      data.application = {
        email: application.email,
        rent: listing.price,
        deposit: listing.deposit
      };
      data.property = {
        unit_number: listing.unit_number,
        name: listing.property.name,
        street_address: listing.address1,
        city: listing.city,
        state: listing.state,
        zip: listing.zip
      };
      if (listing.property.type === 1) {
        data.property.unit_number = 'Single family';
      }
      var landlord = application.landlord;
      data.landlord = {
        first_name: landlord.firstName,
        last_name: landlord.lastName,
        email: landlord.email,
        phone: landlord.phone,
        street_address: landlord.address1,
        city: landlord.city,
        state: landlord.state,
        zip: landlord.zip,
        mode: landlord.mode
      };
      data.credit_card = {
        first_name: landlord.firstName,
        last_name: landlord.lastName
      };
      return data;
    }
  }
}());