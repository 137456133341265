(function () {
  'use strict';
  var settingsPaymentsMain = {
      bindings: { settings: '<' },
      templateUrl: '/app/modules/shared/pages/payments/main.html',
      controller: [
        '$rootScope',
        '$mdMedia',
        '$state',
        'ngDialog',
        'BreadCrumbsService',
        'HttpService',
        'APP_CONFIG',
        'DwollaPermissionsService',
        function ($rootScope, $mdMedia, $state, ngDialog, BreadCrumbsService, HttpService, APP_CONFIG, DwollaPermissionsService) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.payments_view.label'));
          vm.allowed_currencies = APP_CONFIG.payments.allowed_currencies;
          vm.$mdMedia = $mdMedia;
          vm.hasAccessToDwolla = DwollaPermissionsService.hasAccess;
          vm.requireSubscription = DwollaPermissionsService.requireSubscription;
          vm.requireVerification = DwollaPermissionsService.requireVerification;
        }
      ]
    };
  var settingsPaymentsEdit = {
      bindings: { item: '<' },
      templateUrl: '/app/modules/shared/pages/payments/edit.html',
      controller: [
        '$rootScope',
        '$state',
        'BreadCrumbsService',
        'Notify',
        'HttpService',
        'AuthDataService',
        'string_util',
        'APP_CONFIG',
        function ($rootScope, $state, BreadCrumbsService, Notify, HttpService, AuthDataService, string_util, APP_CONFIG) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.payments_view.label'), 'settings.payments_view');
          BreadCrumbsService.addCrumb(Lang.get('settings.payments.' + $state.params.gateway + '.label'));
          vm.gatewayName = $state.params.gateway;
          vm.ui = {
            showCancelButton: function () {
              return vm.item.enabled;
            },
            showUpdateButton: function () {
              return vm.item.id && vm.gatewayName !== 'paypal';
            },
            showActivateButton: function () {
              return vm.item.id && !vm.item.enabled;
            },
            showSaveButton: function () {
              return !vm.item.id && vm.gatewayName !== 'paypal';
            }
          };
          if (vm.item && _.isNull(vm.item.config)) {
            vm.item.config = { fee_payer: false };
          }
          vm.feePayerOptions = [
            [{
                key: false,
                value: Lang.get('settings.stripe_payer')
              }],
            [{
                key: true,
                value: Lang.get('settings.stripe_receiver')
              }]
          ];
          vm.disableFeePayerPaypal = [
            false,
            false
          ];
          // Save or Update
          vm.action = {
            update: function (status) {
              HttpService.put('/settings/payments/' + $state.params.gateway, _.extend(_.clone(vm.item), {
                enabled: status,
                gateway: $state.params.gateway
              }), function () {
                $state.go('settings.payments_view');
                Notify.success(Lang.get('notify_actions.successfully'));
              });
            },
            delete: function () {
              Notify.confirm(function () {
                HttpService.delete('/settings/payments/' + $state.params.gateway, function () {
                  $state.go('settings.payments_view');
                  Notify.success(Lang.get('notify_actions.successfully'));
                });
              }, Lang.get('settings.confirm_delete_settings_dialog'));
            }
          };
          var payPalToken = string_util.randString(32);
          vm.paypal = {
            link: function () {
              return _.get(APP_CONFIG, 'services.paypal.setup_url') + '?token=' + payPalToken;
            },
            update: function () {
              HttpService.post('/settings/payments/paypal_integration_token', { token: payPalToken });
            }
          };
        }
      ]
    };
  angular.module('app.payments.components', []).component('settingsPaymentsMain', settingsPaymentsMain).component('settingsPaymentsEdit', settingsPaymentsEdit);
  ;
}());