(function () {
  'use strict';
  authInterceptor.$inject = [
    '$rootScope',
    '$q',
    '$timeout',
    '$location',
    'APP_CONFIG',
    'SessionExpiresService'
  ];
  myHttpInterceptor.$inject = ['$q'];
  cacheHttpInterceptor.$inject = [
    '$cacheFactory',
    '$timeout',
    '$httpParamSerializer'
  ];
  subscriptionInterceptor.$inject = [
    '$q',
    '$injector'
  ];
  suspendedAccountInterceptor.$inject = [
    '$q',
    '$injector'
  ];
  maintenanceInterceptor.$inject = [
    '$timeout',
    '$q'
  ];
  supportPretendInterceptor.$inject = [
    '$q',
    '$injector'
  ];
  throttlingInterceptor.$inject = [
    '$injector',
    '$q'
  ];
  duplicateRequestInterceptor.$inject = ['$injector'];
  welcomeInterceptor.$inject = [
    '$q',
    '$injector'
  ];
  AuthDataService.$inject = [
    '$rootScope',
    '$http',
    '$location',
    '$state',
    '$timeout',
    'cfpLoadingBar',
    'ContentService',
    'SubscriptionService',
    'APP_CONFIG',
    'AuthTransformer'
  ];
  loginAsUser.$inject = [
    'HttpService',
    'Notify',
    'AuthDataService'
  ];
  beforeUnload.$inject = [
    '$rootScope',
    '$window'
  ];
  angular.module('app.factories', []).factory('authInterceptor', authInterceptor).factory('myHttpInterceptor', myHttpInterceptor).factory('cacheHttpInterceptor', cacheHttpInterceptor).factory('subscriptionInterceptor', subscriptionInterceptor).factory('suspendedAccountInterceptor', suspendedAccountInterceptor).factory('maintenanceInterceptor', maintenanceInterceptor).factory('supportPretendInterceptor', supportPretendInterceptor).factory('throttlingInterceptor', throttlingInterceptor).factory('duplicateRequestInterceptor', duplicateRequestInterceptor).factory('welcomeInterceptor', welcomeInterceptor).factory('AuthTransformer', AuthTransformer).factory('AuthDataService', AuthDataService).factory('loginAsUser', loginAsUser).factory('beforeUnload', beforeUnload);
  ;
  /* Fn
	 ============================================================================================================= */
  function authInterceptor($rootScope, $q, $timeout, $location, APP_CONFIG, SessionExpiresService) {
    return {
      response: function (response) {
        if ($rootScope.auth.id && !$rootScope.auth_data.rememberMe && !window.cordova && APP_CONFIG.app_env !== 'selenium') {
          SessionExpiresService.setTimer();
        }
        if (response) {
          if (_.isObject(response.data)) {
            // Redirect to page, if user was not authorized.
            if (response.data.authorization === false) {
              $rootScope.auth = {};
              $timeout(function () {
                $location.path('/logout');
              });
            }
          }
        }
        return response;
      },
      responseError: function (response) {
        if (response.status === 401) {
          // remove any stale tokens
          $rootScope.auth = {};
          $timeout(function () {
            $location.path('/logout');
          });
          return $q.reject(response);
        } else {
          return $q.reject(response);
        }
      }
    };
  }
  function subscriptionInterceptor($q, $injector) {
    return {
      response: function (response) {
        var $http = $injector.get('$http');
        if (_.isObject(response.data) && !$http.pendingRequests.length) {
          if (response.data.subscription_redirect) {
            // Show message
            if (response.data.message) {
              $injector.get('Notify').error(response.data.message);
            }
            // Redirect to billing page
            $injector.get('$state').transitionTo('subscription.packages');
            //fix issue with wrong/old auth_data
            // window.location.href = response.data.subscription_redirect;
            return $q.reject(response);
          }
        }
        return response;
      }
    };
  }
  function welcomeInterceptor($q, $injector) {
    return {
      response: function (response) {
        var $http = $injector.get('$http');
        if (_.isObject(response.data) && !$http.pendingRequests.length) {
          if (response.data.welcome_redirect) {
            // Redirect to welcome page
            $injector.get('$state').transitionTo('welcome.add');
            return $q.reject(response);
          }
        }
        return response;
      }
    };
  }
  function suspendedAccountInterceptor($q, $injector) {
    return {
      response: function (response) {
        var $http = $injector.get('$http');
        if (_.isObject(response.data) && !$http.pendingRequests.length) {
          if (response.data.suspended_account) {
            // Redirect to welcome page
            $injector.get('$state').transitionTo('common.suspended_account');
            return $q.reject(response);
          }
        }
        return response;
      }
    };
  }
  function supportPretendInterceptor($q, $injector) {
    return {
      responseError: function (response) {
        if (response.status === 405 && !_.isUndefined(response.data.support_interceptor) && response.data.message) {
          $injector.get('Notify').error(response.data.message);
        }
        return $q.reject(response);
      }
    };
  }
  function maintenanceInterceptor($timeout, $q) {
    return {
      responseError: function (response) {
        if (response.status === 503) {
          $timeout(function () {
            window.location.reload();
          });
        }
        return $q.reject(response);
      }
    };
  }
  function throttlingInterceptor($injector, $q) {
    return {
      responseError: function (response) {
        if (response.status === 429) {
          $injector.get('Notify').error(Lang.get('notify_actions.too_many_requests'));
        }
        return $q.reject(response);
      }
    };
  }
  function duplicateRequestInterceptor($injector) {
    // Manually injecting dependencies to avoid circular dependency problem
    return {
      'request': function request(config) {
        // if html template - no need check pending requests
        if (config && config.url && config.url.indexOf('.html') !== -1) {
          return config;
        }
        var $http = $injector.get('$http'), $q = $injector.get('$q'), copiedConfig = angular.copy(config);
        delete copiedConfig.headers;
        function configsAreEqual(pendingRequestConfig) {
          var copiedPendingRequestConfig = angular.copy(pendingRequestConfig);
          delete copiedPendingRequestConfig.headers;
          return angular.equals(copiedConfig, copiedPendingRequestConfig);
        }
        if ($http.pendingRequests.some(configsAreEqual)) {
          //debugger;
          return $q.reject({ config: config });
        }
        return config;
      }
    };
  }
  function myHttpInterceptor($q) {
    function success(response) {
      angular.element('#loading').hide();
      return response;
    }
    function error(response) {
      if (_.includes([
          401,
          403
        ], response.status)) {
        // Catch error and redirect
        window.location = '/login';
      }
      return $q.reject(response);  // otherwise
    }
    return function (promise) {
      return promise.then(success, error);
    };
  }
  function cacheHttpInterceptor($cacheFactory, $timeout, $httpParamSerializer) {
    var ttlMap = {};
    return {
      request: function (config) {
        if (config && config.ttl) {
          var ttl = config.ttl;
          var url = config.url + (config.params ? '?' + $httpParamSerializer(config.params) : '');
          config.cache = true;
          delete config.ttl;
          if (new Date().getTime() - (ttlMap[url] || 0) > ttl) {
            $cacheFactory.get('$http').remove(url);
            ttlMap[url] = new Date().getTime();  // config.cache = false;
          }
        }
        return config;
      }
    };
  }
  function AuthTransformer() {
    /**
		 * Constructor
		 */
    function Auth(user) {
      var self = this;
      angular.forEach(user, function (value, key) {
        self[key] = value;
      });
    }
    /**
		 * Constructor for helper methods
		 */
    function Methods() {
      // Main
      this._isAdministration = function () {
        return this.role === 'super' || this.role === 'marketing' || this.role === 'support';
      };
      this._isSuper = function () {
        return this.role === 'super';
      };
      this._isDevOps = function () {
        return this.role === 'devops';
      };
      this._isDeveloper = function () {
        return this.role === 'developer';
      };
      this._isLandlord = function () {
        return this.role === 'admin';
      };
      this._isTenant = function () {
        return this.role === 'tenant';
      };
      this._isPro = function () {
        return this.role === 'professional';
      };
      this._isOwner = function () {
        return this.role === 'owner';
      };
      this._isSupport = function () {
        return this.role === 'support';
      };
      this._isLandlordAdmin = function () {
        return this.subAccount && this.subAccount.role === 'landlord_admin';
      };
      this._isPM = function () {
        return this.mode === 2;
      };
      this._role = function () {
        if (this.role === 'admin') {
          return 'landlord';
        }
        if (this.role === 'landlord_admin') {
          return 'landlord';
        }
        return this.role;
      };
      // Settings
      this._isWebsiteActive = function () {
        return this.settings && this.settings.website && this.settings.website.is_inited;
      };
      // Payments
      this._isPaymentsEnabled = function (gateway) {
        if (!gateway) {
          return this.payments && this.payments.is_enabled;
        } else {
          return this.payments && this.payments.gateway[gateway].enabled;
        }
      };
      this._isPaymentsAvailable = function (iso_code) {
        return this._isPaymentsEnabled() && this.payments.currencies && this.payments.currencies.indexOf(iso_code) !== -1;
      };
      this._hasAccessToSection = function (section, action) {
        if (!this._isLandlordAdmin()) {
          return true;
        }
        if (section === 'general') {
          return true;
        }
        var permissions = this.subAccount.permissions;
        if (!permissions && !permissions.length) {
          return false;
        }
        var permission = _.find(permissions, { section: section });
        if (!permission) {
          return false;
        }
        return permission.actions.indexOf(action) !== -1;
      };
      this._is2FAEnabled = function () {
        return this.google2fa_enabled;
      };
      this._isFromUsa = function () {
        return this.country === 'US';
      };
      this._isPremiumPlan = function () {
        return this.plan_name === 'plan_2' || this.plan_name === 'plan_2_annual';
      };
    }
    /**
		 * Public method, assigned to prototype
		 */
    Auth.prototype = new Methods();
    /**
		 * Static method, assigned to class
		 */
    Auth.build = function (user) {
      return new Auth(user);
    };
    /**
		 * Return the constructor function
		 */
    return Auth;
  }
  function AuthDataService($rootScope, $http, $location, $state, $timeout, cfpLoadingBar, ContentService, SubscriptionService, APP_CONFIG, AuthTransformer) {
    // Factory current user object
    var currentUser = {};
    // Default redirect
    var redirect_after_auth = {
        state: 'home.main',
        params: {}
      };
    return {
      isLoggedIn: function () {
        return currentUser && currentUser.id;
      },
      setAuth: function (response) {
        // Set user with subscription information
        SubscriptionService.setUser(response.user);
        SubscriptionService.setPackages(response.auth_data.packages);
        $rootScope.auth = AuthTransformer.build(response.user);
        $rootScope.auth_data = {
          counters: response.auth_data ? response.auth_data.counters : {},
          subscription: response.auth_data ? response.auth_data.subscription : {},
          rememberMe: response.auth_data ? response.auth_data.rememberMe : false,
          welcome_redirect: response.auth_data ? response.auth_data.welcome_redirect : false
        };
        // Set Translations
        if (response.auth_data && response.auth_data.trans) {
          Lang.setMessages(_.extend(_.clone(Lang.messages), response.auth_data.trans));
        }
        // Set factory currentUser
        currentUser = _.extend(currentUser, response.user || {});
      },
      updateAuth: function (user) {
        SubscriptionService.setUser(user);
        user = this.transformForSubAdmin(user);
        $rootScope.auth = AuthTransformer.build(user);
        // Set factory currentUser
        currentUser = _.extend(user || {});
      },
      updateAuthData: function (user_data) {
        $rootScope.auth_data = user_data;
      },
      setRedirect: function (params) {
        // Redirect if application apply
        if (params.application_apply) {
          redirect_after_auth = {
            state: 'applications.apply',
            params: { rental_id: parseInt(params.application_apply) }
          };
        }
      },
      redirectAfterAuth: function () {
        if ($location.path() === '/login' || $location.path().indexOf('/confirm') !== -1 || $location.path() === '/signup' || $location.path() === '/forgot-password/changePassword' || $location.path().indexOf('/two_factor') !== -1) {
          // If need redirect to welcome page
          if ($rootScope.auth_data.welcome_redirect) {
            return $state.go('welcome.add');
          }
          // Check if account suspended
          if ($rootScope.auth.is_suspended) {
            return $state.go('common.suspended_account');
          }
          // Check if need to redirect
          if (!redirect_after_auth || !redirect_after_auth.state) {
            return false;
          }
          // Change state
          // $state.transitionTo(redirect_after_auth.state, _.extend({}, redirect_after_auth.params), {
          // 	reload: true, inherit: false
          // });
          $state.go(redirect_after_auth.state, _.extend({}, redirect_after_auth.params));
        } else if ($rootScope.auth_data.welcome_redirect) {
          $state.go('welcome.add');
        }
        return true;
      },
      clearAuth: function () {
        $rootScope.auth = AuthTransformer.build({});
        $rootScope.auth_data = {};
        // Clear factory currentUser
        currentUser = {};
      },
      verify: function (callback) {
        var self = this, headers = {};
        if (window.cordova && _.isObject(window.device)) {
          headers['Device'] = [
            window.device.platform,
            window.device.uuid
          ].join(' ');
        }
        $http.get(APP_CONFIG.external_api + '/auth/verify', {
          withCredentials: true,
          headers: headers
        }).then(onSuccess, onError);
        function onSuccess(response) {
          if (response.data && response.data.user) {
            self.loadModule(response.data);
            if (callback) {
              callback(null, response.data);
            }
          } else {
            self.clearAuth();
            if ([
                '/login',
                '/signup',
                '/confirm',
                '/forgot-password',
                '/two_factor',
                '/forgot-password/confirm',
                '/forgot-password/changePassword',
                '/forgot-password/success'
              ].indexOf($location.path()) === -1) {
              $location.path('/login');
            }
            if (callback) {
              callback(response.data, null);
            }
          }
        }
        function onError(err) {
          // need timeout if page close before load success
          $timeout(function () {
            self.clearAuth();
          }, 0);
          // Define method fo redirect
          $location.path('/login');
          if (callback) {
            callback(err.data, null);
          }
        }
      },
      logout: function (HttpService) {
        var self = this;
        HttpService.get('/auth/logout', function (resp) {
          if (!resp.user || !resp.token) {
            self.clearAuth();
            // Loader start
            cfpLoadingBar.start(1);
            // Define method fo redirect
            if (!window.cordova) {
              window.location.href = '/login';  // '/login?email=' + currentUserEmail;
            } else {
              window.location.href = 'index.html';
            }
          } else {
            // Admin
            self.setAuth(resp);
            // Loader start
            cfpLoadingBar.start(1);
            // Define method fo redirect
            if (!window.cordova) {
              window.location.href = '/users/landlords';  // '/login?email=' + currentUserEmail;
            } else {
              window.location.href = 'index.html';
            }
          }
        }, function () {
          self.clearAuth();
          if (!window.cordova) {
            window.location.href = '/login';  // '/login?email=' + currentUserEmail;
          } else {
            window.location.href = 'index.html';
          }
        });
      },
      removeAccount: function () {
        this.clearAuth();
        $location.path('/logout');
      },
      loadModule: function (resp) {
        if (!resp || !resp.user) {
          this.clearAuth();
          return false;
        }
        resp.user = this.transformForSubAdmin(resp.user);
        this.setAuth(resp);
        this.setRedirect(_.clone($location.search()));
        ContentService.loadContent(resp.user.role);
      },
      redirectToSubscriptionPay: function (plan) {
        window.location.href = '/subscription/pay/' + plan;
      },
      transformForSubAdmin: function (user) {
        if (user.master) {
          var subAccount = user;
          user = _.clone(user.master);
          user.subAccount = _.clone(subAccount);
        } else {
          user.subAccount = _.clone(user);
        }
        return user;
      }
    };
  }
  /**
	 * Login as User
	 * @param HttpService
	 * @param Notify
	 * @param AuthDataService
	 * @returns {{login: login}}
	 */
  function loginAsUser(HttpService, Notify, AuthDataService) {
    return {
      login: function (user_id) {
        // Login on User Domains
        HttpService.get('/admin/users/loginAs/' + user_id, function (resp) {
          AuthDataService.setAuth(resp);
          // If user Logged, then login in other domains.
          window.location.href = '/';
        }, function () {
          Notify.error(Lang.get('notify_actions.ooops'));
        });
      }
    };
  }
  /**
	 * before Unload
	 * @param $rootScope
	 * @param $window
	 * @returns {{}}
	 */
  function beforeUnload($rootScope, $window) {
    // Events are broadcast outside the Scope Lifecycle
    $window.onbeforeunload = function () {
      var confirmation = {}, event = $rootScope.$broadcast('onBeforeUnload', confirmation);
      if (event.defaultPrevented) {
        return confirmation.message;
      }
    };
    $window.onunload = function () {
      $rootScope.$broadcast('onUnload');
    };
    return {};
  }
}());