(function () {
  'use strict';
  var widgetCalendar = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/widgets/calendar.html',
      controller: [
        '$state',
        '$element',
        'HttpService',
        'CalendarService',
        function ($state, $element, HttpService, CalendarService) {
          'ngInject';
          var vm = this, fp;
          vm.$onInit = function () {
            vm.isLoaded = false;
            getStatistics();
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          /**
			 * get Statistics
			 */
          function getStatistics() {
            HttpService.getWParams('/statistics/events', { ignoreLoadingBar: true }, function (resp) {
              vm.isLoaded = true;
              vm.currentMonth = moment().format('MMMM');
              vm.currentYear = moment().format('YYYY');
              vm.dateOpts = {
                dateFormat: 'm/d/Y',
                animate: false,
                inline: true,
                onDayCreate: function (dObj, dStr, fp, dayElem) {
                  // Utilize dayElem.dateObj, which is the corresponding Date
                  if (resp.length) {
                    var events = CalendarService.countDuplicateValue(resp), event = _.find(events, { 'start': dayElem.getAttribute('aria-label') });
                    dayElem.classList.add(moment(dayElem.getAttribute('aria-label')).format('dddd').toLowerCase());
                    if (!_.isUndefined(event)) {
                      dayElem.classList.add('active');
                      dayElem.innerHTML += '<span class=\'badges\'>' + event.count + '</span>';
                    }
                  }
                },
                onChange: function (selectedDates, dateStr) {
                  $state.go('calendar.main', { date: moment(dateStr).format('YYYY-MM-DD') });
                }
              };
              fp = new Flatpickr($element.find('#calendar')[0], vm.dateOpts);
            });
          }
          /**
			 * destroy flatPicker
			 */
          vm.$onDestroy = function () {
            if (!_.isUndefined(fp)) {
              fp.destroy();
            }
          };
        }
      ]
    };
  var widgetFeed = {
      bindings: { items: '<' },
      templateUrl: '/app/modules/shared/components/widgets/feed.html',
      controller: [
        '$rootScope',
        '$filter',
        'ng_util',
        'FeedHelper',
        'FeedService',
        'EmitService',
        function ($rootScope, $filter, ng_util, FeedHelper, FeedService, EmitService) {
          'ngInject';
          var vm = this, emitService = EmitService;
          vm.pagination = {};
          vm.userRole = $rootScope.auth._role();
          vm.pagination.list = FeedHelper.prepareDataWithShowDateLabels(vm.items);
          ng_util.definePropertyObj(emitService, 'reloadListFeed', function (value) {
            if (!_.isUndefined(value)) {
              loadFeeds();
            }
          });
          /**
			 * load Feeds
			 */
          function loadFeeds() {
            FeedService.getFeeds().then(function (resp) {
              vm.pagination.list = FeedHelper.prepareDataWithShowDateLabels(resp.list);
            });
          }
        }
      ]
    };
  var widgetFeedContent = {
      bindings: {
        feed: '<',
        userRole: '<',
        setAsViewed: '&',
        setAllAsViewed: '&'
      },
      templateUrl: '/app/modules/shared/components/widgets/feed-content.html',
      controller: [
        '$interpolate',
        '$filter',
        'FeedService',
        function ($interpolate, $filter, FeedService) {
          'ngInject';
          var vm = this;
          vm.feedIsClickAble = feedIsClickAble;
          vm.setAsViewed = setAsViewed;
          vm.getTextObject = getTextObject;
          vm.getDate = getDate;
          vm.icon = getIcon();
          vm.currentDate = moment().format('D');
          function getTextObject(type) {
            if (vm.feed.type !== 200 && vm.feed.type !== 201) {
              return {
                title: Lang.get('feed.' + vm.userRole + '.messages_title.' + vm.feed.type, vm.feed.details),
                message: Lang.get('feed.' + vm.userRole + '.messages.' + vm.feed.type, vm.feed.details)
              }[type];
            } else {
              return {
                title: vm.feed.name,
                message: vm.feed.details.text
              }[type];
            }
          }
          function getDate() {
            var time = $filter('convertDateByTimezone')(vm.feed.created_at, 'h');
            if (!vm.feed.isToday && !vm.feed.isYesterday) {
              return $filter('dateFormat')(vm.feed.created_at) + ' ' + time;
            } else {
              return Lang.get(vm.feed.isToday ? 'general.today' : 'general.yesterday') + ' ' + time;
            }
          }
          function getIcon() {
            switch (true) {
            case vm.feed.type >= 1 && vm.feed.type <= 10:
              return {
                type: 'connection',
                svg: '#icon-line-users-add'
              };
            case vm.feed.type >= 11 && vm.feed.type <= 22:
              return {
                type: 'maintenance',
                svg: '#icon-line-document-pen'
              };
            case vm.feed.type >= 40 && vm.feed.type <= 46:
              return {
                type: 'application',
                svg: '#icon-line-document-men'
              };
            case vm.feed.type >= 70 && vm.feed.type <= 80:
              return {
                type: 'screening',
                svg: '#icon-line-office'
              };
            case vm.feed.type >= 100 && vm.feed.type <= 101:
              return {
                type: 'cloud',
                svg: '#icon-line-cloud'
              };
            case vm.feed.type === 201:
              return {
                type: 'sponsor',
                svg: '#icon-line-favorite'
              };
            case vm.feed.type === 200:
              return {
                type: 'cloud',
                svg: '#icon-line-cloud'
              };
            case vm.feed.type >= 50 && vm.feed.type <= 57:
              return {
                type: 'payment',
                svg: '#icon-line-finance'
              };
            case vm.feed.type === 30:
              return {
                type: 'properties',
                svg: '#icon-line-finance'
              };
            case vm.feed.type === 132:
              return {
                type: 'properties',
                svg: '#icon-line-property'
              };
            case vm.feed.type === 220:
              return {
                type: 'reminder-todo',
                svg: '#icon-line-reminder-todo'
              };
            case vm.feed.type === 141:
              return {
                type: 'verification-approved',
                svg: '#icon-line-approved'
              };
            case vm.feed.type === 140:
              return {
                type: 'verification-declined',
                svg: '#icon-line-decline'
              };
            case vm.feed.type === 150:
              return {
                type: 'export-is-ready',
                svg: '#icon-line-data-export'
              };
            case vm.feed.type >= 180 && vm.feed.type <= 189:
              return {
                type: 'tenantmatch',
                svg: '#icon-line-tenantmatch'
              };
            case vm.feed.type >= 120 && vm.feed.type <= 131:
              return {
                type: 'property-board',
                svg: '#icon-line-board'
              };
            case vm.feed.type >= 160 && vm.feed.type <= 179:
              return {
                type: 'cloud',
                svg: '#icon-line-cloud'
              };
            case vm.feed.type === 111:
            case vm.feed.type === 114:
            case vm.feed.type === 115:
            case vm.feed.type === 118:
            case vm.feed.type === 119:
              return {
                type: 'lease',
                svg: '#icon-line-lease'
              };
            case vm.feed.type === 116:
              return {
                type: 'lease',
                svg: '#icon-line-approved'
              };
            case vm.feed.type === 117:
              return {
                type: 'lease-expire',
                svg: '#icon-line-approved'
              };
            case vm.feed.type === 110:
              return {
                type: 'lease-expire',
                svg: '#icon-line-lease'
              };
            case vm.feed.type === 112:
              return {
                type: 'lease-signed-ll',
                svg: '#icon-line-lease'
              };
            case vm.feed.type === 113:
              return {
                type: 'lease-signed-tn',
                svg: '#icon-line-lease'
              };
            case vm.feed.type === 230:
              return {
                type: 'rental-report',
                svg: '#icon-line-report'
              };
            case vm.feed.type === 260:
              return {
                type: 'tips-tricks',
                svg: '#icon-line-blog-adm'
              };
            case vm.feed.type === 250:
              return {
                type: 'new-notice',
                svg: '#icon-line-document'
              };
            case vm.feed.type === 270:
              return {
                type: 'domain-review',
                svg: '#icon-line-domain-review'
              };
            case vm.feed.type === 271:
              return {
                type: 'domain-rejected',
                svg: '#icon-line-domain-rejected'
              };
            case vm.feed.type === 272:
              return {
                type: 'domain-approved',
                svg: '#icon-line-domain-approved'
              };
            }
          }
          function feedIsClickAble() {
            return vm.feed.openLink || vm.feed.details.url;
          }
          function setAsViewed() {
            FeedService.setFeedAsViewed(vm.feed);
          }
        }
      ]
    };
  var widgetToDoList = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/widgets/to_do_list.html',
      controller: [
        '$rootScope',
        '$element',
        'HttpService',
        function ($rootScope, $element, HttpService) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            vm.isLoaded = false;
            getStatistics();
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          function getStatistics() {
            HttpService.getWParams('/statistics/todos', { ignoreLoadingBar: true }, function (resp) {
              vm.isLoaded = true;
              vm.source = resp;
              vm.isTodo = isTodo;
              function isTodo() {
                return vm.source.length > 0;
              }
            });
          }
        }
      ]
    };
  var widgetPanel = {
      bindings: { title: '@' },
      templateUrl: '/app/modules/shared/components/widgets/panel.html',
      transclude: true
    };
  var widgetCloudStorage = {
      bindings: { source: '<' },
      templateUrl: '/app/modules/shared/components/widgets/cloud_store.html',
      controller: [
        '$filter',
        '$element',
        function ($filter, $element) {
          'ngInject';
          var vm = this;
          vm.$onInit = function () {
            vm.isLoaded = false;
            getStatistics();
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          function getStatistics() {
            vm.isLoaded = true;
            vm.color = [
              '#7489e3',
              '#41a541'
            ];
            //Storage data
            vm.storage = {
              max: vm.source.allowed,
              used: vm.source.used,
              available: vm.source.allowed - vm.source.used
            };
            vm.data_storage = [
              [
                Lang.get('settings.used'),
                vm.storage.used
              ],
              [
                Lang.get('settings.available'),
                vm.storage.available
              ]
            ];
            vm.tooltip = {
              show: true,
              format: {
                value: function (value) {
                  return $filter('bytesToHuman')(value, 2);
                }
              }
            };
          }
        }
      ]
    };
  var widgetAccountSettings = {
      bindings: { role: '@' },
      templateUrl: '/app/modules/shared/components/widgets/account_settings.html',
      controller: [
        '$rootScope',
        '$state',
        'cfpLoadingBar',
        'Notify',
        'HttpService',
        'CounterService',
        'EmitService',
        'AuthDataService',
        function ($rootScope, $state, cfpLoadingBar, Notify, HttpService, CounterService, EmitService, AuthDataService) {
          'ngInject';
          var vm = this;
          vm.account = {
            reset: function () {
              Notify.confirm(false, Lang.get('ll_profile.reset_title'), '/app/modules/shared/pages/settings/dialog/reset_account.html', {
                confirmResetAccount: function (itemData) {
                  cfpLoadingBar.start();
                  HttpService.put('/auth/profile/account_reset', itemData, function () {
                    cfpLoadingBar.complete();
                    resetCounters();
                    $state.go('home.main', {}, { reload: true });
                    Notify.close();
                  }, function (err) {
                    var password = _.get(err, 'errors.password.0');
                    if (password) {
                      Notify.error(password);
                    }
                    return false;
                  });
                }
              });
            },
            remove: function () {
              Notify.confirm(false, Lang.get('ll_profile.delete_title'), '/app/modules/shared/pages/settings/dialog/remove_account.html', {
                confirmRemoveAccount: function (itemData) {
                  cfpLoadingBar.start();
                  HttpService.put('/auth/profile/account_remove', itemData, function () {
                    Notify.close();
                    AuthDataService.removeAccount();
                  }, function (response) {
                    return _.reduce(response.errors, function (acc, value, key) {
                      acc['remove.' + key] = value;
                      return acc;
                    }, { hideErrorTitle: true });
                  });
                }
              });
            }
          };
          function resetCounters() {
            CounterService.reset();
            // Update feed
            EmitService.feedReloadList();
          }
        }
      ]
    };
  var widgetImport = {
      bindings: {
        type: '@',
        label: '@',
        importData: '=',
        step: '=',
        countRows: '=',
        fileExtension: '=',
        headers: '='
      },
      templateUrl: '/app/modules/shared/components/widgets/import.html',
      controller: [
        '$rootScope',
        '$state',
        'FileUploader',
        'APP_CONFIG',
        'localStorageService',
        'AuthDataService',
        'cfpLoadingBar',
        'Notify',
        'ImportService',
        function ($rootScope, $state, FileUploader, APP_CONFIG, localStorageService, AuthDataService, cfpLoadingBar, Notify, ImportService) {
          'ngInject';
          var vm = this;
          vm.allowedRows = APP_CONFIG.import.rows;
          vm.uploader = new FileUploader(uploadParams());
          vm.removeFile = removeFile;
          function removeFile(item) {
            item.remove();
            document.getElementById('uploader').value = '';
          }
          function uploadParams() {
            return {
              url: APP_CONFIG.external_api + '/import/' + vm.type + '/upload',
              removeAfterUpload: true,
              queueLimit: 1,
              filters: [{
                  name: 'csvFilter',
                  fn: function (item) {
                    var allowedExtensions = [
                        '.csv',
                        '.xls',
                        '.xlsx'
                      ], ext = item.name.substr(item.name.lastIndexOf('.')), isAllowed = allowedExtensions.indexOf(ext) > -1;
                    if (!isAllowed) {
                      Notify.alert(Lang.get('general.uploader_error_extension_type.5'));
                    }
                    return isAllowed;
                  }
                }],
              formData: [],
              onProgressItem: function () {
                cfpLoadingBar.start();
              },
              onCompleteItem: function (item, response, status, headers) {
                cfpLoadingBar.complete();
                if (status === 200) {
                  vm.importData = response.data;
                  vm.step = response.step;
                  vm.countRows = response.countRows;
                  vm.fileExtension = response.fileExtension;
                  if (response.data.length > 0) {
                    var headerTypes = _.invert(Lang.get('import.types.' + vm.type + '.headers')), headerErrors = [];
                    _.forEach(response.data[0], function (item) {
                      item = _.isString(item) ? item.charAt(0).toUpperCase() + item.substr(1).toLowerCase() : item;
                      if (headerTypes[item]) {
                        var mappingData = {
                            key: headerTypes[item],
                            value: item
                          };
                        vm.headers.mapping.push(mappingData);
                        vm.headers.mapping_check.push({ key: headerTypes[item] });
                        headerErrors.push(headerTypes[item]);
                      } else {
                        var mappingEmptyData = {
                            key: undefined,
                            value: undefined
                          };
                        vm.headers.mapping.push(mappingEmptyData);
                        vm.headers.mapping_check.push({ key: undefined });
                      }
                    });
                    vm.headers.errors = ImportService.validate(vm, headerErrors);
                  }
                }
              },
              onErrorItem: function (item, response) {
                Notify.error(response.message ? response.message : Lang.get('notify_actions'));
              }
            };
          }
        }
      ]
    };
  var widgetVideo = {
      bindings: {
        file: '=',
        type: '<'
      },
      templateUrl: '/app/modules/shared/components/widgets/video.html',
      controller: [
        '$sce',
        '$filter',
        'APP_CONFIG',
        function ($sce, $filter, APP_CONFIG) {
          'ngInject';
          var vm = this;
          vm.statuses = APP_CONFIG.constants.video_job_status;
          if (vm.file && vm.file.video_job && vm.file.video_job.state === 2) {
            vm.config = {
              sources: [
                {
                  src: $sce.trustAsResourceUrl($filter('getAvatarUrl')(vm.file, 'original_mp4', vm.type)),
                  type: 'video/mp4'
                },
                {
                  src: $sce.trustAsResourceUrl($filter('getAvatarUrl')(vm.file, 'original_webm', vm.type)),
                  type: 'video/webm'
                },
                {
                  src: $sce.trustAsResourceUrl($filter('getAvatarUrl')(vm.file, 'original_ogg', vm.type)),
                  type: 'video/ogg'
                }
              ],
              theme: { url: 'https://unpkg.com/videogular@2.1.2/dist/themes/default/videogular.css' }
            };
          }
        }
      ]
    };
  angular.module('app.components.widgets', []).component('widgetCalendar', widgetCalendar).component('widgetFeed', widgetFeed).component('widgetFeedContent', widgetFeedContent).component('widgetToDoList', widgetToDoList).component('widgetPanel', widgetPanel).component('widgetCloudStorage', widgetCloudStorage).component('widgetAccountSettings', widgetAccountSettings).component('widgetImport', widgetImport).component('widgetVideo', widgetVideo);
  ;
}());