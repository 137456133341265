(function () {
  'use strict';
  var forgotPasswordComponent = {
      bindings: {},
      templateUrl: '/app/modules/auth/forgot/forgot-password.html',
      controller: [
        '$state',
        '$location',
        'HttpService',
        'BreadCrumbsService',
        'Notify',
        function ($state, $location, HttpService, BreadCrumbsService, Notify) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('auth.breadcrumbs.forgot_password'));
          vm.item = _.clone($location.search());
          if (_checkResend()) {
            sendEmail();
          }
          vm.sendEmail = sendEmail;
          /**
			 * Action send Forgot Password.
			 */
          function sendEmail() {
            // Post email to recovering.
            HttpService.post('/auth/forgotPassword', vm.item, function () {
              // If user success Send Email, then go to confirm code.
              if (_checkResend()) {
                Notify.success(Lang.get('auth.confirmation_email_has_been_re_sent'));
              }
              $state.go('auth.forgotPasswordConfirm', { email: encodeURIComponent(vm.item.email) });
            });
          }
          /**
			 * Check Resend email
			 * @returns {*}
			 * @private
			 */
          function _checkResend() {
            return vm.item.reSend;
          }
        }
      ]
    };
  var forgotPasswordConfirmComponent = {
      templateUrl: '/app/modules/auth/forgot/confirm.html',
      controller: [
        '$state',
        '$location',
        'BreadCrumbsService',
        'HttpService',
        'Notify',
        function ($state, $location, BreadCrumbsService, HttpService, Notify) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('auth.breadcrumbs.forgot_password_confirm'));
          vm.item = _.clone($location.search());
          vm.item.email = decodeURIComponent(vm.item.email);
          vm.sendCode = sendCode;
          vm.isComplete = isComplete;
          // Check if already set Confirmation Code, if set send to server.
          if (_ckeckConfirmation()) {
            sendCode();
          }
          /**
			 * Check status is_complete all inputs
			 * @param status
			 */
          function isComplete(status) {
            vm.item.is_complete = status;
            if (status) {
              sendCode();
            }
          }
          /**
			 * Action send Code for verify.
			 */
          function sendCode() {
            // Post email to recovering.
            HttpService.post('/auth/forgotPassword/verifyCode', vm.item, function (resp) {
              // If user success Send Email, then go to confirm code.
              Notify.success(Lang.get('auth.form.choose_new_password.label'));
              var params = {
                  email: encodeURIComponent(vm.item.email),
                  confirmation_code: vm.item.confirmation_code
                };
              if (resp.twoFactorAuthRequired) {
                params.twoFactorAuthRequired = true;
              }
              $state.go('auth.forgotPasswordChangePassword', params);
            }, function (resp) {
              vm.errors = _.get(resp, 'errors');
            });
          }
          /**
			 * Ckeck Confirmation
			 * @returns {*}
			 * @private
			 */
          function _ckeckConfirmation() {
            return vm.item.confirmation_code && vm.item.confirmation_code.length;
          }
        }
      ]
    };
  var forgotPasswordChangeComponent = {
      templateUrl: '/app/modules/auth/forgot/change.html',
      controller: [
        '$stateParams',
        '$location',
        'BreadCrumbsService',
        'HttpService',
        'AuthDataService',
        'Notify',
        function ($stateParams, $location, BreadCrumbsService, HttpService, AuthDataService, Notify) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('auth.breadcrumbs.forgot_password_change_password'));
          vm.twoFactorAuthRequired = $stateParams.twoFactorAuthRequired;
          vm.item = _.clone($location.search());
          vm.item.email = decodeURIComponent(vm.item.email);
          if (vm.twoFactorAuthRequired) {
            vm.item.two_factor_auth_code = '';
          }
          vm.changePassword = changePassword;
          /**
			 * Action send new Password for change
			 */
          function changePassword() {
            // Post email to recovering.
            HttpService.post('/auth/forgotPassword/changePassword', vm.item, function (resp) {
              if (resp.user) {
                BreadCrumbsService.clear();
                AuthDataService.loadModule(resp);
              } else {
                AuthDataService.clearAuth();
                if (resp.profile) {
                  // Not registered by social network
                  Notify.error(Lang.get('auth.auth_errors.2'));
                }
              }
            }, function (resp) {
              if (_.has(resp, 'message')) {
                Notify.error(resp.message);
              }
            });
          }
        }
      ]
    };
  angular.module('app.forgot.components', []).component('forgotPasswordComponent', forgotPasswordComponent).component('forgotPasswordConfirmComponent', forgotPasswordConfirmComponent).component('forgotPasswordChangeComponent', forgotPasswordChangeComponent);
  ;
}());