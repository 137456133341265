(function () {
  'use strict';
  HttpService.$inject = [
    '$http',
    '$rootScope',
    '$q',
    '$state',
    '$timeout',
    'APP_CONFIG',
    'cfpLoadingBar',
    'CordovaService',
    'Notify',
    'UniqueAgentIDService'
  ];
  ChatService.$inject = [
    '$notification',
    '$filter',
    '$state',
    'EmitService'
  ];
  FeedBackService.$inject = [
    '$state',
    '$stateParams',
    '$rootScope'
  ];
  FeedService.$inject = [
    '$q',
    '$state',
    '$location',
    'HttpService',
    'EmitService'
  ];
  RealTimeService.$inject = [
    '$rootScope',
    '$state',
    '$timeout',
    '$filter',
    'ng_util',
    'AuthDataService',
    'Notify',
    'APP_CONFIG',
    'ChatService',
    'FeedBackService',
    'CounterService',
    'EmitService',
    'HttpService',
    'TenantConnectionService',
    'UniqueAgentIDService',
    'PaymentService'
  ];
  CounterService.$inject = [
    '$rootScope',
    'ng_util',
    'EmitService'
  ];
  SeoService.$inject = ['BreadCrumbsService'];
  ColumnInterfaceService.$inject = [
    '$mdMedia',
    '$window',
    'ng_util',
    'localStorageService',
    'EmitService'
  ];
  LangService.$inject = [
    'HttpService',
    'APP_CONFIG'
  ];
  PaymentService.$inject = ['Notify'];
  PaypalPayService.$inject = ['$filter'];
  StripePayService.$inject = [
    'HttpService',
    'cfpLoadingBar',
    'Notify'
  ];
  DwollaPayService.$inject = [
    'HttpService',
    'cfpLoadingBar',
    'Notify'
  ];
  RefreshSelectOptionsService.$inject = [
    'HttpService',
    '$q',
    'APP_CONFIG'
  ];
  ConnectionService.$inject = [
    'HttpService',
    'Notify'
  ];
  ImportService.$inject = [
    '$filter',
    '$state',
    'array_util',
    'HttpService',
    'Notify',
    'cfpLoadingBar'
  ];
  UserPaidService.$inject = [
    '$state',
    '$rootScope',
    'HttpService',
    'Notify'
  ];
  TenantConnectionService.$inject = [
    'HttpService',
    'Notify'
  ];
  SessionExpiresService.$inject = [
    '$timeout',
    'APP_CONFIG'
  ];
  DwollaPermissionsService.$inject = [
    '$rootScope',
    'SubscriptionService',
    'APP_CONFIG'
  ];
  angular.module('app.services', []).service('HttpService', HttpService).service('ChatService', ChatService).service('FeedBackService', FeedBackService).service('FeedService', FeedService).service('RealTimeService', RealTimeService).service('CounterService', CounterService).service('BreadCrumbsService', BreadCrumbsService).service('SeoService', SeoService).service('anchorSmoothScroll', anchorSmoothScroll).service('ColumnInterfaceService', ColumnInterfaceService).service('EmitService', EmitService).service('FilterService', FilterService).service('AppValidationService', AppValidationService).service('LangService', LangService).service('PaymentService', PaymentService).service('PaypalPayService', PaypalPayService).service('StripePayService', StripePayService).service('DwollaPayService', DwollaPayService).service('RefreshSelectOptionsService', RefreshSelectOptionsService).service('ConnectionService', ConnectionService).service('ImportService', ImportService).service('UserPaidService', UserPaidService).service('TenantConnectionService', TenantConnectionService).service('SessionExpiresService', SessionExpiresService).service('DwollaPermissionsService', DwollaPermissionsService).service('DotsService', DotsService);
  ;
  /* Fn
	 ============================================================================================================= */
  function HttpService($http, $rootScope, $q, $state, $timeout, APP_CONFIG, cfpLoadingBar, CordovaService, Notify, UniqueAgentIDService) {
    var baseUrl = APP_CONFIG.external_api, currentFormName = 'form', isRequestSent = false;
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        get: getFn,
        getWParams: getFnWParams,
        post: postFn,
        put: putFn,
        delete: deleteFn,
        setCurrentFormName: setCurrentFormName,
        getCurrentFormName: getCurrentFormName,
        getResolve: getResolveFn,
        config: config
      };
    return service;
    // function _uniqueRequestAwareHttpService() {
    // 	var uniqueRequestOptionName = 'unique',
    // 		requestIdOptionName = 'requestId',
    // 		DUPLICATED_REQUEST_STATUS_CODE = 499,
    // 		EMPTY_BODY = '',
    // 		EMPTY_HEADERS = {};
    //
    // 	function checkForDuplicates(requestConfig) {
    // 		return !!requestConfig[uniqueRequestOptionName];
    // 	}
    //
    // 	function checkIfDuplicated(requestConfig) {
    // 		var duplicated = $http.pendingRequests.filter(function (pendingReqConfig) {
    // 			return pendingReqConfig[requestIdOptionName] && pendingReqConfig[requestIdOptionName] === requestConfig[requestIdOptionName];
    // 		});
    //
    // 		return duplicated.length > 0;
    // 	}
    //
    // 	function buildRejectedRequestPromise(requestConfig) {
    // 		var dfd = $q.defer();
    //
    // 		var response = {
    // 			data: EMPTY_BODY,
    // 			headers: EMPTY_HEADERS,
    // 			status: DUPLICATED_REQUEST_STATUS_CODE,
    // 			config: requestConfig
    // 		};
    //
    // 		console.info('Such request is already in progres, rejecting this one with', response);
    //
    // 		dfd.reject(response);
    //
    // 		return dfd.promise;
    // 	}
    //
    // 	return function (requestConfig) {
    // 		if(checkForDuplicates(requestConfig) && checkIfDuplicated(requestConfig)) {
    // 			return buildRejectedRequestPromise(requestConfig);
    // 		}
    //
    // 		return $http(requestConfig);
    // 	};
    // }
    /**
		 *
		 * @returns {string}
		 */
    function getCurrentFormName() {
      return currentFormName;
    }
    /**
		 *
		 * @param formName
		 */
    function setCurrentFormName(formName) {
      currentFormName = formName;
    }
    /**
		 *
		 * @param options
		 * @returns {{withCredentials: boolean}}
		 */
    function config(options) {
      var cfg = {
          headers: { uaid: UniqueAgentIDService.token() },
          withCredentials: true
        };
      if (window.cordova) {
        cfg.headers = { 'Device': CordovaService.getDeviceHeaderValue() };
      }
      if (options) {
        _.extend(cfg, options);
      }
      return cfg;
    }
    /**
		 *
		 * @param result
		 * @private
		 */
    function _extract(result) {
      return result.data;
    }
    /**
		 *
		 * @param resp
		 * @param callback
		 * @returns {*}
		 * @private
		 */
    function _successCallback(resp, callback) {
      if (callback) {
        return callback(_extract(resp));
      } else {
        return resp;
      }
    }
    /**
		 *
		 * @param resp
		 * @param callback
		 */
    function _errorCallback(resp, callback) {
      var data;
      if (callback) {
        data = callback(_extract(resp));
      }
      if (_.isUndefined(data)) {
        // resp.data.errors
        data = _extract(resp).errors;
      }
      $rootScope.$emit('form:error', data);
    }
    /**
		 *
		 * @param url
		 * @param callback
		 * @param errorCb
		 * @returns {Promise}
		 */
    function getFn(url, callback, errorCb) {
      url = baseUrl + url;
      return $http.get(url, config()).then(function (resp) {
        return _successCallback(resp, callback);
      }, function (resp) {
        _errorCallback(resp, errorCb);
      });
    }
    /**
		 *
		 * @param url
		 * @param params
		 * @param callback
		 * @param errorCb
		 * @returns {Promise}
		 */
    function getFnWParams(url, params, callback, errorCb) {
      params = params || {};
      _.extend(params, config());
      url = baseUrl + url;
      return $http.get(url, params).then(function (resp) {
        return _successCallback(resp, callback);
      }, function (resp) {
        _errorCallback(resp, errorCb);
      });
    }
    /**
		 *
		 * @param url
		 * @param data
		 * @param callback
		 * @param errorCb
		 * @param options
		 * @returns {Promise}
		 */
    function postFn(url, data, callback, errorCb, options) {
      if (isRequestSent) {
        return;
      }
      isRequestSent = true;
      url = baseUrl + url;
      return $http.post(url, data, config(options)).then(function (resp) {
        $rootScope.$emit('form:success');
        isRequestSent = false;
        return _successCallback(resp, callback);
      }, function (resp) {
        isRequestSent = false;
        _errorCallback(resp, errorCb);
      });
    }
    /**
		 *
		 * @param url
		 * @param data
		 * @param callback
		 * @param errorCb
		 * @param options
		 * @returns {Promise}
		 */
    function putFn(url, data, callback, errorCb, options) {
      if (isRequestSent) {
        return;
      }
      isRequestSent = true;
      url = baseUrl + url;
      return $http.put(url, data, config(options)).then(function (resp) {
        $rootScope.$emit('form:success');
        isRequestSent = false;
        return _successCallback(resp, callback);
      }, function (resp) {
        isRequestSent = false;
        _errorCallback(resp, errorCb);
      });
    }
    /**
		 *
		 * @param url
		 * @param callback
		 * @param errorCb
		 * @returns {Promise}
		 */
    function deleteFn(url, callback, errorCb) {
      cfpLoadingBar.start();
      url = baseUrl + url;
      return $http.delete(url, config()).then(function (resp) {
        $rootScope.$emit('form:success');
        cfpLoadingBar.complete();
        return _successCallback(resp, callback);
      }, function (resp) {
        cfpLoadingBar.complete();
        _errorCallback(resp, errorCb);
      });
    }
    /**
		 * Simple $http get request with result check
		 * and state redirect if error occurred
		 *
		 * @param url
		 * @param fallbackState
		 * @param params
		 * @returns {deferred.promise|{then, catch, finally}|{then}}
		 */
    function getResolveFn(url, fallbackState, params) {
      params = params || {};
      _.extend(params, config());
      url = baseUrl + url;
      var deferred = $q.defer();
      $http.get(url, params).then(function (resp) {
        deferred.resolve({ data: resp.data });
      }, function (err) {
        //show error and reject resolve
        if (err.data && err.data.message) {
          Notify.error(err.data.message, 10000);
        }
        deferred.reject();
        $timeout(function () {
          //run callback function or just redirect to fallback state
          if (_.isFunction(fallbackState)) {
            fallbackState();
          } else {
            $state.go(fallbackState);
          }
        });
      });
      return deferred.promise;
    }
  }
  function FeedBackService($state, $stateParams, $rootScope) {
    /**
		 *
		 * @param feedback
		 * @param comment
		 */
    this.commentWasAdded = function (feedback, comment) {
      if ($state.current.name === 'feedback.list') {
        $rootScope.$emit('feedback:comment_add', { feedback: feedback });
      }
      // check if user is on the exact feedback. And insert comment if he is
      if ($state.current.name === 'feedback.show' && parseInt($stateParams.id) === feedback.id) {
        $rootScope.$emit('feedback:comment_new', { comment: comment });
      }
    };
  }
  function ChatService($notification, $filter, $state, EmitService) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        newMessageWasAdded: newMessageWasAdded,
        sendBrowserNotification: sendBrowserNotification,
        threadWasRead: threadWasRead
      };
    return service;
    /**
		 * Helper method to group fire events
		 * @param message
		 */
    function newMessageWasAdded(message) {
      if ($state.current.name === 'messages.list') {
        EmitService.messageNew(message);
      }
      this.sendBrowserNotification(message);
    }
    /**
		 *
		 * @param data
		 */
    function threadWasRead(data) {
      if ($state.current.name === 'messages.list') {
        EmitService.threadWasRead(data);
      }
    }
    /**
		 * Show HTML5 browser notification message
		 * @param message
		 */
    function sendBrowserNotification(message) {
      // Check for mobile app
      if (window.cordova) {
        return false;
      }
      var notification = $notification('New Message', {
          body: $filter('strip_tags')(message.body),
          dir: 'auto',
          lang: 'en',
          delay: 10000,
          focusWindowOnClick: true
        });
      notification.$on('click', function () {
        $state.go('messages.list');
      });
    }
  }
  /**
	 * Service for feed activity
	 */
  function FeedService($q, $state, $location, HttpService, EmitService) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        getFeeds: getFeeds,
        setFeedAsViewed: setFeedAsViewed,
        setAllFeedsAsViewed: setAllFeedsAsViewed,
        findFeedAndUpdate: findFeedAndUpdate
      };
    return service;
    /**
		 *
		 * @returns {promise|Promise|f|*}
		 */
    function getFeeds() {
      var deferred = $q.defer();
      HttpService.get('/feed/small', function (resp) {
        deferred.resolve(resp);
      });
      return deferred.promise;
    }
    /**
		 *
		 * @param feed
		 */
    function setFeedAsViewed(feed) {
      if (feed.isViewed === 0) {
        HttpService.post('/feed/setAsViewed/' + feed.id);
        feed.isViewed = 1;
        if ($state.current.name === 'feed.list') {
          EmitService.feedFindUpdateList(feed.id);
        }
      }
      //move to url
      if (feed.openLink) {
        $location.url(feed.openLink);
      }
      //open new window. If custom feed with url
      if (feed.details.url) {
        window.open(feed.details.url, '_blank');
      }
    }
    /**
		 *
		 * @param feeds
		 */
    function setAllFeedsAsViewed(feeds) {
      HttpService.post('/feed/setAllAsViewed', {}, function () {
        _.forEach(feeds, function (feed) {
          feed.isViewed = 1;
        });
      });
    }
    /**
		 *
		 * @param obj
		 * @param feed_id
		 */
    function findFeedAndUpdate(obj, feed_id) {
      _.map(obj.pagination.list, function (item) {
        if (item.id === feed_id) {
          item.isViewed = !'1';  //set as read or unread conversation
        }
        return item;
      });
    }
  }
  function RealTimeService($rootScope, $state, $timeout, $filter, ng_util, AuthDataService, Notify, APP_CONFIG, ChatService, FeedBackService, CounterService, EmitService, HttpService, TenantConnectionService, UniqueAgentIDService, PaymentService) {
    var socket, socketInit = false;
    socket = {
      on: angular.noop,
      emit: angular.noop
    };
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        create: create,
        reconnect: reconnect,
        initAuthResolver: initAuthResolver,
        initMaintenanceModeCheck: initMaintenanceModeCheck,
        initMessages: initMessages,
        initFeeds: initFeeds,
        initFeedbacks: initFeedbacks,
        initOnlineUsers: initOnlineUsers,
        initCounters: initCounters,
        initUserRefresher: initUserRefresher,
        initRouteResolver: initRouteResolver
      };
    return service;
    /**
		 * Create connection
		 */
    function create() {
      _createConnection();
    }
    /**
		 *
		 */
    function reconnect() {
      try {
        if (socketInit) {
          socket.io.disconnect();
          socketInit = false;
        }
        _createConnection();
      } catch (err) {
      }
    }
    /**
		 *
		 */
    function initAuthResolver() {
      socket.on('App\\Events\\AngularRouter\\AuthSocialLogin', function (resp) {
        if (resp.token) {
          ng_util.safeApply($rootScope, function () {
            HttpService.post('/auth/login_by_token', { token: resp.token }, function (resp) {
              if (resp.twoFactorAuthRequired) {
                $state.go('auth.two_factor_validate');
              } else {
                AuthDataService.loadModule(resp);
              }
            });
          });
        }
      });
      socket.on('App\\Events\\AngularRouter\\AuthSocialLoginProfile', function (resp) {
        if ($state.current.name === 'auth.login') {
          Notify.error(Lang.get('auth.auth_errors.10'));
        }
        if ($state.current.name === 'auth.signup') {
          $rootScope.$emit('auth:signup_form', resp);
        }
      });
    }
    /**
		 *
		 */
    function initMaintenanceModeCheck() {
      /**
			 * Maintenance mode was enabled
			 */
      socket.on('App\\Events\\Dev\\MaintenanceModeWasEnabled', function (data) {
        var deadline = $filter('convertDateByTimezone')(data.mode_enables_at.datetime, 'dd MMM yyyy h:mm');
        Notify.success(Lang.get('maintenance_mode.before_message', { 'datetime': deadline }), 10 * 60 * 1000);
        $rootScope.$emit('maintenance_mode:enable', data);
      });
      socket.on('App\\Events\\Dev\\MaintenanceModeWasLaunched', function () {
        Notify.error(Lang.get('maintenance_mode.now_message'));
        $timeout(function () {
          window.location.reload();
        }, 5000);
      });
    }
    /**
		 * Init real time event handlers for messages.
		 * New message was received, Message was read by participant etc.
		 */
    function initMessages() {
      /**
			 * New message was received with email
			 */
      socket.on('App\\Events\\Messages\\NewMessageReceived', function (data) {
        ChatService.newMessageWasAdded(data.message);
      });
      socket.on('App\\Events\\Messages\\ThreadWasRead', function (data) {
        ChatService.threadWasRead(data);
      });
    }
    /**
		 *
		 */
    function initFeeds() {
      /**
			 * New feed was created
			 */
      socket.on('App\\Events\\Feed\\NewFeedWasCreated', function () {
        EmitService.feedReloadList();  // EmitService.feedReloadWidget();
      });
    }
    /**
		 * Feedback comment was added
		 */
    function initFeedbacks() {
      socket.on('App\\Events\\Feedback\\CommentAdded', function (data) {
        FeedBackService.commentWasAdded(data.feedback, data.comment);
      });
    }
    /**
		 * Init users count
		 */
    function initOnlineUsers() {
      $rootScope.users_online = { sockets: [] };
      socket.on('online_users', function (data) {
        var sockets = _.get(data, 'sockets', []);
        ng_util.safeApply($rootScope, function () {
          $rootScope.users_online = { sockets: sockets };
        });
      });
    }
    /**
		 *
		 */
    function initCounters() {
      /**
			 * Update Count New Invites on Decline, Approve, Create and Delete.
			 */
      socket.on('App\\Events\\Counters\\UpdateCounters', function (data) {
        CounterService.update(data);
      });
      /**
			 * Export
			 */
      socket.on('App\\Events\\Export\\UpdateExportProgressBar', function (data) {
        $rootScope.$emit('export:update-progress_bar', data.export_progress_bar_percent);
      });
      socket.on('App\\Events\\Export\\ExportWasCreated', function (data) {
        $rootScope.$emit('export:update-download_token', data);
      });
      /**
			 * File manager
			 */
      socket.on('App\\Events\\FileManager\\UpdateDownloadFileProgressBar', function (data) {
        $rootScope.$emit('filemanager:update-progress_bar', data.percent);
      });
      socket.on('App\\Events\\FileManager\\DownloadFileWasCreated', function (data) {
        $rootScope.$emit('filemanager:update-download_file', data.file);
      });
    }
    /**
		 *
		 */
    function initUserRefresher() {
      socket.on('App\\Events\\Verification\\Approved', function (data) {
        ng_util.safeApply($rootScope, function () {
          $rootScope.auth.verification = data.verification;
        });
      });
      socket.on('App\\Events\\Verification\\Declined', function (data) {
        ng_util.safeApply($rootScope, function () {
          $rootScope.auth.verification = data.verification;
        });
      });
    }
    /**
		 *
		 */
    function initRouteResolver() {
      /**
			 * Logout
			 */
      socket.on('App\\Events\\AngularRouter\\Logout', function (resp) {
        if (!resp || resp.except !== UniqueAgentIDService.token()) {
          $state.go('auth.logout');
        }
      });
      /**
			 * ALL
			 * Update auth & auth_data with counters
			 */
      socket.on('App\\Events\\AngularRouter\\UpdateMassAuthData', function () {
        ng_util.safeApply($rootScope, function () {
          HttpService.get('/auth/profile/auth_data', function (resp) {
            // Update rootScope auth
            AuthDataService.updateAuth(resp.user);
            delete resp.auth_data.trans;
            CounterService.update(resp.auth_data);
          });
        });
      });
      socket.on('App\\Events\\AngularRouter\\UpdateSingleAuthData', function (data) {
        ng_util.safeApply($rootScope, function () {
          // Update rootScope auth user instance
          AuthDataService.updateAuth(data.user);
          // Update all counter
          CounterService.update(data.auth_data);
        });
      });
      socket.on('App\\Events\\AngularRouter\\UpdateUserStorage', function (data) {
        EmitService.setStorage(data.storage);
      });
      socket.on('App\\Events\\AngularRouter\\TransactionOnlinePaid', function (data) {
        // Notify user for success payment
        PaymentService.showSuccessMessage(data);
        // Redirect to transactions list
        $state.go('transactions.list');
      });
      /**
			 * Landlord
			 * PayPal Setup success
			 */
      socket.on('App\\Events\\AngularRouter\\PayPalSetupSuccess', function (data) {
        ng_util.safeApply($rootScope, function () {
          AuthDataService.updateAuth(data.user);
          $rootScope.paypal_setup_inprogress = false;
        });
        $state.go('settings.payments_edit', { gateway: 'paypal' }, { reload: true });
      });
      // Connect social providers
      socket.on('App\\Events\\AngularRouter\\AuthSocialLoginProfile', function (resp) {
        ng_util.safeApply($rootScope, function () {
          $rootScope.$emit('landlord:social_link_added', resp);
        });
      });
      // Gte credentials for google calendar
      socket.on('App\\Events\\AngularRouter\\AuthSocialCalendarCredentials', function (resp) {
        ng_util.safeApply($rootScope, function () {
          $rootScope.$emit('landlord:google_calendar_credentials', resp);
        });
      });
      /**
			 * TENANT
			 * Redirect to apply listing
			 */
      socket.on('App\\Events\\AngularRouter\\ApplicationApply', function (data) {
        if (data.listing_id) {
          $state.go('applications.apply', { rental_id: data.listing_id }, { reload: true });
          if (window.cordova && window.cordova_ref) {
            window.cordova_ref.close();
            window.cordova_ref = undefined;
          }
        }
      });
      /**
			 * TENANT
			 * Redirect to application after fee paid
			 */
      socket.on('App\\Events\\AngularRouter\\TenantApplicationFeePaid', function (data) {
        if (data.application_id) {
          // Notify user for success payment
          PaymentService.showSuccessMessage(data);
          $state.go('applications.view', { id: data.application_id }, { reload: true });
        }
      });
      /**
			 * TENANT
			 * Load new connection
			 */
      socket.on('App\\Events\\Invites\\NewConnectionToRegisteredUser', function (data) {
        if (data.contact && data.contact.role === 'tenant') {
          TenantConnectionService.loadData();
        }
      });
    }
    function _createConnection() {
      if (!socketInit) {
        try {
          socketInit = true;
          socket = io(APP_CONFIG.socketUrl, {
            secure: true,
            transports: ['websocket'],
            upgrade: false,
            query: { uaid: UniqueAgentIDService.token() }
          });
          socket.on('connect', function () {
            socketInit = true;
          });
          socket.on('disconnect', function () {
            socketInit = false;
          });
          socket.on('reconnect_attempt', function () {
            socket.io.opts.transports = [
              'polling',
              'websocket'
            ];
          });
        } catch (err) {
          window.console.error('Check sockets!');
        }
      }
    }
  }
  function BreadCrumbsService() {
    /**
		 *
		 * @param label
		 * @param state
		 */
    this.addCrumb = function (label, state) {
      state = state || false;
      crumbs.push({
        state: state,
        label: label
      });
    };
    /**
		 *
		 */
    this.getCrumbs = function () {
      return crumbs;
    };
    /**
		 *
		 */
    this.clear = function () {
      crumbs = this.init();
    };
    /**
		 *
		 * @returns {[null]}
		 */
    this.init = function () {
      return [{
          state: 'home.main',
          label: Lang.get('nav.home.label') !== 'nav.home.label' ? Lang.get('nav.home.label') : null
        }];
    };
    var crumbs = this.init();
  }
  /**
	 * Service for meta title and meta description etc.
	 * @type {string[]}
	 */
  function SeoService(BreadCrumbsService) {
    /**
		 * Get title for html
		 * @returns {string}
		 */
    this.getTitle = function () {
      var lastCrumb = _.last(BreadCrumbsService.getCrumbs()).label;
      return (lastCrumb ? lastCrumb + ' - ' : '') + Lang.get('constants.company_name_snake');
    };
  }
  /**
	 * Service for meta title and meta description etc.
	 * @type {string[]}
	 */
  function CounterService($rootScope, ng_util, EmitService) {
    /**
		 * PRIVATE
		 */
    var store = {
        user_tenant: 0,
        user_owner: 0,
        user_professional: 0,
        module_application: 0,
        module_workorder: 0,
        module_rentwish: 0,
        module_invite: 0,
        module_feedback: 0,
        module_feeds: 0,
        module_calendar: 0,
        module_messages: 0,
        module_board_posts: 0,
        total_users: function () {
          var self = this;
          return self.user_tenant + self.user_owner + self.user_professional;
        }
      };
    var store_nulled = _.clone(store);
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        init: init,
        getCounter: getCounter,
        update: update,
        plus: plus,
        minus: minus,
        reset: reset,
        resetOne: resetOne
      };
    return service;
    /**
		 * Check for state reload
		 * @param old_data
		 * @param data
		 * @returns {*}
		 * @private
		 */
    function _checkForReload(old_data, data) {
      // Check for feed
      if (old_data.module_feeds !== data.module_feeds) {
        EmitService.feedReloadList();  // EmitService.feedReloadWidget();
      }
      // if user use pagination and page > 1, no need update
      if ($rootScope.state.params.page && $rootScope.state.params.page > 1) {
        return false;
      }
      // Compare states and counter changes
      switch ($rootScope.state.current.name) {
      case 'tenants.list':
        if (old_data.user_tenant !== data.user_tenant) {
          return $rootScope.state.reload();
        }
        break;
      case 'owners.list':
        if (old_data.user_owner !== data.user_owner) {
          return $rootScope.state.reload();
        }
        break;
      case 'professionals.list':
        if (old_data.user_professional !== data.user_professional) {
          return $rootScope.state.reload();
        }
        break;
      case 'applications.list':
        if (old_data.module_application !== data.module_application) {
          return $rootScope.state.reload();
        }
        break;
      }
      return true;
    }
    /**
		 * PUBLIC
		 */
    /**
		 * Set initial count from backend config
		 */
    function init() {
      if ($rootScope.auth_data && $rootScope.auth_data.counters) {
        store = _.extend(store, $rootScope.auth_data.counters);
      }
    }
    /**
		 * For use in template
		 *
		 * $root.getCounter('user_tenant')
		 * $root.getCounter('total_users()')
		 *
		 * @param type
		 * @returns {*}
		 */
    function getCounter(type) {
      if (type.indexOf('()') !== -1) {
        return store[type.replace('()', '')]();
      } else {
        return store[type];
      }
    }
    /**
		 * Update from DB
		 * @param data
		 */
    function update(data) {
      if (data) {
        var old_store = _.clone(store);
        // Update counters
        store = _.assignIn(store, data.counters);
        // Check for reload
        _checkForReload(old_store, store);
      }
      // $digest
      ng_util.safeApply($rootScope);
    }
    /**
		 * For custom
		 * @param type
		 */
    function plus(type) {
      store[type] += 1;
    }
    /**
		 *
		 * @param type
		 */
    function minus(type) {
      store[type] -= 1;
    }
    /**
		 * Reset
		 */
    function reset() {
      store = _.clone(store_nulled);
    }
    /**
		 * ResetOne
		 * @param type
		 */
    function resetOne(type) {
      store[type] = 0;
    }
  }
  function anchorSmoothScroll() {
    /**
		 *
		 * @param eID
		 */
    this.scrollTo = function (eID) {
      // This scrolling function
      // is from http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript
      var startY = currentYPosition(), stopY = elmYPosition(eID) - 60, distance = stopY > startY ? stopY - startY : startY - stopY, i, speed, step, leapY, timer;
      if (distance < 100) {
        scrollTo(0, stopY);
        return;
      }
      speed = Math.round(distance / 100);
      if (speed >= 20) {
        speed = 30;
      }
      step = Math.round(distance / 25);
      leapY = stopY > startY ? startY + step : startY - step;
      timer = 0;
      if (stopY > startY) {
        for (i = startY; i < stopY; i += step) {
          setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
          leapY += step;
          if (leapY > stopY)
            leapY = stopY;
          timer++;
        }
        return;
      }
      for (i = startY; i > stopY; i -= step) {
        setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);
        leapY -= step;
        if (leapY < stopY)
          leapY = stopY;
        timer++;
      }
      function currentYPosition() {
        // Firefox, Chrome, Opera, Safari
        if (self.pageYOffset)
          return self.pageYOffset;
        // Internet Explorer 6 - standards mode
        if (document.documentElement && document.documentElement.scrollTop)
          return document.documentElement.scrollTop;
        // Internet Explorer 6, 7 and 8
        if (document.body.scrollTop)
          return document.body.scrollTop;
        return 0;
      }
      function elmYPosition(eID) {
        var elm = document.getElementById(eID), y = elm.offsetTop, node = elm;
        while (node.offsetParent && node.offsetParent != document.body) {
          node = node.offsetParent;
          y += node.offsetTop;
        }
        return y;
      }
    };
  }
  function ColumnInterfaceService($mdMedia, $window, ng_util, localStorageService, EmitService) {
    var _gridName, _model = {}, $media = $mdMedia('xs-max');
    var service = {
        init: init,
        getModel: getModel,
        changeGrid: changeGrid
      };
    ng_util.definePropertyObj(EmitService, 'resizeWatch', function (matchMedia) {
      if (!_.isUndefined(matchMedia) && $media !== matchMedia) {
        _model.interface = 'column';
      }
    });
    return service;
    /**
		 *
		 * @param gridName
		 */
    function init(gridName) {
      _gridName = gridName;
      _model.interface = localStorageService.get(_gridName) && $window.innerWidth >= 768 ? localStorageService.get(_gridName) : 'column';
      return _model;
    }
    /**
		 * Get model
		 */
    function getModel() {
      return _model;
    }
    /**
		 * ChangeGrid
		 * @param grid
		 */
    function changeGrid(grid) {
      localStorageService.set(_gridName, grid);
    }
  }
  function FilterService() {
    this.$params = { isShow: false };
    this.$source = {};
    this.$filterTags = {};
    this.$request = false;
    this.$transaction = false;
    this.$fraud_payment = false;
    this.$timeline = { isShow: false };
    this.display = function () {
      this.$params = { isShow: this.$params ? !this.$params.isShow : true };
    };
    this.setRequest = function () {
      this.$request = true;
    };
    this.isFilter = function (filter) {
      return _.every(filter, function (f) {
        return typeof f === 'undefined';
      });
    };
    this.getSource = function () {
      return this.$source;
    };
    this.changeSource = function (source) {
      this.$source = source;
      this.$filterTags = source;
    };
    this.showDetails = function (details) {
      this.$params = {
        isShow: true,
        details: details
      };
    };
    this.showTransactionsDetails = function (transaction) {
      this.$transaction = {
        isShow: true,
        transaction: transaction
      };
    };
    this.hideTransactionsDetails = function () {
      this.$transaction = { isShow: this.$transaction ? !this.$transaction.isShow : true };
    };
    this.showFraudPaymentDetails = function (fraud_payment) {
      this.$fraud_payment = {
        isShow: true,
        details: fraud_payment
      };
    };
    this.hideFraudPaymentDetails = function () {
      this.$fraud_payment = { isShow: this.$fraud_payment ? !this.$fraud_payment.isShow : true };
    };
    this.cancelTransactions = function () {
      this.$transaction = { isShow: false };
    };
    this.showTimeline = function () {
      this.$timeline = { isShow: true };
    };
    this.hideTimeline = function () {
      this.$timeline = { isShow: this.$timeline ? !this.$timeline.isShow : true };
    };
    this.cancel = function () {
      this.$request = false;
      this.$params = { isShow: false };
    };
  }
  function EmitService() {
    // file manager set storage
    this.setStorage = function (storage) {
      this.$storage = storage;
    };
    //feeds
    this.feedReloadList = function () {
      this.$reloadListFeed = true;
    };
    //feed update
    this.feedFindUpdateList = function (id) {
      this.$findUpdateListFeed = id;
    };
    this.watchResize = function (size) {
      this.$resizeWatch = size;
    };
    this.messageNew = function (message) {
      this.$newMessage = message;
    };
    this.threadWasRead = function (thread) {
      this.$threadWasReadObject = thread;
    };
    this.clear = function () {
      this.$resizeWatch = undefined;
      //feed
      this.$reloadListFeed = undefined;
      this.$findUpdateListFeed = undefined;
      //message
      this.$newMessage = undefined;
      this.$threadWasReadObject = undefined;
    };
  }
  function AppValidationService() {
    var _settings;
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        setLandlordSettings: setLandlordSettings,
        checkIfRequired: checkIfRequired
      };
    return service;
    /**
		 *
		 * @param settings
		 */
    function setLandlordSettings(settings) {
      _settings = settings;
    }
    /**
		 *
		 * @param section
		 * @param fieldKey
		 * @param defaultRule
		 * @returns {*}
		 */
    function checkIfRequired(section, fieldKey, defaultRule) {
      defaultRule = defaultRule || false;
      if (!_settings) {
        return defaultRule;
      }
      return _.get(_.get(_settings, section), fieldKey);
    }
  }
  function LangService(HttpService, APP_CONFIG) {
    var currentLanguage = APP_CONFIG.language || 'en';
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        getLang: getLang,
        setLang: setLang,
        isLang: isLang
      };
    return service;
    /**
		 *
		 * @returns {string}
		 */
    function getLang() {
      return currentLanguage;
    }
    /**
		 *
		 * @param lang
		 * @param callback
		 */
    function setLang(lang, callback) {
      if ([
          'en',
          'uk'
        ].indexOf(lang) === -1) {
        // eslint-disable-next-line no-console
        console.log('Language ' + lang + ' not supported');
      } else {
        currentLanguage = lang;
        //
        HttpService.put('/lang/' + lang, {}, function (resp) {
          if (callback) {
            callback(resp);
          }
        });
      }
    }
    /**
		 *
		 * @param lang
		 * @returns {boolean}
		 */
    function isLang(lang) {
      return currentLanguage === lang;
    }
  }
  function PaymentService(Notify) {
    // noinspection UnnecessaryLocalVariableJS
    var service = { showSuccessMessage: showSuccessMessage };
    return service;
    function showSuccessMessage(data) {
      switch (_.get(data, 'gateway')) {
      case 'stripe':
      case 'paypal':
        Notify.ok({ title: Lang.get('settings.payment_actions.payment_successful.message') });
        break;
      case 'dwolla':
        Notify.ok({
          title: Lang.get('settings.payment_actions.payment_successful_dwolla.message'),
          line1: Lang.get('settings.payment_actions.payment_successful_dwolla.line1'),
          line2: Lang.get('settings.payment_actions.payment_successful_dwolla.line2')
        });
        break;
      }
    }
  }
  function PaypalPayService($filter) {
    /**
		 * 
		 * @param checkout_token
		 * @returns {*}
		 */
    this.generateLink = function (checkout_token) {
      return $filter('dashboard_url')('/pay/' + checkout_token + '/paypal');
    };
  }
  function StripePayService(HttpService, cfpLoadingBar, Notify) {
    /**
		 *
		 * @param checkout
		 * @returns {*}
		 */
    this.calculateAmountWithFee = function (checkout) {
      var amount = parseFloat(checkout.amount), fee_payer_receiver = _.get(checkout, 'receiver.payments.gateway.stripe.fee_payer'), percent = _.get(checkout, 'receiver.payments.gateway.stripe.stripe_fee.percent'), fixed = _.get(checkout, 'receiver.payments.gateway.stripe.stripe_fee.fixed'), f_percentage, p_charge;
      if (!fixed || !percent || fee_payer_receiver) {
        return amount.toFixed(2) * 100;
      }
      f_percentage = percent / 100;
      p_charge = (amount + fixed) / (1 - f_percentage);
      return p_charge.toFixed(2) * 100;
    };
    /**
		 *
		 * @param options
		 */
    this.transfer = function (options) {
      cfpLoadingBar.start();
      // Make payment
      var handler = window.StripeCheckout.configure({
          key: options.stripe_pk,
          image: options.image,
          locale: options.locale || 'auto',
          token: function (token) {
            // Go and get redirect link
            HttpService.post('/pay/' + options.checkout_token + '/stripe', { stripe_token: token.id }, responseSuccess, responseError);
            function responseSuccess() {
              cfpLoadingBar.complete();  // Wait for submit application by socket
            }
            function responseError(error) {
              cfpLoadingBar.complete();
              if (error.message) {
                Notify.error(error.message);
              }
            }
          },
          email: options.email,
          opened: function () {
            cfpLoadingBar.complete();
          },
          closed: function () {
            cfpLoadingBar.complete();
            // Fix keybord for mobile
            if (window.cordova && _.isFunction(window.Keyboard)) {
              window.Keyboard.hide();
            }
          }
        });
      // Open Checkout with further options:
      handler.open({
        name: options.name,
        description: options.description,
        amount: options.amount,
        currency: options.currency
      });
    };
  }
  function DwollaPayService(HttpService, cfpLoadingBar, Notify) {
    /**
		 *
		 * @param details
		 * @param callback
		 */
    this.transfer = function (details, callback) {
      HttpService.post('/pay/' + details.checkout_token + '/dwolla', {
        source_id: details.source_id,
        checkout_token: details.checkout_token
      }, responseSuccess, responseError);
      function responseSuccess(resp) {
        cfpLoadingBar.complete();
        if (callback) {
          callback(null, resp);
        }
      }
      function responseError(error) {
        cfpLoadingBar.complete();
        if (error.message) {
          Notify.error(error.message);
        }
        if (callback) {
          callback(error, null);
        }
      }
    };
  }
  function RefreshSelectOptionsService(HttpService, $q, APP_CONFIG) {
    var service = {
        $userOptions: [],
        init: init,
        refreshPropertyOptions: refreshPropertyOptions,
        refreshUnitOptions: refreshUnitOptions,
        refreshLeaseOptions: refreshLeaseOptions,
        refreshProfessionalOptions: refreshProfessionalOptions,
        refreshClientOptions: refreshClientOptions,
        refreshTenantOptions: refreshTenantOptions,
        refreshEquipmentOptions: refreshEquipmentOptions,
        refreshTenantOptionsForNotices: refreshTenantOptionsForNotices,
        refreshUserOptions: refreshUserOptions,
        refreshAccountOptions: refreshAccountOptions
      };
    var CONST_TRANSACTION_ACCOUNT = APP_CONFIG.constants.transaction_account;
    // default service params
    service.optionsParams = {
      showRefreshPropertyUrl: true,
      refreshPropertyUrl: true,
      showRefreshUnitUrl: true,
      refreshUnitUrl: true,
      showRefreshLeaseUrl: true,
      refreshLeaseUrl: true,
      showRefreshClientUrl: true,
      refreshClientUrl: true,
      showRefreshProfessionalUrl: true,
      refreshProfessionalUrl: true,
      showRefreshTenantUrl: true,
      refreshTenantUrl: true,
      showRefreshUserUrl: true,
      refreshUserUrl: true,
      showRefreshAccountUrl: true,
      refreshAccountUrl: true
    };
    return service;
    /**
		 * Reset service params to default
		 */
    function init() {
      this.optionsParams = {
        showRefreshPropertyUrl: true,
        refreshPropertyUrl: true,
        showRefreshUnitUrl: true,
        refreshUnitUrl: true,
        showRefreshLeaseUrl: true,
        refreshLeaseUrl: true,
        showRefreshClientUrl: true,
        refreshClientUrl: true,
        showRefreshProfessionalUrl: true,
        refreshProfessionalUrl: true,
        showRefreshTenantUrl: true,
        refreshTenantUrl: true,
        showRefreshUserUrl: true,
        refreshUserUrl: true,
        showRefreshAccountUrl: true,
        refreshAccountUrl: true
      };
    }
    /**
		 * Refresh property options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshPropertyOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshPropertyUrl) {
        var params = { q: q };
        if (model.item.property_id) {
          params.id = model.item.property_id;
        }
        if (model.item.item_category === 'App\\PropertyUnitLease') {
          params.lease = model.item.item_id;
        }
        if (model.item.item_category === 'App\\PropertyUnit') {
          params.unit = model.item.item_id;
        }
        if (_.get(model.item, 'client.id') && _.get(model.item, 'client.role') === 'owner') {
          params.owner = _.get(model.item, 'client.id');
        }
        var promise = _asyncRequest(HttpService, '/landlord/property/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshPropertyUrl) {
            self.optionsParams.showRefreshPropertyUrl = false;
          }
          self.optionsParams.refreshPropertyUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list);
          }
        });
      }
    }
    /**
		 * Refresh unit options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshUnitOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshUnitUrl && model.item && model.item.property_id) {
        var params = {
            property_id: model.item.property_id,
            q: q
          };
        if (model.item.unit_id) {
          params.id = model.item.unit_id;
        }
        if (model.item.item_category === 'App\\PropertyUnitLease') {
          params.lease = model.item.item_id;
        }
        var promise = _asyncRequest(HttpService, '/landlord/property/unitsForFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshUnitUrl) {
            self.optionsParams.showRefreshUnitUrl = false;
          }
          self.optionsParams.refreshUnitUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list);
          }
        });
      }
    }
    /**
		 * Refresh lease options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshLeaseOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshLeaseUrl && model.item && model.item.client) {
        var params = {
            user_id: model.item.client.id,
            q: q
          };
        if (model.item.item_category === 'App\\PropertyUnitLease') {
          params.lease = model.item.item_id;
        }
        if (!_.isUndefined(model.item.isNotice)) {
          params.isNotice = model.item.isNotice;
        }
        var promise = _asyncRequest(HttpService, '/landlord/property/leaseForFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshLeaseUrl) {
            self.optionsParams.showRefreshLeaseUrl = false;
          }
          self.optionsParams.refreshLeaseUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list);
          }
        });
      }
    }
    /**
		 * Refresh professional options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshProfessionalOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshProfessionalUrl) {
        var params = { q: q };
        if (model.item.unit_id) {
          params.id = model.item.unit_id;
        }
        if (!_.isUndefined(model.item.landlord_professional_id)) {
          params.id = model.item.landlord_professional_id;
        }
        // if is workorder model
        if (!_.isUndefined(model.item.isWorkorder)) {
          params.is_workorder = true;
          params.id = model.item.id;
        }
        var promise = _asyncRequest(HttpService, '/landlord/professionals/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshProfessionalUrl) {
            self.optionsParams.showRefreshProfessionalUrl = false;
          }
          self.optionsParams.refreshProfessionalUrl = false;
          if (_.isFunction(callbackFn)) {
            if (!_.isUndefined(obj.data.professionals)) {
              callbackFn(obj.data);
            } else {
              callbackFn(obj.data.list);
            }
          }
        });
      }
    }
    /**
		 * Refresh client options for professional
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshClientOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshClientUrl) {
        var params = { q: q };
        if (!_.isUndefined(model.item.landlord_professional_id)) {
          params.id = model.item.landlord_professional_id;
        }
        var promise = _asyncRequest(HttpService, '/professional/contacts/forSelect', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshClientUrl) {
            self.optionsParams.showRefreshClientUrl = false;
          }
          self.optionsParams.refreshClientUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list);
          }
        });
      }
    }
    /**
		 * Refresh tenant options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshTenantOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshTenantUrl && model.item && model.item.property_id) {
        var params = {
            property_id: model.item.property_id,
            workorder_id: model.item.id,
            q: q
          };
        if (model.item.unit_id) {
          params.id = model.item.unit_id;
          params.unit_id = model.item.unit_id;
        }
        var promise = _asyncRequest(HttpService, '/landlord/tenants/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshTenantUrl) {
            self.optionsParams.showRefreshTenantUrl = false;
          }
          self.optionsParams.refreshTenantUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list, obj.data.details);
          }
        });
      }
    }
    /**
		 * Refresh equipment options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshEquipmentOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshEquipmentUrl && model.item) {
        var params = { property_id: model.item.property_id };
        if (model.item.unit_id) {
          params.unit_id = model.item.unit_id;
        }
        var promise = _asyncRequest(HttpService, '/landlord/equipments/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshEquipmentUrl) {
            self.optionsParams.showRefreshEquipmentUrl = false;
          }
          self.optionsParams.refreshEquipmentUrl = false;
          if (_.isFunction(callbackFn)) {
            _.forEach(obj.data.list, function (equipment, id) {
              var key = equipment.id;
              obj.data.list[id] = {
                key: key,
                value: equipment
              };
            });
            callbackFn(obj.data.list, obj.data.details);
          }
        });
      }
    }
    /**
		 * Refresh tenant options for notices
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshTenantOptionsForNotices(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshTenantUrl && model) {
        var params = { q: q };
        if (!_.isUndefined(model.status)) {
          params.status = model.status;
        }
        if (!_.isUndefined(model.connection)) {
          params.connection = model.connection;
        }
        if (!_.isUndefined(model.withEmail)) {
          params.withEmail = model.withEmail;
        }
        var promise = _asyncRequest(HttpService, '/landlord/tenants/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshTenantUrl) {
            self.optionsParams.showRefreshTenantUrl = false;
          }
          self.optionsParams.refreshTenantUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list, obj.data.details);
          }
        });
      }
    }
    /**
		 * Refresh user options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshUserOptions(q, model, callbackFn) {
      var self = this, account = _.get(model.item, 'account') ? parseInt(_.get(model.item, 'account')) : null, accountsForOwners = [
          CONST_TRANSACTION_ACCOUNT.i_owner,
          CONST_TRANSACTION_ACCOUNT.e_owner,
          CONST_TRANSACTION_ACCOUNT.e_management_fee
        ];
      if (self.optionsParams.showRefreshUserUrl) {
        var params = { q: q };
        if (model.item.client && model.item.client.id) {
          params.id = model.item.client.id;
        } else if (model.item.params_client_id) {
          params.id = model.item.params_client_id;
        }
        // exclude role Distribution/Contribution & Management Fee
        if (!_.includes(accountsForOwners, account)) {
          params.exclude_role = 'owner';
        }
        if (_.includes(accountsForOwners, account)) {
          params.role = 'owner';
        }
        if (_.get(model.item, 'payer.role') === 'tenant' || model.item.category === 'liability') {
          params.role = 'tenant';
        }
        var promise = _asyncRequest(HttpService, '/landlord/transactions/usersForSelect', params, $q);
        promise.then(function (obj) {
          service.$userOptions = obj.data.list;
          if (!obj.data.showSearch && self.optionsParams.refreshUserUrl) {
            self.optionsParams.showRefreshUserUrl = false;
          }
          self.optionsParams.refreshUserUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list, obj.data.details);
          }
        });
      }
    }
    /**
		 * Refresh account options
		 * @param q
		 * @param model
		 * @param callbackFn
		 */
    function refreshAccountOptions(q, model, callbackFn) {
      var self = this;
      if (self.optionsParams.showRefreshAccountUrl) {
        var params = { q: q };
        if (model.item && !_.isUndefined(model.item.account_id)) {
          params.id = model.item.account_id;
        }
        if (model.item.category) {
          params.category = model.item.category;
          switch (model.item.category) {
          case 'income':
          case 'expense':
            params.category = model.item.category;
            if (_.get(model, 'item.account.type') === APP_CONFIG.constants.transaction_account_type.liability) {
              if (model.item.account_id === APP_CONFIG.constants.transaction_account.l_credits) {
                params.category = 'credits';
              } else {
                params.category = 'liability';
              }
            }
            break;
          }
        }
        // For credits
        if (params.id && parseInt(params.id) === APP_CONFIG.constants.transaction_account.l_credits) {
          params.category = 'credits';
        }
        var promise = _asyncRequest(HttpService, '/landlord/transactions/accounts/forFilter', params, $q);
        promise.then(function (obj) {
          if (!obj.data.showSearch && self.optionsParams.refreshAccountUrl) {
            self.optionsParams.showRefreshAccountUrl = false;
          }
          self.optionsParams.refreshAccountUrl = false;
          if (_.isFunction(callbackFn)) {
            callbackFn(obj.data.list, obj.data.details);
          }
        });
      }
    }
    /**
		 * Async request
		 * @param HttpService
		 * @param url
		 * @param params
		 * @param $q
		 * @returns {promise|Promise|f|*}
		 */
    function _asyncRequest(HttpService, url, params, $q) {
      var deferred = $q.defer();
      HttpService.getWParams(url, { params: params }, function (resp) {
        deferred.resolve({ data: resp });
      }, function () {
        deferred.reject();
      });
      return deferred.promise;
    }
  }
  function ConnectionService(HttpService, Notify) {
    var service = {
        sendInvite: sendInvite,
        resendInvite: resendInvite,
        accept: accept,
        decline: decline,
        removeConnection: removeConnection
      };
    return service;
    /**
		 *
		 * @param model
		 * @param role
		 */
    function sendInvite(model, role) {
      Notify.confirm(function (email) {
        if (email) {
          HttpService.post('/landlord/connections/request', {
            id: model.item.id,
            email: email
          }, function () {
            model.item.email = email;
            model.item.status = 1;
            Notify.success(Lang.get(role + 's.invites.send.notification.success', { name: model.item.name }));
          }, function (message) {
            if (message.errors && message.errors.email) {
              Notify.error(message.errors.email[0]);
            } else if (message.email) {
              Notify.error(message.email[0]);
              service.sendInvite(model, role);
            } else if (message.message) {
              Notify.error(message.message);
            }
          });
        } else {
          Notify.error(Lang.get('notify_actions.landlord.invites.empty_email'));
          service.sendInvite(model, role);
        }
      }, Lang.get(role + 's.connect_service_pro'), '/app/modules/shared/pages/connection/send-invite-dialog.html', {
        email: model.item.email,
        role: role + 's'
      });
    }
    /**
		 *
		 * @param model
		 * @param role
		 */
    function resendInvite(model, role) {
      Notify.confirm(function () {
        HttpService.post('/landlord/connections/request/resend', { id: model.item.id }, function () {
          model.item.status = 1;
          model.item.canResendInvite = false;
          Notify.success(Lang.get(role + 's.invites.resend.notification.success', { name: model.item.name }));
        }, function (message) {
          Notify.error(message.error);
        });
      });
    }
    /**
		 *
		 * @param id
		 * @param role
		 * @param successCallBack
		 * @param errorCallBack
		 */
    function accept(id, role, successCallBack, errorCallBack) {
      Notify.confirm(function () {
        HttpService.post('/' + role + '/connections/' + id + '/accept', {}, function () {
          if (_.isFunction(successCallBack)) {
            successCallBack();
          }
        }, function (message) {
          if (_.isFunction(errorCallBack)) {
            errorCallBack();
          } else {
            Notify.error(message.error);
          }
        });
      });
    }
    /**
		 *
		 * @param id
		 * @param role
		 * @param successCallBack
		 * @param errorCallBack
		 */
    function decline(id, role, successCallBack, errorCallBack) {
      Notify.confirm(function () {
        HttpService.post('/' + role + '/connections/' + id + '/decline', {}, function () {
          if (_.isFunction(successCallBack)) {
            successCallBack();
          }
        }, function (message) {
          if (_.isFunction(errorCallBack)) {
            errorCallBack();
          } else {
            Notify.error(message.error);
          }
        });
      });
    }
    /**
		 *
		 * @param id
		 * @param role
		 * @param successCallBack
		 * @param errorCallBack
		 */
    function removeConnection(id, role, successCallBack, errorCallBack) {
      Notify.confirm(function () {
        HttpService.post('/landlord/connections/' + id + '/remove', {}, function () {
          if (_.isFunction(successCallBack)) {
            successCallBack();
          }
        }, function (message) {
          if (_.isFunction(errorCallBack)) {
            errorCallBack();
          } else {
            Notify.error(message.error);
          }
        });
      }, Lang.get(role + 's.invites.disconnect.description'));
    }
  }
  function ImportService($filter, $state, array_util, HttpService, Notify, cfpLoadingBar) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        init: init,
        validate: validate
      };
    return service;
    /**
		 * @param model
		 */
    function init(model) {
      var self = this;
      model.importData = [];
      model.step = 'upload';
      model.deleteAll = 0;
      model.headers = {
        list: $filter('trans_as_array')('import.types.' + model.type + '.headers'),
        required: Lang.get('import.types.' + model.type + '.required'),
        mapping: [],
        mapping_check: [],
        edit_mapping: [],
        errors: [],
        unitsHeaders: [],
        propertyHeaders: [],
        propertyHeadersForComplete: $filter('trans_as_array')('import.types.' + model.type + '.property_headers'),
        isContactsDetailKeys: isContactsDetailKeysFn,
        isContactsCommonKeys: isContactsCommonKeysFn
      };
      model.headers.errors = self.validate(model, []);
      model.changeHeader = changeHeaderFn;
      model.helpers = {
        back: function (step) {
          // cleaned errors
          model.headers.errors = [];
          switch (step) {
          case 'mapping':
            // cleaned headers
            model.headers.mapping = [];
            model.headers.mapping_check = [];
            model.headers.edit_mapping = [];
            model.step = 'upload';
            model.headers.errors = self.validate(model, []);
            break;
          case 'edit_mapping':
            HttpService.post('/import/mapping', {
              type: model.type,
              fileExtension: model.fileExtension
            }, function (resp) {
              model.importData = resp.data;
              model.step = resp.step;
              model.countRows = resp.countRows;
              _requiredFields();
            }, function (resp) {
              Notify.error(resp.message || Lang.get('notify_actions.ooops'));
            });
            model.deleteAll = 0;
            break;
          default:
            model.step = 'upload';
          }
        },
        complete: function () {
          var data = {
              type: model.type,
              headers: model.headers.mapping,
              data: [],
              with_header: model.importData[0] && model.importData[0].isHeader || 0,
              step: model.step,
              fileExtension: model.fileExtension
            };
          model.headers.errors = [];
          if (model.step === 'mapping') {
            //start loader
            cfpLoadingBar.start();
            //validate fields
            _requiredFields();
            //finish loader
            cfpLoadingBar.complete();
            if (!_.isUndefined(model.headers.errors) && model.headers.errors.length) {
              return;
            }
          }
          if (model.step === 'edit_mapping') {
            data.data = model.importData;
          }
          HttpService.post('/import/store', data, function (resp) {
            if (resp.step === 'edit_mapping') {
              model.categories = resp.data.categories;
              model.countError = resp.data.countError || 0;
              model.headers.mapping = resp.data.headers;
              model.headers.edit_mapping = resp.data.headers;
              model.headers.unitHeaders = resp.data.unitHeaders;
              model.headers.propertyHeaders = resp.data.propertyHeaders;
            }
            model.importData = resp.data.data;
            model.countSuccess = resp.data.countSuccess || 0;
            model.step = resp.step;
          }, function (resp) {
            Notify.error(resp.message || Lang.get('notify_actions.ooops'));
          });
        },
        removeItem: function () {
          Notify.confirm(function () {
            //start loader
            cfpLoadingBar.start();
            var data = _.clone(model.importData);
            _.forEach(data, function (item) {
              var index = _.indexOf(model.importData, item);
              if (!_.isUndefined(item) && item.errors && item.isDelete) {
                model.importData.splice(parseInt(index), 1);
                if (model.type === 'property') {
                  model.countError = model.countError - item.units.length;
                } else {
                  --model.countError < 0 ? 0 : model.countError;
                }
              }
              var oldUnits = _.clone(item.units);
              if (!_.isUndefined(item) && item.errors && oldUnits && !item.isDelete) {
                _.forEach(oldUnits, function (unit) {
                  if (!_.isUndefined(unit) && unit.isDelete) {
                    var units = model.importData[index].units, unitIndex = _.indexOf(units, unit);
                    units.splice(parseInt(unitIndex), 1);
                    --model.countError;
                  }
                });
              }
            });
            model.helpers.showRemoveButton();
            //finish loader
            cfpLoadingBar.complete();
          }, Lang.get('import.mapping.delete_confirm'));
        },
        compliteViewBtn: function () {
          if (model.type === 'tenants') {
            return $state.go('tenants.without_lease_list');
          }
          return $state.go(model.type + '.list');
        },
        checkAll: function (data) {
          //start loader
          cfpLoadingBar.start();
          _.forEach(data, function (item, key) {
            if (item.errors) {
              model.importData[key].isDelete = model.deleteAll ? 0 : 1;
              if (model.type === 'property' && item.units) {
                _.forEach(item.units, function (unit) {
                  unit.isDelete = model.deleteAll ? 0 : 1;
                });
              }
            }
          });
          model.deleteAll = model.deleteAll ? 0 : 1;
          //finish loader
          cfpLoadingBar.complete();
        },
        changeIsDelete: function (key) {
          model.deleteAll = 0;
          if (model.type === 'property') {
            if (model.importData[key]) {
              _.forEach(model.importData[key].units, function (unit) {
                unit.isDelete = model.importData[key].isDelete;
              });
            }
          }
        },
        showRemoveButton: function () {
          var isMark = 0;
          _.forEach(model.importData, function (item) {
            if (item.errors && item.isDelete) {
              isMark += item.isDelete;
            }
            if (item.errors && item.units && model.type === 'property') {
              _.forEach(item.units, function (unit) {
                if (unit.isDelete) {
                  isMark += unit.isDelete;
                }
              });
            }
          });
          return isMark > 0;
        },
        showOrHideChild: function (key) {
          if (_.isUndefined(model.importData[key])) {
            return;
          }
          model.importData[key].isShow = model.importData[key].isShow ? 0 : 1;
        },
        propertyModes: array_util.partition($filter('trans_as_array')('import.mapping.property_modes'))
      };
      /**
			 * @param key
			 * @param index
			 */
      function changeHeaderFn(key, index) {
        var newIndex = _.findIndex(model.headers.mapping_check, { key: key });
        if (newIndex === -1 || _.isUndefined(key)) {
          model.headers.mapping[index].key = key;
          model.headers.mapping_check[index] = { key: key };
          _requiredFields();
          return;
        }
        var lastKey = model.headers.mapping_check[index] ? model.headers.mapping_check[index].key : null;
        if (newIndex < 0) {
          return;
        }
        if (model.headers.mapping[newIndex].key) {
          model.headers.mapping[newIndex].key = lastKey;
        }
        if (model.headers.mapping_check[newIndex].key) {
          model.headers.mapping_check[newIndex].key = lastKey;
        }
        model.headers.mapping[index].key = key;
        if (model.headers.mapping_check[index]) {
          model.headers.mapping_check[index].key = key;
        } else {
          model.headers.mapping_check[index] = { key: key };
        }
        _requiredFields();
      }
      function _requiredFields() {
        var requiredField = [];
        model.headers.edit_mapping = _.compact(_.clone(model.headers.mapping));
        _.forEach(model.headers.mapping, function (item) {
          if (item) {
            var isRequired = Lang.has('import.types.' + model.type + '.required.' + item.key);
            if (!_.isUndefined(isRequired)) {
              requiredField.push(item.key);
            }
          }
        });
        //validate fields
        model.headers.errors = self.validate(model, requiredField);
      }
      /**
			 * @param header
			 * @returns {boolean}
			 */
      function isContactsDetailKeysFn(header) {
        return header.key !== 'category_id' && header.key !== 'gender' && (header.key === 'account_number' || header.key === 'routing_number' || header.key === 'tax_id');
      }
      /**
			 * @param header
			 * @returns {boolean}
			 */
      function isContactsCommonKeysFn(header) {
        return header.key !== 'category_id' && header.key !== 'account_number' && header.key !== 'routing_number' && header.key !== 'tax_id' && header.key !== 'gender';
      }
    }
    /**
		 * @param model
		 * @param requiredField
		 * @returns {Array}
		 */
    function validate(model, requiredField) {
      var differenceErrors = [], field = null, requiredFieldInvert = _.invert(_.clone(requiredField));
      //transform array data, if field is undefined
      _.forEach(requiredField, function (item, index) {
        if (_.isUndefined(item)) {
          requiredField.splice(index, 1);
        }
      });
      function _addErrors(key) {
        field = Lang.get('import.types.' + model.type + '.headers.' + key);
        var required = Lang.get('import.errors.required', { field: field });
        if (_.indexOf(differenceErrors, required) === -1) {
          differenceErrors.push(required);
        }
      }
      function _getDifferenceErrors() {
        differenceErrors = _.difference(model.headers.required, requiredField);
        differenceErrors = differenceErrors.reduce(function (acc, item) {
          field = Lang.get('import.types.' + model.type + '.headers.' + item);
          acc.push(Lang.get('import.errors.required', { field: field }));
          return acc;
        }, []);
      }
      function _validateEmails() {
        if (requiredFieldInvert['email_2'] && requiredFieldInvert['email_3'] && !requiredFieldInvert['email']) {
          _addErrors('email');
        }
        if (!requiredFieldInvert['email_3'] && requiredFieldInvert['email_2'] && !requiredFieldInvert['email']) {
          _addErrors('email');
        }
        if (requiredFieldInvert['email_3'] && !requiredFieldInvert['email_2'] && !requiredFieldInvert['email']) {
          _addErrors('email');
        }
      }
      switch (model.type) {
      case 'tenants':
      case 'owners':
      case 'contacts':
        if (!requiredField.length || _.isEmpty(_.difference(model.headers.required, _.difference(model.headers.required, requiredField)))) {
          _getDifferenceErrors();
          if (model.type === 'tenants') {
            _validateEmails();
          }
          break;
        }
        //draw error, if selected company field
        if (!requiredFieldInvert['company']) {
          //draw error, if selected first name field
          if (requiredFieldInvert['firstName'] && !requiredFieldInvert['lastName']) {
            _addErrors('lastName');
          }
          //draw error, if selected last name field
          if (requiredFieldInvert['lastName'] && !requiredFieldInvert['firstName']) {
            _addErrors('firstName');
          }
        }
        if (model.type === 'tenants') {
          _validateEmails();
        }
        break;
      case 'professionals':
      case 'providers':
        if (!requiredField.length || _.isEmpty(_.difference(model.headers.required, _.difference(model.headers.required, requiredField)))) {
          _getDifferenceErrors();
          break;
        }
        //draw error, if selected company and category id fields
        if (requiredFieldInvert['company']) {
          if (requiredFieldInvert['category_id']) {
            differenceErrors = [];
            return;
          } else {
            _addErrors('category_id');
            break;
          }
        }
        if (requiredFieldInvert['category_id'] && !requiredFieldInvert['firstName']) {
          _addErrors('firstName');
        }
        if (requiredFieldInvert['category_id'] && !requiredFieldInvert['lastName']) {
          _addErrors('lastName');
        }
        //draw error, if selected first name field
        if (requiredFieldInvert['firstName'] && !requiredFieldInvert['lastName'] && !requiredFieldInvert['category_id']) {
          _addErrors('lastName');
        }
        //draw error, if selected last name field
        if (requiredFieldInvert['lastName'] && !requiredFieldInvert['firstName'] && !requiredFieldInvert['category_id']) {
          _addErrors('firstName');
        }
        //draw error, if selected category id field and type is professional
        if (!requiredFieldInvert['category_id']) {
          _addErrors('category_id');
        }
        break;
      case 'property':
        _getDifferenceErrors();
        break;
      }
      return differenceErrors;
    }
  }
  function UserPaidService($state, $rootScope, HttpService, Notify) {
    var user = $rootScope.auth;
    // noinspection UnnecessaryLocalVariableJS
    var service = { init: init };
    return service;
    /**
		 *
		 * @param vm
		 */
    function init(vm) {
      switch (vm.service) {
      case '123movers':
        _movers(vm);
        break;
      default:
        return;
      }
    }
    /**
		 *
		 * @param vm
		 * @private
		 */
    function _movers(vm) {
      vm.data = {
        service: vm.service,
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone: user.phone,
        zip_to: !_.isUndefined(vm.lease) ? vm.lease.zip : null,
        date: !_.isUndefined(vm.lease) ? vm.lease.rent_from : null,
        parent_id: !_.isUndefined(vm.lease_id) ? vm.lease_id : 0,
        step: !_.isUndefined(vm.item) ? vm.item.step : 'moving_details',
        requestid: null,
        term: null,
        weight: _getMoversWeight()
      };
      vm.save = function () {
        HttpService.post('/paid_service', vm.data, function (response) {
          vm.errors = null;
          vm.data = response;
          if (response.step === 'finish') {
            vm.showForm = false;
          }
        }, function (errors) {
          if (errors.step === 'finish') {
            vm.showForm = false;
            return;
          }
          if (errors.step) {
            vm.data.step = errors.step;
          }
          vm.errors = errors.message;
        });
      };
      vm.skip = function () {
        HttpService.post('/paid_service/skip', {
          name: vm.service,
          parent_id: vm.lease_id
        }, function () {
          vm.showForm = false;
          $state.go('rentals.list');
        }, function (errors) {
          Notify.error(errors.message);
        });
      };
      function _getMoversWeight() {
        if (!vm.lease || !vm.lease.propertyUnit) {
          return null;
        }
        var bedroom = vm.lease.propertyUnit.bedrooms, moveWeightValue = Lang.get('services.123movers.moveWeightValue');
        if (!moveWeightValue[bedroom]) {
          return null;
        }
        return moveWeightValue[bedroom];
      }
    }
  }
  function TenantConnectionService(HttpService, Notify) {
    this.$item = [];
    /**
		 * Load data
		 */
    this.loadData = function () {
      var self = this;
      HttpService.get('/tenant/contacts', function (response) {
        self.$item = response.invites;
      }, function () {
        Notify.error(Lang.get('notify_actions.ooops'));
      });
    };
  }
  function SessionExpiresService($timeout, APP_CONFIG) {
    var service = {
        setTimer: setTimer,
        active: setActive,
        hide: hide
      };
    var timeout, active = false;
    return service;
    /**
		 * SetTimer
		 */
    function setTimer() {
      $timeout.cancel(timeout);
      // Convert minutes to ms
      var warnTime = APP_CONFIG.session_lifetime * 60 * 1000;
      timeout = $timeout(function () {
        angular.element('body').addClass('session-expires');
        active = true;
      }, warnTime);
    }
    /**
		 * SetActive
		 * @returns {boolean}
		 */
    function setActive() {
      return active;
    }
    /**
		 * Hide
		 */
    function hide() {
      active = false;
    }
  }
  function DwollaPermissionsService($rootScope, SubscriptionService, APP_CONFIG) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        hasAccess: hasAccess,
        hasActiveCustomer: hasActiveCustomer,
        requireSubscription: requireSubscription,
        requireVerification: requireVerification,
        requireTenantCharge: requireTenantCharge
      };
    return service;
    /**
		 * Check for Landlords: allowed US only or already created
		 * Other roles: always allow
		 * @returns {boolean}
		 */
    function hasAccess() {
      if ($rootScope.auth && $rootScope.auth._isLandlord()) {
        if (_.get($rootScope.auth, 'payments.gateway.dwolla.customer')) {
          return true;
        }
        if ($rootScope.auth.country && $rootScope.auth.country !== 'US') {
          return false;
        }
      }
      return true;
    }
    /**
		 * Check for created customer
		 * @returns {boolean}
		 */
    function hasActiveCustomer() {
      return _.get($rootScope.auth, 'payments.gateway.dwolla.customer') && _.includes([
        APP_CONFIG.constants.dwolla.customer.status.verified,
        APP_CONFIG.constants.dwolla.customer.status.unverified
      ], _.get($rootScope.auth, 'payments.gateway.dwolla.status'));
    }
    /**
		 * For Landlords only
		 * @returns {boolean}
		 */
    function requireSubscription() {
      return $rootScope.auth && $rootScope.auth._isLandlord() && !SubscriptionService.pkg.userHasAccess('tc_payments');
    }
    /**
		 * For Landlords, ServicePro and Owners
		 * @returns {boolean}
		 */
    function requireVerification() {
      var dwolla = $rootScope.auth && $rootScope.auth.payments.gateway.dwolla, verification = $rootScope.auth && $rootScope.auth.verification;
      if ($rootScope.auth._role() === 'tenant') {
        return false;
      }
      // If user already setup account
      if (dwolla.customer || dwolla.enabled) {
        return false;
      }
      // for old users
      if (!verification && dwolla.verification_paid) {
        return false;
      }
      return !verification || !verification.verified_at;
    }
    /**
		 * For Tenants only
		 * @returns {boolean}
		 */
    function requireTenantCharge() {
      var dwolla = $rootScope.auth.payments.gateway.dwolla;
      if ($rootScope.auth._role() !== 'tenant') {
        return false;
      }
      return !dwolla.verification_paid;
    }
  }
  function DotsService() {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        setDots: setDots,
        getDots: getDots,
        updateDots: updateDots,
        clearDots: clearDots
      }, dotsArray = {};
    return service;
    /**
		 *
		 * @param index
		 * @returns {any[]}
		 */
    function setDots(index) {
      dotsArray[index] = false;
    }
    /**
		 *
		 * @returns {*}
		 */
    function getDots() {
      return dotsArray;
    }
    /**
		 *
		 * @param dots
		 */
    function updateDots(dots) {
      dotsArray = dots;
    }
    /**
		 * clear dots
		 */
    function clearDots() {
      dotsArray = {};
    }
  }
}());