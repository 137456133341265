(function () {
  'use strict';
  var confirmComponent = {
      templateUrl: '/app/modules/auth/confirm/confirm.html',
      controller: [
        '$location',
        'BreadCrumbsService',
        'HttpService',
        'AuthDataService',
        function ($location, BreadCrumbsService, HttpService, AuthDataService) {
          'ngInject';
          var vm = this;
          BreadCrumbsService.addCrumb(Lang.get('auth.breadcrumbs.email_confirmation'));
          vm.item = _.clone($location.search());
          vm.item.email = decodeURIComponent(vm.item.email);
          vm.item.type = 1;
          // ConfirmationRepository::TYPE_EMAIL
          // Check if already set Confirmation Code, if set send to server.
          if (vm.item.confirmation_code && vm.item.confirmation_code.length) {
            sendConfirm();
          }
          // Action send Confirm Code.
          vm.sendConfirm = sendConfirm;
          vm.isComplete = isComplete;
          /**
			 * Check status is_complete all inputs
			 * @param status
			 */
          function isComplete(status) {
            vm.item.is_complete = status;
            if (status) {
              sendConfirm();
            }
          }
          /**
			 * Post Confirm code
			 */
          function sendConfirm() {
            HttpService.post('/auth/signup_confirm', vm.item, function (resp) {
              BreadCrumbsService.clear();
              delete $location.search().email;
              AuthDataService.loadModule(resp);
              if (_.has(vm.item, 'subscription') && _.has(Lang.get('auth.plans_with_annual_for_ll'), vm.item.subscription)) {
                delete $location.search().subscription;
                AuthDataService.redirectToSubscriptionPay(vm.item.subscription);
              }
            }, function (resp) {
              vm.errors = _.get(resp, 'errors');
            });
          }
        }
      ]
    };
  angular.module('app.confirm.component', []).component('confirmComponent', confirmComponent);
  ;
}());