(function () {
  'use strict';
  var signupComponent = {
      bindings: {},
      templateUrl: '/app/modules/auth/signup/signup.html',
      controller: [
        '$rootScope',
        '$scope',
        '$state',
        '$location',
        'HttpService',
        'Notify',
        'AuthInviteService',
        'BreadCrumbsService',
        'AuthDataService',
        'ng_util',
        'SocialConnectService',
        'browserInfo',
        function ($rootScope, $scope, $state, $location, HttpService, Notify, AuthInviteService, BreadCrumbsService, AuthDataService, ng_util, SocialConnectService, browserInfo) {
          'ngInject';
          // Init social profile
          var vm = this, locationParams = _.clone($location.search()), socializerProfile = null;
          vm.isMobile = browserInfo.isMobile();
          BreadCrumbsService.addCrumb(Lang.get('auth.sign_up'));
          // social errors
          if (locationParams.authError) {
            Notify.error(Lang.get('auth.auth_errors.' + locationParams.authError));
          }
          vm.item = {
            role: locationParams.role || 0,
            email: locationParams.email
          };
          vm.signup = signup;
          vm.socialConnect = SocialConnectService.open;
          vm.sendAnalitics = sendAnalitics;
          vm.sendAnaliticsFacebook = sendAnaliticsFacebook;
          /**
			 * Action send Login Form.
			 */
          function signup() {
            var zones = Lang.get('form.time_zone_in_minute'), zones_inverted = _.invert(zones), time_of_tz = moment().utcOffset();
            if (zones_inverted[time_of_tz]) {
              vm.item.timeZone = zones_inverted[time_of_tz];
            }
            if (socializerProfile) {
              vm.item.socializer_profile = socializerProfile;
            }
            HttpService.post('/auth/signup', vm.item, function (resp) {
              // Send Analytics Sign info.
              sendAnalitics('signup');
              if (resp.isConfirmed) {
                BreadCrumbsService.clear();
                delete $location.search().email;
                AuthDataService.loadModule(resp);
              } else {
                // Go to page confirm code.
                $state.go('auth.confirm', { email: encodeURIComponent(resp.email) });
              }
            }, function (resp) {
              if (!resp) {
                return Notify.error(Lang.get('notify_actions.ooops'));
              }
              if (_.has(resp, 'message')) {
                Notify.error(resp.message);
              }
            });
          }
          $rootScope.$on('auth:signup_form', function (event, data) {
            if (data.profile) {
              ng_util.safeApply($rootScope, function () {
                socializerProfile = data.profile;
                // Send analitics
                if (socializerProfile.provider === 'facebook')
                  sendAnalitics('fbsignup');
                if (socializerProfile.provider === 'google')
                  sendAnalitics('googsignup');
                if (socializerProfile.provider === 'linkedin')
                  sendAnalitics('linsignup');
                vm.item.firstName = socializerProfile.first_name;
                vm.item.lastName = socializerProfile.last_name;
                vm.item.email = socializerProfile.email;
                vm.item.password = '';
                angular.element('input[model="item.password"]').focus();
              });
            } else if (data.user && data.auth_data) {
              BreadCrumbsService.clear();
              AuthDataService.loadModule(data);
            }
          });
          // Autofill input after response invite
          $rootScope.$on('auth:invite', function () {
            var invite = AuthInviteService.getInvite();
            vm.item = _.extend(vm.item, invite);
          });
          /**
			 * Action send analytics info by registration.
			 * @param type
			 */
          function sendAnalitics(type) {
            if (typeof ga !== 'undefined') {
              ga('send', 'event', 'button', 'click', type);
            }
          }
          function sendAnaliticsFacebook(type) {
            if (typeof fbq !== 'undefined') {
              fbq('track', type);
            }
          }
        }
      ]
    };
  angular.module('app.signup.component', []).component('signupComponent', signupComponent);
  ;
}());