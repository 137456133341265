(function () {
  'use strict';
  /**
	 * @namespace dwollaCustomerChargeComponent
	 * @desc Customer charge for tenant
	 */
  var dwollaCustomerChargeComponent = {
      bindings: {},
      templateUrl: '/app/modules/shared/pages/dwolla/dwollaCustomerCharge/view.html',
      controller: [
        '$rootScope',
        '$state',
        '$element',
        'cfpLoadingBar',
        'HttpService',
        'Notify',
        'AuthDataService',
        'BreadCrumbsService',
        function ($rootScope, $state, $element, cfpLoadingBar, HttpService, Notify, AuthDataService, BreadCrumbsService) {
          'ngInject';
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.payments_view.label'), 'settings.payments_view');
          BreadCrumbsService.addCrumb(Lang.get('settings.payments.tcpayments.label'));
          var vm = this;
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.action = { submit: submit };
          vm.card = {
            cvc: undefined,
            number: undefined,
            exp_month: undefined,
            exp_year: undefined
          };
          function submit() {
            if (!vm.agree) {
              vm.error = Lang.get('subscription.agree_error');
              return;
            }
            cfpLoadingBar.start();
            Stripe.card.createToken(vm.card, function (status, response) {
              if (response.error) {
                cfpLoadingBar.complete();
                return Notify.error(response.error.message);
              }
              HttpService.post('/dwolla/customer/verification_charge', { stripe_token: response.id }, function () {
                // No need wait for socket update auth
                _.set($rootScope.auth, 'payments.gateway.dwolla.verification_paid', true);
                // Redirect
                $state.go('payments.dwolla_customer');
              }, function (errors) {
                Notify.error(_.get(errors, 'message', Lang.get('notify_actions.ooops')));
              });
            });
          }
        }
      ]
    };
  angular.module('app.dwollaCustomerCharge.component', []).component('dwollaCustomerChargeComponent', dwollaCustomerChargeComponent);
  ;
}());