(function () {
  'use strict';
  FileUploaderService.$inject = [
    '$rootScope',
    '$filter',
    'APP_CONFIG',
    'Notify',
    'FileUploader',
    'FileService',
    'CordovaService',
    'image_util'
  ];
  FileService.$inject = ['APP_CONFIG'];
  PopupService.$inject = ['FileService'];
  angular.module('app.core.upload.services', []).service('FileUploaderService', FileUploaderService).service('FileService', FileService).service('PopupService', PopupService);
  ;
  /**
	 * File Uploader Service
	 *
	 * @returns {{getUploader: getUploader, getFiles: getFiles, setFiles: setFiles, setProgress: setProgress, getProgress: getProgress}}
	 */
  function FileUploaderService($rootScope, $filter, APP_CONFIG, Notify, FileUploader, FileService, CordovaService, image_util) {
    // Override FileUploader method for Re-adding same file
    // https://github.com/nervgh/angular-file-upload/wiki/FAQ#4-no-file-chosen-or-re-add-same-file
    FileUploader.FileSelect.prototype.isEmptyAfterSelection = function () {
      return true;
    };
    FileUploader.prototype.removeFromQueue = function removeFromQueue(value) {
      // fix removeFromQueue
      if (this.queue.length) {
        var index = this.getIndexOfItem(value), item = this.queue[index];
        if (item.isUploading)
          item.cancel();
        this.queue.splice(index, 1);
        item._destroy();
        this.progress = this._getTotalProgress();
      }
    };
    FileUploader.prototype.clearQueueAll = function clearQueueAll() {
      // fix removeFromQueue
      _.remove(this.queue);
    };
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        getUploader: getUploader,
        getFiles: getFiles,
        setFiles: setFiles,
        setProgress: setProgress,
        getProgress: getProgress,
        clearUploader: clearUploader
      }, cfg = APP_CONFIG.files, uploadFiles = {}, progress = [], showNotify = true;
    return service;
    /**
		 * Get files
		 * @returns {Array}
		 */
    function getFiles() {
      return uploadFiles;
    }
    /**
		 * Set files
		 * @param fileType
		 * @param files
		 */
    function setFiles(fileType, files) {
      uploadFiles[fileType] = files;
    }
    /**
		 * Set progress
		 * @param status
		 */
    function setProgress(status) {
      progress = status;
    }
    /**
		 * Get progress
		 * @returns {Array}
		 */
    function getProgress() {
      return progress;
    }
    /**
		 * Clear all files
		 */
    function clearUploader(fileType) {
      uploadFiles[fileType] = [];
    }
    /**
		 *
		 * @param fileType
		 * @returns {*}
		 * @private
		 */
    function _getFilesLength(fileType) {
      return getFiles()[fileType] && getFiles()[fileType].length;
    }
    /**
		 * Init config FileUploader
		 * @param config
		 * @returns {{autoUpload: boolean, removeAfterUpload: boolean, queueLimit: number, filters: *[], onBeforeUploadItem: onBeforeUploadItem, onProgressAll: onProgressAll, onCompleteAll: onCompleteAll, onErrorItem: onErrorItem}}
		 * @private
		 */
    function _getConfig(config) {
      var fileType = config.fileType, maxCount = config.maxCount, error, defaultConfig = {
          autoUpload: true,
          removeAfterUpload: true,
          queueLimit: config.multiple ? 10 : 1,
          filters: [
            {
              name: 'imageSize',
              fn: function (item) {
                var fileMaxSize = cfg.upload_max_size, allow = item.size <= 1024 * 1024 * fileMaxSize;
                if (!allow) {
                  Notify.alert(Lang.get('general.uploader_error_size', { limit: fileMaxSize }));
                }
                return allow;
              }
            },
            {
              name: 'fileTypeFilter',
              fn: function (item) {
                if (!fileType) {
                  Notify.alert(Lang.get('files.select_right_type'));
                  return false;
                }
                var parts = item.name.split('.'), notSupportedExt, ext = parts[parts.length - 1], extType = cfg.rules[fileType].type;
                if (!ext) {
                  Notify.alert(Lang.get('general.uploader_error_extension_type.' + extType));
                  return false;
                }
                notSupportedExt = !_.includes(cfg.types[extType], ext.toLowerCase());
                // If is image
                if (!notSupportedExt && extType === 1) {
                  var is_image = image_util.isTypeImage(item.type);
                  if (!is_image) {
                    notSupportedExt = true;
                  }
                }
                // If not valid - show alert
                if (notSupportedExt) {
                  Notify.alert(Lang.get('general.uploader_error_extension_type.' + extType));
                }
                return !notSupportedExt;
              }
            }
          ],
          onBeforeUploadItem: function (item) {
            item.url = APP_CONFIG.external_api + '/files?type=' + fileType;
            if (window.cordova) {
              item.withCredentials = true;
              item.headers = { 'Device': CordovaService.getDeviceHeaderValue() };
            }
            if ($rootScope.auth._isAdministration()) {
              item.url += config.parentId ? '&parent_id=' + config.parentId : '';
            }
          },
          onProgressAll: function (progress) {
            setProgress(progress);
            showNotify = true;  // Notify if filters errors
          },
          onCompleteAll: function () {
            setProgress(0);
          },
          onErrorItem: function (item, response) {
            if (response.file) {
              error = _.get(response.file, '0');
            } else if (_.has(response, 'message')) {
              error = response.message;
            } else {
              error = Lang.get('notify_actions.something_went_wrong');
            }
            Notify.alert(error);
          }
        };
      // if multiple added to filter limitCount
      if (config.multiple) {
        defaultConfig.filters.push({
          name: 'limitCount',
          fn: function () {
            var count = _getFilesLength(fileType) || 0;
            if (count + this.queue.length >= maxCount) {
              if (showNotify) {
                Notify.alert(Lang.get('transactions.form.attachment.maxcountmanyerrortext', { count: maxCount }));
                showNotify = false;
              }
              return false;
            } else {
              return true;
            }
          }
        });
        // check cannot_remove
        if (_getFilesLength(fileType)) {
          _.each(getFiles()[fileType], function (file) {
            file.cannot_remove = cfg.rules[file.type] ? cfg.rules[file.type].cannot_remove || false : false;
          });
        }
      }
      return defaultConfig;
    }
    /**
		 * Get object uploader
		 * @param config
		 * @returns {FileUploader|*}
		 */
    function getUploader(config) {
      return new FileUploader(_getConfig(config));
    }
  }
  /**
	 * File Service
	 *
	 * @returns {{downloadUrl: downloadUrl, getAvatarUrl: getAvatarUrl, getMaxCount: getMaxCount}}
	 */
  function FileService(APP_CONFIG) {
    var cfg = APP_CONFIG.files, prefix = window.cordova ? '' : '/';
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        downloadUrl: downloadUrl,
        getAvatarUrl: getAvatarUrl,
        getMaxCount: getMaxCount
      };
    return service;
    /**
		 * Get Avatar Url
		 * If avatar = null and is type return stubs
		 * @param avatar
		 * @param size
		 * @param type
		 * @returns {string}
		 */
    function getAvatarUrl(avatar, size, type) {
      if (!_.has(avatar, 'token') && type) {
        return _stubUrl(type, size);
      } else if (_.has(avatar, 'sizes')) {
        if (avatar.sizes[size]) {
          return _.get(avatar.sizes[size], 'url');
        }
      }
    }
    /**
		 * Get full link to download resource
		 * @param file
		 * @returns {*}
		 */
    function downloadUrl(file) {
      return file.file_url;
    }
    /**
		 * Return stub url
		 *
		 * @param imageType
		 * @param size
		 * @returns {string}
		 */
    function _stubUrl(imageType, size) {
      return prefix + 'images/stubs/' + cfg.dir[imageType] + '/' + size + '.jpg';
    }
    /**
		 * Get max count
		 * @param fileType
		 * @returns {number}
		 */
    function getMaxCount(fileType) {
      return _.get(cfg.rules[fileType], 'maxCount', 10);
    }
  }
  /**
	 * Popup Service
	 * Open PhotoSwipe library
	 *
	 * @returns {{open: open}}
	 */
  function PopupService(FileService) {
    // noinspection UnnecessaryLocalVariableJS
    var service = { open: open };
    return service;
    /**
		 *
		 * @param file
		 * @param attachments
		 */
    function open(file, attachments) {
      var gallery, options, files, pswpElement = document.querySelectorAll('.pswp')[0];
      if (attachments) {
        files = _.clone(attachments);
      } else {
        files = [file];
      }
      // add filter only image
      files = _filterOnlyImage(files);
      _.map(files, function (file) {
        _setParams(file);
      });
      options = {
        index: _.indexOf(files, file),
        showHideOpacity: true,
        closeOnScroll: false,
        history: false
      };
      if (!attachments) {
        options.index = 1;
      }
      // Initializes and opens PhotoSwipe
      gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, files, options);
      gallery.init();
    }
    /**
		 * Filter only image
		 * @param files
		 * @returns {Array}
		 * @private
		 */
    function _filterOnlyImage(files) {
      return _.filter(files, function (file) {
        return !(file && !file.is_image);
      });
    }
    /**
		 *
		 * @param file
		 * @private
		 */
    function _setParams(file) {
      var size = 'fullsize', sizeObj;
      file.src = FileService.getAvatarUrl(file, size, file.type);
      file.title = '';
      if (_.has(file, 'sizes')) {
        sizeObj = _.get(file.sizes, size);
        file.w = sizeObj.width || 1200;
        file.h = sizeObj.height || 900;
      }
      return file;
    }
  }
}());