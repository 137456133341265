(function () {
  'use strict';
  CordovaService.$inject = [
    '$rootScope',
    '$state',
    '$http',
    'ng_util',
    'APP_CONFIG'
  ];
  angular.module('app.services.cordova', []).service('CordovaService', CordovaService);
  ;
  /**
	 * Service for Mobile Cordova App
	 * @type {string[]}
	 */
  function CordovaService($rootScope, $state, $http, ng_util, APP_CONFIG) {
    var deviceInited = false;
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        init: init,
        isCordova: isCordova,
        initStatusBar: initStatusBar,
        dialogAvailable: dialogAvailable,
        dialogAlert: dialogAlert,
        dialogConfirm: dialogConfirm,
        dialogOk: dialogOk,
        initDevice: initDevice,
        getDeviceHeaderValue: getDeviceHeaderValue,
        initPushNotifications: initPushNotifications,
        pushNotificationRouteResolver: pushNotificationRouteResolver,
        toastAvailable: toastAvailable,
        toast: toast,
        openExternalUrl: openExternalUrl
      };
    return service;
    /**
		 *
		 */
    function init() {
      if (!this.isCordova()) {
        return;
      }
      // Status bar
      this.initStatusBar();
      // Stop splash screen
      if (navigator.splashscreen) {
        navigator.splashscreen.hide();
      }
      // Deep linking
      if (window.universalLinks) {
        window.universalLinks.subscribe('dashboard_deep_links', function (event) {
          console.log('redirect: ', event);
          ng_util.safeApply($rootScope, function () {
            // check if user already auth
            if ($rootScope.auth.id) {
              switch (event.path) {
              case '/login':
                // APPLICATION APPLY
                if (event.params.application_apply) {
                  $state.go('applications.apply', { rental_id: parseInt(event.params.application_apply) });
                }
                break;
              }
            } else {
              switch (event.path) {
              case '/login':
                $state.go('auth.login', event.params);
                break;
              case '/signup':
                $state.go('auth.signup', event.params);
                break;
              case '/confirm':
                $state.go('auth.confirm', event.params);
                break;
              case '/forgot-password':
                $state.go('auth.forgotPassword', event.params);
                break;
              case '/two_factor':
                $state.go('auth.two_factor_validate', event.params);
                break;
              case '/forgot-password/confirm':
                $state.go('auth.forgotPasswordConfirm', event.params);
                break;
              case '/forgot-password/changePassword':
                $state.go('auth.forgotPasswordConfirm', event.params);
                break;
              }
            }
          });
        });
      }
    }
    /**
		 * Is cordova app
		 * @returns {boolean}
		 */
    function isCordova() {
      return !!window.cordova;
    }
    /**
		 *
		 * @param role
		 * @returns {boolean}
		 */
    function initStatusBar(role) {
      if (!this.isCordova()) {
        return false;
      }
      if (!_.isObject(window.StatusBar)) {
        return false;
      }
      switch (role) {
      case 'admin':
        window.StatusBar.styleLightContent();
        window.StatusBar.backgroundColorByHexString('#561409');
        break;
      case 'landlord':
        window.StatusBar.styleLightContent();
        window.StatusBar.backgroundColorByHexString('#40434b');
        break;
      case 'tenant':
        window.StatusBar.styleLightContent();
        window.StatusBar.backgroundColorByHexString('#23262d');
        break;
      case 'professional':
        window.StatusBar.styleLightContent();
        window.StatusBar.backgroundColorByHexString('#23262d');
        break;
      case 'owner':
        window.StatusBar.styleLightContent();
        window.StatusBar.backgroundColorByHexString('#093e44');
        break;
      default:
        window.StatusBar.styleDefault();
        window.StatusBar.backgroundColorByHexString('#FFFFFF');
        break;
      }
    }
    /**
		 *
		 * @returns {boolean|*}
		 */
    function dialogAvailable() {
      return this.isCordova() && _.isObject(navigator.notification);
    }
    /**
		 *
		 * @param message
		 * @param title
		 * @returns {boolean}
		 */
    function dialogAlert(message, title) {
      if (!this.dialogAvailable()) {
        return false;
      }
      navigator.notification.alert(message, null, title, 'Close');
      return true;
    }
    /**
		 *
		 * @param callback
		 * @param message
		 * @returns {boolean}
		 */
    function dialogConfirm(callback, message) {
      if (!this.dialogAvailable()) {
        return false;
      }
      function _onConfirm(buttonIndex) {
        if (buttonIndex === 1) {
          callback();
        }
      }
      navigator.notification.confirm(message, _onConfirm, null, [
        'Ok',
        'Cancel'
      ]);
      return true;
    }
    /**
		 *
		 * @param message
		 * @param title
		 * @returns {boolean}
		 */
    function dialogOk(message, title) {
      if (!this.dialogAvailable()) {
        return false;
      }
      navigator.notification.alert(message, null, title, Lang.get('general.ok_got_it'));
      return true;
    }
    /**
		 *
		 * @returns {boolean}
		 */
    function initDevice() {
      var self = this;
      if (deviceInited) {
        return false;
      }
      if (!this.isCordova()) {
        return false;
      }
      if (!_.isObject(window.device)) {
        return false;
      }
      $http.post(APP_CONFIG.external_api + '/device', window.device, {
        withCredentials: true,
        headers: { 'Device': self.getDeviceHeaderValue() }
      }).then(function () {
        deviceInited = true;
        // Init pushes
        self.initPushNotifications();
      });
    }
    /**
		 *
		 * @returns {*}
		 */
    function getDeviceHeaderValue() {
      if (!this.isCordova()) {
        return false;
      }
      if (!_.isObject(window.device)) {
        return false;
      }
      return [
        window.device.platform,
        window.device.uuid
      ].join(' ');
    }
    /**
		 *
		 * @returns {boolean}
		 */
    function initPushNotifications() {
      var self = this;
      if (!_.isObject(PushNotification))
        return false;
      var push = PushNotification.init({
          android: { senderID: APP_CONFIG.services.gcm.senderId },
          ios: {
            alert: 'true',
            badge: 'true',
            sound: 'true',
            clearBadge: 'true'
          }
        });
      push.on('registration', function (registrationResult) {
        window.cordova.getAppVersion.getVersionNumber(function (version) {
          var obj = _.extend(_.clone(window.device), {
              token: registrationResult.registrationId,
              version: version
            });
          $http.post(APP_CONFIG.external_api + '/device/push_register', obj, {
            withCredentials: true,
            headers: { 'Device': self.getDeviceHeaderValue() }
          });
        });
      });
      push.on('notification', function (data) {
        self.pushNotificationRouteResolver(data);
      });
      push.on('error', function (e) {
        window.console.log('PUSH ERROR: ' + e.message);
      });
    }
    /**
		 *
		 * @param pushData
		 * @returns {boolean}
		 */
    function pushNotificationRouteResolver(pushData) {
      var self = this, payload;
      self.toast(pushData.title, 'info');
      if (!pushData.additionalData.data) {
        return false;
      }
      payload = pushData.additionalData.data;
      // Detect PushNotification was clicked
      // https://github.com/phonegap/phonegap-plugin-push/blob/master/docs/API.md#callback-parameters-2
      // foreground = pushData.additionalData.foreground;
      if (!pushData.additionalData.foreground || pushData.additionalData.coldstart) {
        switch (payload.action) {
        // For all users
        case 'App\\Events\\Messages\\NewMessageReceived':
          $state.go('messages.list');
          break;
        case 'App\\Events\\PropertyUnitLease\\ExpirationNotifyLandlord':
        case 'App\\Events\\PropertyUnitLease\\ExpirationNotifyLandlordToday':
          $state.go('leases.view', {
            property_id: payload.property_id,
            unit_id: payload.unit_id,
            id: payload.id
          });
          break;
        case 'App\\Events\\WorkOrder\\RequestWasUpdatedByLandlord':
          $state.go('workorders.view', { id: payload.id });
          break;
        // For Landlord
        case 'App\\Events\\Invites\\NewShareByTenantToLandlord':
          $state.go('tenants.list');
          break;
        case 'App\\Events\\Invites\\NewConnectionByProfessionalToLandlord':
          $state.go('professionals.list');
          break;
        case 'App\\Events\\Application\\NewApplicationByTenant':
          $state.go('applications.list_new');
          break;
        case 'App\\Events\\WorkOrder\\SharedWithLandlordByTenant':
          $state.go('workorders.view', { id: payload.id });
          break;
        case 'App\\Events\\Verification\\Approved':
          break;
        case 'App\\Events\\Verification\\Declined':
          break;
        case 'App\\Events\\Transactions\\OnlinePaymentCreated':
          $state.go('transactions.checkout', { token: payload.token });
          break;
        case 'App\\Events\\ToDoList\\RemindMeTodoList':
          $state.go('calendar.main');
          break;
        case 'App\\Events\\Property\\InsuranceExpiration':
          $state.go('reminders.list', { property_id: payload.property_id });
          break;
        // For Tenant
        case 'App\\Events\\Invites\\NewShareByLandlordToTenant':
          $state.go('rentals.list');
          break;
        case 'App\\Events\\Application\\NewApplicationByLandlordForTenant':
          $state.go('applications.view', { id: payload.id });
          break;
        case 'App\\Events\\WorkOrder\\ShareWithTenantByLandlord':
          $state.go('workorders.view', { id: payload.id });
          break;
        case 'App\\Events\\Application\\ApproveApplicationByLandlord':
          $state.go('applications.list');
          break;
        case 'App\\Events\\Application\\DeclineApplicationByLandlord':
          $state.go('applications.list');
          break;
        case 'App\\Events\\Transactions\\CreateInvoiceByLandlord':
        case 'App\\Events\\Transactions\\CreateRecurringByLandlord':
        case 'App\\Events\\Transactions\\RemindTenantForDueInvoice':
          $state.go('transactions.list');
          break;
        // For Professional
        case 'App\\Events\\Invites\\NewConnectionByLandlordToProfessional':
          $state.go('invites.list');
          break;
        case 'App\\Events\\WorkOrder\\AssignProfessionalByLandlord':
          $state.go('workorders.view', { id: payload.id });
          break;
        case 'App\\Events\\WorkOrder\\StatusWasChangedToDeferredByLandlord':
        case 'App\\Events\\WorkOrder\\StatusWasChangedToInProgressByLandlord':
        case 'App\\Events\\WorkOrder\\StatusWasChangedToResolvedByLandlord':
          $state.go('workorders.view', { id: payload.id });
          break;
        // For Owner
        case 'App\\Events\\Invites\\NewConnectionByLandlordToOwner':
          $state.go('invites.list');
          break;
        }
      }
    }
    /**
		 *
		 * @returns {boolean|*}
		 */
    function toastAvailable() {
      return this.isCordova() && _.isObject(window.plugins.toast);
    }
    /**
		 *
		 * @param message
		 * @param type
		 * @param duration
		 * @returns {boolean}
		 */
    function toast(message, type, duration) {
      if (!this.toastAvailable()) {
        return false;
      }
      var styling = {
          opacity: 0.9,
          backgroundColor: '#333333',
          textColor: '#ffffff',
          textSize: 13,
          cornerRadius: 6,
          horizontalPadding: 16,
          verticalPadding: 12
        };
      // type: info|success|warning|error;
      if (type === 'info') {
        styling.backgroundColor = '#333333';
      }
      if (type === 'success') {
        styling.backgroundColor = '#5cb85c';
      }
      if (type === 'warning') {
        styling.backgroundColor = '#f0ad4e';
      }
      if (type === 'error') {
        styling.backgroundColor = '#d9534f';
      }
      window.plugins.toast.showWithOptions({
        message: message,
        duration: duration || 2000,
        position: 'bottom',
        styling: styling
      });
    }
    function openExternalUrl(href) {
      var browserTab = _.get(window, 'cordova.plugins.browsertab');
      if (browserTab) {
        browserTab.isAvailable(function (result) {
          if (!result) {
            window.cordova_ref = cordova.InAppBrowser.open(event.currentTarget.href, '_blank', 'location=no');
          } else {
            window.cordova_ref = browserTab.openUrl(href);
          }
        });
      }
    }
  }
}());