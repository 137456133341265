(function () {
  'use strict';
  angular.module('app.auth.services', []).service('AuthInviteService', AuthInviteService);
  ;
  /**
	 * Invite Service
	 * @type {string[]}
	 */
  function AuthInviteService() {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        getInvite: getInvite,
        setInvite: setInvite,
        close: close
      };
    var invite;
    return service;
    function getInvite() {
      return invite;
    }
    /**
		 * If is set Invite
		 * @param data
		 */
    function setInvite(data) {
      invite = {
        role: data.allow_role,
        firstName: data.receiverFirstName,
        lastName: data.receiverLastName,
        email: data.receiverEmail,
        invite_token: data.token,
        disabledRole: true
      };
    }
  }
}());