(function () {
  'use strict';
  /**
	 * @todo @knazark Add description about this core module
	 */
  angular.module('app.core', [
    'app.core.upload.services',
    'app.core.camera.services',
    'app.core.upload.components'
  ]);
  ;
}());