(function () {
  'use strict';
  var age = {
      bindings: { birthday: '<' },
      templateUrl: '/app/modules/shared/components/form/age.html',
      controller: [
        'ng_util',
        'DateHelper',
        function (ng_util, DateHelper) {
          'ngInject';
          var vm = this;
          vm.age = false;
          vm.$onChanges = function (bindings) {
            if (bindings.birthday.currentValue) {
              vm.age = DateHelper.calculateAge(bindings.birthday.currentValue);
            }
          };
        }
      ]
    };
  var customUiSelect = {
      bindings: {
        model: '=',
        options: '=',
        required: '<',
        disabled: '<',
        url: '@',
        label: '@',
        tooltip: '@',
        searchenabled: '<?',
        allowclean: '@',
        placeholder: '@',
        default: '<?',
        name: '@',
        filter: '&?',
        select: '&',
        refreshoptions: '&?',
        footer: '<?',
        errors: '<?',
        tooltipSymbol: '@'
      },
      transclude: true,
      templateUrl: '/app/modules/shared/components/form/ui-select.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        '$q',
        'HttpService',
        function ($rootScope, $element, $attrs, $parse, $q, HttpService) {
          'ngInject';
          var vm = this, refreshUrl = true;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.filter = vm.filter ? vm.filter() : function () {
            return true;
          };
          vm.refresh = function (q) {
            if (vm.refreshoptions) {
              $parse(vm.refreshoptions)(vm)(q);
            } else if (vm.url) {
              var params = { q: q };
              if (!_.isUndefined(vm.model)) {
                params.id = vm.model;
              }
              var promise = asyncRequest(HttpService, vm.url, params, $q);
              promise.then(function (obj) {
                vm.options = obj.data.list;
                if (!obj.data.showSearch && refreshUrl) {
                  vm.url = false;
                }
                refreshUrl = false;
              });
            }
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customUiSelectSymbol = {
      bindings: {
        model: '=',
        options: '=',
        required: '<',
        disabled: '<',
        label: '@',
        labelClass: '@',
        searchenabled: '<?',
        allowclean: '@',
        placeholder: '@',
        default: '<?',
        name: '@',
        select: '&'
      },
      templateUrl: '/app/modules/shared/components/form/ui-select-symbol.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customUiSelectMultiple = {
      bindings: {
        model: '=',
        options: '<',
        required: '<',
        disabled: '<',
        label: '@',
        placeholder: '@?',
        name: '@',
        filter: '&?',
        select: '&',
        lockItems: '<',
        errors: '<'
      },
      templateUrl: '/app/modules/shared/components/form/ui-select-multiple.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.lockChoice = lockChoice;
          function lockChoice(id) {
            return vm.lockItems && vm.lockItems.indexOf(id) !== -1;
          }
          errorController($rootScope, vm);
        }
      ]
    };
  var customUiSelectDetails = {
      bindings: {
        model: '=',
        options: '<',
        required: '<',
        disabled: '<',
        url: '@',
        label: '@',
        searchenabled: '<?',
        allowclean: '@',
        placeholder: '@?',
        name: '@',
        filter: '&?',
        select: '&',
        attr: '<',
        errors: '<',
        footer: '<',
        refreshoptions: '&?',
        default: '<?',
        contacts: '<?'
      },
      templateUrl: '/app/modules/shared/components/form/ui-select-details.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        '$q',
        'HttpService',
        'LeaseService',
        function ($rootScope, $element, $attrs, $parse, $q, HttpService, LeaseService) {
          'ngInject';
          var vm = this, refreshUrl = true;
          vm.getTenantDetail = vm.contacts ? getDetails : LeaseService.getTenantDetail;
          vm.type = vm.attr && vm.attr.type ? vm.attr.type : vm.type;
          vm.size = vm.attr && vm.attr.size;
          vm.width = vm.attr && vm.attr.width;
          vm.class = vm.attr && vm.attr.class;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.filter = vm.filter ? vm.filter() : function () {
            return true;
          };
          vm.refresh = function (q) {
            if (vm.refreshoptions) {
              $parse(vm.refreshoptions)(vm)(q);
            } else if (vm.url) {
              var params = { q: q };
              if (!_.isUndefined(vm.model)) {
                params.id = vm.model;
              }
              var promise = asyncRequest(HttpService, vm.url, params, $q);
              promise.then(function (obj) {
                vm.options = obj.data.list;
                if (!obj.data.showSearch && refreshUrl) {
                  vm.url = false;
                }
                refreshUrl = false;
              });
            }
          };
          errorController($rootScope, vm);
          function getDetails(id, detailKey) {
            var tenantDetails = _.find(vm.contacts, { id: id });
            if (!detailKey) {
              return tenantDetails;
            }
            return tenantDetails ? tenantDetails[detailKey] : '';
          }
        }
      ]
    };
  var customPriceSelect = {
      bindings: {
        modelMinPrice: '=',
        modelMaxPrice: '=',
        options: '<',
        disabled: '<?',
        name: '@'
      },
      templateUrl: '/app/modules/shared/components/form/price-select.html',
      controller: [
        'ng_util',
        function (ng_util) {
          'ngInject';
          var vm = this, minPriceObj;
          /**
			 * Set default value
			 */
          vm.showPriceSelect = false;
          vm.showMinFilter = false;
          vm.showMaxFilter = false;
          vm.minPrice = 9;
          vm.$minPriceFocus = false;
          vm.$maxPriceFocus = false;
          vm.changeMinPrice = changeMinPrice;
          vm.changeMaxPrice = changeMaxPrice;
          vm.changeShowMaxPriceOptions = changeShowMaxPriceOptions;
          vm.changeShowPriceSelect = changeShowPriceSelect;
          vm.changeShowMinPriceOptions = changeShowMinPriceOptions;
          /**
			 * Set default value for min price select
			 */
          minPriceObj = _.find(vm.options, { 'value': vm.modelMinPrice });
          if (minPriceObj) {
            vm.changeMinPrice(minPriceObj.key);
          }
          /**
			 * Watch min price focus
			 */
          ng_util.definePropertyObj(vm, 'minPriceFocus', function (value) {
            if (!_.isUndefined(value)) {
              vm.changeShowMinPriceOptions();
            }
          });
          /**
			 * Watch max price focus
			 */
          ng_util.definePropertyObj(vm, 'maxPriceFocus', function (value) {
            if (!_.isUndefined(value)) {
              vm.changeShowMaxPriceOptions();
            }
          });
          /**
			 * Show input and select for change min and max price
			 */
          function changeShowPriceSelect() {
            vm.showPriceSelect = !vm.showPriceSelect;
            vm.showMaxFilter = false;
            vm.$minPriceFocus = vm.showPriceSelect;
          }
          /**
			 * Show select for min price
			 */
          function changeShowMinPriceOptions() {
            vm.showMinFilter = true;
            vm.showMaxFilter = false;
          }
          /**
			 * Show select for max price
			 */
          function changeShowMaxPriceOptions() {
            vm.showMinFilter = false;
            vm.showMaxFilter = true;
          }
          /**
			 * Change min price in select
			 */
          function changeMinPrice(key) {
            vm.minPrice = key + 1;
            if (key === -1) {
              vm.modelMinPrice = undefined;
            } else {
              vm.modelMinPrice = vm.options[key].value;
            }
            vm.$maxPriceFocus = true;
            angular.element('.max-price-item .form-control').focus();
          }
          /**
			 * Change max price in select
			 */
          function changeMaxPrice(key) {
            if (key === -1) {
              vm.modelMaxPrice = undefined;
            } else {
              vm.modelMaxPrice = vm.options[key].value;
            }
            vm.changeShowPriceSelect();
          }
        }
      ]
    };
  var customSwitchery = {
      bindings: {
        model: '=',
        label: '@',
        title: '@',
        name: '@',
        showtext: '@',
        yestext: '@',
        enabledtext: '@',
        requiredtext: '@',
        disabled: '<',
        flexbox: '@',
        watcher: '&?',
        watcherEntity: '<',
        titletooltip: '@'
      },
      transclude: true,
      templateUrl: '/app/modules/shared/components/form/switchery.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        function ($rootScope, $element, $attrs, $parse) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.checkboxClick = function () {
            if (vm.watcher) {
              $parse(vm.watcher)(vm)({
                model: vm.model,
                entity: vm.watcherEntity
              });
            }
          };
        }
      ]
    };
  var customInput = {
      bindings: {
        model: '=',
        disabled: '<',
        required: '<',
        run: '&?',
        watcher: '&?',
        titletooltip: '@',
        label: '@',
        name: '@',
        errors: '<?',
        moneySymbol: '<?',
        tooltipSymbol: '@'
      },
      templateUrl: '/app/modules/shared/components/form/input-combined.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        'ng_util',
        function ($scope, $rootScope, $element, $attrs, $parse, ng_util) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
          };
          errorController($rootScope, vm);
          vm.inputChanged = function () {
            if (vm.watcher) {
              vm.watcher({ model: vm.model });
            }
          };
          if (vm.run) {
            vm.$model = vm.model;
            ng_util.definePropertyObj(vm, 'model', function (value) {
              if (!_.isUndefined(value)) {
                $parse(vm.run)(vm);
              }
            });
          }
        }
      ]
    };
  var customInputDomain = {
      bindings: {
        model: '=',
        required: '<',
        disabled: '<',
        errors: '<?',
        subdomain: '<?',
        label: '@',
        placeholder: '@',
        name: '@'
      },
      templateUrl: '/app/modules/shared/components/form/input-domain.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$attrs',
        function ($scope, $rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customDatepickerInput = {
      bindings: {
        model: '=',
        disabled: '<',
        required: '<',
        label: '@',
        name: '@',
        attr: '<',
        mode: '@',
        watcher: '&?',
        instance: '&',
        declined: '<',
        maxDate: '@',
        minDate: '@',
        enableFilter: '&?',
        errors: '<?',
        placeholderdate: '@?',
        tooltipSymbol: '@',
        icondatepicker: '<'
      },
      templateUrl: '/app/modules/shared/components/form/input-datepicker.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        'browserInfo',
        function ($rootScope, $element, $attrs, browserInfo) {
          'ngInject';
          var vm = this, date_format_to_string = $rootScope.auth && $rootScope.auth.date_format_to_string, time_format_to_string = $rootScope.auth && $rootScope.auth.time_format_to_string, enableTime = vm.attr && vm.attr.enableTime, enableFocus = vm.attr && vm.attr.enableFocus, fp, dateFormat;
          vm.icondatepicker = $attrs.icondatepicker;
          vm.isMobile = browserInfo.isMobile();
          vm.$onInit = function () {
            if (!enableFocus) {
              initFp();
            } else {
              var controlInput = $element.find('.form-control');
              // input bind focus, blur
              controlInput.bind('focus', function () {
                vm.dateOpts.clickOpens = false;
                initFp();
                fp.open();
              });
            }
          };
          // options flatPicker
          vm.dateOpts = {
            dateFormat: 'm/d/Y',
            animate: false,
            allowInput: true,
            required: vm.required ? vm.required : false,
            placeholder: vm.placeholderdate ? vm.placeholderdate : '',
            onClose: function (selectedDates, dateStr, instance) {
              if (vm.dateOpts.mode !== 'range' && !enableTime) {
                var inputValue;
                if (vm.dateOpts.altFormat === 'd/m/Y') {
                  if ((instance.altInput || instance.input).value) {
                    inputValue = moment((instance.altInput || instance.input).value, 'DD/MM/YYYY').toDate();
                  }
                } else {
                  inputValue = (instance.altInput || instance.input).value;
                }
                inputValue = inputValue && isDate(inputValue) && moment(inputValue).toDate();
                instance.setDate(inputValue, true);
              }
            },
            maxDate: vm.maxDate,
            minDate: vm.minDate
          };
          if (vm.enableFilter && _.isFunction(vm.enableFilter)) {
            vm.dateOpts.enable = [function (date) {
                // return true to enable
                return vm.enableFilter()(date);
              }];
          }
          // change AUTH format
          dateFormat = date_format_to_string.replace(/m{2}/, function () {
            return 'm';
          }).replace(/d{2}/, function () {
            return 'd';
          }).replace(/y{4}/, function () {
            return 'Y';
          });
          // return selectedDates
          if (vm.watcher) {
            vm.dateOpts.onChange = function (selectedDates) {
              return vm.watcher({ selectedDates: selectedDates });
            };
          }
          // if not mobile init format
          if (!vm.isMobile) {
            // set Opts
            vm.dateOpts.altInput = true;
            vm.dateOpts.altFormat = dateFormat;
          }
          // if enable mode 'range'
          if (vm.mode) {
            vm.dateOpts.mode = vm.mode;
            if (vm.mode === 'range') {
              vm.dateOpts.allowInput = false;
            }
          }
          // if enable time
          if (enableTime) {
            vm.dateOpts.enableTime = true;
            vm.dateOpts.dateFormat = 'Y-m-d H:i';
            vm.dateOpts.allowInput = false;
            if (time_format_to_string === 'HH:mm') {
              // time_24hr
              vm.dateOpts.time_24hr = true;
              time_format_to_string = time_format_to_string.replace(/H{2}/, function () {
                return 'H';
              }).replace(/m{2}/, function () {
                return 'i';
              });
              vm.dateOpts.altFormat = dateFormat + ' ' + time_format_to_string;
            } else {
              vm.dateOpts.time_24hr = false;
              time_format_to_string = time_format_to_string.replace(/m{2}/, function () {
                return 'i';
              }).replace(/A/, function () {
                return 'K';
              });
              vm.dateOpts.altFormat = dateFormat + ' ' + time_format_to_string;
            }
          }
          function initFp() {
            fp = new Flatpickr($element.find('.form-control')[0], vm.dateOpts);
            datePostSetup();
          }
          /**
			 * setup date
			 */
          function datePostSetup() {
            if (!_.isUndefined(fp)) {
              if (vm.model && isDate(vm.model) && vm.dateOpts.mode !== 'range') {
                fp.setDate(moment(vm.model).toDate());
              } else if (vm.dateOpts.mode === 'range') {
                if (!_.isUndefined(vm.model) && isDate(vm.model)) {
                  fp.setDate(vm.model);
                }
              }
              if (vm.instance) {
                return vm.instance({ fpItem: fp });
              }
            }
          }
          /**
			 * destroy flatPicker
			 */
          vm.$onDestroy = function () {
            if (!_.isUndefined(fp)) {
              fp.destroy();
            }
          };
          /**
			 * clear input
			 */
          vm.clearInput = function () {
            if (!_.isUndefined(fp)) {
              fp.clear();
            }
          };
          /**
			 * init flatPicker
			 */
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
          };
          function isDate(date) {
            return new Date(date).toString() !== 'Invalid Date';
          }
          errorController($rootScope, vm);
        }
      ]
    };
  var customMaskPhone = {
      bindings: {
        model: '=',
        disabled: '<',
        required: '<',
        label: '@',
        name: '@',
        flag: '@?',
        countryIso: '=?',
        defaultMask: '@',
        isPreview: '<?',
        errors: '<?'
      },
      templateUrl: '/app/modules/shared/components/form/input-mask-phone.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$attrs',
        function ($scope, $rootScope, $element, $attrs) {
          'ngInject';
          var vm = this, input = $element.find('input')[0], countryCallingCodes = [];
          //Only numbers
          if (!_.isUndefined(vm.model) && vm.model !== null && angular.isString(vm.model)) {
            vm.model = vm.model.replace(/\D/g, '');
          }
          vm.invalidMsg = function () {
            if (vm.required) {
              if (input.value === '') {
                input.setCustomValidity('Please fill out this field.');
              } else {
                input.setCustomValidity('');
              }
            }
          };
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
            if (vm.required && _.isUndefined(vm.model)) {
              input.setCustomValidity('Please fill out this field.');
            }
            if (vm.defaultMask) {
              countryCallingCodes.push({
                mask: vm.defaultMask,
                cc: vm.flag
              });
            } else {
              countryCallingCodes = Lang.get('general.countries');
            }
            var maskList = $.masksSort(countryCallingCodes, [], /[0-9]|#/, 'mask'), maskOpts = {
                inputmask: {
                  definitions: {
                    '#': {
                      validator: '[0-9]',
                      cardinality: 1
                    }
                  },
                  showMaskOnHover: false,
                  autoUnmask: true
                },
                match: /[0-9]/,
                replace: '#',
                list: maskList,
                listKey: 'mask',
                onMaskChange: function (maskObj, completed) {
                  vm.countryIso = vm.cc = completed ? maskObj.cc : vm.defaultMask ? vm.flag : false;
                  $(this).attr('placeholder', $(this).inputmask('getemptymask'));
                  if (vm.isPreview) {
                    var inputmaskValue = $(this)[0].inputmask._valueGet();
                    if (inputmaskValue.indexOf('_') === -1) {
                      vm.inputmaskValue = inputmaskValue;
                    } else {
                      vm.inputmaskValue = inputmaskValue.replace(/\D/g, '');
                    }
                  }
                }
              };
            $(input).inputmasks(maskOpts);
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customTextarea = {
      bindings: {
        model: '=',
        disabled: '<',
        required: '<',
        maxlength: '<?',
        elastic: '<?',
        label: '@',
        name: '@',
        rows: '@'
      },
      templateUrl: '/app/modules/shared/components/form/textarea-combined.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
          };
          vm.getMaxLengthCounter = getMaxLengthCounter;
          errorController($rootScope, vm);
          function getMaxLengthCounter() {
            if (_.isUndefined(vm.maxlength)) {
              return false;
            }
            if (_.isNull(vm.model) || _.isUndefined(vm.model)) {
              return 0;
            }
            return parseInt(vm.model.replace(/\s+/g, ' ').length);
          }
        }
      ]
    };
  var customCheckbox = {
      bindings: {
        model: '=',
        label: '@',
        name: '@',
        title: '@',
        tooltip: '@',
        isBoolean: '@',
        disabled: '<',
        watcher: '&?',
        checked: '<',
        watcherFn: '&?'
      },
      templateUrl: '/app/modules/shared/components/form/checkbox.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        function ($rootScope, $element, $attrs, $parse) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.trueValue = 1;
          vm.falseValue = 0;
          if (vm.isBoolean) {
            vm.trueValue = true;
            vm.falseValue = false;
          }
          // Fix in Application View, when is inited 2 and more equal widgets. (TODO recode).
          vm.uniqueid = Math.floor(Math.random() * (999 - 1));
          if (vm.watcher) {
            vm.checkboxClick = function () {
              $parse(vm.watcher)(vm)();
            };
          } else if (vm.watcherFn) {
            vm.checkboxClick = vm.watcherFn;
          } else {
            vm.checkboxClick = function () {
              return false;
            };
          }
          errorController($rootScope, vm);
        }
      ]
    };
  var customCheckboxMultiple = {
      bindings: {
        model: '=',
        options: '<',
        column: '@',
        required: '@',
        disabled: '@',
        modeldisabled: '<',
        style: '@',
        name: '@'
      },
      templateUrl: '/app/modules/shared/components/form/column-checkbox-multiple.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          //column-checkbox-multiple
          vm.disabled = vm.disabled ? parseInt(vm.disabled) : -1;
          vm.model = angular.isArray(vm.model) ? vm.model : [];
          vm.toggleSelection = function (key) {
            var idx = vm.model.indexOf(key);
            // is currently selected
            if (idx > -1) {
              vm.model.splice(idx, 1);
            } else {
              vm.model.push(key);
            }
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customRadio = {
      bindings: {
        model: '=',
        options: '<',
        label: '@',
        required: '@',
        disabled: '<',
        column: '@',
        name: '@',
        int: '@',
        description: '<',
        default: '<?',
        watcher: '&?',
        type: '@'
      },
      templateUrl: [
        '$attrs',
        function ($attrs) {
          'ngInject';
          if ($attrs.column) {
            return '/app/modules/shared/components/form/column-radio.html';
          } else {
            return '/app/modules/shared/components/form/radio.html';
          }
        }
      ],
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        '$parse',
        function ($rootScope, $element, $attrs, $parse) {
          'ngInject';
          var vm = this;
          if (_.isUndefined(vm.model) && !_.isUndefined(vm.default)) {
            vm.model = vm.default;
          }
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          if (!_.isArray(vm.disabled) && !_.isBoolean(vm.disabled)) {
            vm.disabled = vm.disabled ? parseInt(vm.disabled) : -1;
          }
          var newOptions = [];
          for (var i in vm.options) {
            if (_.isObject(vm.options[i])) {
              newOptions = vm.options;
              break;
            }
            newOptions.push({
              key: i,
              value: vm.options[i]
            });
          }
          vm.isDisabled = isDisabled;
          vm.options = newOptions;
          function isDisabled(key) {
            if (_.isArray(vm.disabled)) {
              return _.indexOf(vm.disabled, key) >= 0;
            } else if (_.isBoolean(vm.disabled)) {
              return vm.disabled;
            } else {
              return key == vm.disabled;
            }
          }
          vm.radioClick = function () {
            if (vm.watcher) {
              $parse(vm.watcher)(vm)();
            }
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customRadioType = {
      bindings: {
        model: '=',
        options: '<',
        name: '@',
        lang: '@',
        required: '@?'
      },
      templateUrl: '/app/modules/shared/components/form/radio-type.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          errorController($rootScope, vm);
        }
      ]
    };
  /**
	 * @namespace customSelect
	 * @desc Component for select option
	 * @example <custom-select></custom-select>
	 * @param {Number} model
	 * @param {Array} options
	 * @param {Boolean} required
	 * @param {Boolean} disabled
	 * @param {Boolean} default
	 * @param {String} name
	 * @param {String} label
	 */
  var customSelect = {
      bindings: {
        model: '=',
        options: '<',
        required: '<',
        disabled: '<',
        default: '<?',
        name: '@',
        label: '@'
      },
      templateUrl: '/app/modules/shared/components/form/select.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          // If options is object: {1: 'A opt', 2: 'B opt'}
          // convert to array of objects
          if (!angular.isArray(vm.options)) {
            var newOptions = [];
            for (var index in vm.options) {
              newOptions.push({
                key: index,
                value: vm.options[index]
              });
            }
            vm.options = newOptions;
          }
          errorController($rootScope, vm);
        }
      ]
    };
  var textEditor = {
      bindings: {
        model: '=',
        name: '@',
        type: '@',
        dragElements: '<',
        disabled: '<',
        options: '<',
        label: '@',
        inline: '@',
        required: '<',
        insertElement: '&',
        maxlength: '@?',
        minlength: '@?'
      },
      templateUrl: '/app/modules/shared/components/form/text-editor.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$attrs',
        function ($scope, $rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.options = {
            toolbar: {
              buttons: [
                'bold',
                'italic',
                'underline',
                'anchor',
                'h2',
                'unorderedlist'
              ],
              static: true,
              align: 'center',
              sticky: true,
              updateOnEmptySelection: true
            },
            extensions: { markdown: new MeMarkdown() }
          };
          if (vm.maxlength) {
            vm.options.maxlength = parseInt(vm.maxlength);
          }
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
            postCompile($element);
          };
          vm.getMaxLengthCounter = getMaxLengthCounter;
          // fix if empty editor
          $scope.$watch('$ctrl.model', function (newVal, oldVal) {
            if (newVal !== oldVal) {
              vm.errors = null;
              if (newVal === '<p><br></p>' || newVal === '<br>') {
                vm.model = null;
              }
              if (vm.minlength && parseInt(vm.minlength) - vm.options.countCharacters > 0) {
                var attributeName = vm.name.split('.');
                vm.errors = [Lang.get('validation.min.string', {
                    attribute: attributeName[attributeName.length - 1],
                    min: vm.minlength
                  })];
              }
            }
          });
          errorController($rootScope, vm);
          function getMaxLengthCounter() {
            if (_.isUndefined(vm.maxlength)) {
              return false;
            }
            if (_.isNull(vm.model) || _.isUndefined(vm.model)) {
              return 0;
            }
            return vm.options.countCharacters;
          }
        }
      ]
    };
  var ckEditor = {
      bindings: {
        model: '=',
        name: '@',
        type: '@',
        dragElements: '<',
        options: '<',
        inline: '@',
        required: '<',
        insertElement: '&',
        ckloaded: '&?'
      },
      templateUrl: '/app/modules/shared/components/form/ck-editor.html',
      controller: [
        '$rootScope',
        '$element',
        '$attrs',
        function ($rootScope, $element, $attrs) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            preCompile($rootScope.auth || {}, $element, $attrs);
          };
          vm.isMobile = function () {
            return window.cordova;
          };
          vm.setElement = function (value) {
            var text = '&nbsp<span class="h-card">' + value + '</span>&nbsp', editor = CKEDITOR.instances.tenantcloud, selection = editor.getSelection(), ranges = selection.getRanges(), range = ranges[0], newRange = editor.createRange();
            if (_.isUndefined(range)) {
              editor.setData(text + (vm.model || ''));
              return;
            }
            newRange.moveToRange(range);
            selection.selectRanges([newRange]);
            editor.insertHtml(text);
          };
          vm.checkLoadedEditor = function (loaded) {
            if (vm.ckloaded)
              vm.ckloaded({ loaded: loaded });
          };
          errorController($rootScope, vm);
        }
      ]
    };
  var customGeo = {
      bindings: {
        model: '=',
        required: '<',
        disabled: '<',
        geocomplete: '<',
        initmap: '@',
        label: '@',
        name: '@',
        fieldnameAddress: '@',
        fieldnameCounty: '@',
        fieldnameCity: '@',
        fieldnameState: '@',
        fieldnameCountry: '@',
        fieldnameZip: '@',
        inithidemap: '<',
        errors: '<?',
        params: '<'
      },
      templateUrl: '/app/modules/shared/components/global/input-geo.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$timeout',
        '$interval',
        '$compile',
        'ng_util',
        function ($scope, $rootScope, $element, $timeout, $interval, $compile, ng_util) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            postCompile($element);
          };
          $timeout(function () {
            var place = {}, $input = $element.find('input'), google_place_id = vm.geocomplete.google_place_id || undefined, is_edit = _.clone(vm.geocomplete.id) || null;
            vm.fieldname = {
              address: vm.fieldnameAddress || 'address1',
              county: vm.fieldnameCounty || 'county',
              city: vm.fieldnameCity || 'city',
              state: vm.fieldnameState || 'state',
              country: vm.fieldnameCountry || 'country',
              zip: vm.fieldnameZip || 'zip'
            };
            // Watcher
            $scope.$watchGroup([
              '$ctrl.geocomplete.' + vm.fieldname.address,
              '$ctrl.geocomplete.' + vm.fieldname.county,
              '$ctrl.geocomplete.' + vm.fieldname.city,
              '$ctrl.geocomplete.' + vm.fieldname.state,
              '$ctrl.geocomplete.' + vm.fieldname.country,
              '$ctrl.geocomplete.' + vm.fieldname.zip
            ], function (newVal) {
              if (is_edit && vm.geocomplete.google_place_id) {
                is_edit = null;
                place[vm.fieldname.address] = vm.geocomplete[vm.fieldname.address];
                place[vm.fieldname.city] = vm.geocomplete.city;
                place[vm.fieldname.county] = vm.geocomplete.county;
                place[vm.fieldname.state] = vm.geocomplete.state;
                place[vm.fieldname.country] = vm.geocomplete.country;
                place[vm.fieldname.zip] = vm.geocomplete.zip;
                return false;
              }
              if (newVal[0] == place.address1 && newVal[1] == place.city && newVal[2] == place.county && newVal[3] == place.state && newVal[4] == place.zip && newVal[5] == place.country) {
                vm.geocomplete.google_place_id = google_place_id;
              } else {
                vm.geocomplete.google_place_id = undefined;
              }
            });
            var options = { componentRestrictions: { country: [] } };
            if (vm.params) {
              if (vm.params.country && vm.params.country.length > 0) {
                options.componentRestrictions = { country: [vm.params.country] };
              }
            }
            // Start binding GeoComplete
            $input.geocomplete(options).bind('geocode:result', function (event, result) {
              // Clean
              place[vm.fieldname.address] = '';
              place[vm.fieldname.county] = '';
              _.forEach(result.address_components, function (value) {
                if (value.types[0] === 'street_number') {
                  place[vm.fieldname.address] = place[vm.fieldname.address] + value.long_name + ' ';
                }
                if (value.types[0] === 'route') {
                  place[vm.fieldname.address] = place[vm.fieldname.address] + value.long_name;
                }
                if (value.types[0] === 'neighborhood') {
                  place[vm.fieldname.county] = value.long_name;
                }
                if (value.types[0] === 'locality') {
                  place[vm.fieldname.city] = value.long_name;
                }
                if (value.types[0] === 'sublocality') {
                  if (value.long_name) {
                    place[vm.fieldname.city] = value.long_name;
                  }
                }
                if (value.types[0] === 'administrative_area_level_1') {
                  place[vm.fieldname.state] = value.short_name;
                }
                if (value.types[0] === 'country') {
                  place[vm.fieldname.country] = value.short_name;
                }
                if (value.types[0] === 'postal_code') {
                  place[vm.fieldname.zip] = value.long_name;
                }
              });
              place.formatted_address = result.formatted_address;
              var coordinates = {
                  lat: result.geometry.location.lat(),
                  lon: result.geometry.location.lng(),
                  heading: 0,
                  pitch: 0,
                  zoom: 1,
                  url: ''
                };
              // Extend scope
              angular.extend(vm.geocomplete, place);
              vm.geocomplete.coordinates = coordinates;
              google_place_id = result.place_id;
              vm.geocomplete.google_place_id = result.place_id;
              angular.element(this).blur();
              this.value = vm.model;
              ng_util.safeApply($scope, function () {
                // reinit map & street view
                if (vm.initmap && !window.cordova) {
                  create_map_and_streetview(vm.geocomplete.coordinates, 'map_canvas');
                }
              });
            });
          }, 0);
          if (!vm.geocomplete || !vm.geocomplete.coordinates) {
            vm.geocomplete.coordinates = {
              lat: 41.8895973,
              lon: -87.6216798,
              heading: 0,
              pitch: 10,
              zoom: 12,
              street: true
            };
          } else {
            if (_.isString(vm.geocomplete.coordinates)) {
              vm.geocomplete.coordinates = JSON.parse(vm.geocomplete.coordinates);
            }
          }
          // First init map & street view
          if (vm.initmap && !window.cordova) {
            create_map_and_streetview(vm.geocomplete.coordinates, 'map_canvas');
          }
          if (vm.inithidemap && !window.cordova) {
            $scope.$watch('$ctrl.inithidemap', function (newVal, oldVal) {
              if (newVal !== oldVal) {
                if (!panorama) {
                  return false;
                }
                if (newVal === 2) {
                  panorama.setVisible(true);
                } else {
                  panorama.setVisible(false);
                }
              }
            });
          }
          errorController($rootScope, vm);
          var map, panorama, marker, service, googleCoords;
          function create_map_and_streetview(coordinates, map_id) {
            // Fix , if div#map_id undefined or not inited
            var stop = undefined;
            if (document.getElementById(map_id)) {
              initMap();
            } else {
              stop = $interval(function () {
                if (document.getElementById(map_id)) {
                  initMap();
                  stopInterval();
                }
              }, 300, 5);
            }
            function initMap() {
              if (!window.google) {
                return false;
              }
              if (!coordinates.lat) {
                return false;
              }
              // COORDINATES
              googleCoords = new google.maps.LatLng(parseFloat(coordinates.lat), parseFloat(coordinates.lon));
              var myOptions = {
                  zoom: 14,
                  center: googleCoords,
                  mapTypeId: google.maps.MapTypeId.ROADMAP,
                  backgroundColor: '#FFFFFF',
                  streetViewControl: false,
                  keyboardShortcuts: false,
                  scrollwheel: false
                };
              // Check if map initialized
              if (!map) {
                // MAP
                map = new google.maps.Map(document.getElementById(map_id), myOptions);
                // MARKER
                marker = new google.maps.Marker({
                  map: map,
                  position: googleCoords
                });
                // PANORAMA
                panorama = map.getStreetView();
                // PANORAMA Listeners
                google.maps.event.addListener(panorama, 'pov_changed', function () {
                  ng_util.safeApply($scope, function () {
                    vm.geocomplete.coordinates.heading = (Math.round(panorama.getPov().heading * 100) / 100).toFixed(2);
                    vm.geocomplete.coordinates.pitch = (Math.round(panorama.getPov().pitch * 100) / 100).toFixed(2);
                    vm.geocomplete.coordinates.zoom = (Math.round(panorama.getPov().zoom * 100) / 100).toFixed(2);
                  });
                });
                google.maps.event.addListener(panorama, 'position_changed', function () {
                  ng_util.safeApply($scope, function () {
                    vm.geocomplete.coordinates.lat = panorama.getPosition().lat();
                    vm.geocomplete.coordinates.lon = panorama.getPosition().lng();
                  });
                  googleCoords = new google.maps.LatLng(panorama.getPosition().lat(), panorama.getPosition().lng());
                  // MAP
                  map.setCenter(googleCoords);
                  // MARKER
                  marker.setPosition(googleCoords);
                });
                google.maps.event.addListener(map, 'idle', function () {
                  google.maps.event.trigger(map, 'resize');
                  updateMap(googleCoords);
                });
                // Check for street view status
                service = new google.maps.StreetViewService();
              } else {
                updateMap(googleCoords);
              }
              // Check for street view status
              service.getPanoramaByLocation(googleCoords, 50, function (result, status) {
                if (status === 'OK') {
                  if (vm.geocomplete.coordinates && result.links && result.links.length) {
                    vm.geocomplete.coordinates.street = 1;
                  } else {
                    vm.geocomplete.coordinates.street = 0;
                  }
                  // PANORAMA
                  var angle = google.maps.geometry.spherical.computeHeading(result.location.latLng, googleCoords);
                  var panoOptions = {
                      position: googleCoords,
                      addressControl: false,
                      linksControl: false,
                      panControl: false,
                      zoomControlOptions: { style: google.maps.ZoomControlStyle.SMALL },
                      pov: {
                        heading: coordinates.heading ? parseFloat(coordinates.heading) : angle,
                        pitch: coordinates.pitch ? parseFloat(coordinates.pitch) : 10,
                        zoom: coordinates.zoom ? parseFloat(coordinates.zoom) : 1
                      },
                      enableCloseButton: false,
                      visible: panorama.getVisible(),
                      scrollwheel: false
                    };
                  panorama.setOptions(panoOptions);
                } else {
                  if (vm.geocomplete.coordinates) {
                    vm.geocomplete.coordinates.street = 0;
                  }
                }
              });
            }
            function updateMap(googleCoords) {
              // MAP
              map.setCenter(googleCoords);
              // MARKER
              marker.setPosition(googleCoords);
            }
            function stopInterval() {
              if (angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
              }
            }
          }
        }
      ]
    };
  var customGeoWithParams = {
      bindings: {
        model: '=',
        required: '@',
        disabled: '<',
        geocomplete: '<',
        label: '@',
        name: '@',
        params: '<'
      },
      templateUrl: '/app/modules/shared/components/global/input-geo.html',
      controller: [
        '$scope',
        '$rootScope',
        '$element',
        '$timeout',
        function ($scope, $rootScope, $element, $timeout) {
          'ngInject';
          var vm = this;
          vm.$postLink = function () {
            postCompile($element);
          };
          errorController($rootScope, vm);
          $timeout(function () {
            var place = {}, $input = $element.find('input');
            var options = {
                types: [],
                componentRestrictions: { country: [] }
              };
            if (vm.params) {
              if (vm.params.cities) {
                options.types = ['(cities)'];
              }
              if (vm.params.country && vm.params.country.length > 0) {
                options.componentRestrictions = { country: [vm.params.country] };
              }
            }
            // Start binding GeoComplete
            $input.geocomplete(options).bind('geocode:result', function (event, result) {
              place.address = '';
              _.forEach(result.address_components, function (value) {
                if (value.types[0] === 'locality') {
                  place['city'] = value.long_name;
                }
                if (value.types[0] === 'administrative_area_level_1') {
                  place['state'] = value.short_name;
                }
                if (value.types[0] === 'country') {
                  place['country'] = value.short_name;
                }
              });
              place.address = result.formatted_address;
              // Extend scope
              angular.extend(vm.geocomplete, place);
              angular.element(this).blur();
            });
          }, 0);
        }
      ]
    };
  function preCompile(auth, element, attrs) {
    var input = element.find('textarea, input, select, .uiSelect')[0], elementInput = element.find('.elementInput'), elementInputInner = element.find('.elementInput .combined'), measurement = auth.measurement ? Lang.get('general.measurement_length.' + (auth.measurement || 'feet')) : 'in', commonAttr = {
        type: true,
        title: true,
        name: false,
        model: false,
        required: false,
        disabled: false,
        label: false,
        options: false,
        rows: true,
        cols: true,
        min: false,
        max: false,
        mask: true,
        maxlength: true,
        maximum: true,
        autofocus: true,
        placeholder: true,
        pattern: true,
        checked: true,
        attr: false,
        size: true,
        autocomplete: true,
        icondatepicker: false,
        iconlength: false,
        iconpercent: false,
        iconrange: false
      };
    // commonAttr - if only remove attribute, then 'attr': false
    // commonAttr - if remove and set to input attribute, then 'attr': true
    _.forEach(attrs, function (attr, index) {
      var findAttr = _.propertyOf(commonAttr)(index);
      if (!_.isUndefined(input) && !_.isUndefined(findAttr) && findAttr) {
        input.setAttribute(index, attr);
      }
      if (index !== 'class') {
        element[0].removeAttribute(index);
      }
    });
    if (attrs.min !== undefined) {
      input.setAttribute('min', parseInt(attrs.min));
      input.setAttribute('step', '0.01');
    }
    if (attrs.max !== undefined) {
      input.setAttribute('max', parseFloat(attrs.max));
    }
    //custom icons
    if (attrs.icondatepicker) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon btn-calendar"><i class="icon-line icon-line-annual3"></i></span>');
    }
    if (attrs.iconrange) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon btn-calendar"><i class="icon-line icon-line-calendar-range"></i></span>');
    }
    if (attrs.iconlength) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><span>' + measurement + '</span></span>');
    }
    if (attrs.iconpercent) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><i class="icon-line icon-line-percent"></i></span>');
    }
    if (attrs.iconfacebook) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><i class="icon-line icon-line-facebook-1"></i></span>');
    }
    if (attrs.icontwitter) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><i class="icon-line icon-line-twitter-1"></i></span>');
    }
    if (attrs.icongoogle) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><i class="icon-line icon-line-gplus"></i></span>');
    }
    if (attrs.iconlinkedin) {
      elementInput.addClass('input-group');
      elementInputInner.after('<span class="input-group-addon"><i class="icon-line icon-line-linkedin-1"></i></span>');
    }
  }
  function postCompile(element) {
    var controlInput = element.find('.form-control'), controlTextarea = element.find('.form-control--textarea'), elementInput = angular.element('.elementInput');
    angular.element(elementInput).on('click', function () {
      angular.element(this).find(':input').focus();
      angular.element(this).find('.form-control--textarea').focus();
    });
    // textarea bind focus, blur
    controlTextarea.bind('focus', function () {
      bindFocus.call(this);
    });
    controlTextarea.bind('blur', function () {
      bindBlur.call(this);
    });
    // input bind focus, blur
    controlInput.bind('focus', function () {
      bindFocus.call(this);
    });
    controlInput.bind('blur', function () {
      bindBlur.call(this);
    });
    function bindFocus() {
      angular.element(this).parents('.elementInput').addClass('focused');
    }
    function bindBlur() {
      angular.element(this).parents('.elementInput').removeClass('focused');
      if (angular.element(this).val()) {
        angular.element(this).closest('.elementInput').find('label').addClass('fade');
      } else {
        angular.element(this).closest('.elementInput').find('label').removeClass('fade');
      }
    }
  }
  function errorController($rootScope, vm) {
    $rootScope.$on('form:error', function (event, res) {
      if (res && res[vm.name]) {
        vm.errors = res[vm.name];
      } else {
        vm.errors = null;
      }
    });
  }
  function asyncRequest(HttpService, url, params, $q) {
    var deferred = $q.defer();
    HttpService.getWParams(url, { 'params': params }, function (resp) {
      deferred.resolve({ data: resp });
    }, function () {
      deferred.reject();
    });
    return deferred.promise;
  }
  angular.module('app.components.form', []).component('age', age).component('customUiSelect', customUiSelect).component('customUiSelectMultiple', customUiSelectMultiple).component('customUiSelectDetails', customUiSelectDetails).component('customPriceSelect', customPriceSelect).component('customSwitchery', customSwitchery).component('customInput', customInput).component('customInputDomain', customInputDomain).component('customDatepickerInput', customDatepickerInput).component('customMaskPhone', customMaskPhone).component('customTextarea', customTextarea).component('customCheckbox', customCheckbox).component('customCheckboxMultiple', customCheckboxMultiple).component('customRadio', customRadio).component('customRadioType', customRadioType).component('customSelect', customSelect).component('textEditor', textEditor).component('ckEditor', ckEditor).component('customGeo', customGeo).component('customGeoWithParams', customGeoWithParams).component('customUiSelectSymbol', customUiSelectSymbol);
}());