(function () {
  'use strict';
  CurrencyService.$inject = ['APP_CONFIG'];
  angular.module('app.services.currency', []).service('CurrencyService', CurrencyService);
  ;
  function CurrencyService(APP_CONFIG) {
    // noinspection UnnecessaryLocalVariableJS
    var service = {
        getCurrencies: getCurrencies,
        getCurrencyName: getCurrencyName,
        getSymbol: getSymbol
      };
    /**
		 * Get Currencies
		 * @returns {*}
		 */
    function getCurrencies() {
      return APP_CONFIG.currencies;
    }
    /**
		 * Get Currency Name
		 * @param currency
		 * @returns {string}
		 */
    function getCurrencyName(currency) {
      var currencyObj = _.find(APP_CONFIG.currencies, { 'iso_code': currency });
      return currencyObj ? '<b>' + currencyObj.symbol + '</b>' + ' ' + currencyObj.name : '-';
    }
    /**
		 * Get Symbol
		 * @returns {*}
		 */
    function getSymbol() {
      return _.reduce(APP_CONFIG.currencies, function (acc, item) {
        acc[item.iso_code] = item.symbol;
        return acc;
      }, {
        'null': '$',
        '0': '$',
        '1': '$'
      });
    }
    return service;
  }
}());