(function () {
  'use strict';
  var authContentComponent = {
      templateUrl: '/app/modules/auth/body.html',
      controller: [
        '$state',
        function ($state) {
          'ngInject';
          var vm = this;
          vm.state = $state;
          vm.isGetParams = isGetParams;
          /**
			 * Check is params application apply or invite
			 * @returns {*}
			 */
          function isGetParams() {
            return vm.state.params.application_apply || vm.state.params.invite;
          }
        }
      ]
    };
  var authHeaderComponent = {
      templateUrl: '/app/modules/auth/header.html',
      controller: [
        '$filter',
        'CordovaService',
        function ($filter, CordovaService) {
          'ngInject';
          var vm = this;
          vm.isCordovaApp = CordovaService.isCordova();
          vm.promoSiteUrl = $filter('promo_site_url')('/');
        }
      ]
    };
  angular.module('app.auth.components', []).component('authContentComponent', authContentComponent).component('authHeaderComponent', authHeaderComponent);
  ;
}());