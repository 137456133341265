(function () {
  'use strict';
  /**
	 * @namespace dwollaBankAccountComponent
	 * @desc Bank account & balance
	 * @param {Object} customer - Customer information
	 * @param {Object} sources
	 * @param {Object} recurring
	 */
  var dwollaBankAccountComponent = {
      bindings: {
        customer: '<',
        sources: '<',
        balance: '<'
      },
      templateUrl: '/app/modules/shared/pages/dwolla/dwollaBankAccount/view.html',
      controller: [
        '$element',
        'DwollaService',
        'BreadCrumbsService',
        function ($element, DwollaService, BreadCrumbsService) {
          'ngInject';
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.main.label'), 'settings.main');
          BreadCrumbsService.addCrumb(Lang.get('settings.nav.payments_view.label'), 'settings.payments_view');
          BreadCrumbsService.addCrumb(Lang.get('dwolla.tabs.bank_account'));
          var vm = this;
          vm.$onInit = function () {
            DwollaService.clearCache();
            vm.customer = DwollaService.prepareCustomer(vm.customer);
          };
          vm.$postLink = function () {
            $element.addClass('u-flex--flex');
          };
          vm.$onDestroy = function () {
            DwollaService.destroyHandlers();
          };
        }
      ]
    };
  angular.module('app.dwollaBankAccount.component', []).component('dwollaBankAccountComponent', dwollaBankAccountComponent);
  ;
}());