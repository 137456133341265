(function () {
  'use strict';
  var filterPanelShowButton = {
      bindings: {},
      template: '<a class="m-btn m-btn--filter m-l-10" href ng-click="$ctrl.showFilterPanel();"><span>{{:: \'general.filter\' | trans }}</span></a>',
      controller: [
        'FilterService',
        function (FilterService) {
          'ngInject';
          var vm = this;
          vm.showFilterPanel = function () {
            FilterService.display();
          };
        }
      ]
    };
  var filterPanel = {
      bindings: {},
      templateUrl: '/app/modules/shared/components/filter/filter-panel.html',
      transclude: true,
      controller: [
        '$scope',
        '$element',
        '$location',
        '$q',
        'FilterService',
        'PagingFilterService',
        'ng_util',
        'scroll_util',
        function ($scope, $element, $location, $q, FilterService, PagingFilterService, ng_util, scroll_util) {
          'ngInject';
          var vm = this, promise;
          PagingFilterService.bind($scope);
          // open filter
          ng_util.definePropertyObj(FilterService, 'params', function (params) {
            if (!_.isUndefined(params)) {
              if (params.isShow) {
                $element.addClass('open');
                promise = $q(function (resolve) {
                  resolve();
                });
                promise.then(function () {
                  FilterService.setRequest();
                });
                scroll_util.addStyleScrollBar(true);
              } else {
                scroll_util.addStyleScrollBar(false);
                $element.removeClass('open');
              }
            }
          });
          vm.showFilterMenu = function () {
            FilterService.display();
          };
        }
      ]
    };
  angular.module('app.components.filter', []).component('filterPanelShowButton', filterPanelShowButton).component('filterPanel', filterPanel);
  ;
}());