(function () {
  'use strict';
  angular.module('app.utils', []).factory('array_util', array_util).factory('string_util', string_util).factory('ng_util', ng_util).factory('image_util', image_util).factory('scroll_util', scroll_util);
  ;
  function array_util() {
    return {
      partition: partition,
      uniqueItems: uniqueItems,
      chunk: chunk,
      split: split,
      sortObject: sortObject,
      convertObjectKeyToString: convertObjectKeyToString,
      customSortPosition: customSortPosition,
      getByKeyNested: getByKeyNested
    };
    /**
		 * Divide into parts
		 * @param items
		 * @param size
		 * @returns {Array}
		 */
    function partition(items, size) {
      var i = 0, result = _.groupBy(items, function () {
          i++;
          return Math.floor(i / size);
        });
      return _.values(result);
    }
    /**
		 * Return array of unique values from collections
		 * @param data
		 * @param key
		 * @param sort
		 * @returns {Array}
		 */
    function uniqueItems(data, key, sort) {
      var result = [];
      for (var i = 0; i < data.length; i++) {
        var value = data[i][key];
        if (result.indexOf(value) === -1) {
          result.push(value);
        }
      }
      if (sort) {
        result = _.sortBy(result, function (val) {
          return val;
        });
      }
      return result;
    }
    /**
		 * Get {start} nested arrays each containing maximum of {amount} items
		 * @param arr
		 * @param start
		 * @param amount
		 * @returns {Array}
		 */
    function chunk(arr, start, amount) {
      var result = [], len = arr.length;
      start = start || 0;
      amount = amount || 500;
      do {
        result.push(arr.slice(start, start + amount));
        start += amount;
      } while (start < len);
      return result;
    }
    function split(a, n) {
      var len = a.length, out = [], i = 0;
      while (i < len) {
        var size = Math.ceil((len - i) / n--);
        out.push(a.slice(i, i += size));
      }
      return out;
    }
    function convertObjectKeyToString(obj) {
      var arr = {};
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          arr[prop.toString()] = obj[prop];
        }
      }
      return arr;
    }
    function customSortPosition(screening_questions, screening_positions) {
      var questions = [];
      var question;
      var index;
      angular.forEach(screening_positions, function (val) {
        question = _.find(screening_questions, { id: val });
        if (_.isObject(question)) {
          questions.push(question);
        }
      });
      angular.forEach(screening_questions, function (val) {
        if (_.isObject(val)) {
          index = _.indexOf(screening_positions, val.id);
          if (index == -1) {
            questions.push(val);
          }
        }
      });
      return questions;
    }
    function sortObject(obj) {
      var arr = [];
      for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          arr.push({
            'key': prop,
            'value': obj[prop]
          });
        }
      }
      arr.sort(function (a, b) {
        return a.value - b.value;
      });
      //arr.sort(function(a, b) { a.value.toLowerCase().localeCompare(b.value.toLowerCase()); }); //use this to sort as strings
      return arr;  // returns array
    }
    function getByKeyNested(obj, keyNested, value) {
      if (_.isString(keyNested)) {
        return getByKeyNested(obj, keyNested.split('.'), value);
      } else if (keyNested.length === 1 && value !== undefined) {
        obj[keyNested[0]] = value;
        return obj;
      } else if (keyNested.length === 0) {
        return obj;
      }
      return getByKeyNested(obj[keyNested[0]], keyNested.slice(1), value);
    }
  }
  function string_util() {
    return {
      split_chars: splitNChars,
      randString: randString,
      base64_decode: base64_decode
    };
    function splitNChars(txt, num) {
      var result = [];
      for (var i = 0; i < txt.length; i += num) {
        result.push(txt.substr(i, num));
      }
      return result;
    }
    function randString(length) {
      var l = length > 25 ? 25 : length;
      var str = Math.random().toString(36).substr(2, l);
      if (str.length >= length) {
        return str;
      }
      return str.concat(this.randString(length - str.length));
    }
    function base64_decode(c) {
      0 <= c.indexOf('=') && (c = c.substr(0, c.indexOf('=')));
      for (var k = 0, d = 0, b, l, e, g, f = 0, a, h, m = ''; k < c.length; ++k) {
        l = '=' == c.charAt(k) ? 0 : 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'.indexOf(c.charAt(k));
        d = (d + 6) % 8;
        if (6 != d) {
          b += l >> d;
          if (0 == f)
            g = !0, h = 0, e = 1, 128 > b && (e = 0, h = b & 64, g = !1);
          else if (128 != (b & 192))
            return !1;
          for (a = 32; g && 0 < a; a >>= 1)
            b & a ? ++e : g = !1;
          g || (a = 6 + 6 * f - e, 6 < a && (a = 6), a && (h += b % (1 << a) << 6 * (e - f)));
          f == e ? (m += String.fromCharCode(h), f = 0) : ++f;
        }
        b = d ? l % (1 << d) << 8 - d : 0;
      }
      return m;
    }
  }
  function ng_util() {
    return {
      safeApply: safeApply,
      definePropertyObj: definePropertyObj
    };
    function definePropertyObj(obj, prop, callback) {
      var propAccessor = '$' + prop;
      Object.defineProperty(obj, propAccessor, {
        get: function () {
          return obj[prop];
        },
        set: function (value) {
          obj[prop] = value;
          if (_.isFunction(callback)) {
            callback(value);
          }
        },
        enumerable: true,
        configurable: true
      });
      obj[propAccessor] = obj[prop];
    }
    function safeApply($scope, fn) {
      if ($scope.$root && $scope.$root.$$phase) {
        var phase = $scope.$root.$$phase;
        if (phase === '$apply' || phase === '$digest') {
          if (fn && _.isFunction(fn)) {
            fn();
          }
        } else {
          $scope.$apply(fn || angular.noop);
        }
      } else {
        $scope.$apply(fn || angular.noop);
      }
    }
  }
  function image_util() {
    return {
      isImage: isImage,
      isTypeImage: isTypeImage
    };
    function isImage(fileExt) {
      return [
        'jpeg',
        'jpg',
        'png'
      ].indexOf(fileExt.toLowerCase()) !== -1;
    }
    function isTypeImage(fileType) {
      return [
        'image/jpeg',
        'image/jpg',
        'image/png'
      ].indexOf(fileType.toLowerCase()) !== -1;
    }
  }
  function scroll_util() {
    return {
      getScrollBar: getScrollBar,
      addStyleScrollBar: addStyleScrollBar
    };
    function getScrollBar() {
      var $c = $('<div style=\'position:absolute; top:-10000px; left:-10000px; width:100px; height:100px; overflow:scroll;\'></div>').appendTo('body');
      var dim = {
          width: $c.width() - $c[0].clientWidth,
          height: $c.height() - $c[0].clientHeight
        };
      $c.remove();
      return dim;
    }
    function addStyleScrollBar(show) {
      var scrollBar = this.getScrollBar();
      if (show) {
        angular.element('body').addClass('panel__scrollbar');
        angular.element('.l-container').css('padding-right', scrollBar.width + 'px');
      } else {
        angular.element('body').removeClass('panel__scrollbar');
        angular.element('.l-container').css('padding-right', '0px');
      }
    }
  }
}());