(function () {
  'use strict';
  configure.$inject = ['$stateProvider'];
  angular.module('app.dwolla.states', [
    'app.dwolla.services',
    'app.dwollaLayout.component',
    'app.dwollaBankAccount.component',
    'app.dwollaSettings.component',
    'app.dwollaTransfers.component',
    'app.dwollaCustomerCharge.component',
    'app.dwolla.components'
  ]).config(configure);
  ;
  function configure($stateProvider) {
    $stateProvider.state('payments', {
      abstract: true,
      url: '/settings',
      views: {
        '': {
          component: 'dwollaLayoutComponent',
          bindings: { customer: 'customer.data' }
        },
        'body@payments': { templateUrl: '/app/modules/shared/pages/dwolla/dwollaLayout/body.html' }
      },
      resolve: {
        customer: [
          'HttpService',
          function (HttpService) {
            return HttpService.getResolve('/dwolla/customer', 'settings.payments_view');
          }
        ]
      }
    }).state('payments.dwolla_customer_charge', {
      url: '/payments/tcpayments/charge',
      views: { content: { component: 'dwollaCustomerChargeComponent' } },
      resolve: {
        checkAccess: [
          '$rootScope',
          '$q',
          '$timeout',
          '$state',
          'DwollaPermissionsService',
          function ($rootScope, $q, $timeout, $state, DwollaPermissionsService) {
            var deferred = $q.defer();
            if (!$rootScope.auth._isTenant()) {
              $timeout(function () {
                $state.go('settings.payments_view');
              });
              deferred.reject();
            } else if (!DwollaPermissionsService.requireTenantCharge()) {
              $timeout(function () {
                $state.go('payments.dwolla_customer');
              });
              deferred.reject();
            } else {
              deferred.resolve();
            }
            return deferred.promise;
          }
        ]
      }
    }).state('payments.dwolla_customer', {
      url: '/payments/tcpayments/customer?action?plaid_enabled',
      views: {
        content: {
          component: 'dwollaBankAccountComponent',
          bindings: {
            customer: 'customer.data',
            sources: 'sources.data',
            balance: 'balance.data'
          }
        }
      },
      resolve: {
        checkAccess: [
          '$rootScope',
          '$q',
          '$timeout',
          '$state',
          'DwollaPermissionsService',
          function ($rootScope, $q, $timeout, $state, DwollaPermissionsService) {
            var deferred = $q.defer();
            if (DwollaPermissionsService.requireVerification()) {
              $timeout(function () {
                $state.go('settings.verification_main');
              });
              deferred.reject();
            } else if (DwollaPermissionsService.requireSubscription()) {
              $timeout(function () {
                $state.go('subscription.packages', { redirect: 'tcpayments' });
              });
              deferred.reject();
            } else if (DwollaPermissionsService.requireTenantCharge()) {
              $timeout(function () {
                $state.go('payments.dwolla_customer_charge');
              });
            } else {
              deferred.resolve();
            }
            return deferred.promise;
          }
        ],
        sources: [
          'HttpService',
          'DwollaPermissionsService',
          function (HttpService, DwollaPermissionsService) {
            if (!DwollaPermissionsService.hasActiveCustomer()) {
              return { data: [] };
            }
            return HttpService.get('/dwolla/funding_sources');
          }
        ],
        balance: [
          'HttpService',
          'DwollaPermissionsService',
          function (HttpService, DwollaPermissionsService) {
            if (!DwollaPermissionsService.hasActiveCustomer()) {
              return { data: [] };
            }
            return HttpService.get('/dwolla/funding_sources/balance');
          }
        ]
      }
    }).state('payments.dwolla_settings', {
      url: '/payments/tcpayments/settings',
      views: {
        content: {
          component: 'dwollaSettingsComponent',
          bindings: {
            customer: 'customer.data',
            sources: 'sources.data',
            recurring: 'recurring.data'
          }
        }
      },
      resolve: {
        sources: [
          '$rootScope',
          'HttpService',
          function ($rootScope, HttpService) {
            if ($rootScope.auth._isTenant()) {
              return { data: [] };
            }
            return HttpService.get('/dwolla/funding_sources');
          }
        ],
        recurring: [
          '$rootScope',
          'HttpService',
          function ($rootScope, HttpService) {
            if ($rootScope.auth._isTenant()) {
              return { data: [] };
            }
            return HttpService.get('/dwolla/recurring');
          }
        ]
      }
    }).state('payments.dwolla_transfers', {
      url: '/payments/tcpayments/transfers',
      views: {
        content: {
          component: 'dwollaTransfersComponent',
          bindings: { transfers: 'transfers.data' }
        }
      },
      resolve: {
        transfers: [
          '$httpParamSerializer',
          'HttpService',
          function ($httpParamSerializer, HttpService) {
            return HttpService.getWParams('/dwolla/transfers', {
              params: {
                limit: 25,
                offset: 0
              }
            });
          }
        ]
      }
    });
  }
}());